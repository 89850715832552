import React from 'react'
import {
  TextLink,
  GridItem,
  Row,
  Grid,
  ShadowBox,
  Accordion,
  Column,
  Text,
  Header,
  SelectList,
} from '@mattilsynet/mt-ui'

interface ISettingsViewProps {
  goBack: () => void
  openAccordions
  toggleOpenAccordions: (id: string) => () => void
  onUpdateLanguage: (language: string) => void
  currentLanguage: string
}

export const SettingsView = ({
  goBack,
  openAccordions,
  toggleOpenAccordions,
  onUpdateLanguage,
  currentLanguage,
}: ISettingsViewProps) => {
  const languageOptions = [
    {
      label: 'Bokmål',
      value: 'nb',
    },
    {
      label: 'Nynorsk',
      value: 'nn',
    },
  ]

  return (
    <Grid id="settings">
      <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
        <Row>
          <TextLink leftArrow onClick={goBack}>
            Tilbake
          </TextLink>
        </Row>
      </GridItem>
      <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
        <Column padding={[3, 2]} spacing={3}>
          <Header as="h1" size="heading2">
            Personlige innstillinger
          </Header>
          <ShadowBox>
            <Accordion
              onClick={toggleOpenAccordions('maal')}
              open={openAccordions.includes('maal')}
              title="Velg målform"
              paddingChildren={[2, 1, 2, 1]}
            >
              <Text size="normal" weight="regular" margin={[0, 0, 2, 0]}>
                Dokumentet blir opprettet med den målformen du velger, mens i
                selve løsningen er det kun bokmål.
              </Text>
              <SelectList
                dataList={languageOptions}
                onClick={(selectedValue) => onUpdateLanguage(selectedValue)}
                selected={[currentLanguage]}
              />
            </Accordion>
          </ShadowBox>
        </Column>
      </GridItem>
    </Grid>
  )
}
