import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'
import HomeRoute from './home'
import ForberedNewTilsyn from './forbered-new-tilsyn'
import StartTilsyn from './start-tilsyn'
import FerdistillingRoute from './ferdigstilling'
import SammenstillingRoute from './sammenstilling'
import Settings from './settings'
import About from './about'
import AccessDeniedRoute from './access-denied'
import { Login, Logout, LogoutCallback } from '../components/login'
import NoRoute from './no-route'
import React from 'react'
import { Private } from './private'
import { Layout } from './layout'

export { router }

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/" element={<Private element={<HomeRoute />} />} />
      <Route
        path="/forbered-new-tilsyn"
        element={<Private element={<ForberedNewTilsyn />} />}
      />
      <Route
        path="/start-tilsyn/:id"
        element={<Private element={<StartTilsyn />} />}
      />
      <Route
        path="/ferdigstilling/:id"
        element={<Private element={<FerdistillingRoute />} />}
      />
      <Route
        path="/sammenstilling/:id"
        element={<Private element={<SammenstillingRoute />} />}
      />
      <Route path="/settings" element={<Private element={<Settings />} />} />
      <Route path="/about" element={<Private element={<About />} />} />

      <Route path="/access-denied" element={<AccessDeniedRoute />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/callback" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/logout/callback" element={<LogoutCallback />} />

      <Route path="*" element={<Private element={<NoRoute />} />} />
    </Route>
  )
)
