import { Column, Text } from '@mattilsynet/mt-ui'
import React, { useCallback } from 'react'
import { ModalButton } from '../../components/modal-button'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { modalContentPaddingLarge } from '../../components/modal-buttons-container/constants'
import { ModalWrapper } from '../../components/modal-wrapper'

interface IDeleteDialogModal {
  isOpen: boolean
  entityId: string
  entity: string
  onCancel: () => void
  onDelete: (imageId: string) => void
}

export const DeleteDialogModal = ({
  isOpen,
  entityId,
  entity,
  onCancel,
  onDelete,
}: IDeleteDialogModal) => {
  const onDeleteImage = useCallback(
    () => onDelete(entityId),
    [entityId, onDelete]
  )

  const modalText = `Er du sikker på at du vil slette ${entity}?`

  return (
    <ModalWrapper isOpen={isOpen} onCancel={onCancel}>
      <Column padding={modalContentPaddingLarge}>
        <Text textAlign="center" weight="bold">
          {modalText}
        </Text>
      </Column>
      <ModalButtonsContainer>
        <ModalButton secondary onClick={onCancel}>
          Avbryt
        </ModalButton>
        <ModalButton onClick={onDeleteImage}>Ja, slett bildet</ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>
  )
}
