// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kontrollpunkt-item__icon--delete {
  fill: var(--error-icon-color);
}

.kontrollpunkter-section .button-add .Button {
  max-width: 16em;
}

.kontrollpunkter-section__contrastbox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/features/kontrollpunkter/components/style.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".kontrollpunkt-item__icon--delete {\n  fill: var(--error-icon-color);\n}\n\n.kontrollpunkter-section .button-add .Button {\n  max-width: 16em;\n}\n\n.kontrollpunkter-section__contrastbox {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
