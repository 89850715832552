// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-description-wrapper .ImageDescription {
  position: relative;
}

.image-description-wrapper .image-top-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffffcc;
  padding: 1em 0.5em;
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/image-description-wrapper/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,2BAA2B;EAC3B,kBAAkB;EAClB,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".image-description-wrapper .ImageDescription {\n  position: relative;\n}\n\n.image-description-wrapper .image-top-info {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  background-color: #ffffffcc;\n  padding: 1em 0.5em;\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
