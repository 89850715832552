// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#about .status-ok {
  color: green;
  font-weight: bold;
}

#about .status-fail {
  color: darkred;
  font-weight: bold;
}

#about h1 {
  font-size: 1.25em;
}

#about h2 {
  font-size: 1.125em;
}

#about .TextLink {
  display: inline-flex;
}

#about .space {
  margin-right: 0.25em;
}
`, "",{"version":3,"sources":["webpack://./src/routes/about/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["#about .status-ok {\n  color: green;\n  font-weight: bold;\n}\n\n#about .status-fail {\n  color: darkred;\n  font-weight: bold;\n}\n\n#about h1 {\n  font-size: 1.25em;\n}\n\n#about h2 {\n  font-size: 1.125em;\n}\n\n#about .TextLink {\n  display: inline-flex;\n}\n\n#about .space {\n  margin-right: 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
