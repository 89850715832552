import { queryApi } from '../../../api'
import { IKontrollpunkt, IKontrollpunktMutateVars } from '../types'
import { ENDPOINT } from '../../../constants'
import { createQueryStringFromObject } from '../../../common/createQueryStringFromObject'
import { IQueryApiResponse, Prettify } from '../../../common/types'

type PatchArgs = Prettify<{
  kontrollpunkt: IKontrollpunkt
  data: Partial<IKontrollpunkt>
}>

export const kontrollpunktApi = {
  getByTilsynsobjektId: async (tilsynsobjektId: number) => {
    const queries = createQueryStringFromObject('?')({
      'filter.tilsynsobjektId': tilsynsobjektId,
    })

    return queryApi.get<
      IQueryApiResponse<{ kontrollpunkter: IKontrollpunkt[] }>
    >(`${ENDPOINT.TILSYNSKVITTERING}/kontrollpunkter${queries}`)
  },
  post: async ({ kontrollpunkt }: IKontrollpunktMutateVars) => {
    return queryApi.post(`${ENDPOINT.TILSYNSKVITTERING}/kontrollpunkter`, {
      tilsynsobjektId: kontrollpunkt.tilsynsobjektId,
      beskrivelse: kontrollpunkt.beskrivelse,
      sorteringsrekkefoelge: kontrollpunkt.sorteringsrekkefoelge,
    })
  },
  put: async ({ kontrollpunkt }: IKontrollpunktMutateVars) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/kontrollpunkter/${kontrollpunkt.id}`

    return queryApi.put(url, {
      ...kontrollpunkt,
      observasjoner: [],
    })
  },
  delete: async ({ kontrollpunkt }: IKontrollpunktMutateVars) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/kontrollpunkter/${kontrollpunkt.id}`

    return queryApi.delete(url)
  },
  patch: async ({ kontrollpunkt, data }: PatchArgs) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/kontrollpunkter/${kontrollpunkt.id}`

    return queryApi.patch(url, data)
  },
}
