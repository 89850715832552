import { IKvitteringData } from '../../ducks/kvittering/types'
import { ISakState } from '../../ducks/sak/types'
import { ITilsynsobjekt } from '../../ducks/kvittering/tilsynsobjekt/types'
import { IVeiledning } from '../../ducks/kvittering/veiledning/types'
import { isUuid } from '../../common/string-utils'
import { isKvitteringSyncing } from '../../common/query'
import { isBilderValid } from '../../features/bilder/queries/helpers'
import { deltakerQueryHelpers } from '../../features/deltakere/queries/helpers'
import { ENDPOINT } from '../../constants'
import { IKontrollpunkt } from '../../features/kontrollpunkter/types'

export const isKontrollpunktValid = (currentKvittering: IKvitteringData) =>
  currentKvittering?.tilsynsobjekter
    .map((tilsynsobjekt: ITilsynsobjekt) => tilsynsobjekt?.kontrollpunkter)
    .map(
      (kontrollpunkter: IKontrollpunkt[]) =>
        kontrollpunkter.length > 0 &&
        kontrollpunkter
          .map(
            (kontrollpunkt: IKontrollpunkt) => kontrollpunkt.beskrivelse !== ''
          )
          .every((value) => value)
    )
    .every((value) => value)

export const isObservasjonValid = (currentKvittering: IKvitteringData) => {
  const tilsynsobjekter = currentKvittering?.tilsynsobjekter
  const kontrollpunkter = tilsynsobjekter.flatMap(
    (tilsynsobjekt) => tilsynsobjekt?.kontrollpunkter
  )
  const observasjoner = kontrollpunkter.flatMap(
    (kontrollpunk: IKontrollpunkt) =>
      kontrollpunk?.observasjoner.length > 0
        ? kontrollpunk.observasjoner
        : { tekst: '' }
  )
  return observasjoner.every((obs) => obs?.tekst !== '')
}

export const isValidVirksomhetTilStede = (currentKvittering: IKvitteringData) =>
  (!!currentKvittering.ingenTilStede &&
    !!currentKvittering.ingenTilStedeBegrunnelse?.length) ||
  (currentKvittering['virksomhetsDeltagere'] || []).some(
    (deltager) => !!deltager.navn.trim()
  )

export const isValidMattilsynetSkjermet = (
  currentKvittering: IKvitteringData
) =>
  (!!currentKvittering.mattilsynetSkjermet &&
    !!currentKvittering.mattilsynetSkjermetBegrunnelse) ||
  !currentKvittering.mattilsynetSkjermet

export const isValidfaktaarkUtlevert = (currentKvittering: IKvitteringData) =>
  currentKvittering.faktaarkUtlevert !== null &&
  currentKvittering.faktaarkUtlevert !== undefined

export const isValidBilderGjennomgaatt = (currentKvittering) =>
  currentKvittering.bilderGjennomgaatt !== null &&
  currentKvittering.bilderGjennomgaatt !== undefined

export const isValidBilderGjennomgaattBegrunnelse = (
  currentKvittering: IKvitteringData
) =>
  Boolean(
    (currentKvittering.bilderGjennomgaattBegrunnelse &&
      !isUuid(currentKvittering.bilderGjennomgaattBegrunnelse)) ||
      currentKvittering.bilderGjennomgaatt
  )

export const isValidRegistrertInnholdGjennomgaatt = (
  currentKvittering: IKvitteringData
) =>
  currentKvittering.registrertInnholdGjennomgaatt !== null &&
  currentKvittering.registrertInnholdGjennomgaatt !== undefined

export const isValidRegistrertInnholdGjennomgaattBegrunnelse = (
  currentKvittering
) =>
  Boolean(
    (currentKvittering.registrertInnholdGjennomgaattBegrunnelse &&
      !isUuid(currentKvittering.registrertInnholdGjennomgaattBegrunnelse)) ||
      currentKvittering.registrertInnholdGjennomgaatt
  )

export const isValidSak = (
  currentKvittering: IKvitteringData,
  sak: ISakState
) => {
  return (
    currentKvittering.noarksakAar &&
    currentKvittering.noarksakSekvensnummer &&
    !!sak.sakNumber &&
    !sak.sakFail &&
    !sak.sakNotFound &&
    !sak.sakClosed
  )
}

export const isVeiledning = (currentKvittering: IKvitteringData) =>
  currentKvittering?.tilsynsobjekter &&
  currentKvittering?.tilsynsobjekter
    .map((tilsynsobjekt: ITilsynsobjekt) => tilsynsobjekt?.veiledninger)
    .map(
      (veiledninger = []) =>
        veiledninger?.length > 0 &&
        veiledninger
          .map((veiledning: IVeiledning) => veiledning.tekst !== '')
          .some((value) => value)
    )
    .some((value) => value)

export const isValidTypeTilsyn = (currentKvittering: IKvitteringData) => {
  return currentKvittering.typeTilsyn === 'ANNET'
    ? !!currentKvittering.typeTilsynBeskrivelse &&
        currentKvittering.typeTilsynBeskrivelse.trim().length > 0
    : !!currentKvittering.typeTilsyn
}

export const checkValidation = (
  kvittering: IKvitteringData,
  onSuccess: () => void,
  onFail: (value: string) => void
) => {
  // Spreading kvittering as it is immutable.
  // Can remove when deltagere is part of kvittering in tanstack.
  const currentKvittering = { ...kvittering } as IKvitteringData

  if (isKvitteringSyncing(currentKvittering.id ?? '')) {
    return onFail(
      'Du må vente på at Tilsynskvitteringen er ferdiglastet opp i sky før du kan sende den ut.'
    )
  }

  const tempVirksomhetsDeltagere = deltakerQueryHelpers.getVirksomhetDeltakere(
    currentKvittering.id ?? '',
    `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${currentKvittering.id}/virksomhet-deltagere`
  )
  const tempMattilsynetDeltagere = deltakerQueryHelpers.getMattilsynetDeltakere(
    currentKvittering.id ?? '',
    `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${currentKvittering.id}/mattilsynet-deltagere`
  )
  const tempAndreDeltagere = deltakerQueryHelpers.getAndreDeltakere(
    currentKvittering.id ?? '',
    `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${currentKvittering.id}/andre-deltagere`
  )

  currentKvittering.virksomhetsDeltagere =
    tempVirksomhetsDeltagere ?? currentKvittering.virksomhetsDeltagere
  currentKvittering.mattilsynetDeltagere =
    tempMattilsynetDeltagere ?? currentKvittering.mattilsynetDeltagere
  currentKvittering.andreDeltagere =
    tempAndreDeltagere ?? currentKvittering.andreDeltagere

  if (
    isKontrollpunktValid(currentKvittering) &&
    isObservasjonValid(currentKvittering) &&
    isBilderValid(currentKvittering.id ?? '') &&
    isValidVirksomhetTilStede(currentKvittering) &&
    isValidMattilsynetSkjermet(currentKvittering) &&
    isValidfaktaarkUtlevert(currentKvittering) &&
    isValidBilderGjennomgaatt(currentKvittering) &&
    isValidBilderGjennomgaattBegrunnelse(currentKvittering) &&
    isValidRegistrertInnholdGjennomgaatt(currentKvittering) &&
    isValidRegistrertInnholdGjennomgaattBegrunnelse(currentKvittering) &&
    isValidTypeTilsyn(currentKvittering)
  ) {
    return onSuccess()
  }

  const errorText =
    'Utfyllingen din har mangler. Fyll ut det som mangler før du kan gå videre.'

  return onFail(errorText)
}
