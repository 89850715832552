import React from 'react'
import {
  Column,
  Row,
  RowItem,
  InputField,
  Button,
  LoadingDots,
} from '@mattilsynet/mt-ui'
import './style.css'

interface ISakSearchProps {
  onAddSak: () => void
  onChange: (e: any) => void
  sakNumberValue: string
  errorText: string
  disabled: boolean
}

const SakSearch = ({
  onAddSak,
  onChange,
  sakNumberValue,
  errorText,
  disabled,
}: ISakSearchProps) => {
  //TODO: This will eventually be removed when we apply the latest UI for Saksnummer
  const onSubmitSearchSak = (e) => {
    if (e.key === 'Enter' && sakNumberValue.length > 0) {
      e.currentTarget.blur()
      onAddSak()
    }
  }

  return (
    <Row id="sak-search">
      <Column fluid spacing={1}>
        <Row align="center">
          <RowItem>
            <InputField
              className="inputField"
              value={sakNumberValue}
              label="Saksnummer"
              onChange={onChange}
              pattern="[0-9]*"
              placeholder="Feks. 2018/342343"
              inputMode="decimal"
              status={Boolean(errorText) ? 'danger' : undefined}
              errorText={errorText}
              onKeyDown={onSubmitSearchSak}
            />
          </RowItem>
          <RowItem alignSelf="flex-end" className="legg-til-sak-button">
            <Button
              secondary
              onClick={onAddSak}
              disabled={disabled}
              iconAfter={disabled}
              icon={disabled ? <LoadingDots /> : undefined}
            >
              {disabled ? `Kvittering blir opprettet` : 'Legg til sak'}
            </Button>
          </RowItem>
        </Row>
      </Column>
    </Row>
  )
}

export default SakSearch
