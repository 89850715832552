// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.observasjon__rowitem textarea {
    max-height: 60vh;
}

.observasjon__rowitem>.Textarea>.LabelWrapper>textarea {
    overflow-y: auto;
}

@media screen and (max-width: 768px) {
    .observasjon__rowitem textarea {
        max-height: 40vh;
    }
}`, "",{"version":3,"sources":["webpack://./src/features/observasjoner/components/style.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".observasjon__rowitem textarea {\n    max-height: 60vh;\n}\n\n.observasjon__rowitem>.Textarea>.LabelWrapper>textarea {\n    overflow-y: auto;\n}\n\n@media screen and (max-width: 768px) {\n    .observasjon__rowitem textarea {\n        max-height: 40vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
