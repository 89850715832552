// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumbnail-list {
  display: grid;
  grid: auto-flow min-content / repeat(auto-fill, minmax(2.5em, 1fr));
  grid-gap: 1em;
}

.thumbnail-container {
  position: relative;
}

.thumbnail-container .rounded-indicator {
  position: absolute;
  right: 3%;
  top: 10%;
}

.thumbnail-container .remove-icon-button {
  position: absolute;
  top: -0.85em;
  right: -0.85em;
  padding: 0.25em;
}
`, "",{"version":3,"sources":["webpack://./src/components/image-thumbnail-wrapper/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mEAAmE;EACnE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,eAAe;AACjB","sourcesContent":[".thumbnail-list {\n  display: grid;\n  grid: auto-flow min-content / repeat(auto-fill, minmax(2.5em, 1fr));\n  grid-gap: 1em;\n}\n\n.thumbnail-container {\n  position: relative;\n}\n\n.thumbnail-container .rounded-indicator {\n  position: absolute;\n  right: 3%;\n  top: 10%;\n}\n\n.thumbnail-container .remove-icon-button {\n  position: absolute;\n  top: -0.85em;\n  right: -0.85em;\n  padding: 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
