import { Mutation, MutationCache, QueryClient } from '@tanstack/react-query'
import { apm } from '@elastic/apm-rum'
import { shouldHandleError, shouldIgnoreError } from '../common/query'
import store from '../reducers/store'
import { kvitteringSelectors } from '../ducks/kvittering/selectors'
import { kontrollpunktKeys } from '../features/kontrollpunkter/queries/helpers'
import {
  andreDeltakereKeys,
  mattilsynetDeltakerKeys,
  virksomhetDeltakerKeys,
} from '../features/deltakere/queries/helpers'
import { veiledningerKeys } from '../features/veiledninger/queries/helpers'
import { kvitteringQueryHelpers } from '../features/kvitteringer/queries/helpers'
import { metadataQueryHelpers } from '../features/bilder/queries/helpers'

/**
 * Invalid when retry is true. Will never be called.
 *
 * Keeping it for the time being. May be deleted.
 */
const onMutationError = (
  error: unknown,
  variables: unknown,
  context: unknown,
  mutation: Mutation<unknown, unknown, unknown, unknown>
) => {
  if (!shouldHandleError(error) || shouldIgnoreError(error)) {
    return
  }

  const errMessage = `${JSON.stringify(mutation.options.mutationKey)}: ${
    (error as Error)?.message
  }`
  const err = new Error(errMessage, {
    cause: error as Error,
  })

  Object.defineProperty(err, 'stack', {
    value: (error as Error).stack,
    writable: true,
    configurable: true,
  })

  apm.captureError(err)
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24 * 4, // 4 days
      staleTime: 1000 * 60 * 60, // 60 minutes
    },
    mutations: {
      gcTime: 1000 * 60 * 60 * 24 * 24, // 24 days
      retry: true, // Retry mutations forever until they succeed
    },
  },
  mutationCache: new MutationCache({
    onError: onMutationError,
  }),
})

const shouldIgnoreMutation = (mutationKey: string, kvitteringId: string) => {
  const ignoreIndex = {
    [JSON.stringify(kontrollpunktKeys.add(kvitteringId)[0])]: true,
    [JSON.stringify(virksomhetDeltakerKeys.add()[0])]: true,
    [JSON.stringify(mattilsynetDeltakerKeys.add()[0])]: true,
    [JSON.stringify(andreDeltakereKeys.add()[0])]: true,
    [JSON.stringify(veiledningerKeys.add(kvitteringId)[0])]: true,
  }

  return ignoreIndex[mutationKey] ?? false
}

queryClient.getMutationCache().subscribe((event) => {
  if (!['added', 'removed', 'updated'].includes(event.type)) {
    return
  }

  const mutations = queryClient.getMutationCache().findAll({
    predicate: (mutation) => {
      const mutationKey = mutation.options.mutationKey?.[0] as any
      const shouldIgnore = shouldIgnoreMutation(
        JSON.stringify(mutationKey),
        mutationKey.kvitteringId
      )

      return (
        mutationKey.kvitteringId !== undefined &&
        !shouldIgnore &&
        ['pending', 'idle'].includes(mutation.state.status)
      )
    },
  })

  const mutationCount = mutations.length

  if (mutationCount !== 0) {
    return
  }

  const state = store.getState()

  const forberedte = kvitteringSelectors.getForberedt(state)
  const paabegynte = kvitteringSelectors.getPaabegynt(state)

  const kvitteringer = [...forberedte, ...paabegynte]

  kvitteringer.forEach((kvittering) => {
    if (!kvittering.id) {
      return
    }

    void kvitteringQueryHelpers.invalidateKvittering(kvittering.id)
    void metadataQueryHelpers.invalidateMetadataList(kvittering.id)
  })
})

export const exportedForTesting = {
  onMutationError,
}
