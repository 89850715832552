import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid,
  GridItem,
  Column,
  Row,
  Button,
  TextLink,
  LoadingSpinner,
  ErrorBox,
} from '@mattilsynet/mt-ui'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { useTypedSelector } from '../../common/custom-hooks'
import { IStoreState } from '../../reducers/types'
import { arkiveringStatusActions } from '../../ducks/arkivering-status/actions'
import './style.css'
import {
  ERROR,
  IKvitteringSavingStatus,
  kvitteringStatusEnum,
  NONE,
  OK,
  SAVING,
} from '../../ducks/kvittering/types'
import { useMediaQuery } from 'react-responsive'
import {
  copySaknummer,
  offlineInFerdigstillingProcess,
  useSingleToast,
} from '../../common/toast'
import { ModalWrapper } from '../../components/modal-wrapper'
import FerdigstillingOkContent from '../../components/ferdigstilling-ok-content'
import { CopyToClipboardButton } from '../../components/copy-to-clipboard'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { ModalButton } from '../../components/modal-button'
import { modalContentPaddingLarge } from '../../components/modal-buttons-container/constants'
import { isFerdigstiltStatus } from '../../utils/kvittering'
import { useParams } from 'react-router'
import { useTKNavigate } from '../../common/navigation'

export const getFerdigstillingRoute = (kvitteringId: string) =>
  `/ferdigstilling/${kvitteringId}`

const FerdistillingRoute = () => {
  const { id: kvitteringId } = useParams()

  const navigate = useTKNavigate()
  const singleToast = useSingleToast()

  const currentKvittering = useTypedSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering
  )

  const loading = useTypedSelector(
    (state: IStoreState) => state.kvittering.loading
  )
  const error = useTypedSelector((state: IStoreState) => state.kvittering.error)

  const arkiveringStatus = useTypedSelector((state) => state.arkiveringStatus)

  const combinedArkiveringStatus = arkiveringStatus.data.reduce(
    (acc, cur) => ({
      antallBilder: acc.antallBilder + cur.antallBilder,
      antallBilderArkivert: acc.antallBilderArkivert + cur.antallBilderArkivert,
      numPending: acc.numPending + (cur.status === 'PENDING' ? 1 : 0),
      numError: acc.numError + (cur.status === 'ERROR' ? 1 : 0),
      numArchived: acc.numArchived + (cur.status === 'ARCHIVED' ? 1 : 0),
    }),
    {
      antallBilder: 0,
      antallBilderArkivert: 0,
      numArchived: 0,
      numPending: 0,
      numError: 0,
    }
  )

  const [hasFetchedKvittering, setHasFetchedKvittering] = useState(false)
  const [hasFetchedArchiveStatus, setHasFetchedArchiveStatus] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)

  const dispatch = useDispatch()
  useEffect(() => {
    if (kvitteringId) {
      setHasFetchedKvittering(true)
      dispatch(kvitteringActions.setCurrentKvitteringFromId(kvitteringId))
      return
    }
    navigate('/')
  }, [dispatch, kvitteringId, navigate])

  const {
    noarksakAar,
    noarksakSekvensnummer,
    hoveddokumentId,
    virksomhetsNavn,
  } = currentKvittering?.data || {}

  const saksnummer = `${noarksakAar}/${noarksakSekvensnummer}`

  const hasHoveddokumentId =
    !!noarksakAar && !!noarksakSekvensnummer && !!hoveddokumentId

  const onCopySaknummer = useCallback(
    () => singleToast.showToast(copySaknummer()),
    [singleToast]
  )

  useEffect(() => {
    if (hasFetchedKvittering && !loading && hasHoveddokumentId) {
      setHasFetchedArchiveStatus(true)
      dispatch(
        arkiveringStatusActions.fetch(
          noarksakAar,
          noarksakSekvensnummer,
          hoveddokumentId
        )
      )
    }
  }, [
    hoveddokumentId,
    noarksakAar,
    noarksakSekvensnummer,
    dispatch,
    hasFetchedKvittering,
    hasHoveddokumentId,
    kvitteringId,
    loading,
  ])

  const onCloseModal = (shouldRefreshdata: boolean) => {
    if (shouldRefreshdata) {
      setHasFetchedArchiveStatus(false)
      dispatch(
        dispatch(kvitteringActions.setCurrentKvitteringFromId(kvitteringId!))
      )
    }
    setIsModalOpen(false)
  }

  const onFerdigStilling = useCallback(() => {
    if (isOffline) {
      return singleToast.showToast(offlineInFerdigstillingProcess())
    }

    dispatch(kvitteringActions.ferdigstillKvittering(kvitteringId))
  }, [dispatch, isOffline, singleToast, kvitteringId])

  const ferdigstillingSavingStatus = isFerdigstiltStatus(
    currentKvittering?.data.status
  )
    ? currentKvittering.status
    : NONE

  const renderImageArchiveStatus = () => {
    if (combinedArkiveringStatus.numPending > 0) {
      return (
        <>
          <b>
            Det er bilder på vei til arkivet. Du kan ferdigstille og sende ut
            tilsynskvitteringen når bildene er framme. Det vil ikke ta lang tid.
          </b>
          <p>Last inn siden på nytt for å sjekke om bildene har kommet frem.</p>
          <TextLink
            onClick={() => {
              setHasFetchedArchiveStatus(false)
              dispatch(
                kvitteringActions.setCurrentKvitteringFromId(kvitteringId!)
              )
            }}
          >
            Last inn siden på nytt
          </TextLink>
        </>
      )
    }

    if (combinedArkiveringStatus.antallBilderArkivert > 0) {
      return <p>Alle bildene har blitt arkivert.</p>
    }

    return null
  }

  const onOpenModal = () => {
    if (isOffline) {
      return singleToast.showToast(offlineInFerdigstillingProcess())
    }
    setIsModalOpen(true)
  }

  const renderContent = () => {
    if (
      (!hasFetchedKvittering ||
        (!hasFetchedArchiveStatus &&
          currentKvittering.data.status === kvitteringStatusEnum.ARKIVERT &&
          hasHoveddokumentId) ||
        loading ||
        arkiveringStatus.loading) &&
      !error
    ) {
      return <LoadingSpinner title="Henter arkiveringsstatus..." />
    }

    if (!hasHoveddokumentId || arkiveringStatus.error) {
      return (
        <>
          <b>En feil har oppstått</b>
          <TextLink onClick={() => navigate('/')}>
            Gå tilbake til forsiden
          </TextLink>
        </>
      )
    }

    if (
      currentKvittering.data.status === kvitteringStatusEnum.FERDIGSTILT &&
      currentKvittering.status !== SAVING
    ) {
      return (
        <>
          <b>Kvitteringen er ferdigstilt</b>
          <TextLink onClick={() => navigate('/')}>
            Gå tilbake til forsiden
          </TextLink>
        </>
      )
    }

    return (
      <>
        <p>
          {`Tilsynskvittering for tilsyn hos ${virksomhetsNavn} har blitt opprettet i arkivet på sak ${saksnummer}`}
          &nbsp;
          <CopyToClipboardButton
            onCopied={onCopySaknummer}
            value={saksnummer}
          />
        </p>
        {renderImageArchiveStatus()}
        <div>
          <Row justify="center" margin={[1, 0]}>
            <Button
              large
              disabled={!!combinedArkiveringStatus.numPending}
              onClick={() => onOpenModal()}
            >
              Ferdigstill og send ut tilsynskvitteringen
            </Button>
          </Row>
        </div>
      </>
    )
  }

  return (
    <>
      <Grid id="ferdigstilling">
        <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
          <Row>
            <TextLink leftArrow onClick={() => navigate('/')}>
              Tilbake
            </TextLink>
          </Row>
        </GridItem>
        <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
          <Grid>
            <GridItem xl={[2, -2]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
              <Column
                spacing={3}
                fluid
                key={1}
                className="ferdigstilling-content"
              >
                <Row className="main-heading">
                  <h1>Ferdigstilling og utsending av tilsynskvittering</h1>
                </Row>
                <Column spacing={2} fluid>
                  {renderContent()}
                </Column>
              </Column>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      <FerdigstillingModal
        onCancel={onCloseModal}
        isOpen={isModalOpen}
        onFerdigstilling={onFerdigStilling}
        ferdigstillingStatus={ferdigstillingSavingStatus}
        saksnummer={saksnummer}
      />
    </>
  )
}

interface IArchiveModalProps {
  onCancel: (shouldRefreshData: boolean) => void
  isOpen: boolean
  onFerdigstilling: () => void
  ferdigstillingStatus?: IKvitteringSavingStatus
  saksnummer: string
}

const FerdigstillingModal = ({
  onCancel,
  isOpen,
  onFerdigstilling,
  ferdigstillingStatus,
  saksnummer,
}: IArchiveModalProps) => {
  const navigate = useTKNavigate()
  const [isFerdigstilling, setIsFerdigstilling] = useState(false)

  const onCloseModal = () => {
    if (ferdigstillingStatus === OK) {
      navigate('/')
    }
    return onCancel(ferdigstillingStatus === ERROR)
  }

  const _onFerdigstilling = useCallback(() => {
    setIsFerdigstilling(true)
    onFerdigstilling()
  }, [onFerdigstilling])

  useEffect(() => {
    if (!isOpen || ferdigstillingStatus !== SAVING) {
      setIsFerdigstilling(false)
    }
  }, [isOpen, ferdigstillingStatus])

  const marginHorizontal = useMediaQuery({ query: '(min-width: 767px)' })
    ? 14
    : 5

  const renderModalContent = () => {
    if (isFerdigstilling) {
      return (
        <LoadingSpinner
          title="Ferdigstiller og sender ut tilsynskvitteringen..."
          small
        />
      )
    }

    if (ferdigstillingStatus === ERROR) {
      return (
        <ErrorBox
          errorText="Noe gikk galt. Lukk vinduet og prøv igjen"
          errorAction={() => onCancel(true)}
          errorActionText="Lukk vinduet"
        />
      )
    }

    if (ferdigstillingStatus === OK) {
      return <FerdigstillingOkContent saksnummer={saksnummer} />
    }

    return (
      <>
        <Row margin={[0, marginHorizontal]}>
          <p>
            Når du ferdigstiller og sender ut tilsynskvitteringen kan du ikke
            lenger gjøre endringer eller legge til bilder.
          </p>
        </Row>
        <Column spacing={4}>
          <Row margin={[0, marginHorizontal]}>
            <b>
              Er du sikker på at du vil ferdigstille og sende ut
              tilsynskvitteringen?
            </b>
          </Row>
        </Column>
      </>
    )
  }
  return (
    <ModalWrapper
      onCancel={onCloseModal}
      isOpen={isOpen}
      fullscreenMobile
      className="ferdigstilling-modal"
    >
      <Column
        padding={modalContentPaddingLarge}
        spacing={4}
        margin={[2, 0]}
        minHeight="unset"
      >
        {renderModalContent()}
      </Column>
      {ferdigstillingStatus !== OK &&
        ferdigstillingStatus !== ERROR &&
        ferdigstillingStatus !== SAVING && (
          <ModalButtonsContainer>
            <ModalButton secondary onClick={() => onCancel(false)}>
              Avbryt
            </ModalButton>
            <ModalButton allowMultiLine onClick={_onFerdigstilling}>
              Ja, ferdigstill og send ut tilsynskvitteringen
            </ModalButton>
          </ModalButtonsContainer>
        )}
    </ModalWrapper>
  )
}

export default FerdistillingRoute
