import React from 'react'
import { Column, Row, Button, Text, Header } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../../components/modal-wrapper'

interface IInfoModalProps {
  isOpen: boolean
  onCancel: () => void
  onConfirm: () => void
  title?: string
  info?: string
  confirmText?: string
  cancelText?: string
}

interface IInfoModalWithoutConfirmationProps {
  isOpen: boolean
  onCancel: () => void
  title?: string
  info?: string
  cancelText?: string
}
// Trengs disse?
export const InfoModal = ({
  isOpen,
  onCancel,
  onConfirm,
  title = '',
  info = '',
  confirmText = 'Opprett en ny sak',
  cancelText = 'Gå tilbake',
}: IInfoModalProps) => {
  return (
    <ModalWrapper fullscreenMobile isOpen={isOpen} onCancel={onCancel}>
      <div id="info-modal">
        <Column spacing={5} margin={[2, 0]} minHeight="unset">
          {info && (
            <Row justify="center" padding={[0, 4]}>
              <Header as="h1" size="heading2">
                {title}
              </Header>
            </Row>
          )}
          <Row justify="center" padding={[0, 4]}>
            <Text size="normal">{info}</Text>
          </Row>
          <Column spacing={3}>
            <Row justify="center">
              <Button secondary width="22em" onClick={onCancel}>
                {cancelText}
              </Button>
            </Row>
            <Row justify="center">
              <Button secondary width="22em" onClick={onConfirm}>
                {confirmText}
              </Button>
            </Row>
          </Column>
        </Column>
      </div>
    </ModalWrapper>
  )
}

export const InfoModalWithoutConfirmation = ({
  isOpen,
  onCancel,
  title = '',
  info = '',
  cancelText = 'Gå tilbake',
}: IInfoModalWithoutConfirmationProps) => {
  return (
    <ModalWrapper fullscreenMobile isOpen={isOpen} onCancel={onCancel}>
      <div id="info-modal">
        <Column spacing={5} margin={[2, 0]} minHeight="unset">
          {info && (
            <Row justify="center" padding={[0, 4]}>
              <Header as="h1" size="heading2">
                {title}
              </Header>
            </Row>
          )}
          <Row justify="center" padding={[0, 4]}>
            <Text size="normal">{info}</Text>
          </Row>
          <Column spacing={3}>
            <Row justify="center">
              <Button secondary width="22em" onClick={onCancel}>
                {cancelText}
              </Button>
            </Row>
          </Column>
        </Column>
      </div>
    </ModalWrapper>
  )
}
