import { Draft, Immutable } from 'immer'
import { IKvitteringData } from '../../ducks/kvittering/types'
import { getObservasjonFromKvittering } from '../observasjoner'
import { getAllObservasjonerFromKvittering } from '../observasjoner/cache-helpers'
import { BildeGroupedItem, IImage } from './types'
import { getMutationStatus, MutationStatus } from '../../common/query'
import { bildeKeys, metadataQueryHelpers } from './queries/helpers'
import { kvitteringQueryHelpers } from '../kvitteringer/queries/helpers'
import { queryClient } from '../../api/query-client'
import { bildeApi } from './queries/bilde-api'

export {
  getObservasjonBildeIdsFromKvittering,
  updateObservasjonBildeIdsFromKvittering,
  removeBildeIdFromKvittering,
  getAllBildeIdsFromKvittering,
  getAllGroupedBildeIdsFromKvittering,
  toSortedGroupedImagesByDate,
  toSortedBildeIds,
  toSortedBildeMetadataList,
  getNotAttachedBildeIds,
  fixMismatchedBildeAttachments,
}

const getObservasjonBildeIdsFromKvittering = (
  kvittering: Immutable<IKvitteringData>,
  observasjonId: number
) => {
  const observasjon = getObservasjonFromKvittering(kvittering, observasjonId)

  return observasjon?.bildeIds
}

const updateObservasjonBildeIdsFromKvittering = (
  draft: Draft<IKvitteringData>,
  observasjonId: number,
  updateBildeIds: (bildeIds: string[]) => string[]
) => {
  draft.tilsynsobjekter.some((tilsynsobjekt) =>
    tilsynsobjekt.kontrollpunkter.some((kontrollpunkt) =>
      kontrollpunkt.observasjoner.some((observasjon) => {
        if (observasjon.id === observasjonId) {
          observasjon.bildeIds = updateBildeIds(observasjon.bildeIds)
          return true
        }
        return false
      })
    )
  )
}

const removeBildeIdFromKvittering = (
  draft: Draft<IKvitteringData>,
  bildeId: string
) => {
  draft.bildeIds = draft.bildeIds.filter((bilde) => bilde !== bildeId)

  draft.tilsynsobjekter.some((tilsynsobjekt) =>
    tilsynsobjekt.kontrollpunkter.some((kontrollpunkt) =>
      kontrollpunkt.observasjoner.some((observasjon) => {
        if (observasjon.bildeIds.includes(bildeId)) {
          observasjon.bildeIds = observasjon.bildeIds.filter(
            (bilde) => bilde !== bildeId
          )
          return true
        }
        return false
      })
    )
  )
}

const getAllBildeIdsFromKvittering = (
  kvittering: Immutable<IKvitteringData>
): string[] => {
  const observasjonerBildeIds = kvittering.tilsynsobjekter.flatMap(
    (tilsynsobjekt) =>
      tilsynsobjekt.kontrollpunkter.flatMap((kontrollpunkt) =>
        kontrollpunkt.observasjoner.flatMap(
          (observasjon) => observasjon.bildeIds
        )
      )
  )

  return [...kvittering.bildeIds, ...observasjonerBildeIds]
}

const getAllGroupedBildeIdsFromKvittering = (
  kvittering: Immutable<IKvitteringData>
): BildeGroupedItem[] => {
  const observasjoner = getAllObservasjonerFromKvittering(kvittering)

  const observasjonBildeIds = observasjoner.flatMap((observasjon) =>
    observasjon.bildeIds.map<BildeGroupedItem>((bildeId) => ({
      observasjonId: observasjon.id,
      id: bildeId,
    }))
  )

  const kvitteringBildeIds = kvittering.bildeIds.map<BildeGroupedItem>(
    (bildeId) => ({
      id: bildeId,
    })
  )

  return [...kvitteringBildeIds, ...observasjonBildeIds]
}

const toSortedBildeIds = (bildeIds: string[], bildeMetadataList: IImage[]) => {
  return bildeIds.slice().sort(toSortedBilderCompareFn(bildeMetadataList))
}

const toSortedBildeMetadataList = (bildeMetadataList: IImage[]) => {
  return bildeMetadataList.slice().sort(bilderCompareFn)
}

const toSortedGroupedImagesByDate = (
  images: BildeGroupedItem[],
  bildeMetadataList: IImage[]
) =>
  images
    .slice()
    .sort((prev, next) =>
      toSortedBilderCompareFn(bildeMetadataList)(prev.id, next.id)
    )

const toSortedBilderCompareFn =
  (bildeMetadataList: IImage[]) =>
  (prevBildeId: string, nextBildeId: string) => {
    const prevBilde = bildeMetadataList.find(
      (image) => image.id === prevBildeId
    )
    const nextBilde = bildeMetadataList.find(
      (image) => image.id === nextBildeId
    )

    if (!prevBilde || !nextBilde) {
      return 0
    }

    return bilderCompareFn(prevBilde, nextBilde)
  }

const bilderCompareFn = (
  prevBildeMetadata: IImage,
  nextBildeMetadata: IImage
) => {
  const prevDate = new Date(prevBildeMetadata.captureTime ?? '')
  const nextDate = new Date(nextBildeMetadata.captureTime ?? '')

  return prevDate.getTime() - nextDate.getTime()
}

/**
 * Removes upload mutation from cache if it exists. Returns true if mutation was removed.
 * @param kvitteringId
 * @param bildeId
 * @param observasjonId
 * @returns result Whether the mutation was removed.
 */
export const removeBildeMutationsIfExisting = (
  kvitteringId: string,
  bildeId: string
) => {
  const mutations = queryClient.getMutationCache().findAll({
    mutationKey: bildeKeys.all,
    predicate: (mutation) =>
      mutation.state.variables?.bildeMetadata?.id === bildeId &&
      getMutationStatus(mutation) !== MutationStatus.SUCCESS,
    exact: false,
  })

  if (mutations.length > 0) {
    mutations.forEach((mutation) => {
      queryClient.getMutationCache().remove(mutation)
    })

    kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
      removeBildeIdFromKvittering(draft, bildeId)
    })

    metadataQueryHelpers.updateMetadataList(kvitteringId, (draft) =>
      draft?.filter((bilde) => bilde.id !== bildeId)
    )

    return true
  }

  return false
}

/**
 * Returns bildeIds that are not attached to kvittering.
 * @param kvittering
 * @param bildeMetadataList
 */
const getNotAttachedBildeIds = (
  kvittering: IKvitteringData,
  bildeMetadataList: IImage[]
) => {
  if (bildeMetadataList.length === 0) {
    return []
  }

  const bildeIds = new Set(getAllBildeIdsFromKvittering(kvittering))

  return bildeMetadataList
    .filter((metadata) => !bildeIds.has(metadata.id))
    .map((metadata) => metadata.id)
}

/**
 * Attaches bildeIds to kvittering that are not attached.
 * (This can happen when bildeIds are not attached due to a mutation error)
 * @param kvitteringId
 * @param notAttachedBildeIds
 */
const fixMismatchedBildeAttachments = (
  kvitteringId: string,
  notAttachedBildeIds: string[]
) => {
  kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
    notAttachedBildeIds.forEach((bildeId) => {
      void bildeApi.attachToKvittering({ kvitteringId, bildeId })
      draft.bildeIds.push(bildeId)
    })
  })
}
