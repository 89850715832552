import {
  ISakState,
  SakActionTypes,
  GET_SAK,
  GET_SAK_SUCCESS,
  GET_SAK_NOT_FOUND,
  GET_SAK_FAILURE,
  CLEAR_SAK,
  CREATE_SAK,
  CREATE_SAK_SUCCESS,
  CREATE_SAK_FAILED,
} from './types'

export const initialState: ISakState = {
  loading: false,
  sakNumber: '',
  title: '',
  sakFail: false,
  sakNotFound: false,
  sakClosed: false,
  sakHjemmel: '',
  sakTilgang: '',
  error: '',
}

const sakReducer = (
  state = initialState,
  action: SakActionTypes
): ISakState => {
  switch (action.type) {
    case GET_SAK:
      return {
        ...state,
        loading: true,
      }

    case GET_SAK_SUCCESS:
      return {
        ...state,
        loading: false,
        sakNumber: action.sakNumber,
        sakNotFound: false,
        sakFail: false,
        sakClosed: action.sakClosed,
        sakHjemmel: action.sakHjemmel,
        sakTilgang: action.sakTilgang,
        title: action.title,
      }
    case GET_SAK_NOT_FOUND:
      return {
        ...state,
        loading: false,
        sakNumber: action.sakNumber,
        sakNotFound: true,
        sakFail: false,
        sakClosed: false,
        sakHjemmel: '',
        sakTilgang: '',
        title: '',
      }
    case GET_SAK_FAILURE:
      return {
        ...state,
        loading: false,
        sakNumber: action.sakNumber,
        sakNotFound: false,
        sakFail: true,
        sakClosed: false,
        sakHjemmel: '',
        sakTilgang: '',
        title: '',
      }

    case CLEAR_SAK:
      return {
        ...initialState,
      }

    case CREATE_SAK:
      return {
        ...state,
        loading: true,
      }

    case CREATE_SAK_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case CREATE_SAK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}

export default sakReducer
