import React, { ChangeEvent, useState } from 'react'
import { Column, Row, Text, TextArea } from '@mattilsynet/mt-ui'
import { useTypedSelector } from '../../common/custom-hooks'
import { useDispatch } from 'react-redux'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { LabelWithInfo } from '../label-with-info'

const GenerellInfomasjonCard = () => {
  const dispatch = useDispatch()

  const selectedKvittering = useTypedSelector(
    (state) => state.kvittering.selectedKvittering.data
  )

  const [generellInformasjon, setGenerellInformasjon] = useState(
    selectedKvittering.generellInformasjon
  )

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setGenerellInformasjon(e.target.value)

    dispatch(
      kvitteringActions.updateGenerellInformasjon({
        ...selectedKvittering,
        generellInformasjon: e.target.value,
      })
    )
  }

  return (
    <Row id="generell-informasjon">
      <Column fluid spacing={1}>
        <LabelWithInfo label="Faktabeskrivelse om tilsynsobjektet/ene">
          <div>
            <Text>
              Feltet kan benyttes til å registrere nødvendige faktaopplysninger
              om tilsynsobjektet/ene som ikke passer å skrive i
              observasjonsfeltene. F. eks. ved endring i produksjon og endring i
              antall dyr.
            </Text>
            <br />
            <Text>OBS: Feltet skal ikke inneholde vurderinger.</Text>
            <Text>Skriv kort og konsist.</Text>
          </div>
        </LabelWithInfo>
        <TextArea
          onChange={onChange}
          placeholder="F. eks. antall dyr"
          value={generellInformasjon ?? ''}
          defaultRows={2}
          maxLength={500}
          infoText={`${generellInformasjon?.length ?? 0}/500`}
        />
      </Column>
    </Row>
  )
}

export default GenerellInfomasjonCard
