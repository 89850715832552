// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-with-info__main {
  display: grid;
  grid-template-columns: max-content min-content 1fr;
}

.label-with-info__main .Text {
  align-self: center;
}

.label-with-info__main .label-with-info__icon-btn {
  margin: 0 0 0 0.5rem;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.label-with-info__main .label-with-info__icon-btn.open {
  background-color: var(--mt-bg-blaa);
  border-radius: 0.5rem 0.5rem 0 0;
}
:root {
  --sec: 0.2s;
}
.label-with-info__info-box {
  margin-top: 0 !important;
  background-color: var(--mt-bg-blaa);
  border-radius: 0.5rem;
  overflow: hidden;

  height: 0;
  padding: 0 1rem;
  transition:
    height var(--sec) ease-in-out,
    padding var(--sec) ease-in-out;
}

.label-with-info__info-box.open {
  height: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/label-with-info/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kDAAkD;AACpD;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;EACnC,gCAAgC;AAClC;AACA;EACE,WAAW;AACb;AACA;EACE,wBAAwB;EACxB,mCAAmC;EACnC,qBAAqB;EACrB,gBAAgB;;EAEhB,SAAS;EACT,eAAe;EACf;;kCAEgC;AAClC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".label-with-info__main {\n  display: grid;\n  grid-template-columns: max-content min-content 1fr;\n}\n\n.label-with-info__main .Text {\n  align-self: center;\n}\n\n.label-with-info__main .label-with-info__icon-btn {\n  margin: 0 0 0 0.5rem;\n  grid-row: 1 / 2;\n  grid-column: 2 / 3;\n}\n\n.label-with-info__main .label-with-info__icon-btn.open {\n  background-color: var(--mt-bg-blaa);\n  border-radius: 0.5rem 0.5rem 0 0;\n}\n:root {\n  --sec: 0.2s;\n}\n.label-with-info__info-box {\n  margin-top: 0 !important;\n  background-color: var(--mt-bg-blaa);\n  border-radius: 0.5rem;\n  overflow: hidden;\n\n  height: 0;\n  padding: 0 1rem;\n  transition:\n    height var(--sec) ease-in-out,\n    padding var(--sec) ease-in-out;\n}\n\n.label-with-info__info-box.open {\n  height: 100%;\n  padding: 1rem;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
