import React from 'react'
import { Column, Header, Text } from '@mattilsynet/mt-ui'
import { IVirksomhetAdresse } from '../../ducks/kvittering/types'

interface IVirksomhetAdresseInfo {
  adresse?: IVirksomhetAdresse
  tittel: string
}

export const VirksomhetAdresseInfo = ({
  adresse,
  tittel,
}: IVirksomhetAdresseInfo) => {
  if (!adresse || Object.keys(adresse).length === 0) return null

  return (
    <Column justify={'flex-start'} align={'flex-start'} spacing={1}>
      <Header as="h3" size="normal">
        {tittel}
      </Header>
      <Column>
        {adresse.adresse.length > 0 &&
          adresse.adresse.map((adresse, index) => (
            <Text key={index} textAlign="left">
              {adresse}
            </Text>
          ))}
      </Column>
      <Column>
        <Text>{`${adresse?.postnummer || ''} ${adresse?.poststed || ''}`}</Text>
      </Column>
    </Column>
  )
}
