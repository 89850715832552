import { IVeiledning } from '../../ducks/kvittering/veiledning/types'
import { IDeltaker } from '../deltakere/types'
import { IImage } from '../bilder/types'
import { IObservasjon } from '../observasjoner/types'
import { IKontrollpunkt } from '../kontrollpunkter/types'

export { LexiconDataType }
export type { LexiconToType, ILexicon }

enum LexiconDataType {
  deltaker = 'deltaker',
  kontrollpunkt = 'kontrollpunkt',
  observasjon = 'observasjon',
  veiledning = 'veiledning',
  bilde = 'bilde',
}

interface LexiconToType {
  [LexiconDataType.kontrollpunkt]: IKontrollpunkt
  [LexiconDataType.observasjon]: IObservasjon
  [LexiconDataType.deltaker]: IDeltaker
  [LexiconDataType.veiledning]: IVeiledning
  [LexiconDataType.bilde]: IImage
}

type ILexicon = {
  [key in keyof LexiconToType as `${string &
    key}/${string}`]: LexiconToType[key]
}
