import { createSelector } from 'reselect'
import { kvitteringStatusEnum } from './types'
import { IStoreState } from '../../reducers/types'

const selectKvitteringer = (state: IStoreState) => state.kvittering.kvitteringer
const selectTilsynstyper = (state: IStoreState) =>
  state.kvittering.tilsynstyper.data
const selectHentedeKvitteringer = (state: IStoreState) =>
  state.kvittering.hentedeKvitteringer

export const kvitteringSelectors = {
  getAll: (state: IStoreState) => state.kvittering.kvitteringer,
  getPaabegynt: createSelector(selectKvitteringer, (kvitteringer) =>
    kvitteringer.filter((k) => k.status == kvitteringStatusEnum.PAABEGYNT)
  ),
  getForberedt: createSelector(selectKvitteringer, (kvitteringer) =>
    kvitteringer.filter((k) => k.status == kvitteringStatusEnum.FORBEREDT)
  ),
  getArkivert: createSelector(selectKvitteringer, (kvitteringer) =>
    kvitteringer.filter(
      (k) =>
        k.status == kvitteringStatusEnum.ARKIVERT ||
        k.status == kvitteringStatusEnum.ARKIVERING_PAAGAAR
    )
  ),
  getFerdigstilt: createSelector(selectKvitteringer, (kvitteringer) =>
    kvitteringer.filter(
      (k) =>
        k.status == kvitteringStatusEnum.FERDIGSTILT ||
        k.status == kvitteringStatusEnum.FERDIGSTILLING_PAAGAAR
    )
  ),
  getFeilet: createSelector(selectKvitteringer, (kvitteringer) =>
    kvitteringer.filter(
      (k) =>
        k.status == kvitteringStatusEnum.ARKIVERING_PDF_FEILET ||
        k.status == kvitteringStatusEnum.ARKIVERING_VEDLEGG_FEILET ||
        k.status == kvitteringStatusEnum.FERDIGSTILLING_PDF_FEILET ||
        k.status == kvitteringStatusEnum.FERDIGSTILLING_VEDLEGG_FEILET
    )
  ),
  getTypeBeskrivelse: (typeTilsyn: string) =>
    createSelector(selectTilsynstyper, (tilsynstyper) =>
      tilsynstyper
        .filter((t) => t.typeTilsynKey === typeTilsyn)
        .map((t) => t.beskrivelse)
    ),
  getKvitteringerFraKollega: createSelector(
    selectHentedeKvitteringer,
    (hentedeKvitteringer) => hentedeKvitteringer?.kvitteringer ?? []
  ),
  getKvitteringerFraKollegaLoadingStatus: createSelector(
    selectHentedeKvitteringer,
    (hentedeKvitteringer) => ({
      loading: hentedeKvitteringer?.loading ?? false,
      loaded: hentedeKvitteringer?.loaded ?? false,
      error: hentedeKvitteringer?.error,
    })
  ),
  getCurrentKvitteringId: createSelector(
    (state: IStoreState) => state.kvittering,
    ({ currentKvitteringId }) => currentKvitteringId
  ),
}

export const syncSelectors = {
  isAllKvitteringerSyncedOLD: ({
    kvittering: { kvitteringer },
  }: IStoreState) => {
    return kvitteringer.every((kvittering) => {
      return kvittering.synced || kvittering.synced === undefined
    })
  },
}
