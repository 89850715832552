import React, { useCallback, useEffect, useState } from 'react'
import {
  Column,
  Row,
  Button,
  ShadowBox,
  IconButton,
  Header,
  Text,
  ActionIcon,
} from '@mattilsynet/mt-ui'
import './style.css'
import { ADRESSE_ERROR, IKvitteringData } from '../../ducks/kvittering/types'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { AdresseAccordion } from '../adresse-accordion'
import { useTypedSelector } from '../../common/custom-hooks'
import { IStoreState } from '../../reducers/types'
import dayjs from 'dayjs'
import { CopyToClipboardButton } from '../copy-to-clipboard'
import { copySaknummer, useSingleToast } from '../../common/toast'
import { InformationBox } from '../information-box'

interface ITilsynCardItemProps {
  kvittering: IKvitteringData
  onRedigerTilsyn?: () => void
  onOpenDeleteModal?: () => void
  onClickTilsyn?: () => void
  buttonText?: string
  isEditable?: boolean
  onOpenHandlingsMenu: () => void
  isButtonDisabled?: boolean
  hasError?: boolean
}
const TilsynCardItem = ({
  kvittering,
  onClickTilsyn,
  buttonText,
  onOpenHandlingsMenu,
  isButtonDisabled,
  hasError,
}: ITilsynCardItemProps) => {
  const singleToast = useSingleToast()

  const error = useTypedSelector((state: IStoreState) => state.kvittering.error)
  const tilsynstyper = useTypedSelector(
    (state: IStoreState) => state.kvittering.tilsynstyper
  )
  const [typeBeskrivelse, setTypeBeskrivelse] = useState<string | undefined>()

  const {
    fordelingInfo,
    noarksakAar,
    noarksakSekvensnummer,
    tilsynsobjekter,
    typeTilsyn,
    virksomhetsNavn,
    virksomhetsOrgNr,
  } = kvittering

  const onCopySaknummer = useCallback(
    () => singleToast.showToast(copySaknummer()),
    [singleToast]
  )

  useEffect(() => {
    if (!tilsynstyper || !tilsynstyper.data || tilsynstyper.data.length === 0) {
      return
    }
    const beskrivelse = tilsynstyper.data.find(
      (t) => t.typeTilsynKey === typeTilsyn
    )?.beskrivelse
    setTypeBeskrivelse(beskrivelse)
  }, [typeTilsyn, tilsynstyper])

  const orgOrPerson = virksomhetsOrgNr
    ? `Org nr underenhet: ${virksomhetsOrgNr}`
    : `Privatperson`

  const renderFordelingsInfo = useCallback(() => {
    if (!fordelingInfo) {
      return ''
    }

    const { forrigeEierNavn, tidspunkt, fordelingstype } = fordelingInfo

    const fordeltDato = dayjs(new Date(tidspunkt)).format('DD.MM.YYYY')

    const fordeltStr = fordelingstype === 'HENTET' ? 'Hentet fra' : 'Fordelt av'

    return (
      <p className="fordeling">{`${fordeltStr} ${forrigeEierNavn} ${fordeltDato}`}</p>
    )
  }, [fordelingInfo])
  const renderTilsynsobjekter = (tilsynsobjekter) => {
    if (
      !kvittering?.tilsynsobjekter ||
      kvittering?.tilsynsobjekter?.length === 0
    )
      return (
        <Column spacing={2} align="flex-start">
          <p className="title">Tilsynsobjekt mangler</p>
          <p className="subtitle">Aktivitet mangler</p>
        </Column>
      )
    else
      return tilsynsobjekter.map((tilsynsobjekt) => {
        return (
          <Column
            spacing={1}
            align="flex-start"
            key={tilsynsobjekt.tilsynsobjektId || tilsynsobjekt.id}
          >
            <Header as="h3" size="heading2" uppercase>
              {tilsynsobjekt.navn
                ? tilsynsobjekt.navn
                : 'Tilsynsobjekt mangler'}
            </Header>
            <Text>
              {tilsynsobjekt.aktivitetsBeskrivelse
                ? tilsynsobjekt.aktivitetsBeskrivelse
                : 'Aktivitet mangler'}
            </Text>
          </Column>
        )
      })
  }

  const renderSaknummer = () => {
    const saknummer = `${noarksakAar}/${noarksakSekvensnummer}`
    let saknummerText = `Saksnummer: ${saknummer}`
    const isSaknummerSet = noarksakAar !== undefined && noarksakAar !== ''

    if (!isSaknummerSet) {
      saknummerText = 'Saknummer mangler'
    }

    return (
      <p className="saksnummer">
        {saknummerText}
        {isSaknummerSet && (
          <>
            &nbsp;&nbsp;
            <CopyToClipboardButton
              onCopied={onCopySaknummer}
              value={saknummer}
            />
          </>
        )}
      </p>
    )
  }

  return (
    <div id="tilsyn-card-item">
      <ShadowBox>
        <Column padding={3} spacing={3}>
          <Column>
            {hasError && (
              <Row
                margin={[0, 0, 1.5, 0]}
                className="tilsyn-card-item__error-wrapper"
              >
                <InformationBox variant="danger" small>
                  Feilet ved opplasting til Elements. Ta kontakt med
                  brukerstøtte.
                </InformationBox>
              </Row>
            )}

            <Row justify="space-between">
              <Column spacing={1}>
                <Header as="h3" size="heading2" uppercase>
                  {virksomhetsNavn ? virksomhetsNavn : 'Virksomhet mangler'}
                </Header>
                <Text>
                  {!tilsynsobjekter || tilsynsobjekter.length > 0
                    ? `${orgOrPerson}`
                    : 'Org.nr. mangler'}
                </Text>
                <AdresseAccordion
                  item={kvittering}
                  orgNr={virksomhetsOrgNr}
                  fetchAddress={kvitteringActions.fetchAdresse(kvittering)}
                  hasError={!!error && error === ADRESSE_ERROR}
                />
              </Column>
              <Column align="flex-start">
                <IconButton
                  ariaLabel="Tilsynsobjekt handlinger"
                  large
                  icon={<ActionIcon />}
                  onClick={() => onOpenHandlingsMenu()}
                  disabled={hasError}
                />
              </Column>
            </Row>
          </Column>
          <Column spacing={3}>
            {renderTilsynsobjekter(tilsynsobjekter)}
            <Column spacing={1}>
              {renderSaknummer()}
              <p className="typetilsyn">
                {typeTilsyn ? `${typeBeskrivelse}` : 'Tilsynstype mangler'}
              </p>
              {renderFordelingsInfo()}
            </Column>
          </Column>
          {onClickTilsyn && (
            <Button
              secondary
              onClick={onClickTilsyn}
              arrow
              fill
              disabled={isButtonDisabled}
            >
              {buttonText}
            </Button>
          )}
        </Column>
      </ShadowBox>
    </div>
  )
}

export default TilsynCardItem
