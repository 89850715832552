import React, { useCallback } from 'react'
import { Column, LoadingDots, LoadingSpinner, Row } from '@mattilsynet/mt-ui'
import IngenTilstede from './ingen-tilstede'
import {
  useVirksomhetDeltakere,
  useVirksomhetDeltakereMutation,
} from '../index'
import { useTypedSelector } from '../../../common/custom-hooks'
import { useSelector } from 'react-redux'
import { IStoreState } from '../../../reducers/types'
import {
  useDeltakereFns,
  useRemoveEmptyDeltaker,
} from '../hooks/component-hooks'
import { DeleteModal } from '../../../modals'
import AddDeltaker from './add-deltaker'
import { DeltakerItem } from './deltaker-item'
import { createSelector } from 'reselect'
import { ENDPOINT } from '../../../constants'

const VIRKSOMHET_DELTAKERE = 'virksomhet-deltagere'

const selectDataAndDeltakere = createSelector(
  (state: IStoreState) => state.kvittering.selectedKvittering.data,
  ({
    id,
    virksomhetsNavn,
    ingenTilStede,
    [VIRKSOMHET_DELTAKERE]: kvitteringDeltakere,
  }) => ({
    url: `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${id}/${VIRKSOMHET_DELTAKERE}`,
    virksomhetsNavn,
    ingenTilStede,
    kvitteringDeltakere,
  })
)

export const VirksomhetDeltakere = () => {
  const { url, virksomhetsNavn, ingenTilStede, kvitteringDeltakere } =
    useTypedSelector(selectDataAndDeltakere)
  const kvitteringStatus = useSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering.status
  )

  const deltakere = useVirksomhetDeltakere(url, {
    initialData: kvitteringDeltakere,
  })
  const mutation = useVirksomhetDeltakereMutation(url)
  const {
    onAdd,
    onUpdate,
    onRemove,
    shouldAutoFocus,
    hasError,
    remove,
    setDeltakerToBeRemoved,
    deltakerToBeRemoved,
    isAddLoading,
  } = useDeltakereFns(deltakere, mutation, {
    noInitialData: ingenTilStede,
    type: 'virksomhet',
  })

  useRemoveEmptyDeltaker(deltakere.data ?? [], onRemove)

  const shouldDisableOnIngenTilStede = () => {
    const containsVirksomhetsDeltaker = deltakere.data?.some(
      (deltaker) => !!deltaker.navn.trim()
    )

    return (
      containsVirksomhetsDeltaker ||
      kvitteringStatus === 'SAVING_VIRKSOMHETS_DELTAGER'
    )
  }

  const onCancelRemove = useCallback(
    () => setDeltakerToBeRemoved(undefined),
    [setDeltakerToBeRemoved]
  )

  return (
    <>
      <Column spacing={2}>
        <Column spacing={2}>
          <Row className="info-header">Fra {virksomhetsNavn}</Row>

          {deltakere.isLoading && (
            <Row center>
              <LoadingSpinner title={'Laster deltakerer...'} small />
            </Row>
          )}

          {!deltakere.isLoading &&
            deltakere.data?.map((deltaker) => (
              <DeltakerItem
                key={deltaker.id}
                deltaker={deltaker}
                onUpdateDeltaker={onUpdate}
                onRemoveDeltaker={onRemove}
                autoFocus={shouldAutoFocus(deltaker)}
                danger={hasError}
                disabled={ingenTilStede}
              />
            ))}
        </Column>

        {(isAddLoading ||
          (deltakere.isFetching && deltakere.data?.length === 0)) && (
          <Row justify="center">
            <LoadingDots />
          </Row>
        )}

        {!deltakere.isLoading && (
          <>
            <AddDeltaker onAdd={onAdd} disabled={ingenTilStede} />
            <IngenTilstede disabled={shouldDisableOnIngenTilStede()} />
          </>
        )}
      </Column>

      <DeleteModal
        isOpen={!!deltakerToBeRemoved}
        onCancel={onCancelRemove}
        onDelete={remove}
        title="Er du sikker på at du vil slette deltaker?"
        confirmText="Ja, jeg vil slette deltaker"
        cancelText="Avbryt"
      />
    </>
  )
}
