import { ITilsynsobjekt } from '../tilsynsobjekt/types'
import { IVeiledning } from './types'

export const veiledningActionTypes = {
  CREATE_VEILEDNING: 'current-tilsynsobjekt/CREATE_VEILEDNING' as const,
  CREATE_VEILEDNING_SUCCESS:
    'current-tilsynsobjekt/CREATE_VEILEDNING_SUCCESS' as const,
  CREATE_VEILEDNING_FAILED:
    'current-tilsynsobjekt/CREATE_VEILEDNING_FAILED' as const,

  CREATE_LOCAL_VEILEDNING:
    'current-tilsynsobjekt/CREATE_LOCAL_VEILEDNING' as const,

  UPDATE_LOCAL_VEILEDNING:
    'current-tilsynsobjekt/UPDATE_LOCAL_VEILEDNING' as const,

  FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT:
    'current-tilsynsobjekt/FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT' as const,
  FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT_SUCCESS:
    'current-tilsynsobjekt/FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT_SUCCESS' as const,
  FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT_FAILED:
    'current-tilsynsobjekt/FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT_FAILED' as const,

  UPDATE_VEILEDNING: 'current-tilsynsobjekt/UPDATE_VEILEDNING' as const,
  UPDATE_VEILEDNING_SUCCESS:
    'current-tilsynsobjekt/UPDATE_VEILEDNING_SUCCESS' as const,
  UPDATE_VEILEDNING_FAILED:
    'current-tilsynsobjekt/UPDATE_VEILEDNING_FAILED' as const,

  DELETE_LOCAL_VEILEDNING:
    'current-tilsynsobjekt/DELETE_LOCAL_VEILEDNING' as const,

  DELETE_VEILEDNING: 'current-tilsynsobjekt/DELETE_VEILEDNING' as const,
  DELETE_VEILEDNING_SUCCESS:
    'current-tilsynsobjekt/DELETE_VEILEDNING_SUCCESS' as const,
  DELETE_VEILEDNING_FAILED:
    'current-tilsynsobjekt/DELETE_VEILEDNING_FAILED' as const,
  UPDATE_VEILEDNINGER: 'kvittering/UPDATE_VEILEDNINGER' as const,
  UPDATE_VEILEDNINGER_SUCCESS:
    'kvittering/UPDATE_VEILEDNINGER_SUCCESS' as const,
  UPDATE_VEILEDNINGER_FAILED: 'kvittering/UPDATE_VEILEDNINGER_FAILED' as const,
}

export const veiledningActions = {
  updateVeiledninger: (
    tilsynsobjekt: ITilsynsobjekt,
    blockToast?: boolean
  ) => ({
    type: veiledningActionTypes.UPDATE_VEILEDNINGER,
    tilsynsobjekt,
    blockToast,
  }),

  updateVeiledningerSuccess: (payload) => ({
    type: veiledningActionTypes.UPDATE_VEILEDNINGER_SUCCESS,
    payload,
  }),

  updateVeiledningerFailed: (error) => ({
    type: veiledningActionTypes.UPDATE_VEILEDNINGER_FAILED,
    error,
  }),

  createVeiledning: (veiledning) => ({
    type: veiledningActionTypes.CREATE_VEILEDNING,
    veiledning,
  }),

  createVeiledningSuccess: (veiledning: IVeiledning) => ({
    type: veiledningActionTypes.CREATE_VEILEDNING_SUCCESS,
    veiledning,
  }),

  createVeiledningFailed: (veiledning: IVeiledning, error) => ({
    type: veiledningActionTypes.CREATE_VEILEDNING_FAILED,
    veiledning,
    error,
  }),

  createLocalVeiledning: (veiledning: IVeiledning) => ({
    type: veiledningActionTypes.CREATE_LOCAL_VEILEDNING,
    veiledning,
  }),

  updateLocalVeiledning: (veiledning: IVeiledning) => ({
    type: veiledningActionTypes.UPDATE_LOCAL_VEILEDNING,
    veiledning,
  }),

  fetchVeiledningerByTilsynsobjekt: (tilsynsobjekt: ITilsynsobjekt) => ({
    type: veiledningActionTypes.FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT,
    tilsynsobjekt,
  }),

  fetchVeiledningerByTilsynsobjektSuccess: (veiledninger) => ({
    type: veiledningActionTypes.FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT_SUCCESS,
    veiledninger,
  }),

  fetchVeiledningerByTilsynsobjektFailed: (error: string) => ({
    type: veiledningActionTypes.FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT_FAILED,
    error,
  }),

  updateVeiledning: (veiledning: IVeiledning) => ({
    type: veiledningActionTypes.UPDATE_VEILEDNING,
    veiledning,
  }),

  updateVeiledningSuccess: (payload) => ({
    type: veiledningActionTypes.UPDATE_VEILEDNING_SUCCESS,
    payload,
  }),

  updateVeiledningFailed: (error) => ({
    type: veiledningActionTypes.UPDATE_VEILEDNING_FAILED,
    error,
  }),

  deleteLocalVeiledning: (veiledning: IVeiledning) => ({
    type: veiledningActionTypes.DELETE_LOCAL_VEILEDNING,
    veiledning,
  }),

  deleteVeiledning: (veiledning: IVeiledning) => ({
    type: veiledningActionTypes.DELETE_VEILEDNING,
    veiledning,
  }),

  deleteVeiledningSuccess: (veiledning: IVeiledning) => ({
    type: veiledningActionTypes.DELETE_VEILEDNING_SUCCESS,
    veiledning,
  }),

  deleteVeiledningFailed: (error: string) => ({
    type: veiledningActionTypes.DELETE_VEILEDNING_FAILED,
    error,
  }),
}
