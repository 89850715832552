import React, { ChangeEvent, useCallback, useState } from 'react'
import { Column, Row, RowItem, IconButton, Cross } from '@mattilsynet/mt-ui'
import Textarea from '@mattilsynet/mt-ui/dist/elements/text-area'
import './style.css'
import { IVeiledning } from '../../../../ducks/kvittering/veiledning/types'
import { useUpdateVeiledning } from '../..'
import { useKvitteringId } from '../../../../common/kvittering-context'
import { ITilsynsobjekt } from '../../../../ducks/kvittering/tilsynsobjekt/types'

interface IVeiledningItemProps {
  tilsynsobjekt: ITilsynsobjekt
  veiledning: IVeiledning
  onRemoveVeiledning: (veiledning: IVeiledning) => void
  danger: boolean
  autoFocus?: boolean
}

const VeiledningItem = ({
  tilsynsobjekt,
  veiledning,
  onRemoveVeiledning,
  danger,
  autoFocus,
}: IVeiledningItemProps) => {
  const [veiledningText, setVeiledningText] = useState(veiledning.tekst)

  const kvitteringId = useKvitteringId()

  const { mutate: onUpdateVeiledning } = useUpdateVeiledning(
    kvitteringId,
    tilsynsobjekt
  )

  const onChangeDescription = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const tekst = event.currentTarget.value

      setVeiledningText(tekst)

      onUpdateVeiledning({ veiledning: { ...veiledning, tekst } })
    },
    [veiledning, setVeiledningText, onUpdateVeiledning]
  )

  const errorText =
    danger && !veiledningText ? 'Veiledningen mangler beskrivelse' : ''

  return (
    <Column fluid spacing={1}>
      <Row className="veiledning-item-text-area">
        <RowItem flex={1}>
          <Textarea
            onChange={onChangeDescription}
            placeholder="Beskrivelse av veiledning"
            value={veiledningText}
            label="Beskrivelse av veiledning"
            danger={Boolean(errorText)}
            errorText={errorText}
            autoFocus={autoFocus}
          />
        </RowItem>
        <IconButton
          onClick={() => onRemoveVeiledning(veiledning)}
          icon={<Cross innerColor="white" />}
          className="veiledning-item-close-icon"
          ariaLabel="Ny veiledning"
        />
      </Row>
    </Column>
  )
}

export { VeiledningItem }
