import { useCallback } from 'react'
import { BildeGroupedItem } from '../bilder/types'
import { noImagesSelectedForAction, useSingleToast } from '../../common/toast'

export { useCanRunMultiAction }

const useCanRunMultiAction = (
  selectedImages: BildeGroupedItem[],
  action: () => void
) => {
  const singleToast = useSingleToast()

  return useCallback(() => {
    const hasSelectedImages = selectedImages.length > 0

    if (!hasSelectedImages) {
      singleToast.showToast(noImagesSelectedForAction())
      return
    }

    action()
  }, [singleToast, selectedImages, action])
}
