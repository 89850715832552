import { queryApi } from '../../../api'
import { ENDPOINT } from '../../../constants'
import { IKvitteringData, ITilsynstype } from '../../../ducks/kvittering/types'
import { IQueryApiResponse } from '../../../common/types'
import { ICreateKvitteringPayload, IUpdateKvitteringPayload } from '../types'

interface IGetKvitteringOptions {
  signal?: AbortSignal
}

export const kvitteringApi = {
  get: (id: string, options?: IGetKvitteringOptions) =>
    queryApi.get<IKvitteringData>(
      `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${id}?expand=tilsynsobjekter,kontrollpunkter,observasjoner,bildeIds,veiledninger`,
      { signal: options?.signal }
    ),
  getSammenstilling: (url: string, options?: IGetKvitteringOptions) =>
    queryApi.get<IKvitteringData>(url, { signal: options?.signal }),
  getTilsynstyper: (options?: IGetKvitteringOptions) =>
    queryApi.get<IQueryApiResponse<{ tilsynstypeList: ITilsynstype[] }>>(
      `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/tilsynstyper`,
      { signal: options?.signal }
    ),
  post: (payload: ICreateKvitteringPayload) =>
    queryApi.post(`${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer`, payload),
  put: ({ kvittering }: IUpdateKvitteringPayload) =>
    queryApi.put(
      `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvittering.id}`,
      kvittering
    ),
}
