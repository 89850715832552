import { AxiosResponse } from 'axios'
import { queryClient } from '../../../api/query-client'
import {
  DependencyNotPersisted,
  getIdFromSelfUrl,
  shouldIgnoreError,
} from '../../../common/query'
import { dangerToast } from '../../../common/toast'
import { IVeiledning } from '../../../ducks/kvittering/veiledning/types'
import store from '../../../reducers/store'
import { kvitteringQueryHelpers } from '../../kvitteringer/queries/helpers'
import { offlineLexicon } from '../../offline-lexicon'
import { IVeiledningMutateVars, VeiledningMutationOptions } from '../types'
import { veiledningerKeys } from './helpers'
import { veiledningerApi } from './veiledning-api'
import { onlineManager } from '@tanstack/react-query'
import { ENDPOINT } from '../../../constants'

/**
 * Optimistically updates veiledninger
 * in the cache.
 *
 * @returns Previous veiledninger (Context that
 * is passed to onError).
 */
const onMutate: (
  updateVeiledninger: (
    veiledninger: IVeiledning[],
    veiledning: IVeiledning
  ) => IVeiledning[]
) => VeiledningMutationOptions['onMutate'] =
  (updateVeiledninger) =>
  async ({ kvitteringId, veiledning }) => {
    await kvitteringQueryHelpers.cancelKvitteringQuery(kvitteringId)

    const kvittering = kvitteringQueryHelpers.getKvitteringCache(kvitteringId)
    const previousVeiledninger = kvittering?.veiledninger ?? []

    kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
      draft.veiledninger = updateVeiledninger(draft.veiledninger, veiledning)
    })

    return { previousVeiledninger }
  }

/** Displays error toast and reverts cache to previous veiledninger. */
const onError: VeiledningMutationOptions['onError'] = (
  error,
  { kvitteringId },
  context
) => {
  if (shouldIgnoreError(error)) {
    return
  }

  store.dispatch(
    dangerToast('Det har skjedd en feil med veiledningene. Kunne ikke lagre.')
  )

  kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
    draft.veiledninger =
      (context?.previousVeiledninger as IVeiledning[]) ?? draft.veiledninger
  })
}

// Leaving it here for now, in case we need it later
/** Refetches kvittering either on success or error. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onSettled: VeiledningMutationOptions['onSettled'] = (
  _,
  __,
  { kvitteringId }
) => kvitteringQueryHelpers.invalidateKvittering(kvitteringId)

/** Gets mutation url from persisted veiledninger. */
const getMutationUrl = (veiledning: IVeiledning, kvitteringId: string) => {
  let mutationUrl = `${ENDPOINT.TILSYNSKVITTERING}/veiledninger/${veiledning.id}`
  let persistedId = veiledning.id

  if (typeof veiledning.id === 'string') {
    const localVeiledning = offlineLexicon.get(
      offlineLexicon.types.veiledning,
      veiledning.id,
      kvitteringId
    )

    mutationUrl = localVeiledning?._links.self.href ?? mutationUrl
    persistedId = localVeiledning?.id ?? veiledning.id
  }

  if (!mutationUrl) {
    throw new DependencyNotPersisted()
  }

  return [mutationUrl, persistedId] as const
}

/** Gets and merges the mutation url with the variables. */
const withMutationUrl =
  (request: (variables: IVeiledningMutateVars) => Promise<AxiosResponse>) =>
  (variables: IVeiledningMutateVars) => {
    const { veiledning, kvitteringId } = variables

    let url = ''
    let pId: string | undefined = ''
    try {
      const [mutationUrl, persistedId] = getMutationUrl(
        veiledning,
        kvitteringId
      )
      url = mutationUrl
      pId = persistedId ?? veiledning.id
    } catch (error) {
      return Promise.reject(error)
    }

    return request({
      ...variables,
      veiledning: {
        ...veiledning,
        id: pId,
        _links: {
          self: {
            href: url,
          },
        },
      },
    })
  }

// Add veiledning
queryClient.setMutationDefaults(veiledningerKeys.addBase(), {
  mutationFn: veiledningerApi.post,
  onMutate: onMutate((veiledninger, veiledning) => {
    if (onlineManager.isOnline()) {
      return veiledninger
    }

    return [...veiledninger, veiledning]
  }),
  onSuccess: (res, { kvitteringId, veiledning }) => {
    const persistedId = String(
      getIdFromSelfUrl(res.headers.location) ?? veiledning.id
    )
    const newVeiledning = {
      ...veiledning,
      id: persistedId,
      _links: {
        self: {
          href: `${ENDPOINT.TILSYNSKVITTERING}/veiledninger/${persistedId}`,
        },
      },
    } satisfies IVeiledning

    offlineLexicon.set(
      offlineLexicon.types.veiledning,
      veiledning.id!,
      kvitteringId,
      newVeiledning
    )
  },
  onError: onError,
  onSettled: (_, __, { kvitteringId }) => {
    if (queryClient.isMutating() > 1) {
      return
    }

    return kvitteringQueryHelpers.invalidateKvittering(kvitteringId)
  },
} satisfies VeiledningMutationOptions)

// Update veiledning beskrivelse
queryClient.setMutationDefaults(veiledningerKeys.updateBeskrivelseBase(), {
  mutationFn: withMutationUrl(veiledningerApi.put),
  onMutate: onMutate((veiledninger, veiledning) => {
    const oldVeiledning = veiledninger.find((oldV) => oldV.id === veiledning.id)

    if (oldVeiledning) {
      oldVeiledning.tekst = veiledning.tekst
    }

    return veiledninger
  }),
  onError: onError,
} satisfies VeiledningMutationOptions)

// Remove veiledning
queryClient.setMutationDefaults(veiledningerKeys.removeBase(), {
  mutationFn: withMutationUrl(veiledningerApi.delete),
  onMutate: onMutate((veiledninger, veiledning) =>
    veiledninger.filter((oldVeiledning) => oldVeiledning.id !== veiledning.id)
  ),
  onError: onError,
} satisfies VeiledningMutationOptions)
