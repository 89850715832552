import { ENDPOINT } from '../../../constants'
import { queryApi } from '../../../api'
import { IVeiledningMutateVars } from '../types'

export const veiledningerApi = {
  post: async ({ veiledning, tilsynsobjekt }: IVeiledningMutateVars) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynsobjekter/${tilsynsobjekt.id}/veiledninger`

    if (!url) {
      throw new Error('url is missing')
    }

    return queryApi.post(url, { tekst: veiledning.tekst })
  },

  put: async ({ veiledning }: IVeiledningMutateVars) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/veiledninger/${veiledning.id}`

    if (!url) {
      throw new Error('url is missing')
    }

    return queryApi.put(url, {
      ...veiledning,
      id: null,
      _links: {
        self: {
          href: url,
        },
      },
    })
  },

  delete: async ({ veiledning }: IVeiledningMutateVars) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/veiledninger/${veiledning.id}`

    if (!url) {
      throw new Error('url is missing')
    }

    return queryApi.delete(url)
  },
}
