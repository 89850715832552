import { IVirksomhetTilsynsobjekt } from '../../virksomhet-search/types'
import { ITilsynsobjekt } from './types'

export const tilsynsobjektActionTypes = {
  SET_CURRENT_TILSYNSOBJEKT:
    'current-tilsynsobjekt/SET_CURRENT_TILSYNSOBJEKT' as const,
  CREATE_TILSYNSOBJEKT: 'current-kvittering/CREATE_TILSYNSOBJEKT' as const,
  CREATE_TILSYNSOBJEKT_SUCCESS:
    'current-kvittering/CREATE_TILSYNSOBJEKT_SUCCESS' as const,
  CREATE_TILSYNSOBJEKT_FAILED:
    'current-kvittering/CREATE_TILSYNSOBJEKT_FAILED' as const,

  DELETE_TILSYNSOBJEKT: 'current-kvittering/DELETE_TILSYNSOBJEKT' as const,
  DELETE_TILSYNSOBJEKT_SUCCESS:
    'current-kvittering/DELETE_TILSYNSOBJEKT_SUCCESS' as const,
  DELETE_TILSYNSOBJEKT_FAILED:
    'current-kvittering/DELETE_TILSYNSOBJEKT_FAILED' as const,

  UPDATE_TILSYNSOBJEKT: 'current-kvittering/UPDATE_TILSYNSOBJEKT' as const,
  UPDATE_TILSYNSOBJEKT_SUCCESS:
    'current-kvittering/UPDATE_TILSYNSOBJEKT_SUCCESS' as const,
  UPDATE_TILSYNSOBJEKT_FAILED:
    'current-kvittering/UPDATE_TILSYNSOBJEKT_FAILED' as const,
  CLEAR_SELECTED_TILSYNSOBJEKTER:
    'current-kvittering/CLEAR_SELECTED_TILSYNSOBJEKTER' as const,
  FETCH_TILSYNSOBJEKTER: 'current-kvittering/FETCH_TILSYNSOBJEKTER' as const,
  FETCH_TILSYNSOBJEKTER_SUCCESS:
    'current-kvittering/FETCH_TILSYNSOBJEKTER_SUCCESS' as const,
  FETCH_TILSYNSOBJEKTER_FAILED:
    'current-kvittering/FETCH_TILSYNSOBJEKTER_FAILED' as const,
}

export const tilsynsobjektActions = {
  addTilsynsObjekt: (tilsyn, selected, orgNr) => ({
    type: tilsynsobjektActionTypes.CREATE_TILSYNSOBJEKT,
    tilsyn,
    selected,
    orgNr,
  }),

  createTilsynsobjektSuccess: (
    tilsynsobjekt: IVirksomhetTilsynsobjekt,
    orgNr: string
  ) => ({
    type: tilsynsobjektActionTypes.CREATE_TILSYNSOBJEKT_SUCCESS,
    tilsynsobjekt,
    orgNr,
  }),

  createTilsynsobjektFailed: (tilsynsobjekt, error) => ({
    type: tilsynsobjektActionTypes.CREATE_TILSYNSOBJEKT_FAILED,
    tilsynsobjekt,
    error,
  }),

  deleteTilsynsObjekt: (tilsyn, selected) => ({
    type: tilsynsobjektActionTypes.DELETE_TILSYNSOBJEKT,
    tilsyn,
    selected,
  }),

  deleteTilsynsObjektSuccess: (tilsynsobjekt) => ({
    type: tilsynsobjektActionTypes.DELETE_TILSYNSOBJEKT_SUCCESS,
    tilsynsobjekt,
  }),

  deleteTilsynsObjektFailed: (error) => ({
    type: tilsynsobjektActionTypes.DELETE_TILSYNSOBJEKT_FAILED,
    error,
  }),

  updateTilsynsobjekt: (tilsynsobjekt: ITilsynsobjekt) => ({
    type: tilsynsobjektActionTypes.UPDATE_TILSYNSOBJEKT,
    tilsynsobjekt,
  }),

  updateTilsynsobjektSuccess: (tilsynsobjekt: ITilsynsobjekt) => ({
    type: tilsynsobjektActionTypes.UPDATE_TILSYNSOBJEKT_SUCCESS,
    tilsynsobjekt,
  }),

  updateTilsynsobjektFailed: (error: string) => ({
    type: tilsynsobjektActionTypes.UPDATE_TILSYNSOBJEKT_FAILED,
    error,
  }),

  clearSelectedTilsynsobjekter: () => ({
    type: tilsynsobjektActionTypes.CLEAR_SELECTED_TILSYNSOBJEKTER,
  }),

  fetchTilsynsobjekter: (loading = true) => ({
    type: tilsynsobjektActionTypes.FETCH_TILSYNSOBJEKTER,
    loading,
  }),

  fetchTilsynsobjekterSuccess: (tilsynsobjekter) => ({
    type: tilsynsobjektActionTypes.FETCH_TILSYNSOBJEKTER_SUCCESS,
    tilsynsobjekter,
  }),

  fetchTilsynsobjekterFailed: (error) => ({
    type: tilsynsobjektActionTypes.FETCH_TILSYNSOBJEKTER_FAILED,
    error,
  }),

  setCurrentTilsynsobjekt: (tilsynsobjekt: ITilsynsobjekt) => ({
    type: tilsynsobjektActionTypes.SET_CURRENT_TILSYNSOBJEKT,
    tilsynsobjekt,
  }),
}
