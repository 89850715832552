import { CrossDeleteIcon, Row, RowItem, Text } from '@mattilsynet/mt-ui'
import React from 'react'
import { BildeGroupedItem } from '../../bilder/types'
import { DeleteSelectedImages } from './delete-selected-images'
import { RemoveSelectedImages } from './remove-selected-images'
import { AttachSelectedImages } from './attach-selected-images'
import '../style.css'

interface IImageActionsMenu {
  selectedImages: BildeGroupedItem[]
  onClose: () => void
  clearSelectedImages: () => void
}

export const ImageActionsMenu = ({
  selectedImages,
  onClose,
  clearSelectedImages,
}: IImageActionsMenu) => {
  const selectedImagesCount =
    selectedImages.length > 1
      ? `${selectedImages.length} bilder valgt`
      : '1 bilde valgt'

  const selectedImagesText =
    selectedImages.length === 0 ? 'Ingen bilder valgt' : selectedImagesCount

  return (
    <Row className="image-action-menu" padding={2} justify="space-between">
      <RowItem alignSelf="center">
        <Row spacing={2}>
          <button onClick={onClose} className="image-action-menu__button">
            <CrossDeleteIcon size="large" />
          </button>
          <Text>{selectedImagesText}</Text>
        </Row>
      </RowItem>
      <RowItem>
        <Row spacing={2}>
          <RowItem>
            <DeleteSelectedImages
              selectedImages={selectedImages}
              clearSelectedImages={clearSelectedImages}
            />
          </RowItem>
          <RowItem>
            <AttachSelectedImages
              selectedImages={selectedImages}
              clearSelectedImages={clearSelectedImages}
            />
          </RowItem>
          <RowItem>
            <RemoveSelectedImages
              selectedImages={selectedImages}
              clearSelectedImages={clearSelectedImages}
            />
          </RowItem>
        </Row>
      </RowItem>
    </Row>
  )
}
