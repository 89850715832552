import React, { ChangeEvent } from 'react'
import { InputField, Row, RowItem } from '@mattilsynet/mt-ui'
import './style.css'
import { IKvitteringData } from '../../ducks/kvittering/types'
import { formatPhoneNumber } from './utils'

interface IMobilenumberItemProps {
  onUpdateKvittering: (kvittering: IKvitteringData) => void
  kvittering: IKvitteringData
}
export const MobilenumberItem = ({
  onUpdateKvittering: onUpdateKvittering,
  kvittering,
}: IMobilenumberItemProps) => {
  const onChangeKvittering = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    onUpdateKvittering({
      ...kvittering,
      mobilForUndersoekelse: value,
    })
  }

  return (
    <Row className="mobile-number-item-number-area">
      <RowItem flex={1}>
        <InputField
          value={
            kvittering.mobilForUndersoekelse
              ? formatPhoneNumber(kvittering.mobilForUndersoekelse)
              : ''
          }
          placeholder="999 99 999"
          onChange={onChangeKvittering}
          type="decimal"
          inputMode="tel"
          maxLength={10}
          label="Telefonnummer for utsending av brukerundersøkelse"
        />
      </RowItem>
    </Row>
  )
}
