// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#bottom-status {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
}

#bottom-status > svg {
  height: 1.25rem;
  margin-right: 0.25em;
}
`, "",{"version":3,"sources":["webpack://./src/components/status/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,QAAQ;EACR,OAAO;EACP,WAAW;AACb;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB","sourcesContent":["#bottom-status {\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  z-index: 10;\n}\n\n#bottom-status > svg {\n  height: 1.25rem;\n  margin-right: 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
