import React from 'react'
import { Grid, GridItem, Column, Row, Button } from '@mattilsynet/mt-ui'
import { useTKNavigate } from '../../common/navigation'

const NoRoute = () => {
  const navigate = useTKNavigate()

  const goToHome = () => navigate('/')

  return (
    <Grid id="no-route-content">
      <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
        <Grid>
          <GridItem>
            <Column
              justify="center"
              align="center"
              margin={[7.5, 0]}
              spacing={1}
            >
              <Row className="text">Kunne ikke finne siden</Row>
              <Button onClick={goToHome}>Gå til hovedsiden</Button>
            </Column>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  )
}

export default NoRoute
