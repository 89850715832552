import * as R from 'ramda'

const withStringBooleanToBoolean = (value: string): string | boolean => {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return value
  }
}
export const formatSettingsDataResp = (data) =>
  R.pipe(
    R.map((setting) =>
      R.objOf(setting.key)(withStringBooleanToBoolean(setting.value))
    ),
    R.mergeAll
  )(data)
