export const getImageFileExtension = (buffer: ArrayBuffer) => {
  const uint8arr = new Uint8Array(buffer)

  const length = 4
  if (uint8arr.length >= length) {
    const signatureArr = new Array(length)
    for (let i = 0; i < length; i++) {
      signatureArr[i] = uint8arr[i].toString(16)
    }

    const signature = signatureArr.join('').toUpperCase()

    // All jpeg files start with FFD8FF and then a range from E0 to EF
    // Some files have FFDB at the end
    const jpegRegex = /^FFD8FF(E[0-9A-F]|DB)/i
    const isJpegSignature = jpegRegex.test(signature)

    if (isJpegSignature) {
      return 'jpeg'
    }

    switch (signature) {
      case '89504E47':
        return 'png'
      case '47494638':
        return 'gif'
      default:
        throw new Error(`Invalid file type detected: ${signature}`)
    }
  }

  throw new Error('Invalid array buffer')
}

export const ensureActualFileExtension = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result instanceof ArrayBuffer) {
        const actualExt = getImageFileExtension(event.target.result)
        const fileName = file.name.replace(/\.[^/.]+$/, `.${actualExt}`)

        const newFile = new File([event.target.result], fileName, {
          type: `image/${actualExt}`,
        })
        resolve(newFile)
      }

      reject(new Error('Invalid file'))
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsArrayBuffer(file)
  })
}
