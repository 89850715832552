import React, { useCallback, useEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider, colors } from '@mattilsynet/mt-ui'
import { v1 as uuidV1 } from 'uuid'
import { themePicker } from './common/theme'
import store, { persistor } from './reducers/store'
import { useTypedSelector } from './common/custom-hooks'
import '@mattilsynet/mt-ui/dist/assets/global.css'
import './app.css'
import './print.css'
import { config$, oidcConfig$ } from './config'
import { uiActions } from './ducks/ui/actions'
import { notifications } from '@mattilsynet/mt-common'
import dayjs from 'dayjs'
import locale from './common/nb-locale-dayjs'
import './common/elastic-logging'
import { PersistGate } from 'redux-persist/integration/react'
import { isUserDeviceDesktop } from './common/userDevice'
import { authReadySubject } from './auth/helpers'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { queryClient } from './api/query-client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { LocalToggleProvider } from './common/unleashToggles'
import { checkNetwork } from './utils/network'
import { router } from './routes/router'
import { IsQueryClientReadyProvider } from './utils/query-client-ready'
import { persistOptions } from './api/query-client-persister'
import { QueryClient } from '@tanstack/react-query'

dayjs.locale(locale)

config$.subscribe((config) => store.dispatch(uiActions.configLoaded(config)))
oidcConfig$.subscribe((oidcConfig) => {
  store.dispatch(uiActions.setOidcConfig(oidcConfig))
})

store.dispatch(
  uiActions.setUserDevice(
    isUserDeviceDesktop(navigator.platform, navigator.userAgent)
  )
)

const updateNotificationUUID = uuidV1()

export const updateInstalled = () => {
  store.dispatch({ type: 'UPDATE_INSTALLED' })

  store.dispatch(
    notifications.actions.add([
      {
        id: updateNotificationUUID,
        title: 'Applikasjon oppdatert',
        body: 'Oppdatering vil bli tatt i bruk neste gang du åpner applikasjonen',
        created: new Date().toString(),
        status: 'info',
        local: true,
        link: {
          onClick: () => window.location.reload(),
          text: 'Last inn applikasjonen på nytt',
        },
      },
    ])
  )
}

/**
 * HACK: Fixes bug in Tanstack React Query (https://github.com/TanStack/query/issues/6825)
 * REMOVEME: When issue is fixed
 *
 * When the network is unstable the sync can freeze only solved by a refresh.
 * This function forces the sync to continue.
 * @param queryClient
 */
const continueFirstMutation = (queryClient: QueryClient) => {
  const pendingMutations = queryClient
    .getMutationCache()
    .findAll({ status: 'pending' })

  const first = pendingMutations[0]

  first?.continue()
}

const App = () => {
  const [isQueryClientReady, setIsQueryClientReady] = useState(false)

  const env = useTypedSelector((state) => state.ui.environment)
  const theme = themePicker(env)
  colors.makeCustomProperties(theme, document.documentElement)

  useEffect(() => {
    checkNetwork()
  }, [])

  const onSuccess = useCallback(() => {
    if (!window.navigator.onLine) {
      setIsQueryClientReady(true)
    }

    authReadySubject.subscribe((isReady) => {
      const authReadyInit = async () => {
        if (isReady && window.navigator.onLine) {
          // REMOVEME: See `continueFirstMutation` func
          setTimeout(() => continueFirstMutation(queryClient), 1000)
          await queryClient.resumePausedMutations()
          await queryClient.invalidateQueries()

          setIsQueryClientReady(true)
        }
      }

      void authReadyInit()
    })
  }, [])

  return (
    <LocalToggleProvider>
      <PersistGate loading={null} persistor={persistor}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={persistOptions}
          onSuccess={onSuccess}
        >
          <IsQueryClientReadyProvider value={isQueryClientReady}>
            <ThemeProvider value={theme}>
              <RouterProvider router={router} />
            </ThemeProvider>
          </IsQueryClientReadyProvider>

          <ReactQueryDevtools position="top" buttonPosition="top-left" />
        </PersistQueryClientProvider>
      </PersistGate>
    </LocalToggleProvider>
  )
}

export default App
