import { IObservasjon } from './types'
import { IKvitteringData } from '../../ducks/kvittering/types'
import { getDeepItemById } from '../../common/helpers'

let rId = -100
export const observasjonInitialState = (id = rId--): IObservasjon => ({
  tekst: '',
  bildeIds: [],
  kontrollpunktId: '',
  isSavedLocally: false,
  id,
  _links: {
    self: {
      href: '',
    },
  },
})

export const getObservasjonById = (
  kvittering: IKvitteringData,
  observasjonId: number
) =>
  getDeepItemById<IObservasjon>(
    kvittering,
    ['tilsynsobjekter', 'kontrollpunkter', 'observasjoner'],
    observasjonId
  )
