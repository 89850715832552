import { mergeKeyWith } from '../../../common/query'

const getAvdelingstilhorighetKeys = () => {
  const keys = {
    all: [{ entity: 'avdelingstilhorighet' }],
    kvitteringId: (kvitteringId: string) =>
      mergeKeyWith(keys.all, { kvitteringId }),
  } as const

  return keys
}

const avdelingstilhorighetKeys = getAvdelingstilhorighetKeys()

export { avdelingstilhorighetKeys }
