import { v1 as uuidv1 } from 'uuid'
import { IDeltaker } from '../types'
import { DeepReadonly } from '../../../common/types'
import { queryClient } from '../../../api/query-client'

const getDeltakerKeys = (allKey: string) => {
  const keys = {
    all: [{ entity: allKey }] as const,
    kvitteringId: (id: string) => [{ ...keys.all[0], id }] as const,
    url: (kvitteringId: string, url: string) =>
      [{ ...keys.kvitteringId(kvitteringId)[0], url }] as const,
    uuid: (kvitteringId: string, deltakerUuid: string) =>
      [{ ...keys.kvitteringId(kvitteringId)[0], deltakerUuid }] as const,
    add: () => [{ ...keys.all[0], action: 'add' }] as const,
    update: () => [{ ...keys.all[0], action: 'update' }] as const,
    remove: () => [{ ...keys.all[0], action: 'remove' }] as const,
  } as const

  return keys as DeepReadonly<typeof keys>
}

export type IDeltakerKeys = ReturnType<typeof getDeltakerKeys>

export const virksomhetDeltakerKeys = getDeltakerKeys('virksomhet-deltakere')
export const mattilsynetDeltakerKeys = getDeltakerKeys('mattilsynet-deltakere')
export const andreDeltakereKeys = getDeltakerKeys('andre-deltakere')

export const initialDeltaker = (): IDeltaker => ({
  id: uuidv1(),
  navn: '',
  _links: {
    self: {
      href: '',
    },
  },
})

export const getKvitteringId = (url: string): string => {
  const id = url.match(
    /tilsynskvitteringer\/(\d+)\/(virksomhet|mattilsynet|andre)-deltagere/
  )?.[1]

  if (!id) {
    throw new Error('URL must include kvitteringId')
  }

  return id
}

export const deltakerQueryHelpers = {
  getVirksomhetDeltakere: (kvitteringId: string, url: string) =>
    queryClient.getQueryData<IDeltaker[]>(
      virksomhetDeltakerKeys.url(kvitteringId, url)
    ),
  getMattilsynetDeltakere: (kvitteringId: string, url: string) =>
    queryClient.getQueryData<IDeltaker[]>(
      mattilsynetDeltakerKeys.url(kvitteringId, url)
    ),
  getAndreDeltakere: (kvitteringId: string, url: string) =>
    queryClient.getQueryData<IDeltaker[]>(
      andreDeltakereKeys.url(kvitteringId, url)
    ),
  removeDeltakerQueries: (kvitteringId: string) => {
    queryClient.removeQueries({
      queryKey: virksomhetDeltakerKeys.kvitteringId(kvitteringId),
    })
    queryClient.removeQueries({
      queryKey: mattilsynetDeltakerKeys.kvitteringId(kvitteringId),
    })
    queryClient.removeQueries({
      queryKey: andreDeltakereKeys.kvitteringId(kvitteringId),
    })
  },
}
