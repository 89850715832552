import {
  ArkiveringStatusActions,
  IArkiveringStatusState,
  FETCH_KVITTERING_ARKIV_INFO,
  FETCH_KVITTERING_ARKIV_INFO_SUCCESS,
  FETCH_KVITTERING_ARKIV_INFO_FAILED,
} from './types'
import { arkiveringStatusActionsTypes } from './actions'

export const initialState: IArkiveringStatusState = {
  data: [],
  error: '',
  loading: false,
  arkivInfo: {},
}

const arkiveringStatusReducer = (
  state = initialState,
  action: ArkiveringStatusActions
): IArkiveringStatusState => {
  switch (action.type) {
    case arkiveringStatusActionsTypes.FETCH:
      return {
        ...state,
        loading: true,
      }
    case arkiveringStatusActionsTypes.FETCH_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case arkiveringStatusActionsTypes.FETCH_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
        error: '',
      }

    case FETCH_KVITTERING_ARKIV_INFO:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case FETCH_KVITTERING_ARKIV_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        arkivInfo: {
          ...action.payload,
        },
      }

    case FETCH_KVITTERING_ARKIV_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default arkiveringStatusReducer
