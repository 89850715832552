import React, { useCallback } from 'react'
import { Column, LoadingDots, LoadingSpinner, Row } from '@mattilsynet/mt-ui'
import { useAndreDeltakere, useAndreDeltakereMutation } from '../index'
import { useTypedSelector } from '../../../common/custom-hooks'
import {
  useDeltakereFns,
  useRemoveEmptyDeltaker,
} from '../hooks/component-hooks'
import { DeleteModal } from '../../../modals'
import AddDeltaker from './add-deltaker'
import { DeltakerItem } from './deltaker-item'
import { createSelector } from 'reselect'
import { IStoreState } from '../../../reducers/types'
import { ENDPOINT } from '../../../constants'

const ANDRE_DELTAKERE = 'andre-deltagere'

const selectUrlAndDeltakere = createSelector(
  (state: IStoreState) => state.kvittering.selectedKvittering.data,
  ({ id, [ANDRE_DELTAKERE]: kvitteringDeltakere }) => ({
    url: `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${id}/${ANDRE_DELTAKERE}`,
    kvitteringDeltakere,
  })
)

export const AndreDeltakere = () => {
  const { url, kvitteringDeltakere } = useTypedSelector(selectUrlAndDeltakere)

  const deltakere = useAndreDeltakere(url, {
    initialData: kvitteringDeltakere,
  })
  const mutation = useAndreDeltakereMutation(url)
  const {
    onAdd,
    onUpdate,
    onRemove,
    shouldAutoFocus,
    hasError,
    remove,
    setDeltakerToBeRemoved,
    deltakerToBeRemoved,
    isAddLoading,
  } = useDeltakereFns(deltakere, mutation, { type: 'andre' })

  useRemoveEmptyDeltaker(deltakere.data ?? [], onRemove)

  const onCancelRemove = useCallback(
    () => setDeltakerToBeRemoved(undefined),
    [setDeltakerToBeRemoved]
  )

  return (
    <>
      <Column spacing={2}>
        <Column spacing={2}>
          <Row className="info-header">Andre til stede</Row>

          {deltakere.isLoading && (
            <Row center>
              <LoadingSpinner title={'Laster deltakerer...'} small />
            </Row>
          )}

          {!deltakere.isLoading &&
            deltakere.data?.map((deltaker) => (
              <DeltakerItem
                key={deltaker.id}
                deltaker={deltaker}
                onUpdateDeltaker={onUpdate}
                onRemoveDeltaker={onRemove}
                autoFocus={shouldAutoFocus(deltaker)}
                danger={hasError}
              />
            ))}
        </Column>

        {(isAddLoading ||
          (deltakere.isFetching && deltakere.data?.length === 0)) && (
          <Row justify="center">
            <LoadingDots />
          </Row>
        )}

        {!deltakere.isLoading && <AddDeltaker onAdd={onAdd} />}
      </Column>

      <DeleteModal
        isOpen={!!deltakerToBeRemoved}
        onCancel={onCancelRemove}
        onDelete={remove}
        title="Er du sikker på at du vil slette deltaker?"
        confirmText="Ja, jeg vil slette deltaker"
        cancelText="Avbryt"
      />
    </>
  )
}
