import React from 'react'
import {
  TextLink,
  GridItem,
  Row,
  Grid,
  Column,
  RowItem,
  ShadowBox,
} from '@mattilsynet/mt-ui'
import { StatusIndicator } from '../../components/status-indicator'
import './style.css'

interface IAboutViewProps {
  apiStatus: {
    checked: boolean
    ok: boolean
  }
  goBack: () => void
}

export const AboutView = ({ apiStatus, goBack }: IAboutViewProps) => (
  <Grid id="about">
    <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
      <Row>
        <TextLink leftArrow onClick={goBack}>
          Tilbake
        </TextLink>
      </Row>
    </GridItem>
    <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
      <ShadowBox>
        <Column spacing={1} padding={2.5}>
          <h1>Om applikasjonen</h1>
          <Column spacing={1}>
            <h2>Tilsynskvittering</h2>
            <Row>
              Webløsning som lar deg registrere deltakere, kontrollpunkter,
              observasjoner og veiledning på inspeksjoner. Du kan også ta bilder
              direkte i løsningen eller legge til bilder fra fotoløsningen.
              Registreringene resulterer i et dokument som fremviser det
              foreløpige faktagrunnlaget i saken på en strukturert måte for
              part. Løsningen legger til rette for automatisk partsinnsyn ved at
              tilsynskvitteringen, med ev. bilder, arkiveres korrekt og sendes
              ut via Svar-Ut-tjenesten. Utsendelsen gjøres umiddelbart etter
              endt inspeksjon, uavhengig av den etterfølgende
              inspeksjonsrapporten
            </Row>
          </Column>
          <Column spacing={1}>
            <h2>Brukerstøtte</h2>
            <div>
              <span className="space">
                Hvis du har behov for hjelp kan du opprette
              </span>
              <TextLink href="https://makuba.mattilsynet.no/" linkOutside>
                brukerstøttesak
              </TextLink>
              <Row>(Fungerer best på PC på Mattilsynets nettverk)</Row>
            </div>
            <Row>
              Du kan også kontakte IKT-brukerstøtte på telefon eller e-post:
            </Row>
            <Row>
              <RowItem className="space">Telefon:</RowItem>
              <TextLink href="tel:22 77 88 99">22 77 88 99</TextLink>
            </Row>
            <Row>
              <RowItem className="space">E-post:</RowItem>
              <TextLink href="mailto:99@mattilsynet.no">
                99@mattilsynet.no
              </TextLink>
            </Row>
          </Column>
          <Column spacing={1}>
            <h2>Status</h2>
            <Row>
              <RowItem className="space">Server:</RowItem>
              <StatusIndicator checked={apiStatus.checked} ok={apiStatus.ok} />
            </Row>
          </Column>
          <Column spacing={1}>
            <h2>Versjon</h2>
            <Row>{process.env.APP_VERSION}</Row>
          </Column>
        </Column>
      </ShadowBox>
    </GridItem>
  </Grid>
)
