// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#forbered-new-tilsyn {
  margin: 0 0 20px 0;
}

#forbered-new-tilsyn .forbered-title {
  font-size: 1.5em;
  font-weight: bold;
}

#forbered-new-tilsyn .forbered-subtitle {
  font-size: 1.125em;
  font-weight: bold;
}

#forbered-new-tilsyn .button-save .Button {
  max-width: 18em;
}

#forbered-new-tilsyn .description {
  word-break: break-all;
}
`, "",{"version":3,"sources":["webpack://./src/routes/forbered-new-tilsyn/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["#forbered-new-tilsyn {\n  margin: 0 0 20px 0;\n}\n\n#forbered-new-tilsyn .forbered-title {\n  font-size: 1.5em;\n  font-weight: bold;\n}\n\n#forbered-new-tilsyn .forbered-subtitle {\n  font-size: 1.125em;\n  font-weight: bold;\n}\n\n#forbered-new-tilsyn .button-save .Button {\n  max-width: 18em;\n}\n\n#forbered-new-tilsyn .description {\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
