import { queryApi } from '../../../api'
import { ENDPOINT } from '../../../constants'
import { IVirksomhetTilsynsobjekt } from '../../../ducks/virksomhet-search/types'
import { ITilsynsobjektMutateVars } from '../types'

export const tilsynsobjektApi = {
  post: async ({ kvitteringId, tilsynsobjekt }: ITilsynsobjektMutateVars) => {
    const tb = tilsynsobjekt as IVirksomhetTilsynsobjekt

    const tilsynsobjektPayload = {
      tilsynsobjektId: tb.idstring,
      navn: tb.navn,
      aktivitetsBeskrivelse: tb.aktivitet.beskrivelse,
      aktivitetsId: tb.aktivitet.id,
      midlertidig: !!tb.midlertidig,
      virksomhetsNummer: tb.virksomhetsNummer,
      virksomhetsNavn: tb.virksomhetsnavn,
    }

    const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvitteringId}/tilsynsobjekter`

    return queryApi.post(url, tilsynsobjektPayload)
  },
  delete: async ({ tilsynsobjekt }: ITilsynsobjektMutateVars) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynsobjekter/${tilsynsobjekt.id}`

    if (!tilsynsobjekt.id) {
      throw new Error('tilsynsobjektId is missing')
    }

    return queryApi.delete(url)
  },
}
