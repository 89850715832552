import {
  Column,
  ContrastBox,
  ErrorBox,
  Header,
  LoadingSpinner,
  RadioButtonList,
  Row,
  Text,
  UnorderedList,
} from '@mattilsynet/mt-ui'
import React from 'react'
import { TextWithValidation } from '../../components/text-with-validation'
import {
  ERROR_FETCHING_SAMMENSTILLING,
  IKvitteringData,
} from '../../ducks/kvittering/types'
import {
  isValidBilderGjennomgaatt,
  isValidBilderGjennomgaattBegrunnelse,
  isValidfaktaarkUtlevert,
  isValidRegistrertInnholdGjennomgaatt,
  isValidRegistrertInnholdGjennomgaattBegrunnelse,
  isVeiledning,
} from './utils'
import {
  ANNET_BILDER_ID,
  ANNET_REGISTRERT_INNHOLD_ID,
  options,
  optionsBilderGjennomgaat,
  optionsRegistrertInnholdGjennomgaatt,
} from './sammenstilling-options'
import { RadioButtonCustomSammenstilling } from './radio-button-custom-sammenstilling'
import { useTypedSelector } from '../../common/custom-hooks'
import ImageNavigationModal from '../../components/image-navigation-modal'
import { isUuid } from '../../common/string-utils'
import { InformationBox } from '../../components/information-box'
import { IStoreState } from 'src/reducers/types'
import { compareSorteringsrekkefoelge } from '../../features/kontrollpunkter/helpers'
import { BilderEdit } from '../../features/bilder'
import { IDeltaker } from '../../features/deltakere/types'
import { IObservasjon } from '../../features/observasjoner/types'
import { BildeGroupedItem } from '../../features/bilder/types'

const isValue = (value: boolean | undefined) =>
  value !== undefined ? (value ? '1' : '0') : undefined

export const getDefaultBegrunnelse = (begrunnelse, option) => {
  if (
    option.some((option) => option.value === begrunnelse) ||
    isUuid(begrunnelse)
  ) {
    return ''
  }
  return begrunnelse
}

export const SammenstillingLoading = () => (
  <Column spacing={4} fluid>
    <Row center>
      <LoadingSpinner title="Henter sammenstilling..." small />
    </Row>
  </Column>
)

interface ISammenstillingError {
  status?: string
  errorAction: () => void
}
export const SammenstillingError = ({
  status,
  errorAction,
}: ISammenstillingError) => (
  <Column spacing={4} fluid>
    <Row className="title">
      <h1>Sammenstilling</h1>
    </Row>
    <Row minHeight="40vh" center>
      {status === ERROR_FETCHING_SAMMENSTILLING && (
        <ErrorBox
          errorAction={errorAction}
          errorActionText="Prøv igjen"
          errorText="Vi kunne ikke hente sammenstilling."
        />
      )}
    </Row>
  </Column>
)

interface ISammenstilling {
  kvittering: IKvitteringData
  allBilder: BildeGroupedItem[]
  typeBeskrivelse: string[]
  shouldValidate: boolean
  disabled?: boolean

  tempVirksomhetDeltakere?: IDeltaker[]
  tempMattilsynetDeltakere?: IDeltaker[]
  tempAndreDeltakere?: IDeltaker[]

  faktaarkUtlevert?: boolean
  onChangeFaktaaket?: (val: string) => void

  bilderGjennomgaatt?: boolean
  bilderGjennomgaattBegrunnelse: string
  isBildetRadioDirty?: boolean
  onChangeBilderGjennomgaatt?: (val: string) => void
  onChangeBilderGjennomgaattBegrunnelse?: (val: string) => void
  setIsBegrunnelseBildetModalOpen?: () => void

  registrertInnholdGjennomgaatt?: boolean
  registrertInnholdGjennomgaattBegrunnelse: string
  isInnholdRadioDirty?: boolean
  onChangeRegistrertInnholdGjennomgaatt?: (val: string) => void
  onChangeRegistrertInnholdGjennomgaattBegrunnelse?: (val: string) => void
  setIsBegrunnelseRegistrertInnholdGjennomgaattModalOpen?: () => void
}

/**
 * ONLY USE AS EDITABLE COMPONENT
 */
const SammenstillingEdit = ({
  kvittering,
  allBilder,
  typeBeskrivelse,
  shouldValidate,
  disabled = false,

  tempVirksomhetDeltakere,
  tempMattilsynetDeltakere,
  tempAndreDeltakere,

  faktaarkUtlevert,
  onChangeFaktaaket,
  bilderGjennomgaatt,
  bilderGjennomgaattBegrunnelse,
  isBildetRadioDirty,
  onChangeBilderGjennomgaatt,
  onChangeBilderGjennomgaattBegrunnelse,
  setIsBegrunnelseBildetModalOpen,
  registrertInnholdGjennomgaatt,
  registrertInnholdGjennomgaattBegrunnelse,
  isInnholdRadioDirty,
  onChangeRegistrertInnholdGjennomgaatt,
  onChangeRegistrertInnholdGjennomgaattBegrunnelse,
  setIsBegrunnelseRegistrertInnholdGjennomgaattModalOpen,
}: ISammenstilling) => {
  const allBildeIds = allBilder.map((item) => item.id)

  const generelleBildeIds = allBilder.reduce((ids, item) => {
    if (item.observasjonId !== undefined) {
      return ids
    }

    ids.push(item.id)
    return ids
  }, [] as string[])

  const maalform = useTypedSelector(
    (state: IStoreState) => state.userSettings.data?.maal
  )

  const renderDeltagere = (headerText: string, deltagere: any[]) => {
    if (deltagere.length > 0) {
      return (
        <Column spacing={1}>
          {headerText.length > 0 && (
            <Row as="h4" className="info-header">
              {headerText}
            </Row>
          )}
          {deltagere.map((deltager) => (
            <Row key={deltager._links.self.href}>{deltager.navn}</Row>
          ))}
        </Column>
      )
    }
  }

  const renderMattilsynetDeltagere = (
    headerText: string,
    deltagere: any[],
    kvittering: IKvitteringData,
    disabled = false
  ) => {
    const textValue =
      'Du har lagt inn en forklaring på hvorfor navn er skjult. Denne forklaringen vil ikke fremkomme i tilsynskvitteringen.'
    const errText = 'Forklaring på hvorfor navn er skjult mangler'
    if (kvittering.mattilsynetSkjermet) {
      if (!!kvittering.mattilsynetSkjermetBegrunnelse) {
        return (
          <Column spacing={1}>
            {headerText.length > 0 && (
              <Row as="h4" className="info-header">
                {headerText}
              </Row>
            )}
            {deltagere.map((deltager) => (
              <Row key={deltager._links.self.href}>*****</Row>
            ))}
            {disabled ? (
              <Text className="overflowingText">{textValue}</Text>
            ) : (
              <TextWithValidation
                errorText={''}
                value={textValue}
                hasError={false}
                withWhiteSpace
              />
            )}
          </Column>
        )
      } else {
        return (
          <Column spacing={1}>
            {headerText.length > 0 && (
              <Row as="h4" className="info-header">
                {headerText}
              </Row>
            )}
            {deltagere.map((deltager) => (
              <Row key={deltager._links.self.href}>*****</Row>
            ))}
            {disabled ? (
              <Text className="overflowingText" weight={'bold'}>
                {errText}
              </Text>
            ) : (
              <TextWithValidation
                errorText={errText}
                value="1"
                hasError={true}
                as="h4"
                bold
                withWhiteSpace
              />
            )}
          </Column>
        )
      }
    }
    return renderDeltagere(headerText, deltagere)
  }

  const renderVirksomhetDeltagere = (
    kvittering: IKvitteringData,
    disabled = false
  ) => {
    const isPrivatePerson = !kvittering.virksomhetsOrgNr
    const deltakere = tempVirksomhetDeltakere ?? []

    const containsVirksomhetDeltagere = deltakere.some(
      (deltager) => !!deltager.navn.trim()
    )

    if (containsVirksomhetDeltagere) {
      const header = isPrivatePerson ? '' : `Fra ${kvittering.virksomhetsNavn}`
      return renderDeltagere(header, deltakere)
    }

    if (kvittering.ingenTilStede && !!kvittering.ingenTilStedeBegrunnelse) {
      const header = isPrivatePerson
        ? 'Ingen var til stede'
        : 'Ingen fra virksomheten var til stede'

      return (
        <Column spacing={1}>
          <Row as="h4" className="info-header">
            {header}
          </Row>

          <Row>{kvittering.ingenTilStedeBegrunnelse}</Row>
        </Column>
      )
    }

    if (
      kvittering.ingenTilStede &&
      (!kvittering.ingenTilStedeBegrunnelse ||
        kvittering.ingenTilStedeBegrunnelse.length < 1)
    ) {
      const info = {
        header: isPrivatePerson
          ? 'Ingen privatperson var til stede'
          : 'Ingen fra virksomheten var til stede',
        error: isPrivatePerson
          ? 'Forklaring på hvorfor ingen privatperson var til stede mangler'
          : 'Forklaring på hvorfor ingen fra virksomheten var til stede mangler',
      }

      return (
        <Column spacing={1}>
          <Row as="h4" className="info-header">
            {info.header}
          </Row>

          {disabled ? (
            <Text className="overflowingText" weight={'bold'}>
              {info.error}
            </Text>
          ) : (
            <TextWithValidation
              errorText={info.error}
              value="1"
              hasError={true}
              as="h4"
              bold
            />
          )}
        </Column>
      )
    }

    const error = isPrivatePerson
      ? 'Informasjon om deltager for privatpersonen mangler'
      : 'Informasjon om deltager fra virksomheten mangler'

    return (
      <Column spacing={1}>
        {disabled ? (
          <Text>{error}</Text>
        ) : (
          <TextWithValidation
            errorText={error}
            value="1"
            hasError={true}
            as="h4"
            bold
            withWhiteSpace
          />
        )}
      </Column>
    )
  }

  const renderObservasjoner = (observasjon: IObservasjon, key: number) => {
    const hasError = observasjon.tekst == ''
    const bildeIds = allBilder
      .filter((bilde) => bilde.observasjonId === observasjon.id)
      .map((bilde) => bilde.id)

    return (
      <React.Fragment key={key}>
        <Row>
          <Column>
            {disabled ? (
              <Text whiteSpace="pre-wrap" className="overflowingText">
                {observasjon?.tekst}
              </Text>
            ) : (
              <TextWithValidation
                errorText={'Observasjon mangler'}
                value={observasjon.tekst}
                withWhiteSpace
                hasError={hasError}
                bold={hasError}
              />
            )}
          </Column>
        </Row>

        {kvittering.id && bildeIds.length > 0 && (
          <BilderEdit
            bildeIds={bildeIds}
            kvitteringId={kvittering.id}
            observasjonId={observasjon.id}
            galleryBildeIds={allBildeIds}
          />
        )}
      </React.Fragment>
    )
  }

  const renderTilsynsobjekter = (
    kvittering: IKvitteringData,
    disabled = false
  ) =>
    kvittering.tilsynsobjekter?.map((tilsynsobjekt) => {
      const sortedKontrollpunkter = tilsynsobjekt?.kontrollpunkter
        ?.slice()
        .sort(compareSorteringsrekkefoelge)

      return (
        <React.Fragment key={tilsynsobjekt.tilsynsobjektId || tilsynsobjekt.id}>
          <Row as="h3">
            {`${tilsynsobjekt.navn} (${tilsynsobjekt.aktivitetsBeskrivelse})`}
          </Row>

          {!disabled && tilsynsobjekt?.kontrollpunkter?.length === 0 ? (
            <TextWithValidation
              errorText={'1. Kontrollpunkt mangler'}
              value="1"
              hasError={true}
              as="h4"
              bold
              withWhiteSpace
            />
          ) : (
            sortedKontrollpunkter.map((kontrollpunkt, index) => (
              <ContrastBox
                noBorders
                backgroundColor="white"
                key={kontrollpunkt.id}
              >
                <Column spacing={1}>
                  {disabled ? (
                    <Text
                      whiteSpace="pre-wrap"
                      className="overflowingText"
                      weight="bold"
                    >{`${index + 1}. ${kontrollpunkt?.beskrivelse}`}</Text>
                  ) : (
                    <TextWithValidation
                      errorText={`${index + 1}. Kontrollpunkt mangler`}
                      value={`${index + 1}. ${kontrollpunkt.beskrivelse}`}
                      hasError={kontrollpunkt.beskrivelse == ''}
                      as="h4"
                      bold
                      withWhiteSpace
                    />
                  )}

                  {!disabled && kontrollpunkt?.observasjoner?.length === 0 ? (
                    <TextWithValidation
                      errorText={'Observasjon mangler'}
                      value="1"
                      hasError={true}
                      withWhiteSpace
                      bold
                    />
                  ) : (
                    kontrollpunkt?.observasjoner?.map((observasjon, key) =>
                      renderObservasjoner(observasjon, key)
                    )
                  )}
                </Column>
              </ContrastBox>
            ))
          )}
        </React.Fragment>
      )
    })

  const renderTypeTilsyn = (
    kvittering: IKvitteringData,
    typeBeskrivelse: string[],
    disabled = false
  ) => {
    return (
      <ContrastBox noBorders backgroundColor="white">
        <Column spacing={2}>
          <Header as="h2" size="heading2">
            Type tilsyn
          </Header>
          <>
            {!disabled && !kvittering.typeTilsyn ? (
              <TextWithValidation
                errorText={'Tilsynstype mangler'}
                value="1"
                hasError={true}
                as="h4"
                bold
                withWhiteSpace
              />
            ) : (
              <Text>{`${typeBeskrivelse} ${
                kvittering.typeTilsynBeskrivelse &&
                kvittering.typeTilsynBeskrivelse.trim().length > 0
                  ? '- ' + kvittering.typeTilsynBeskrivelse
                  : ''
              }`}</Text>
            )}
            {!disabled &&
              kvittering.typeTilsyn === 'ANNET' &&
              (!kvittering.typeTilsynBeskrivelse ||
                kvittering.typeTilsynBeskrivelse?.trim().length === 0) && (
                <TextWithValidation
                  errorText={'Beskrivelse av type tilsyn mangler'}
                  value="1"
                  hasError={true}
                  as="h4"
                  bold
                  withWhiteSpace
                />
              )}
          </>
        </Column>
      </ContrastBox>
    )
  }

  const renderGenerellInformasjon = ({
    generellInformasjon,
  }: IKvitteringData) => {
    if (generellInformasjon === undefined || generellInformasjon === '') {
      return null
    }

    return (
      <ContrastBox noBorders backgroundColor="white">
        <Column spacing={2}>
          <Header as="h2" size="heading2">
            Faktabeskrivelse om tilsynsobjektet/ene
          </Header>
          <Text className="overflowingText" whiteSpace="pre-wrap">
            {generellInformasjon}
          </Text>
        </Column>
      </ContrastBox>
    )
  }

  const renderVeiledninger = (
    kvittering: IKvitteringData,
    disabled = false
  ) => {
    if (isVeiledning(kvittering)) {
      return (
        <ContrastBox noBorders backgroundColor="white">
          <Column spacing={2}>
            <Header as="h2" size="heading2">
              Veiledning
            </Header>
            {kvittering.tilsynsobjekter
              .filter(
                (tilsynsobjekt) =>
                  tilsynsobjekt.veiledninger &&
                  tilsynsobjekt.veiledninger.length > 0 &&
                  tilsynsobjekt.veiledninger[0]?.tekst?.length > 0
              )
              .map((tilsynsobjekt) => (
                <React.Fragment
                  key={tilsynsobjekt.tilsynsobjektId || tilsynsobjekt.id}
                >
                  <Row as="h3">
                    {`${tilsynsobjekt.navn} (${tilsynsobjekt.aktivitetsBeskrivelse})`}
                  </Row>
                  {tilsynsobjekt.veiledninger
                    ?.filter((veiledning) => !!veiledning.tekst.length)
                    .map((veiledning) => (
                      <Row key={veiledning._links.self.href}>
                        {disabled ? (
                          <Text
                            whiteSpace="pre-wrap"
                            className="overflowingText"
                          >
                            {veiledning.tekst}
                          </Text>
                        ) : (
                          <TextWithValidation
                            errorText={''}
                            value={veiledning.tekst}
                            hasError={false}
                            withWhiteSpace
                          />
                        )}
                      </Row>
                    ))}
                </React.Fragment>
              ))}
          </Column>
        </ContrastBox>
      )
    }

    return null
  }

  const renderGenerelleBilder = () => {
    if (generelleBildeIds.length === 0) {
      return null
    }

    return (
      <ContrastBox noBorders backgroundColor="white">
        <Column spacing={2}>
          <Header as="h2" size="heading2">
            Andre bilder tatt på tilsynet
          </Header>

          <Row>
            <Text>
              Dette er bilder som blir med i tilsynskvitteringen men som ikke er
              knyttet til en observasjon
            </Text>
          </Row>

          {kvittering.id && (
            <BilderEdit
              bildeIds={generelleBildeIds}
              kvitteringId={kvittering.id}
              galleryBildeIds={allBildeIds}
            />
          )}
        </Column>
      </ContrastBox>
    )
  }

  return (
    <Column spacing={4} fluid>
      {maalform && maalform !== 'nb' && (
        <InformationBox variant="warning">
          <Text>
            Du har satt målform til nynorsk, og tekster som automatisk genereres
            i pdf-en vil være nynorske selv om det ikke ser sånn ut her
          </Text>
        </InformationBox>
      )}

      <Row className="title">
        <h1>Sammenstilling</h1>
      </Row>

      {renderTypeTilsyn(kvittering, typeBeskrivelse, disabled)}

      {renderGenerellInformasjon(kvittering)}

      <ContrastBox noBorders backgroundColor="white">
        <Column spacing={2}>
          <Header as="h2" size="heading2">
            Til stede under tilsynet
          </Header>
          {renderVirksomhetDeltagere(kvittering, disabled)}
          {tempMattilsynetDeltakere &&
            tempMattilsynetDeltakere.length > 0 &&
            renderMattilsynetDeltagere(
              'Fra Mattilsynet',
              tempMattilsynetDeltakere,
              kvittering,
              disabled
            )}
          {tempAndreDeltakere &&
            tempAndreDeltakere.length > 0 &&
            renderDeltagere('Andre til stede', tempAndreDeltakere)}
        </Column>
      </ContrastBox>

      <Column spacing={2}>
        <Header as="h2" size="heading2">
          Observasjoner
        </Header>
        {renderTilsynsobjekter(kvittering, disabled)}
      </Column>

      {renderGenerelleBilder()}

      {renderVeiledninger(kvittering, disabled)}

      {/* Faktaarket */}
      <Column spacing={1}>
        <ContrastBox noBorders backgroundColor="white">
          <RadioButtonList
            label="Faktaarket «Informasjon ved tilsyn» har blitt levert ut:"
            options={options}
            onChange={onChangeFaktaaket ?? (() => null)}
            value={isValue(faktaarkUtlevert)}
            noBackgroundColor
            hasError={
              shouldValidate &&
              !isValidfaktaarkUtlevert({ faktaarkUtlevert } as IKvitteringData)
            }
            disabled={disabled}
          />
        </ContrastBox>
        <TextWithValidation
          errorText={'Utfylling mangler'}
          value={''}
          hasError={
            shouldValidate &&
            !isValidfaktaarkUtlevert({ faktaarkUtlevert } as IKvitteringData)
          }
          withWhiteSpace
          as="h4"
          bold
        />
      </Column>
      {/* Bilder / Video */}
      <ContrastBox noBorders backgroundColor="white">
        <RadioButtonCustomSammenstilling
          label="Bilder og/eller video som Mattilsynet tok under inspeksjonen har blitt
          gjennomgått:"
          options={options}
          onChange={onChangeBilderGjennomgaatt}
          value={bilderGjennomgaatt}
          hasError={
            shouldValidate && !isValidBilderGjennomgaatt({ bilderGjennomgaatt })
          }
          optionsBegrunnelse={optionsBilderGjennomgaat}
          onChangeBegrunnelse={onChangeBilderGjennomgaattBegrunnelse}
          customBegrunnelseUniqueId={ANNET_BILDER_ID}
          isRadioDirty={isBildetRadioDirty}
          valueBegrunnelse={bilderGjennomgaattBegrunnelse}
          isValidBegrunnelse={isValidBilderGjennomgaattBegrunnelse({
            bilderGjennomgaatt,
            bilderGjennomgaattBegrunnelse,
          } as IKvitteringData)}
          onCustomBegrunnelseRadioOpen={setIsBegrunnelseBildetModalOpen}
          shouldValidate={shouldValidate}
          disabled={disabled}
        />
      </ContrastBox>
      {/* Innholder */}
      <ContrastBox noBorders backgroundColor="white">
        <RadioButtonCustomSammenstilling
          label="Innholdet som Mattilsynet har registrert under inspeksjonen har blitt
            gjennomgått:"
          options={options}
          onChange={onChangeRegistrertInnholdGjennomgaatt}
          value={registrertInnholdGjennomgaatt}
          hasError={
            shouldValidate &&
            !isValidRegistrertInnholdGjennomgaatt({
              registrertInnholdGjennomgaatt,
            } as IKvitteringData)
          }
          optionsBegrunnelse={optionsRegistrertInnholdGjennomgaatt}
          onChangeBegrunnelse={onChangeRegistrertInnholdGjennomgaattBegrunnelse}
          customBegrunnelseUniqueId={ANNET_REGISTRERT_INNHOLD_ID}
          isRadioDirty={isInnholdRadioDirty}
          valueBegrunnelse={registrertInnholdGjennomgaattBegrunnelse}
          isValidBegrunnelse={isValidRegistrertInnholdGjennomgaattBegrunnelse({
            registrertInnholdGjennomgaatt,
            registrertInnholdGjennomgaattBegrunnelse,
          } as IKvitteringData)}
          onCustomBegrunnelseRadioOpen={
            setIsBegrunnelseRegistrertInnholdGjennomgaattModalOpen
          }
          shouldValidate={shouldValidate}
          disabled={disabled}
        />
      </ContrastBox>

      {allBildeIds.length > 0 && (
        <ContrastBox noBorders backgroundColor="white">
          <Column spacing={1}>
            <Text>Vedlegg:</Text>
            <UnorderedList padding={[0, 4, 4, 4]} dataList={['Bilder']} />
          </Column>
        </ContrastBox>
      )}

      <ImageNavigationModal
        title="Bilder i tilsynskvitteringen"
        editable={!disabled}
      />
    </Column>
  )
}

export { SammenstillingEdit }
