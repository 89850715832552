// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tilsyn-card-item .saksnummer,
.typetilsyn,
.fordeling {
  font-size: 1em;
  font-style: italic;
  text-align: left;
}

#tilsyn-card-item .tilsyn-card-item__error-wrapper > .info-box-container {
  flex-grow: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/tilsyn-card-item/style.css"],"names":[],"mappings":"AAAA;;;EAGE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":["#tilsyn-card-item .saksnummer,\n.typetilsyn,\n.fordeling {\n  font-size: 1em;\n  font-style: italic;\n  text-align: left;\n}\n\n#tilsyn-card-item .tilsyn-card-item__error-wrapper > .info-box-container {\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
