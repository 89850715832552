import { IVeiledning } from '../../../ducks/kvittering/veiledning/types'
import {
  useKvittering,
  useOptimisticDebounceKvitteringMutate,
} from '../../kvitteringer'
import { IVeiledningMutateVars, IVeiledningVars } from '../types'
import { MutationKey, useMutation } from '@tanstack/react-query'
import { veiledningerKeys } from '../queries/helpers'
import { useCallback } from 'react'
import { DEBOUNCE_MS } from '../../../constants'
import { ITilsynsobjekt } from '../../../ducks/kvittering/tilsynsobjekt/types'
import { AxiosResponse } from 'axios'

export {
  useVeiledninger,
  useUpdateVeiledning,
  useAddVeiledning,
  useRemoveVeiledning,
}

const useVeiledninger = (kvitteringId: string) =>
  useKvittering<IVeiledning[]>(kvitteringId, {
    select: (kvittering) => kvittering.veiledninger ?? [],
  })

const useVeiledningMutation = (
  mutationKey: MutationKey,
  kvitteringId: string,
  tilsynsobjekt: ITilsynsobjekt
) => {
  const mutation = useMutation<
    AxiosResponse<null>,
    string | Error,
    IVeiledningMutateVars,
    { previousVeiledninger: IVeiledning[] }
  >({ mutationKey })

  return {
    ...mutation,
    mutate: (variables: IVeiledningVars) =>
      mutation.mutate({ kvitteringId, tilsynsobjekt, ...variables }),
  }
}

const useAddVeiledning = (
  kvitteringId: string,
  tilsynsobjekt: ITilsynsobjekt
) =>
  useVeiledningMutation(
    veiledningerKeys.add(kvitteringId),
    kvitteringId,
    tilsynsobjekt
  )

const useUpdateVeiledning = (
  kvitteringId: string,
  tilsynsobjekt: ITilsynsobjekt
) => {
  const update = useVeiledningMutation(
    veiledningerKeys.updateBeskrivelse(kvitteringId),
    kvitteringId,
    tilsynsobjekt
  )

  const optimisticDebounceUpdate = useOptimisticDebounceKvitteringMutate(
    update.mutate,
    kvitteringId,
    (kvittering, { veiledning }) => {
      if (!kvittering) return kvittering

      const { veiledninger } = kvittering

      for (const oldVeiledning of veiledninger) {
        if (oldVeiledning.id === veiledning.id) {
          oldVeiledning.tekst = veiledning.tekst
          break
        }
      }

      return kvittering
    }
  )

  const updateBeskrivelseFn = useCallback(
    (data: IVeiledningVars) => {
      const updateKey = JSON.stringify(
        veiledningerKeys.updateBeskrivelse(kvitteringId)[0]
      )

      optimisticDebounceUpdate(data, {
        debounceMs: DEBOUNCE_MS,
        key: `${updateKey}_${data.veiledning.id}`,
      })
    },
    [kvitteringId, optimisticDebounceUpdate]
  )

  return { ...update, mutate: updateBeskrivelseFn }
}

const useRemoveVeiledning = (
  kvitteringId: string,
  tilsynsobjekt: ITilsynsobjekt
) =>
  useVeiledningMutation(
    veiledningerKeys.remove(kvitteringId),
    kvitteringId,
    tilsynsobjekt
  )
