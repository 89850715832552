import { useDispatch } from 'react-redux'
import { useViewsContext } from '../../../routes/start-tilsyn'
import { useKvitteringId } from '../../../common/kvittering-context'
import { useTypedSelector } from '../../../common/custom-hooks'
import { uiSelectors } from '../../../ducks/ui/selectors'
import { useBildeMetadata } from '../hooks/metadata-query-hooks'
import { useBilde } from '../hooks/bilde-query-hooks'
import React, { useCallback } from 'react'
import { uiActions } from '../../../ducks/ui/actions'
import {
  Column,
  ErrorBox,
  LoadingSpinner,
  Row,
  RowItem,
  Text,
  TextLink,
} from '@mattilsynet/mt-ui'
import FullscreenIcon from '@mattilsynet/mt-ui/dist/icons/fullscreen'
import dayjs from 'dayjs'
import { ImageDescriptionEditor } from '../../../components/image-description-editor'
import { ImageLocationEditor } from '../../../components/image-location-editor'
import { ImageNavigation } from './image-navigation'

interface ImageInfoProps {
  fullscreen?: boolean
  editable?: boolean
}

export const ImageInfo = ({ editable = true }: ImageInfoProps) => {
  const dispatch = useDispatch()
  const { viewDispatch } = useViewsContext()
  const kvitteringId = useKvitteringId()

  const { imageId: currentImageId, bildeIds } = useTypedSelector(
    uiSelectors.getOpenImageNavigationModal
  )

  const [
    bildeMetadata,
    { refetch: refetchMetadataList, isLoading, isLoadingError },
  ] = useBildeMetadata(kvitteringId, currentImageId!)

  const [url] = useBilde(kvitteringId, bildeMetadata)

  const onShowFullSize = useCallback(() => {
    // Refactor this to exclude the uiAction
    dispatch(uiActions.setImageFullversjonOpen(true))
    viewDispatch('fullversjonImage')
  }, [dispatch, viewDispatch])

  const {
    locationLatitude: lat,
    locationLongitude: lon,
    locationCopiedFromId,
    locationDescription,
    accuracy,
  } = bildeMetadata ?? {}

  const isCopied = !!locationCopiedFromId
  const hasCoordinates = !!lat && !!lon

  let coordinatesText = `GPS [${lat?.toPrecision(8)}, ${lon?.toPrecision(8)}]`

  if (accuracy) {
    const accuracyText = ` nøyaktighet ~ ${new Intl.NumberFormat('no-NO', {
      maximumFractionDigits: 0,
      unit: 'meter',
      style: 'unit',
    }).format(accuracy)}`

    coordinatesText += accuracyText
  }

  if (isCopied) {
    coordinatesText += ' (hentet fra annet bilde på tilsynet)'
  }

  const lokasjonText = hasCoordinates ? coordinatesText : locationDescription

  if (!bildeMetadata && isLoadingError) {
    return (
      <ErrorBox
        errorText="Kunne ikke hente bilde.."
        errorAction={refetchMetadataList}
        errorActionText="Prøv på nytt"
      />
    )
  }

  if ((!bildeMetadata && isLoading) || !url) {
    return <LoadingSpinner title="Henter bilde..." />
  }

  return (
    <ImageNavigation currentImageId={currentImageId ?? ''} bildeIds={bildeIds}>
      {({ handlers, onFocusInput, onBlurInput }) => (
        <Column className={'image-wrapper'}>
          <Row justify="center">
            <Column>
              <div {...handlers} className={'image-container'}>
                <img
                  src={url}
                  className="image-view-display"
                  alt={bildeMetadata?.description}
                />
              </div>
            </Column>
          </Row>
          <Row justify="space-between" padding={[0, 2]}>
            <RowItem>
              <Row wrap={'true' as any}>
                <Text margin={[0, 1, 0, 0]} weight="bold">
                  Tidspunkt
                </Text>
                <Text whiteSpace="nowrap">
                  {dayjs(bildeMetadata?.captureTime).format(
                    'D. MMMM YYYY, H.mm'
                  )}
                </Text>
              </Row>
            </RowItem>
            <RowItem>
              <TextLink onClick={onShowFullSize} leftIcon={<FullscreenIcon />}>
                Vis fullversjon
              </TextLink>
            </RowItem>
          </Row>

          {editable && (
            <Column padding={[2, 2]}>
              {bildeMetadata && (
                <>
                  <ImageDescriptionEditor
                    bildeMetadata={bildeMetadata}
                    onFocus={onFocusInput}
                    onBlur={onBlurInput}
                    key={`${bildeMetadata.id}-description`}
                  />
                  <ImageLocationEditor
                    bildeMetadata={bildeMetadata}
                    onFocus={onFocusInput}
                    onBlur={onBlurInput}
                    key={`${bildeMetadata.id}-lokasjon`}
                  />
                </>
              )}
            </Column>
          )}

          {!editable && (
            <>
              <Row padding={[2, 2]}>
                <div>
                  <Text weight="bold" as="div">
                    Bildetittel
                  </Text>
                  <Text>{bildeMetadata?.description ?? ''}</Text>
                </div>
              </Row>
              {lokasjonText && (
                <Row padding={[0, 2, 2]}>
                  <div>
                    <Text weight="bold" as="div">
                      Sted
                    </Text>
                    <Text>{lokasjonText}</Text>
                  </div>
                </Row>
              )}
            </>
          )}
        </Column>
      )}
    </ImageNavigation>
  )
}
