import { createSelector } from 'reselect'
import { IStoreState } from '../../reducers/types'
import { IBegrunnelseItem } from './types'

export const STORE_NAME = 'hentKvitteringBegrunnelser'

export const hentKvitteringBegrunnelserActionTypes = {
  FETCH_KVITTERING_BEGRUNNELSER:
    `${STORE_NAME}/FETCH_KVITTERING_BEGRUNNELSER` as const,
  FETCH_KVITTERING_BEGRUNNELSER_SUCCESS:
    `${STORE_NAME}/FETCH_KVITTERING_BEGRUNNELSER_SUCCESS` as const,
  FETCH_KVITTERING_BEGRUNNELSER_FAIL:
    `${STORE_NAME}/FETCH_KVITTERING_BEGRUNNELSER_FAIL` as const,
}

export const hentKvitteringBegrunnelserActions = {
  fetchKvitteringBegrunnelser: () => ({
    type: hentKvitteringBegrunnelserActionTypes.FETCH_KVITTERING_BEGRUNNELSER,
  }),
  fetchKvitteringBegrunnelserSuccess: (begrunnelser: IBegrunnelseItem[]) => ({
    type: hentKvitteringBegrunnelserActionTypes.FETCH_KVITTERING_BEGRUNNELSER_SUCCESS,
    begrunnelser: begrunnelser,
  }),
  fetchKvitteringBegrunnelserFail: (error: string) => ({
    type: hentKvitteringBegrunnelserActionTypes.FETCH_KVITTERING_BEGRUNNELSER_FAIL,
    error: error,
  }),
}

export const hentKvitteringBegrunnelserSelectors = {
  getLoadingStatus: createSelector(
    (state: IStoreState) => state[STORE_NAME],
    ({ loading, loaded, error }) => ({ loading, loaded, error })
  ),
  getBegrunnelser: (state: IStoreState) =>
    state[STORE_NAME].overtagelseBegrunnelseList,
}
