import React, { FocusEvent, RefObject, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTypedSelector } from '../../common/custom-hooks'
import { userSelectors } from '../../ducks/user/selectors'
import { UserMenuView } from './view'
import { IStoreState } from '../../reducers/types'
import { useTKNavigate } from '../../common/navigation'

const UserMenu = () => {
  const authUser = useTypedSelector(userSelectors.getAuthUser)
  const userAvdeling = useTypedSelector(userSelectors.getAvdeling)
  const user = useTypedSelector(userSelectors.getMe)

  const [isOpen, toggleUserMenuOpen] = useState(false)
  const userMenuRef: RefObject<HTMLDivElement> = useRef(null)

  const navigate = useTKNavigate()
  const goToPathAndCloseMenu = (path) => () => {
    toggleUserMenuOpen(false)
    navigate(path)
  }

  const environment = useSelector((state: IStoreState) => state.ui.environment)

  const fotoPath = {
    test: 'https://foto.inspektor-test.mattilsynet.io',
    utv: 'https://foto.inspektor-utv.mattilsynet.io',
    dev: 'https://foto.inspektor-utv.mattilsynet.io',
    prod: 'https://foto.inspektor.mattilsynet.io',
    '': 'https://foto.inspektor.mattilsynet.io',
  }

  const onCloseMenuOnBlur = (event: FocusEvent) => {
    if (
      !event.relatedTarget ||
      (userMenuRef.current &&
        !userMenuRef.current.contains(event.relatedTarget as HTMLDivElement))
    ) {
      return toggleUserMenuOpen(false)
    }
  }

  if (!authUser) {
    return null
  }

  return (
    <UserMenuView
      isOpen={isOpen}
      toggleMenu={() => toggleUserMenuOpen(!isOpen)}
      goToLogout={goToPathAndCloseMenu('/logout')}
      user={user.name ? user : authUser}
      userAvdeling={userAvdeling}
      userMenuRef={userMenuRef}
      onCloseMenuOnBlur={onCloseMenuOnBlur}
      goToAbout={goToPathAndCloseMenu('/about')}
      goToSettings={goToPathAndCloseMenu('/settings')}
      calculateFotoPath={fotoPath[environment]}
    />
  )
}

export default UserMenu
