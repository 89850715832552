import { DeleteIcon } from '@mattilsynet/mt-ui'
import React, { useCallback, useState } from 'react'
import { DeleteModal } from '../../../modals'
import { BildeGroupedItem } from '../../bilder/types'
import { useKvitteringId } from '../../../common/kvittering-context'
import { useDeleteBilde } from '../../bilder/hooks/bilde-query-hooks'
import { useCanRunMultiAction } from '../hooks'
import { IMultiActionButton } from '../types'

export const DeleteSelectedImages = ({
  selectedImages,
  clearSelectedImages,
}: IMultiActionButton) => {
  const [isDeleteImagesModalOpen, setIsDeleteImagesModalOpen] = useState(false)

  const kvitteringId = useKvitteringId()

  const { mutate: deleteBilde } = useDeleteBilde(kvitteringId)

  const onDeleteImages = useCallback(() => {
    selectedImages.forEach((image: BildeGroupedItem) => {
      deleteBilde({ bildeId: image.id })
    })

    setIsDeleteImagesModalOpen(false)
    clearSelectedImages()
  }, [
    selectedImages,
    deleteBilde,
    setIsDeleteImagesModalOpen,
    clearSelectedImages,
  ])

  const onOpenDeleteImagesModal = useCanRunMultiAction(selectedImages, () =>
    setIsDeleteImagesModalOpen(true)
  )

  const onCloseDeleteImagesModal = () => setIsDeleteImagesModalOpen(false)

  const deleteImagesModalTitle = `Slett de${
    selectedImages.length === 1
      ? 't valgte bildet'
      : ` ${selectedImages.length} valgte bildene`
  }`

  return (
    <>
      <button
        className="image-action-menu__button"
        onClick={onOpenDeleteImagesModal}
      >
        <span className="tooltip-text">Slett bilder</span>
        <DeleteIcon size="large" />
      </button>
      <DeleteModal
        isOpen={isDeleteImagesModalOpen}
        title={deleteImagesModalTitle}
        onCancel={onCloseDeleteImagesModal}
        onDelete={onDeleteImages}
        explanation={
          'Bildene blir slettet fra tilsynskvitteringen og kan ikke gjenoprettes.'
        }
        confirmText="Slett"
      />
    </>
  )
}
