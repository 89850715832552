import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SettingsView } from './view'
import { useToggleCards, useTypedSelector } from '../../common/custom-hooks'
import { userSettingsActions } from '../../ducks/user-settings/store'
import { useGoBack } from '../../common/navigation'

const Settings = () => {
  const dispatch = useDispatch()
  const goBack = useGoBack()

  const currentLanguage = useTypedSelector(
    (state) => state.userSettings.data?.maal
  )

  const { openCards, toggleCard } = useToggleCards()

  const onUpdateLanguage = (language: string) => {
    dispatch(userSettingsActions.update('maal', language || ''))
  }

  useEffect(() => {
    dispatch(userSettingsActions.fetchInnstillinger())
  }, [dispatch])

  return (
    <SettingsView
      goBack={goBack}
      openAccordions={openCards}
      toggleOpenAccordions={toggleCard}
      onUpdateLanguage={onUpdateLanguage}
      currentLanguage={currentLanguage ?? 'nb'}
    />
  )
}

export default Settings
