import { queryClient } from '../../../api/query-client'
import { DeepReadonly, Prettify } from '../../../common/types'
import { ILexicon, LexiconDataType, LexiconToType } from '../types'

const getLexiconKeys = () => {
  const keys = {
    all: [{ entity: 'offline-lexicon' }] as const,
    kvitteringId: (kvitteringId: string) =>
      [{ ...keys.all[0], kvitteringId }] as const,
  }

  return keys as DeepReadonly<typeof keys>
}

const lexiconKeys = getLexiconKeys()

const getLexiconIdKey = (lexType: keyof LexiconToType, id: string) =>
  `${lexType}/${id}`

/**
 * Lexicon of persisted data to be used in the sync.
 *
 * Keys are of the form: `${lexicon type}/${offline id}`.
 *
 * Values are objects of persisted data (notably ids and urls).
 *
 * <code>Ex: ['observasjon/-1']: { id: 123, ..., _links: { self: { href: 'https://...' } } }: IObservasjon</code>
 */
export const offlineLexicon = {
  types: LexiconDataType,
  get: <T extends keyof LexiconToType>(
    lexType: T,
    id: string,
    kvitteringId: string
  ): LexiconToType[T] | undefined => {
    const lexiconIdKey = getLexiconIdKey(lexType, id)

    const lexicon = queryClient.getQueryData<Prettify<ILexicon>>(
      lexiconKeys.kvitteringId(kvitteringId)
    )

    return lexicon?.[lexiconIdKey]
  },
  set: <T extends keyof LexiconToType, TData extends LexiconToType[T]>(
    lexType: T,
    id: string,
    kvitteringId: string,
    value: TData
  ) => {
    const lexiconIdKey = getLexiconIdKey(lexType, id)

    queryClient.setQueryData<Prettify<ILexicon>>(
      lexiconKeys.kvitteringId(kvitteringId),
      (lexicon) => {
        if (lexicon === undefined) {
          lexicon = {}
        }

        lexicon[lexiconIdKey] = value
        return lexicon
      }
    )
  },
}
