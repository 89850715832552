import { IImageSizes } from '../../features/bilder/types'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { Column, ImageDescription, Text } from '@mattilsynet/mt-ui'
import dayjs from 'dayjs'

import './style.css'
import { IMAGE_DESCRIPTION_MAX_LENGTH } from '../../constants'
import { getErrorInfo } from '../../validators/image-errors'
import { ImageLocationEditor } from '../image-location-editor'
import {
  useBilde,
  useBildeMetadata,
  useUpdateBildeBeskrivelse,
  useUploadStatus,
} from '../../features/bilder'
import { useKvitteringId } from '../../common/kvittering-context'
import validate from '../../validators/text-input-validators'
import { useMetadataBeskrivelseStatus } from '../../features/bilder/hooks/component-hooks'
import { MutationStatus } from '../../common/query'

interface IImageDescriptionWrapperProps {
  imageId: string
  selected: boolean
  onSelect: (id: string) => void
  onShowFullsize: (url: string) => void
  preferredImageSize?: IImageSizes
}

const ImageDescriptionWrapper = ({
  imageId,
  selected,
  onSelect,
  onShowFullsize,
  preferredImageSize = 'small',
}: IImageDescriptionWrapperProps) => {
  const kvitteringId = useKvitteringId()
  const [bildeMetadata] = useBildeMetadata(kvitteringId, imageId)
  const [url] = useBilde(kvitteringId, bildeMetadata, preferredImageSize)
  const uploadStatus = useUploadStatus(imageId)
  const beskrivelseStatus = useMetadataBeskrivelseStatus(imageId)

  const [beskrivelse, setBeskrivelse] = useState(bildeMetadata?.description)
  const { mutate: updateBeskrivelse } = useUpdateBildeBeskrivelse(kvitteringId)

  const onChangeDescription = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (!bildeMetadata) {
        return
      }

      setBeskrivelse(e.target.value)
      updateBeskrivelse({
        bildeMetadata: { ...bildeMetadata, description: e.target.value },
      })
    },
    [bildeMetadata, updateBeskrivelse]
  )

  if (!bildeMetadata) return null

  const bildeRotateText = bildeMetadata.rotate
    ? (`${bildeMetadata.rotate}deg` as '0deg' | '90deg' | '180deg' | '270deg')
    : undefined

  const [danger, warning, errorText] = getErrorInfo(
    validate.validateDescription(beskrivelse)
  )

  const isUploaded = uploadStatus === undefined || uploadStatus === 'UPLOADED'
  const isError = beskrivelseStatus === MutationStatus.ERROR
  const errorTxt = errorText ?? (isError ? 'Kunne ikke lagre beskrivelse' : '')

  return (
    <div className="image-description-wrapper">
      <ImageDescription
        id={bildeMetadata.id}
        image={url}
        imageRotate={bildeRotateText}
        checked={selected}
        onCheck={() => onSelect(bildeMetadata.id)}
        showOriginal={() => onShowFullsize(url ?? '')}
        //
        descriptionLabel="Beskrivende tittel"
        description={beskrivelse ?? bildeMetadata?.description ?? ''}
        descriptionPlaceholder="Beskriv kort hvor bildet er tatt og hva bildet er ment å illustrere"
        onChangeDescription={onChangeDescription}
        descriptionDisabled={!isUploaded}
        descriptionMaxLength={IMAGE_DESCRIPTION_MAX_LENGTH}
        //
        danger={isError || danger}
        warning={warning}
        errorText={errorTxt}
      >
        <Column spacing={1.25}>
          <div className="image-top-info">
            <Text weight="semibold" size="medium">{`Bilde tatt av: ${
              bildeMetadata.currentUserFullName ??
              bildeMetadata.currentUser ??
              'Ukjent'
            }`}</Text>
          </div>

          <ImageLocationEditor bildeMetadata={bildeMetadata} />

          <div>
            <Text weight="bold" size="normal">
              Tidspunkt
            </Text>
            <Text size="normal">
              {dayjs(bildeMetadata.captureTime).format('D. MMMM YYYY, H.mm')}
            </Text>
          </div>
        </Column>
      </ImageDescription>
    </div>
  )
}

export { ImageDescriptionWrapper }
