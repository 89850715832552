import { offlineLexicon } from '../../offline-lexicon'
import { DependencyNotPersisted, mergeKeyWith } from '../../../common/query'

const getKontrollpunktKeys = () => {
  const keys = {
    all: [{ entity: 'kontrollpunkter' }] as const,

    /** Used as defaultMutation key or filter key */
    addBase: () => mergeKeyWith(keys.all, { action: 'add' }),
    /** Used as useMutation key */
    add: (kvitteringId: string) =>
      mergeKeyWith(keys.addBase(), { kvitteringId }),

    /** Used as defaultMutation key or filter key */
    updateBeskrivelseBase: () =>
      mergeKeyWith(keys.all, { action: 'update-beskrivelse' }),
    /** Used as useMutation key */
    updateBeskrivelse: (kvitteringId: string) =>
      mergeKeyWith(keys.updateBeskrivelseBase(), { kvitteringId }),

    /** Used as defaultMutation key or filter key */
    updateSorteringsrekkefoelgeBase: () =>
      mergeKeyWith(keys.all, { action: 'update-sorteringsrekkefoelge' }),
    /** Used as useMutation key */
    updateSorteringsrekkefoelge: (kvitteringId: string) =>
      mergeKeyWith(keys.updateSorteringsrekkefoelgeBase(), { kvitteringId }),

    /** Used as defaultMutation key or filter key */
    removeBase: () => mergeKeyWith(keys.all, { action: 'remove' }),
    /** Used as useMutation key */
    remove: (kvitteringId: string) =>
      mergeKeyWith(keys.removeBase(), { kvitteringId }),
  } as const

  return keys
}

const kontrollpunktKeys = getKontrollpunktKeys()

export type KontrollpunktKeys = typeof kontrollpunktKeys

export { kontrollpunktKeys, getPersistedKontrollpunkt }

const getPersistedKontrollpunkt = async (
  kvitteringId: string,
  kontrollpunktId: string
) => {
  const persistedKontrollpunkt = offlineLexicon.get(
    offlineLexicon.types.kontrollpunkt,
    kontrollpunktId,
    kvitteringId
  )

  if (!persistedKontrollpunkt) {
    throw new DependencyNotPersisted()
  }

  return persistedKontrollpunkt
}
