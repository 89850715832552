// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aktivitet-modal .Input {
  flex: 1;
}

.aktivitet-modal .modal-child .modal-body {
  padding-left: 0;
  padding-right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/modals/aktivitet-modal/style.css"],"names":[],"mappings":"AAAA;EACE,OAAO;AACT;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".aktivitet-modal .Input {\n  flex: 1;\n}\n\n.aktivitet-modal .modal-child .modal-body {\n  padding-left: 0;\n  padding-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
