import * as R from 'ramda'

const urlEncodeStringOrArray = R.ifElse(
  R.is(Array),
  R.map(encodeURIComponent),
  encodeURIComponent
)

const prefixQuerystring = (prefix) =>
  R.ifElse(R.identity, R.concat(prefix), R.always(''))

/**
 * Build query string parameters that can easily be added at the end of a URL
 * @category utils
 * @param prefix
 * @param data
 * @returns string parameters - for instance -> ?expand=tilsynsobjekter&sort.direction=DESC&sort.property=oppdatert
 */
export const createQueryStringFromObject =
  (prefix: string) => (data?: Record<string | number, any>) =>
    R.pipe(
      R.defaultTo({}),
      R.reject(R.isNil), // Remove keys with value undefined or null
      R.reject(R.isEmpty),
      R.map(urlEncodeStringOrArray),
      R.toPairs,
      R.map(R.join('=')),
      R.join('&'),
      prefixQuerystring(prefix)
    )(data)
