import {
  UseMutateFunction,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import {
  KvitteringKeys,
  kvitteringKeys,
  kvitteringQueryHelpers,
} from '../queries/helpers'
import { kvitteringApi } from '../queries/kvittering-api'
import { IKvitteringData, ITilsynstype } from '../../../ducks/kvittering/types'
import { useCallback } from 'react'
import { queryClient } from '../../../api/query-client'
import { DebounceMutate, useDebounceMutate } from '../../../common/query'
import store from '../../../reducers/store'
import { Draft } from 'immer'

export {
  useKvittering,
  useSammenstilling,
  useTilsynstyper,
  useTilsynstypeBeskrivelse,
}

type UseKvitteringOptions<
  TData = IKvitteringData,
  TError = unknown,
> = UseQueryOptions<
  IKvitteringData,
  TError,
  TData,
  ReturnType<KvitteringKeys['kvitteringId']>
>

const useKvittering = <TData = IKvitteringData, TError = Error>(
  kvitteringId: string | undefined,
  options?: Omit<UseKvitteringOptions<TData, TError>, 'queryKey'>
) => {
  if (kvitteringId) {
    ensureInitialData(kvitteringId)
  }

  return useQuery<
    IKvitteringData,
    TError,
    TData,
    ReturnType<KvitteringKeys['kvitteringId']>
  >({
    queryKey: kvitteringKeys.kvitteringId(kvitteringId ?? '-1'),
    queryFn: async ({ queryKey: [{ kvitteringId }], signal }) => {
      const { data } = await kvitteringApi.get(kvitteringId, { signal })
      return data
    },
    enabled: !!kvitteringId,
    ...options,
  })
}

type UseSammenstillingOptions<
  TData = IKvitteringData,
  TError = unknown,
> = UseQueryOptions<
  IKvitteringData,
  TError,
  TData,
  ReturnType<KvitteringKeys['sammenstilling']>
>

const useSammenstilling = <TData = IKvitteringData, TError = Error>(
  kvitteringId: string | undefined,
  options?: Omit<UseSammenstillingOptions<TData, TError>, 'queryKey'>
) => {
  const { data: kvittering } = useKvittering(kvitteringId)

  const url = kvittering?._links?.sammenstilling?.href

  return useQuery<
    IKvitteringData,
    TError,
    TData,
    ReturnType<KvitteringKeys['sammenstilling']>
  >({
    queryKey: kvitteringKeys.sammenstilling(kvitteringId ?? '-1', url),
    queryFn: async ({ signal }) => {
      const { data } = await kvitteringApi.getSammenstilling(url, {
        signal,
      })
      return data
    },
    enabled: !!url,
    placeholderData: kvittering,
    ...options,
  })
}

const useTilsynstyper = <TData = ITilsynstype[]>(
  options?: Omit<
    UseQueryOptions<
      ITilsynstype[],
      Error,
      TData,
      ReturnType<KvitteringKeys['tilsynstyper']>
    >,
    'queryKey'
  >
) => {
  return useQuery({
    queryKey: kvitteringKeys.tilsynstyper(),
    queryFn: async () => {
      const { data } = await kvitteringApi.getTilsynstyper()

      return data._embedded?.tilsynstypeList ?? []
    },
    ...options,
  })
}

const useTilsynstypeBeskrivelse = (typeTilsyn: string) => {
  return useTilsynstyper({
    select: (tilsynstyper) =>
      tilsynstyper.find((type) => type.typeTilsynKey === typeTilsyn)
        ?.beskrivelse,
  })
}

const ensureInitialData = (kvitteringId: string) => {
  const isCached = Boolean(
    queryClient.getQueryData<IKvitteringData>(
      kvitteringKeys.kvitteringId(kvitteringId)
    )
  )

  if (!isCached) {
    queryClient.setQueryData(
      kvitteringKeys.kvitteringId(kvitteringId),
      store
        .getState()
        .kvittering.kvitteringer.find((kv) => kv.id === kvitteringId)
    )
  }
}

export const useOptimisticDebounceKvitteringMutate = <
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
>(
  mutate: UseMutateFunction<TData, TError, TVariables, TContext>,
  kvitteringId: string,
  updateFn: (draft: Draft<IKvitteringData>, variables: TVariables) => void
) => {
  const debounceMutate = useDebounceMutate(mutate)

  return useCallback<DebounceMutate<TData, TError, TVariables, TContext>>(
    (variables, options) => {
      kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) =>
        updateFn(draft, variables)
      )

      debounceMutate(variables, options)
    },
    [debounceMutate, kvitteringId, updateFn]
  )
}
