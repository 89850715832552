import { Column, LoadingDots, PenIcon, Row, TextLink } from '@mattilsynet/mt-ui'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { KontrollpunktEditModal } from '../../../modals/kontrollpunkt-edit-modal'
import { TextWithValidation } from '../../../components/text-with-validation'
import { useKvitteringId } from '../../../common/kvittering-context'
import { useAddObservasjon } from '../../observasjoner'
import { observasjonInitialState } from '../../observasjoner/helpers'
import { IKontrollpunkt } from '../types'
import { useKontrollpunkt } from '../hooks/query-hooks'

interface IKontrollpunktProps {
  kontrollpunktId: string
  index: number
  children?: (kontrollpunkt: IKontrollpunkt) => ReactNode
}

export const Kontrollpunkt = ({
  kontrollpunktId,
  index,
  children,
}: IKontrollpunktProps) => {
  const kvitteringId = useKvitteringId()
  const { data: kontrollpunkt, isFetching } = useKontrollpunkt(
    kvitteringId,
    kontrollpunktId
  )

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const onEdit = useCallback(() => {
    setIsEditModalOpen(true)
  }, [])

  const {
    mutate: addObservasjon,
    isPending: isObservasjonPending,
    isPaused: isObservasjonPaused,
  } = useAddObservasjon(kvitteringId)

  useEffect(() => {
    if (
      !isFetching &&
      !isObservasjonPending &&
      kontrollpunkt?.observasjoner?.length === 0
    ) {
      addObservasjon({
        kontrollpunkt: kontrollpunkt,
        observasjon: observasjonInitialState(),
      })
    }
  }, [addObservasjon, isObservasjonPending, isFetching, kontrollpunkt])

  const renderKontrollpunktBeskrivelse = (
    index: number,
    beskrivelse: string
  ) => {
    if (!beskrivelse) {
      return (
        <TextWithValidation
          errorText={'Kontrollpunkt mangler'}
          value="1"
          hasError={true}
          as="h4"
          bold
        />
      )
    }

    const displayText = `${index + 1}. ${beskrivelse}`
    return (
      <b className="overflowingText kontrollpunkt-beskrivelse">{displayText}</b>
    )
  }

  return (
    <Column>
      <Row align="center">
        {renderKontrollpunktBeskrivelse(
          index,
          kontrollpunkt?.beskrivelse ?? ''
        )}

        <TextLink margin={[0, 0, 0, 1]} onClick={onEdit} leftIcon={<PenIcon />}>
          Rediger kontrollpunkt
        </TextLink>
      </Row>

      {(!isObservasjonPending || isObservasjonPaused) &&
        kontrollpunkt &&
        children?.(kontrollpunkt)}

      {isObservasjonPending && !isObservasjonPaused && (
        <Row justify="center">
          <LoadingDots />
        </Row>
      )}

      {kontrollpunkt && isEditModalOpen && (
        <KontrollpunktEditModal
          isOpen={isEditModalOpen}
          onCancel={() => setIsEditModalOpen(false)}
          kontrollpunkt={kontrollpunkt}
        />
      )}
    </Column>
  )
}
