export const skjermTittel = (tittel: string) => {
  let newTitle = [] as string[]

  newTitle = tittel.split(' ')

  const displayableTitle = newTitle
    .map((titleItem) => titleItem.replace(/\[.*\]/g, '*****'))
    .join(' ')

  return displayableTitle
}

export const skjermOrdWithinBrackets = (str: string) => {
  const skjermedeOrd = str.replace(/\[(?:[^\]]+?)\]/g, (skjermedeOrd) => {
    return skjermedeOrd
      .split(' ')
      .map(() => '*****')
      .join(' ')
  })

  return skjermedeOrd
}

export const originalTittel = (tittel = '') => {
  let newTitle = [] as string[]

  newTitle = tittel.split(' ')

  const displayableTitle = newTitle
    .map((titleItem) => titleItem.replace('[', '').replace(']', ''))
    .join(' ')

  return displayableTitle
}

export const modifyTitleIfPrivatePerson = (
  titlte: string,
  isPrivatePerson: boolean
) => {
  const newTitle = isPrivatePerson ? `|${titlte}|` : titlte
  return `${newTitle} - Inspeksjon`
}
