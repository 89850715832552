// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saks-card-info .forbered-subtitle {
  font-size: 1.125em;
  font-weight: bold;
}

.saks-card-info .info-header {
  font-size: 1em;
  font-weight: bold;
}

.description-info strong {
  font-weight: bold;
  display: contents;
}
`, "",{"version":3,"sources":["webpack://./src/components/sak-card-info/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".saks-card-info .forbered-subtitle {\n  font-size: 1.125em;\n  font-weight: bold;\n}\n\n.saks-card-info .info-header {\n  font-size: 1em;\n  font-weight: bold;\n}\n\n.description-info strong {\n  font-weight: bold;\n  display: contents;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
