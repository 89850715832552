// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-page-nav {
  display: grid;
}

.bottom-page-nav * {
  cursor: pointer;
}

.bottom-page-nav .IconButton {
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/bottom-page-nav/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".bottom-page-nav {\n  display: grid;\n}\n\n.bottom-page-nav * {\n  cursor: pointer;\n}\n\n.bottom-page-nav .IconButton {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
