import { DeepReadonly } from '../../../common/types'

const getTidligereKontrollpunkterKeys = () => {
  const keys = {
    all: [{ entity: 'tidligere-kontrollpunkter' }] as const,
    aktiviteter: (aktiviteter: string[]) =>
      [{ ...keys.all[0], aktiviteter }] as const,
  } as const

  return keys as DeepReadonly<typeof keys>
}

const tidligereKontrollpunkterKeys = getTidligereKontrollpunkterKeys()

export type TidligereKontrollpunkterKeys = typeof tidligereKontrollpunkterKeys

export { tidligereKontrollpunkterKeys }
