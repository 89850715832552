import { firstValueFrom, iif, Observable, of } from 'rxjs'
import { ajax, AjaxRequest } from 'rxjs/ajax'
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import axios, { AxiosResponse, ResponseType } from 'axios'
import * as R from 'ramda'

import { IStoreState } from '../reducers/types'
import { authSubject } from '../auth/keycloak'
import { StateObservable } from 'redux-observable'
import { IUserState } from '../ducks/user/types'

type ReqMethodType = 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH'

export const getToken = () =>
  mergeMap(() => authSubject.pipe(mergeMap((keycloak) => keycloak.getToken())))
export const getTokenPromise = async () => {
  return (await firstValueFrom(authSubject)).getToken()
}

export interface ICommonApi {
  get(
    url: string,
    state: IStoreState
  ): (obs$: Observable<any>) => Observable<any>
  delete(
    url: string,
    state: IStoreState
  ): (obs$: Observable<any>) => Observable<any>
  post(
    url: string,
    state: IStoreState,
    data: any
  ): (obs$: Observable<any>) => Observable<any>
  put(
    url: string,
    state: IStoreState,
    data?: any
  ): (obs$: Observable<any>) => Observable<any>
  patch(
    url: string,
    state: IStoreState,
    data: any
  ): (obs$: Observable<any>) => Observable<any>
  default(
    url: string,
    method: ReqMethodType,
    state: IStoreState,
    data?: any
  ): (obs$: Observable<any>) => Observable<any>
}

interface IQueryApiOptions {
  signal?: AbortSignal
  isFormData?: boolean
  responseType?: ResponseType
}
export interface IQueryApi {
  get<T = unknown>(
    url: string,
    options?: IQueryApiOptions
  ): Promise<AxiosResponse<T>>
  delete(url: string, options?: IQueryApiOptions): Promise<AxiosResponse<null>>
  post<T = null>(
    url: string,
    data: any,
    options?: IQueryApiOptions
  ): Promise<AxiosResponse<T>>
  put<T = null>(
    url: string,
    data: any,
    options?: IQueryApiOptions
  ): Promise<AxiosResponse<T>>
  patch<T = null>(
    url: string,
    data: any,
    options?: IQueryApiOptions
  ): Promise<AxiosResponse<T>>
  default<T = null>(
    url: string,
    method: ReqMethodType,
    data?: any,
    options?: IQueryApiOptions
  ): Promise<AxiosResponse<T>>
}

export type IAjaxWithAuth = (
  state$: StateObservable<IStoreState>
) => (obs$: Observable<AjaxRequest>) => any

const getAuthTokenHeaderSelector = (user: IUserState): AjaxRequest =>
  getAuthTokenHeader(
    R.pipe(
      R.filter(() => R.has('authUser')),
      R.path(['authUser', 'accessToken'])
    )(user)
  )

const getAuthTokenHeader = (token: string) =>
  R.pipe(
    R.concat('Bearer '),
    R.objOf('Authorization'),
    R.objOf('headers')
  )(token)

const withToken = (
  obs$: Observable<AjaxRequest>
): Observable<[AjaxRequest, string]> =>
  obs$.pipe(
    mergeMap((data) => {
      return of(data).pipe(
        getToken(),
        map((accessToken: string | { accessToken: string }) => {
          if (typeof accessToken !== 'string') {
            accessToken = (accessToken as any).accessToken
          }
          return [data, accessToken] as [AjaxRequest, string]
        })
      )
    })
  )

const withAuthentication =
  (url: string, body: any, method: ReqMethodType = 'GET') =>
  (obs$: Observable<any>) =>
    obs$.pipe(
      getToken(),
      map((accessToken: string) =>
        ajax({
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/hal+json,application/json',
            'Content-Type': 'application/json',
          },
          method,
          url,
          body,
        })
      )
    )

export const ajaxWithAuth: IAjaxWithAuth =
  (state$) => (obs$: Observable<AjaxRequest>) => {
    const onlineRequest = (ajaxObj, { user }) =>
      of(ajaxObj).pipe(
        map((ajaxObj) =>
          R.mergeRight(getAuthTokenHeaderSelector(user), ajaxObj)
        ),
        mergeMap(ajax)
      )
    const offlineRequest = (ajaxObj) =>
      of(ajaxObj).pipe(
        withToken,
        map(([ajaxObj, token]) =>
          R.mergeRight(getAuthTokenHeader(token), ajaxObj)
        ),
        mergeMap(ajax)
      )

    return obs$.pipe(
      withLatestFrom(state$),
      mergeMap(([ajaxObj, state]) =>
        iif(
          // Needed for image cache to work.
          // Token doesn't exist in state when
          // refreshing offline.
          () => !state.ui.offline,
          onlineRequest(ajaxObj, state),
          offlineRequest(ajaxObj)
        )
      )
    )
  }

export const commonApi: ICommonApi = {
  get: (url) => (obs$) =>
    obs$.pipe(
      withAuthentication(url, undefined),
      mergeMap((resp$) =>
        resp$.pipe(
          filter((resp) => resp.response as any),
          map((resp) => resp.response)
        )
      )
    ),

  delete: (url, state) => commonApi.default(url, 'DELETE', state),

  post: (url, state, data) => commonApi.default(url, 'POST', state, data),

  put: (url, state, data) => commonApi.default(url, 'PUT', state, data),

  patch: (url, state, data) => commonApi.default(url, 'PATCH', state, data),

  default:
    (url, method, state, data = undefined) =>
    (obs$) =>
      obs$.pipe(
        withAuthentication(url, data, method),
        mergeMap((resp$) => resp$.pipe(map((resp) => resp)))
      ),
}

const withHeaders = async (isFormData = false) => {
  const token = await getTokenPromise()

  return {
    Authorization: `Bearer ${token}`,
    ...(isFormData
      ? {}
      : {
          Accept: 'application/hal+json,application/json',
          'Content-Type': 'application/json',
        }),
  }
}

/**
 * The new request api wrapper.
 * Remove commonApi when all api calls are migrated.
 */
export const queryApi: IQueryApi = {
  get: async (url: string, options?: IQueryApiOptions) =>
    axios.get(url, {
      headers: await withHeaders(),
      signal: options?.signal,
      data: {},
      responseType: options?.responseType,
    }),
  delete: (url, options) => queryApi.default(url, 'DELETE', options),
  post: (url, data, options) => queryApi.default(url, 'POST', data, options),
  put: (url, data, options) => queryApi.default(url, 'PUT', data, options),
  patch: (url, data, options) => queryApi.default(url, 'PATCH', data, options),
  default: async (url, method, data, options) =>
    axios.request({
      url,
      method,
      data,
      headers: await withHeaders(options?.isFormData),
    }),
}
