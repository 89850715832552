import React, { ChangeEvent, useState } from 'react'
import { Column, Row, RowItem, IconButton, Cross } from '@mattilsynet/mt-ui'
import Textarea from '@mattilsynet/mt-ui/dist/elements/text-area'
import './style.css'

import { IKontrollpunkt } from '../../features/kontrollpunkter/types'

interface IKontrollpunktItemProps {
  onSave?: () => void
  onUpdateKontrollpunkt: (kontrollpunkt: IKontrollpunkt) => void
  kontrollpunkt: IKontrollpunkt
  onRemoveKontrollpunkt?: (kontrollpunkt: IKontrollpunkt) => void
  danger: boolean
  placeholder?: string
  label?: string
}

export const KontrollpunktItem = ({
  onUpdateKontrollpunkt,
  kontrollpunkt,
  onRemoveKontrollpunkt,
  danger,
  placeholder = 'Beskrivelse av kontrollpunkt',
  label = 'Beskrivelse av kontrollpunkt',
}: IKontrollpunktItemProps) => {
  const [beskrivelse, setBeskrivelse] = useState(kontrollpunkt.beskrivelse)

  const onChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setBeskrivelse(event.currentTarget.value)

    onUpdateKontrollpunkt({
      ...kontrollpunkt,
      beskrivelse: event.currentTarget.value,
    })
  }

  const errorText =
    danger && !kontrollpunkt.beskrivelse
      ? 'Kontrollpunktet mangler beskrivelse'
      : ''

  return (
    <Column fluid spacing={2.5}>
      <Row className="kontrollpunkt-item-text-area">
        <RowItem flex={1}>
          <Textarea
            onChange={onChangeDescription}
            placeholder={placeholder}
            value={beskrivelse}
            label={label}
            danger={Boolean(errorText)}
            errorText={errorText}
          />
        </RowItem>
        {onRemoveKontrollpunkt && (
          <IconButton
            onClick={() => onRemoveKontrollpunkt(kontrollpunkt)}
            icon={<Cross innerColor="white" />}
            className="kontrollpunkt-item-close-icon"
            ariaLabel="Fjern kontrollpunkt"
          />
        )}
      </Row>
    </Column>
  )
}
