// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-modal .image-list {
  display: grid;
  grid: auto-flow min-content / repeat(auto-fill, minmax(17em, 1fr));
  grid-gap: 1em;
  margin-bottom: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/components/image-gallery-modal/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kEAAkE;EAClE,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".gallery-modal .image-list {\n  display: grid;\n  grid: auto-flow min-content / repeat(auto-fill, minmax(17em, 1fr));\n  grid-gap: 1em;\n  margin-bottom: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
