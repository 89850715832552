import {
  andreDeltakereKeys,
  getKvitteringId,
  IDeltakerKeys,
  mattilsynetDeltakerKeys,
  virksomhetDeltakerKeys,
} from '../queries/helpers'
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import { IDeltaker, IDeltakerVars } from '../types'
import { deltakerApi } from '../queries/deltaker-api'
import { useOptimisticDebounceMutate } from '../../../common/query'
import { useCallback } from 'react'
import { DEBOUNCE_MS } from '../../../constants'

export {
  useVirksomhetDeltakere,
  useVirksomhetDeltakereMutation,
  useMattilsynetDeltakere,
  useMattilsynetDeltakereMutation,
  useAndreDeltakere,
  useAndreDeltakereMutation,
}

type UseDeltakerOptions<TData = IDeltaker[], TError = Error> = Omit<
  UseQueryOptions<IDeltaker[], TError, TData, ReturnType<IDeltakerKeys['url']>>,
  'queryKey'
>

const getDataKey = (url: string) => {
  const entity = RegExp(
    /tilsynskvitteringer\/\d+\/(virksomhet|mattilsynet|andre)-deltagere/
  ).exec(url)

  if (!entity) {
    throw new Error('URL must include deltaker type')
  }

  switch (entity[1]) {
    case 'mattilsynet':
      return 'mattilsynetDeltagere'
    case 'andre':
      return 'andreDeltagere'
    case 'virksomhet':
    default:
      return 'virksomhetDeltagere'
  }
}

const useDeltakere = (
  url: string,
  keys: IDeltakerKeys,
  queryOptions?: UseDeltakerOptions
) => {
  const kvitteringId = getKvitteringId(url)

  return useQuery<
    IDeltaker[],
    Error,
    IDeltaker[],
    ReturnType<IDeltakerKeys['url']>
  >({
    queryKey: keys.url(kvitteringId, url),
    queryFn: async ({ queryKey: [{ url }], signal }) => {
      const { data } = await deltakerApi.get(url, { signal })
      const dataKey = getDataKey(url)

      return data._embedded?.[dataKey] ?? []
    },
    enabled: !!kvitteringId,
    placeholderData: [],
    ...queryOptions,
  })
}

const useVirksomhetDeltakere = (
  url: string,
  queryOptions?: UseDeltakerOptions
) => useDeltakere(url, virksomhetDeltakerKeys, queryOptions)

const useMattilsynetDeltakere = (
  url: string,
  queryOptions?: UseDeltakerOptions
) => useDeltakere(url, mattilsynetDeltakerKeys, queryOptions)

const useAndreDeltakere = (url: string, queryOptions?: UseDeltakerOptions) =>
  useDeltakere(url, andreDeltakereKeys, queryOptions)

type IMutationResult = UseMutationResult<IDeltaker, unknown, IDeltakerVars>
type IDeltakerMutationResult = Omit<IMutationResult, 'mutate'> & {
  mutate: (deltaker: IDeltaker) => void
}
type IMutateMapper = (mutation: IMutationResult) => IDeltakerMutationResult

export interface IDeltakerMutation {
  add: IDeltakerMutationResult
  remove: IDeltakerMutationResult
  onUpdate: (deltaker: IDeltaker) => void
}

const useDeltakerMutation = (
  url: string,
  keys: IDeltakerKeys
): IDeltakerMutation => {
  const mutateMapper: IMutateMapper = useCallback(
    (mutation) => ({
      ...mutation,
      mutate: (deltaker: IDeltaker) => mutation.mutate({ url, deltaker }),
    }),
    [url]
  )

  const kvitteringId = getKvitteringId(url)

  const add: IMutationResult = useMutation({ mutationKey: keys.add() })

  const { mutate: update }: IMutationResult = useMutation({
    mutationKey: keys.update(),
  })
  const optimisticDebounceUpdate = useOptimisticDebounceMutate(
    update,
    keys.url(kvitteringId, url),
    (deltakere, { deltaker }) =>
      deltakere.map((d) =>
        d.id !== deltaker.id ? d : { ...d, navn: deltaker.navn }
      )
  )

  const remove: IMutationResult = useMutation({ mutationKey: keys.remove() })

  const onUpdate = useCallback(
    (deltaker: IDeltaker) => {
      optimisticDebounceUpdate(
        { url, deltaker },
        { debounceMs: DEBOUNCE_MS, key: `${keys.update()}_${deltaker.id}` }
      )
    },
    [optimisticDebounceUpdate, keys, url]
  )

  return {
    add: mutateMapper(add),
    remove: mutateMapper(remove),
    onUpdate,
  }
}

const useVirksomhetDeltakereMutation = (url: string) =>
  useDeltakerMutation(url, virksomhetDeltakerKeys)
const useMattilsynetDeltakereMutation = (url: string) =>
  useDeltakerMutation(url, mattilsynetDeltakerKeys)
const useAndreDeltakereMutation = (url: string) =>
  useDeltakerMutation(url, andreDeltakereKeys)
