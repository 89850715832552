import { swipeToEnum } from '../components/slider'

export function swipeHelper(element, callback) {
  let swipedir = swipeToEnum.NONE
  let startX: number
  let distX: number
  const threshold = 150

  element.addEventListener(
    'touchstart',
    function (event: TouchEvent) {
      const touchobj = event.changedTouches[0]
      startX = touchobj.pageX
    },
    true
  )

  element.addEventListener(
    'touchend',
    function (event: TouchEvent) {
      const touchobj = event.changedTouches[0]
      distX = touchobj.pageX - startX
      if (Math.abs(distX) >= threshold) {
        swipedir = distX < 0 ? swipeToEnum.LEFT : swipeToEnum.RIGHT
        callback(swipedir)
      }
    },
    false
  )
}
