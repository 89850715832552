import {
  Column,
  DeleteIcon,
  IconButton,
  Row,
  RowItem,
  Text,
} from '@mattilsynet/mt-ui'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { DeleteModalLarge } from '../../../modals/delete-modal-large'
import { useKvitteringId } from '../../../common/kvittering-context'
import Arrow from '@mattilsynet/mt-ui/dist/icons/arrow'
import Textarea from '@mattilsynet/mt-ui/dist/elements/text-area'
import {
  useRemoveKontrollpunkt,
  useUpdateKontrollpunktBeskrivelse,
} from '../hooks/query-hooks'
import './style.css'
import { IKontrollpunkt } from '../types'

interface KontrollpunktEditProps {
  sequenceNumber: number
  kontrollpunkt: IKontrollpunkt
  shouldValidate: boolean
  autoFocus: boolean
  isLastItem?: boolean
  onMoveUp: () => void
  onMoveDown: () => void
}

export const KontrollpunktEdit = ({
  kontrollpunkt,
  sequenceNumber,
  shouldValidate,
  autoFocus,
  isLastItem,
  onMoveDown,
  onMoveUp,
}: KontrollpunktEditProps) => {
  const kvitteringId = useKvitteringId()

  const [beskrivelse, setBeskrivelse] = useState(kontrollpunkt.beskrivelse)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const { mutate: updateBeskrivelse } =
    useUpdateKontrollpunktBeskrivelse(kvitteringId)
  const { mutate: remove } = useRemoveKontrollpunkt(kvitteringId)

  const onChangeBeskrivelse = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const beskrivelse = event.currentTarget.value

    setBeskrivelse(beskrivelse)
    updateBeskrivelse({
      kontrollpunkt: {
        ...kontrollpunkt,
        beskrivelse: beskrivelse,
      },
    })
  }

  const onDelete = useCallback(() => {
    remove({ kontrollpunkt })
    setIsDeleteModalOpen(false)
  }, [kontrollpunkt, remove])

  const onClickDelete = () => {
    if (kontrollpunkt.beskrivelse === '') {
      return onDelete()
    }
    setIsDeleteModalOpen(true)
  }

  const errorText =
    shouldValidate && !kontrollpunkt.beskrivelse
      ? 'Kontrollpunktet mangler beskrivelse'
      : ''

  const hasKontrollpunktImages = kontrollpunkt.observasjoner.some(
    (observasjon) => observasjon.bildeIds.length > 0
  )

  return (
    <Column className="description">
      <Row justify="space-between" align="baseline">
        <Text as="label" weight="semibold">{`Kontrollpunkt ${
          sequenceNumber ?? ''
        }`}</Text>
        <Row spacing={1}>
          {onMoveUp && (
            <IconButton
              icon={<Arrow rotateDirection="UP" />}
              onClick={onMoveUp}
              small
              disabled={sequenceNumber === 1}
            />
          )}
          {onMoveDown && (
            <IconButton
              icon={<Arrow rotateDirection="DOWN" />}
              onClick={onMoveDown}
              small
              disabled={isLastItem}
            />
          )}
          <IconButton
            icon={
              <DeleteIcon
                className="kontrollpunkt-item__icon--delete"
                size="large"
              />
            }
            onClick={onClickDelete}
            small
          />
        </Row>
      </Row>
      <Row>
        <RowItem flex={1}>
          <Textarea
            onChange={onChangeBeskrivelse}
            placeholder="Beskrivelse av kontrollpunkt"
            value={beskrivelse}
            danger={Boolean(errorText)}
            errorText={errorText}
            autoFocus={autoFocus}
            defaultRows={1}
          />
        </RowItem>
      </Row>

      <DeleteModalLarge
        key={kontrollpunkt.id}
        isOpen={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onDelete={onDelete}
        title={'Er du sikker på at du vil slette kontrollpunktet?'}
        description={
          <Column spacing={2}>
            <Row>
              <Text>Du er i ferd med å slette et kontrollpunkt</Text>
            </Row>
            <Row>
              <Text>
                Hvis kontrollpunktet du sletter har tilknyttede observasjoner
                vil disse også slettes.
              </Text>
            </Row>
            <Row>
              <Text>
                {Boolean(hasKontrollpunktImages) &&
                  `Om det er bilder knyttet til kontrollpunktet, vil disse fortsatt ligge på tilsynskvitteringen`}
              </Text>
            </Row>
          </Column>
        }
        confirmText="Ja, jeg vil slette kontrollpunktet"
        cancelText="Avbryt"
      />
    </Column>
  )
}
