import { IKvitteringData } from './types'

// TODO: delete old group after renaming
export const formatKvittering = (kvittering: IKvitteringData) => {
  return {
    ...kvittering,
    'mattilsynet-deltagere': kvittering['mattilsynetDeltagere'],
    'andre-deltagere': kvittering['andreDeltagere'],
    'virksomhet-deltagere': kvittering['virksomhetsDeltagere'],
  }
}

// TODO: delete old group after renaming
export const formatAllKvitteringer = (allKvitteringer: IKvitteringData[]) => {
  return allKvitteringer.map((kvittering: any) => {
    return {
      ...kvittering,
      'mattilsynet-deltagere': kvittering.mattilsynetDeltagere,
      'andre-deltagere': kvittering.andreDeltagere,
      'virksomhet-deltagere': kvittering.virksomhetsDeltagere,
    }
  })
}

export const formatKvitteringToValidIds = (kvittering: IKvitteringData) => {
  if (!kvittering.tilsynsobjekter) {
    return kvittering
  }
  const assignId = (item) =>
    !item.id || typeof item.id !== 'string' || !item._links.self.href
      ? item
      : {
          ...item,
          id: Number(item._links.self.href.split('/').pop()),
        }

  return {
    ...kvittering,
    kontrollpunktGrupper: kvittering.kontrollpunktGrupper
      ? kvittering.kontrollpunktGrupper
          .filter(
            (kontrollpunkt) =>
              kontrollpunkt.beskrivelse || kontrollpunkt._links.self.href
          )
          .map((kontrollpunkt) => assignId(kontrollpunkt))
      : [],
    tilsynsobjekter: kvittering.tilsynsobjekter.map((tilsynsobjekt) =>
      !tilsynsobjekt.veiledninger || !tilsynsobjekt.kontrollpunkter
        ? tilsynsobjekt
        : {
            ...tilsynsobjekt,
            kontrollpunkter: tilsynsobjekt.kontrollpunkter.map(
              (kontrollpunkt) => {
                return {
                  ...kontrollpunkt,
                  observasjoner: kontrollpunkt.observasjoner
                    .filter(
                      (observasjon) =>
                        observasjon.tekst || observasjon._links.self.href
                    )
                    .map((observasjon) => assignId(observasjon)),
                }
              }
            ),
            veiledninger: tilsynsobjekt.veiledninger
              .filter(
                (veileding) => veileding.tekst || veileding._links.self.href
              )
              .map((veiledning) => assignId(veiledning)),
          }
    ),
  }
}

export const getIdFromSelfLink = (href?: string) => {
  return parseInt(href?.split('/')?.pop()?.split('?')?.[0] || '')
}

export const isNumeric = (str) => {
  if (typeof str != 'string') return false
  return (
    // @ts-ignore
    !isNaN(str) && !isNaN(parseFloat(str))
  )
}
