// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ferdigstilling .ferdigstilling-content {
  min-height: calc(100vh - 300px);
}

#ferdigstilling .main-heading {
  font-size: 1.5em;
}

.ferdigstilling-modal .archive-checkmark {
  min-width: 1.5em;
  max-width: 1.5em;
  margin-right: 0.5em;
  margin-left: -2em;
}
`, "",{"version":3,"sources":["webpack://./src/routes/ferdigstilling/style.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":["#ferdigstilling .ferdigstilling-content {\n  min-height: calc(100vh - 300px);\n}\n\n#ferdigstilling .main-heading {\n  font-size: 1.5em;\n}\n\n.ferdigstilling-modal .archive-checkmark {\n  min-width: 1.5em;\n  max-width: 1.5em;\n  margin-right: 0.5em;\n  margin-left: -2em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
