import {
  BildeGroupedItem,
  BildeMutationVars,
  BildeUploadStatus,
  MetadataVars,
  IImage,
} from '../types'
import { bildeKeys } from '../queries/helpers'
import {
  getMutationStatus,
  MutationStatus,
  useMutationStatus,
} from '../../../common/query'
import {
  useAllKvitteringErrorBilder,
  useBildeMetadataList,
  useBildeMetadatasFromIds,
} from './metadata-query-hooks'
import { isImageValid } from '../../../validators/text-input-validators'
import { useAllGroupedBildeIds } from './bilde-query-hooks'
import { useDeepMemo } from '../../../common/custom-hooks'
import { toSortedGroupedImagesByDate } from '../cache-helpers'
import { useMutationState } from '@tanstack/react-query'
import { distinctBy } from '../../../common/helpers'
import { useMemo } from 'react'

export {
  useUploadStatus,
  useMetadataBeskrivelseStatus,
  useIsBilderValid,
  useIsAnyBilderNotUploaded,
  useHasAnyBilderUploadError,
  useLocalBildeFile,
  useGalleryImages,
}

const useUploadStatus = (bildeId: string) => {
  const statusResult = useMutationStatus<MetadataVars>({
    mutationKey: bildeKeys.uploadBase(),
    predicate: (mutation) =>
      mutation.state.variables?.bildeMetadata?.id === bildeId,
  })

  return getUploadStatus(statusResult[0]?.[0])
}

const useMetadataBeskrivelseStatus = (bildeId: string) => {
  const statusResult = useMutationStatus<MetadataVars>({
    mutationKey: bildeKeys.updateBeskrivelseBase(),
    predicate: (mutation) =>
      mutation.state.variables?.bildeMetadata.id === bildeId,
  })

  return statusResult[0]?.[0]
}

const useIsAnyBilderNotUploaded = (bildeIds: string[]) => {
  const statusResult = useMutationStatus<MetadataVars>({
    mutationKey: bildeKeys.uploadBase(),
    predicate: (mutation) =>
      bildeIds.includes(mutation.state.variables?.bildeMetadata.id ?? '') &&
      getMutationStatus(mutation) === MutationStatus.WAITING,
  })

  return statusResult.length > 0
}

const useHasAnyBilderUploadError = (bildeIds: string[]) => {
  const statusResult = useMutationStatus<MetadataVars>({
    mutationKey: bildeKeys.uploadBase(),
    predicate: (mutation) =>
      bildeIds.includes(mutation.state.variables?.bildeMetadata.id ?? '') &&
      getMutationStatus(mutation) === MutationStatus.ERROR,
  })

  return statusResult.length > 0
}

const useLocalBildeFile = (bildeId: string) => {
  const [localBilde] = useMutationState({
    filters: {
      mutationKey: bildeKeys.uploadBase(),
      predicate: (mutation) =>
        mutation.state.variables?.bildeMetadata.id === bildeId,
    },
    select: (mutation) => (mutation.state.variables as BildeMutationVars).bilde,
  })

  return localBilde
}

const getUploadStatus = (status: MutationStatus) => {
  const statusMapper: Record<MutationStatus, BildeUploadStatus> = {
    [MutationStatus.WAITING]: BildeUploadStatus.WAITING,
    [MutationStatus.PENDING]: BildeUploadStatus.UPLOADING,
    [MutationStatus.SUCCESS]: BildeUploadStatus.UPLOADED,
    [MutationStatus.ERROR]: BildeUploadStatus.ERROR,
  }

  return statusMapper[status]
}

const useIsBilderValid = (kvitteringId: string, bildeIds: string[]) => {
  const { data: metadatas } = useBildeMetadatasFromIds(kvitteringId, bildeIds)

  if (!metadatas) {
    return true
  }

  return !metadatas.some((metadata) => !isImageValid(metadata))
}

const useGalleryImages = (kvitteringId: string) => {
  const { data: groupedBildeIds } = useAllGroupedBildeIds(kvitteringId)
  const { data: bildeMetadataList, refetch } =
    useBildeMetadataList(kvitteringId)

  const errorBilder = useAllKvitteringErrorBilder(kvitteringId)
  const { errorGroupedBildeIds, errorBildeMetadataList } = useMemo(
    () =>
      errorBilder.reduce(
        (result, { bildeMetadata, observasjonId }) => {
          result.errorGroupedBildeIds.push({
            id: bildeMetadata.id,
            observasjonId,
          })
          result.errorBildeMetadataList.push(bildeMetadata)

          return result
        },
        {
          errorGroupedBildeIds: [] as BildeGroupedItem[],
          errorBildeMetadataList: [] as IImage[],
        }
      ),
    [errorBilder]
  )

  return useDeepMemo(() => {
    if (
      (!groupedBildeIds || !bildeMetadataList) &&
      (errorGroupedBildeIds.length === 0 || errorBildeMetadataList.length === 0)
    ) {
      return []
    }

    const bildeIds = distinctBy(
      [...(groupedBildeIds ?? []), ...errorGroupedBildeIds],
      (group) => group.id
    )
    const metadataList = [
      ...(bildeMetadataList ?? []),
      ...errorBildeMetadataList,
    ]

    return [
      toSortedGroupedImagesByDate(bildeIds, metadataList),
      refetch,
    ] as const
  }, [
    groupedBildeIds,
    bildeMetadataList,
    errorGroupedBildeIds,
    errorBildeMetadataList,
    refetch,
  ])
}
