// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kvittering-navigator {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg-navigator);
  z-index: 500;
}

.kvittering-navigator-keyboard-opened {
  position: absolute;
}

.kvittering-navigator .navigator-grid {
  padding: 0;
}

.kvittering-navigator .navigator-grid__row {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 0.5rem;
}

@media screen and (hover: none) and (orientation: landscape) {
  .kvittering-navigator {
    position: absolute;
  }
}

@media screen and (max-width: 768px) {
  .kvittering-navigator .navigator-grid__row {
    justify-content: space-between;
    gap: unset;
  }

}`, "",{"version":3,"sources":["webpack://./src/components/kvittering-navigator/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,QAAQ;EACR,OAAO;EACP,qCAAqC;EACrC,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,8BAA8B;IAC9B,UAAU;EACZ;;AAEF","sourcesContent":[".kvittering-navigator {\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  background-color: var(--bg-navigator);\n  z-index: 500;\n}\n\n.kvittering-navigator-keyboard-opened {\n  position: absolute;\n}\n\n.kvittering-navigator .navigator-grid {\n  padding: 0;\n}\n\n.kvittering-navigator .navigator-grid__row {\n  display: flex;\n  justify-content: center;\n  gap: 2rem;\n  padding: 0.5rem;\n}\n\n@media screen and (hover: none) and (orientation: landscape) {\n  .kvittering-navigator {\n    position: absolute;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .kvittering-navigator .navigator-grid__row {\n    justify-content: space-between;\n    gap: unset;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
