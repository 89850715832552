import {
  MutationKey,
  useMutation,
  useMutationState,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query'
import { BildeKeys, bildeKeys } from '../queries/helpers'
import { bildeApi } from '../queries/bilde-api'
import { AxiosResponse } from 'axios'
import {
  BildeMutationVars,
  BildeUpdateVars,
  MetadataVars,
  IImage,
} from '../types'
import { useCallback, useMemo } from 'react'
import { DEBOUNCE_MS } from '../../../constants'
import {
  isErrorMutation,
  useOptimisticDebounceMutate,
} from '../../../common/query'
import { produce } from 'immer'
import {
  fixMismatchedBildeAttachments,
  getNotAttachedBildeIds,
  toSortedBildeMetadataList,
} from '../cache-helpers'
import { distinctBy } from '../../../common/helpers'
import { Prettify } from '../../../common/types'
import { kvitteringQueryHelpers } from '../../kvitteringer/queries/helpers'
import { IKvitteringData } from '../../../ducks/kvittering/types'

export {
  useBildeMetadataList,
  useBildeMetadata,
  useBildeMetadatasFromIds,
  useUpdateBildeBeskrivelse,
  useUpdateBildeLokasjon,
  useObservasjonErrorBilder,
  useAllKvitteringErrorBilder,
}

type UseBildeMetadataOptions<TData = IImage, TError = unknown> = Omit<
  UseQueryOptions<
    IImage[],
    TError,
    TData,
    ReturnType<BildeKeys['metadataList']>
  >,
  'queryKey' | 'queryFn'
>

const useBildeMetadataList = <TData = IImage[], TError = Error>(
  kvitteringId: string,
  options?: UseBildeMetadataOptions<TData, TError>
) => {
  return useQuery<
    IImage[],
    TError,
    TData,
    ReturnType<BildeKeys['metadataList']>
  >({
    queryKey: bildeKeys.metadataList(kvitteringId),
    queryFn: async ({ queryKey: [{ kvitteringId }] }) => {
      const { data } = await bildeApi.getMetadatas({ kvitteringId })

      const metadataList = data._embedded?.imageMetaDataKategorisertList ?? []

      const kvittering = kvitteringQueryHelpers.getKvitteringCache(kvitteringId)
      const notAttachedBildeIds = getNotAttachedBildeIds(
        kvittering as IKvitteringData,
        metadataList
      )
      fixMismatchedBildeAttachments(kvitteringId, notAttachedBildeIds)

      return metadataList
    },
    enabled: !!kvitteringId,
    ...options,
  })
}

/** Get bilde metadata from query cache. If not found, get from mutation error. */
const useBildeMetadata = (kvitteringId: string, bildeId: string) => {
  const bildeMetadata = useBildeMetadataList(kvitteringId, {
    select: (metadataList) =>
      metadataList.find((metadata) => metadata.id === bildeId),
  })

  // Get metadata from mutation error,
  // when the metadata is "removed" from the query cache on invalidation
  const [errorBildeMetadata] = useMutationState({
    filters: {
      mutationKey: bildeKeys.uploadBase(),
      predicate: (mutation) =>
        mutation.state.variables?.bildeMetadata.id === bildeId &&
        isErrorMutation(mutation),
    },
    select: (mutation) =>
      (mutation.state.variables as BildeMutationVars).bildeMetadata,
  })

  return [bildeMetadata.data ?? errorBildeMetadata, bildeMetadata] as const
}

/** Get bilde metadata for all bildeIds from query cache. If not found, get from mutation error. */
const useBildeMetadatasFromIds = (kvitteringId: string, bildeIds: string[]) => {
  const bildeMetadataList = useBildeMetadataList(kvitteringId, {
    select: (metadataList) =>
      metadataList.filter((metadata) => bildeIds.includes(metadata.id)),
  })

  // Get metadata from mutation error,
  // when the metadata is "removed" from the query cache on invalidation
  const errorBildeMetadataList = useMutationState({
    filters: {
      mutationKey: bildeKeys.uploadBase(),
      predicate: (mutation) =>
        bildeIds.includes(mutation.state.variables?.bildeMetadata.id ?? '') &&
        isErrorMutation(mutation),
    },
    select: (mutation) =>
      (mutation.state.variables as BildeMutationVars).bildeMetadata!,
  })

  const sortedBilder = useMemo(() => {
    const metadataList = distinctBy(
      [...(bildeMetadataList.data ?? []), ...errorBildeMetadataList],
      (bildeMetadata) => bildeMetadata.id
    )

    return toSortedBildeMetadataList(metadataList)
  }, [bildeMetadataList.data, errorBildeMetadataList])

  return {
    ...bildeMetadataList,
    data: sortedBilder,
  }
}

const useObservasjonErrorBilder = (observasjonId: number) => {
  return useMutationState({
    filters: {
      mutationKey: bildeKeys.uploadToObservasjonBase(),
      predicate: (mutation) =>
        mutation.state.variables?.observasjonId === observasjonId &&
        isErrorMutation(mutation),
    },
    select: (mutation) =>
      (
        mutation.state.variables as Prettify<
          // mutationKey is upload key, so we know that bildeMetadata is defined
          Omit<BildeMutationVars, 'bildeMetadata'> & {
            bildeMetadata: IImage
          }
        >
      ).bildeMetadata,
  })
}

const useAllKvitteringErrorBilder = (kvitteringId: string) => {
  return useMutationState({
    filters: {
      mutationKey: bildeKeys.uploadBase(),
      predicate: (mutation) =>
        mutation.state.variables?.kvitteringId === kvitteringId &&
        isErrorMutation(mutation),
    },
    select: (mutation) =>
      mutation.state.variables as Prettify<
        // mutationKey is upload key, so we know that bildeMetadata is defined
        Omit<BildeMutationVars, 'bildeMetadata' | 'observasjonId'> & {
          bildeMetadata: IImage
          observasjonId?: number
        }
      >,
  })
}

const useMetadataMutation = (
  mutationKey: MutationKey,
  kvitteringId: string
) => {
  const mutation = useMutation<
    AxiosResponse<null>,
    string | Error,
    BildeUpdateVars,
    { previousMetadatas: IImage[] }
  >({ mutationKey })

  return {
    ...mutation,
    mutate: (variables: MetadataVars) =>
      mutation.mutate({ kvitteringId, ...variables }),
  }
}

const useUpdateBildeBeskrivelse = (kvitteringId: string) => {
  const update = useMetadataMutation(
    bildeKeys.updateBeskrivelse(kvitteringId),
    kvitteringId
  )

  const optimisticDebounceUpdate = useOptimisticDebounceMutate<
    IImage,
    unknown,
    MetadataVars
  >(
    update.mutate,
    bildeKeys.metadataList(kvitteringId),
    (metadataList, { bildeMetadata }) =>
      produce(metadataList, (draft) => {
        const oldMetadata = draft.find(
          (metadata) => metadata.id === bildeMetadata.id
        )

        if (oldMetadata) {
          oldMetadata.description = bildeMetadata.description
        }
      })
  )

  const updateBeskrivelseFn = useCallback(
    (data: MetadataVars) => {
      const updateKey = JSON.stringify(
        bildeKeys.updateBeskrivelse(kvitteringId)[0]
      )

      optimisticDebounceUpdate(data, {
        debounceMs: DEBOUNCE_MS,
        key: `${updateKey}_${data.bildeMetadata.id}`,
      })
    },
    [kvitteringId, optimisticDebounceUpdate]
  )

  return { ...update, mutate: updateBeskrivelseFn }
}

const useUpdateBildeLokasjon = (kvitteringId: string) => {
  const update = useMetadataMutation(
    bildeKeys.updateLokasjon(kvitteringId),
    kvitteringId
  )

  const optimisticDebounceUpdate = useOptimisticDebounceMutate<
    IImage,
    unknown,
    MetadataVars
  >(
    update.mutate,
    bildeKeys.metadataList(kvitteringId),
    (metadataList, { bildeMetadata }) =>
      produce(metadataList, (draft) => {
        const oldMetadata = draft.find(
          (metadata) => metadata.id === bildeMetadata.id
        )

        if (oldMetadata) {
          oldMetadata.locationDescription = bildeMetadata.locationDescription
          oldMetadata.locationDescriptionUpdateTime =
            bildeMetadata.locationDescriptionUpdateTime
          oldMetadata.locationLatitude = bildeMetadata.locationLatitude
          oldMetadata.locationLongitude = bildeMetadata.locationLongitude
          oldMetadata.locationCopiedFromId = bildeMetadata.locationCopiedFromId
        }
      })
  )

  const updateLokasjonFn = useCallback(
    (data: MetadataVars) => {
      const updateKey = JSON.stringify(
        bildeKeys.updateLokasjon(kvitteringId)[0]
      )

      optimisticDebounceUpdate(data, {
        debounceMs: DEBOUNCE_MS,
        key: `${updateKey}_${data.bildeMetadata.id}`,
      })
    },
    [kvitteringId, optimisticDebounceUpdate]
  )

  return { ...update, mutate: updateLokasjonFn }
}
