import * as R from 'ramda'

export const sortByKey =
  (sortingKey: string, sortDescending = false) =>
  <T>(data: T): T =>
    R.pipe(
      R.sortBy(
        R.pipe(
          R.prop(sortingKey),
          R.defaultTo(''),
          R.when(Number, Number),
          R.when(R.is(String), R.toLower)
        )
      ),
      R.when(() => sortDescending, R.reverse)
    )(data)
