import { Column, LoadingDots, Row, RowItem, TextArea } from '@mattilsynet/mt-ui'
import React, { ChangeEvent, ReactNode, useState } from 'react'
import { DeleteModal } from '../../../modals'
import {
  useObservasjon,
  useRemoveObservasjon,
  useUpdateObservasjon,
} from '../hooks/query-hooks'
import { useKvitteringId } from '../../../common/kvittering-context'
import { IObservasjon } from '../types'
import { IKontrollpunkt } from '../../kontrollpunkter/types'
import './style.css'

interface IObservasjonProps {
  kontrollpunkt: IKontrollpunkt
  observasjonId: number
  autofocus: boolean
  isValid: boolean
  children?: (observasjon: IObservasjon) => ReactNode
}

const Observasjon = ({
  kontrollpunkt,
  observasjonId,
  autofocus,
  isValid,
  children,
}: IObservasjonProps) => {
  const kvitteringId = useKvitteringId()
  const { data: observasjon } = useObservasjon(kvitteringId, observasjonId)

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false)

  const [beskrivelse, setBeskrivelse] = useState<string>()
  const { mutate: updateObservasjon } = useUpdateObservasjon(kvitteringId)
  const { mutate: removeObservasjon } = useRemoveObservasjon(kvitteringId)

  if (!observasjon) {
    return (
      <Row justify="center">
        <LoadingDots />
      </Row>
    )
  }

  const hasOneObservasjon = kontrollpunkt.observasjoner.length === 1

  const onChangeObservasjon = (
    kontrollpunkt: IKontrollpunkt,
    event: ChangeEvent<HTMLTextAreaElement>,
    observasjon: IObservasjon
  ) => {
    setBeskrivelse(event.currentTarget.value)
    updateObservasjon({
      kontrollpunkt,
      observasjon: { ...observasjon, tekst: event.currentTarget.value },
    })
  }

  const onDelete = () => {
    if (hasOneObservasjon) {
      return undefined
    }

    return () => onRemove(kontrollpunkt, observasjon)
  }

  const onRemove = (
    kontrollpunkt: IKontrollpunkt,
    observasjon: IObservasjon
  ) => {
    if (
      observasjon.tekst.length !== 0 ||
      observasjon.bildeIds?.length > 0 /* ||
      newImageIds?.length > 0*/
    ) {
      setIsConfirmDeleteOpen(true)
      return
    }

    removeObservasjon({ kontrollpunkt, observasjon })
  }

  const onDeleteObservasjon = () => {
    removeObservasjon({
      kontrollpunkt,
      observasjon,
    })

    setIsConfirmDeleteOpen(false)
  }

  const errorText =
    isValid && !observasjon.tekst ? 'Observasjonen mangler beskrivelse' : ''

  return (
    <Column className="observasjon">
      <Row style={{ width: '97%' }}>
        <RowItem flex={1} className="observasjon__rowitem">
          <TextArea
            onChange={(event) =>
              onChangeObservasjon(kontrollpunkt, event, observasjon)
            }
            placeholder="Beskrivelse av observasjon"
            value={beskrivelse ?? observasjon.tekst}
            onDelete={onDelete()}
            danger={Boolean(errorText)}
            errorText={errorText}
            autoFocus={autofocus}
            label="Beskrivelse av observasjon"
          />
        </RowItem>
      </Row>

      {children?.(observasjon)}

      <DeleteModal
        isOpen={isConfirmDeleteOpen}
        onCancel={() => setIsConfirmDeleteOpen(false)}
        onDelete={onDeleteObservasjon}
        title={`Er du sikker på at du vil slette observasjonen?`}
        confirmText="Ja, jeg vil slette observasjonen"
        cancelText="Avbryt"
        explanation={`${
          observasjon.bildeIds.length === 0 /* || newImageIds.length === 0*/
            ? ''
            : `Om det er bilder knyttet til observasjonen, vil disse fortsatt ligge på tilsynskvitteringen`
        }`}
      />
    </Column>
  )
}

export { Observasjon }
