import localForage from 'localforage'
import {
  PersistedClient,
  Persister,
  PersistQueryClientProviderProps,
} from '@tanstack/react-query-persist-client'
import { DehydratedState } from '@tanstack/react-query'
import { isBildeUploadKey } from '../features/bilder/queries/helpers'
import { shouldHandleError } from '../common/query'
import { stripFunctions } from '../common/helpers'

/** Get element type of array. Ex. ArrElement<strint[]> => string */
type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never

const cacheDatabase = localForage.createInstance({
  name: 'query-cache',
  storeName: 'tilsynskvittering',
  version: 1.0,
})

/**
 * Transforms Error obj to normal obj
 * so that it can be serialized.
 */
const objectifyError = (error: unknown) => {
  if (!error) {
    return error
  }

  return JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)))
}

/**
 * Parses a mutation to be serialized including Errors
 * and sets necessary statuses.
 */
const parseMutation = (
  mutation: ArrElement<DehydratedState['mutations']>
): ArrElement<DehydratedState['mutations']> => {
  const { error, failureReason } = mutation.state

  return {
    ...mutation,
    state: {
      ...mutation.state,
      error: objectifyError(error),
      failureReason: objectifyError(failureReason),
    },
  }
}

const parsePersistedClient = (client: PersistedClient): PersistedClient =>
  stripFunctions({
    ...client,
    clientState: {
      ...client.clientState,
      mutations: client.clientState.mutations.map(parseMutation),
    },
  })

const createIDBPersister = (idbValidKey = 'cache') => {
  return {
    persistClient: async (client: PersistedClient) => {
      const parsedClient = parsePersistedClient(client)

      await cacheDatabase.setItem(idbValidKey, parsedClient)
    },
    restoreClient: async () => {
      return await cacheDatabase.getItem(idbValidKey)
    },
    removeClient: async () => {
      await cacheDatabase.removeItem(idbValidKey)
    },
  } as Persister
}

const persister = createIDBPersister()

export const persistOptions: PersistQueryClientProviderProps['persistOptions'] =
  {
    persister: persister,
    dehydrateOptions: {
      shouldDehydrateMutation: (mutation) => {
        const isValidError =
          mutation.state.status === 'error' &&
          shouldHandleError(mutation.state.error)

        const isBildeUploading =
          mutation.state.status === 'pending' &&
          isBildeUploadKey(mutation.options.mutationKey ?? [])

        return mutation.state.isPaused || isValidError || isBildeUploading
      },
    },
  }

export const exportedForTesting = {
  objectifyError,
  parseMutation,
  parsePersistedClient,
}
