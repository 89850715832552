// Validation to check if sak-number is on form 4digits/OneOrMoreDigits
const formatSaknumber = (sakNumber) => {
  if (sakNumber.length === 4) {
    const year = sakNumber.slice(0, 4)
    const sakCounter = sakNumber.slice(4)
    return `${year}/${sakCounter}`
  }
  return sakNumber
}

export const onKeyDown = (e, oldValue) => {
  const newValue = e.target.value
  const valid = /(^\d{0,4}$)|(^\d{4}\/\d{0,9}$)/.test(newValue)

  if (valid) {
    if (oldValue.length > newValue.length) {
      if (oldValue.slice(-1) === '/') {
        return newValue.slice(0, -1)
      }
      return formatSaknumber(newValue)
    }

    return formatSaknumber(newValue)
  } else {
    return oldValue
  }
}

export const isSakNumberValid = (sakNumber) => /\d{4}\/\d+/g.test(sakNumber)
