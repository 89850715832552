import { ITilsynsobjekt } from './types'

export const currentTilsynsobjektInitialState: ITilsynsobjekt = {
  aktivitetsId: '',
  tilsynskvitteringId: '',
  tilsynsobjektId: '',
  navn: '',
  aktivitetsBeskrivelse: '',
  _links: {
    self: {
      href: '',
    },
    kontrollpunkter: {
      href: '',
    },
    veiledninger: {
      href: '',
    },
  },
  kontrollpunkter: [],
  veiledninger: [],
  identiteter: [],
}
