import React, { memo } from 'react'
import { ImageThumbnailWrapper } from '../../image-thumbnail-wrapper'
import { RoundIndicator } from '@mattilsynet/mt-ui'
import { useImageRemoval, useImageDeletionModal } from '../hooks'
import { useScrollToElement } from '../../../common/custom-hooks'

interface IObservasjonImage {
  id: string
  onClick?: (id: string) => void
  kvitteringId: string
  observasjonId: number
  isSelected?: boolean
  shouldScrollIntoView?: boolean
  hasIndicator?: boolean
}

const ObservasjonImage = memo(function ObservasjonImage({
  id,
  kvitteringId,
  observasjonId,
  shouldScrollIntoView,
  hasIndicator,
  isSelected,
  onClick,
}: IObservasjonImage) {
  const { openDeleteConfirmation, ImageDeleteDialog } =
    useImageDeletionModal(kvitteringId)
  const { openRemoveConfirmation, ImageRemoveDialog } = useImageRemoval(
    observasjonId,
    openDeleteConfirmation
  )

  const elementRef = useScrollToElement(shouldScrollIntoView ?? false)

  return (
    <div ref={elementRef}>
      <ImageThumbnailWrapper
        selected={isSelected}
        onClick={onClick}
        key={id}
        imageId={id}
        onRemove={openRemoveConfirmation}
        shouldValidate
        indicator={
          hasIndicator ? <RoundIndicator>O</RoundIndicator> : undefined
        }
      />

      <ImageRemoveDialog />
      <ImageDeleteDialog />
    </div>
  )
})

export { ObservasjonImage }
