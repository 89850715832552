// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tidligere-kontrollpunkter h1 {
  font-size: 1.5rem;
}

.tidligere-kontrollpunkt .radio-item-content {
  padding: 0;
}

.kontrollpunkt-tekst {
  word-break: break-all;
}

@media screen and (max-width: 767px) {
  .tidligere-kontrollpunkt-knapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 0.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/features/tidligere-kontrollpunkter/components/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,uBAAuB;IACvB,eAAe;EACjB;AACF","sourcesContent":[".tidligere-kontrollpunkter h1 {\n  font-size: 1.5rem;\n}\n\n.tidligere-kontrollpunkt .radio-item-content {\n  padding: 0;\n}\n\n.kontrollpunkt-tekst {\n  word-break: break-all;\n}\n\n@media screen and (max-width: 767px) {\n  .tidligere-kontrollpunkt-knapper {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background-color: white;\n    padding: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
