import React, { useState } from 'react'
import './style.css'

import { Accordion, Column, Disconnected, Text } from '@mattilsynet/mt-ui'

export const AccordionBarOffline = () => {
  const [isOpen, setIsOpen] = useState(false)

  const renderHeader = () => {
    return (
      <div className="accordion-bar-header-container">
        <div className="accordion-bar-header-empty-icon"></div>
        <Disconnected className="accordion-bar-header-icon-size"></Disconnected>
        <Text size="normal" weight="regular">
          Applikasjonen er offline
        </Text>
      </div>
    )
  }

  return (
    <Accordion
      header={renderHeader()}
      onClick={() => setIsOpen(!isOpen)}
      open={isOpen}
      paddingChildren={[1, 2, 2, 2]}
      small
      stickyPosition={52}
      backgroundColor={'#FBF5CB'}
    >
      <Column className={'info-text'} spacing={1}>
        <Text size="normal" weight="bold">
          Du har ingen internettforbindelse.
        </Text>
        <Text size="normal">
          Du kan fortsatt starte et forberedt tilsyn og gjøre registreringer.
        </Text>
        <Column>
          <Text size="normal">Du kan ikke:</Text>
          <Text size="normal">- Forberede et nytt tilsyn</Text>
          <Text size="normal">- Legge til sak eller tilsynsobjekt</Text>
          <Text size="normal">- Velge type tilsyn</Text>
          <Text size="normal">- Hente bilder fra galleriet</Text>
          <Text size="normal">- Opprette tilsynskvitteringen i arkivet </Text>
          <Text size="normal">- Fordele tilsynskvittering til kollega</Text>
          <Text size="normal">
            - Se sammenstillingen til en arkivert tilsynskvittering
          </Text>
          <Text size="normal">
            - Ferdigstille og sende ut tilsynskvitteringen
          </Text>
        </Column>
        <Text size="normal">
          For å gjøre dette trenger applikasjonen internettforbindelse.
        </Text>
      </Column>
    </Accordion>
  )
}
