import { IToast, toast } from '@mattilsynet/mt-common'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { IStoreState } from '../reducers/types'
import { useTypedSelector } from './custom-hooks'

export const useSingleToast = () => {
  const dispatch = useDispatch()
  const toasts = useTypedSelector((state: IStoreState) => state['@toasts'])
  const showToast = useCallback(
    (toastArg: IToast) => {
      if (toasts.find((t) => t.text === toastArg.text)) {
        return
      }

      dispatch(toast.actions.showToast(toastArg))
    },
    [toasts, dispatch]
  )
  return {
    showToast,
  }
}

export const successToast = (
  text: string,
  timeoutSeconds = 3,
  dismissible = true
) =>
  toast.actions.showToast({
    text: text,
    timeoutSeconds,
    dismissible,
    type: 'SUCCESS',
  })

export const dangerToast = (
  text = 'Det har skjedd en feil. Kunne ikke lagre.',
  timeoutSeconds = 10,
  dismissible = true
) =>
  toast.actions.showToast({
    text,
    timeoutSeconds,
    dismissible,
    type: 'DANGER',
  })

export const warningToast = (
  text: string,
  timeoutSeconds = 5,
  dismissible = true
) =>
  toast.actions.showToast({
    text,
    timeoutSeconds,
    dismissible,
    type: 'WARN',
  })

export const online = (): IToast => ({
  text: 'Applikasjonen er <b>online</b>, og alle funksjoner er tilgjengelig.',
  timeoutSeconds: 5,
  type: 'SUCCESS',
  dismissible: true,
})

export const copySaknummer = (): IToast => ({
  text: 'Saknummeret er kopiert',
  timeoutSeconds: 3,
  type: 'SUCCESS',
  dismissible: true,
})

export const offlineGeneral = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. Sjekk din internettilgang. \n' +
    '\n' +
    'Du kan starte et forberedt tilsyn og det du skriver vil bli lagret. \n' +
    '\n' +
    'Du kan ikke legge til sak, tilsynsobjekt, type tilsyn, opprette tilsynskvitteringer i arkivet, eller ferdigstille og sende ut tilsynskvitteringer før du er online. \n',
  timeoutSeconds: 15,
  type: 'WARN',
  dismissible: true,
})

export const offlineInForberedelseProcess = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b> \n' +
    '\n' +
    'Du kan derfor ikke forberede et nytt tilsyn. \n',
  type: 'DANGER',
  dismissible: true,
})

export const offlineInRegisteringProcess = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. Sjekk din internettilgang. \n' +
    '\n' +
    'Det du registrerer blir lagret, men du kan ikke opprette tilsynskvitteringen i arkivet før du er online.',
  timeoutSeconds: 10,
  type: 'WARN',
  dismissible: true,
})

export const offlineInFerdigstillingProcess = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. Du kan ikke ferdigstille og sende ut tilsynskvitteringen før applikasjonen er online. \n' +
    '\n' +
    'Prøv på nytt når du har internettilgang. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineInFerdigstillingFromHome = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke ferdigstille og sende ut tilsynskvitteringer. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineInSakAndTilsynsobjekt = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke legge til sak eller tilsynsobjekt. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineInTypeTilsynSelect = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke velge tilsynstype. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineInArkiveringSlide = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du må vente til du er online med å gå til siste side for å opprette tilsynskvitteringen i arkivet. \n',
  dismissible: true,
  type: 'DANGER',
})

export const sakITekstfelt = (): IToast => ({
  text: 'Saken må legges til eller tekstfeltet må være tomt',
  timeoutSeconds: 10,
  dismissible: true,
  type: 'DANGER',
})

export const offlineInHjemmelSelect = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. Du kan ikke velge hjemmel eller opprette tilsynskvitteringen i arkivet før applikasjonen er online\n' +
    '\n' +
    'Prøv på nytt når du har internettilgang. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineOnVelgEnkeltord = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke velge enkeltord som skal skjermes. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineOnSkjermMottaker = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke velge om mottakeren skal skjermes. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineOnUnntaOffentlighet = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke velge om dokument og/eller bilder skal unntas offentlighet. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineOnArchiveKvittering = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. Du kan ikke opprette tilsynskvitteringen i arkivet før applikasjonen er online. \n' +
    '\n' +
    'Prøv på nytt når du har internettilgang. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineOnSeSammenstilling = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke se sammenstillingene til arkiverte tilsynskvitteringer. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineOnOpenFordelTilKollega = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke fordele tilsynskvitteringer til kollega. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineOnHentFraKollega = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke hente tilsynskvitteringer fra kollega. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineOnHentBilderFraGalleri = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke hente bilder fra galleriet. \n',
  dismissible: true,
  type: 'DANGER',
})

export const offlineOnOpenTidligereKontrollpunkter = (): IToast => ({
  text:
    'Applikasjonen er <b>offline</b>. \n' +
    '\n' +
    'Du kan derfor ikke hente kontrollpunkter fra tidligere tilsynskvittering. \n',
  dismissible: true,
  type: 'DANGER',
})

export const noImagesSelectedForAction = (): IToast => ({
  text: 'Du må velge bilder for å kunne utføre denne handlingen',
  dismissible: true,
  type: 'DANGER',
})

export const alreadyAttachedImagesToObservasjon = (): IToast => ({
  text: 'Du har valgt bilder som er allerede knyttet til en observasjon. Frigjør disse bildene før du forsøker å knytte dem til observasjon',
  dismissible: true,
  type: 'WARN',
})

export const arkiveringTemporaryDisabled = (): IToast => ({
  text: 'Arkivering av tilsynskvitteringen er midlertidig deaktivert. Prøv igjen senere.',
  dismissible: true,
  type: 'WARN',
  timeoutSeconds: 3,
})
