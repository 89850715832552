import { IKvitteringData } from './types'

export const currentKvitteringInitialState: IKvitteringData = {
  id: '',
  bildeIds: [],
  tilsynsdato: '',
  tilsynsdatoSlutt: undefined,
  noarksak: '',
  journalpostId: '',
  userId: '',
  forhold: '',
  observasjoner: [],
  veiledninger: [],
  untattOffentlighet: false,
  hjemmelForUnntattOffentlighet: '',
  registrertInnholdGjennomgaat: false,
  ferdigstilt: false,
  opprettet: '',
  oppdatert: '',
  tilsynsobjekter: [],
  virksomhetsNavn: '',
  virksomhetsOrgNr: '',
  'mattilsynet-deltagere': [],
  'virksomhet-deltagere': [],
  'andre-deltagere': [],
  kontrollpunktGrupper: [],
  faktaarkUtlevert: undefined,
  bilderGjennomgaatt: undefined,
  bilderGjennomgaattBegrunnelse: '',
  registrertInnholdGjennomgaatt: undefined,
  registrertInnholdGjennomgaattBegrunnelse: '',
  ingenTilStede: undefined,
  ingenTilStedeBegrunnelse: '',
  typeTilsyn: '',
  typeTilsynBeskrivelse: '',
  synced: true,
}

export const tilsynstyperInitialState = {
  data: [],
  loading: false,
  loaded: false,
  error: '',
}
