import { createReducer } from '@mattilsynet/redux-common'
import * as R from 'ramda'
import { IUserSettingsKeys, IUserSettingsState } from './types'

export const STORE_NAME = 'userSettings'

export const userSettingActionTypes = {
  FETCH_FAIL: `${STORE_NAME}/FETCH_FAIL`,
  FETCH_MAALFORM: `${STORE_NAME}/FETCH_MAALFORM`,
  FETCH_INNSTILLINGER: `${STORE_NAME}/FETCH_INNSTILLINGER`,
  FETCH_OK: `${STORE_NAME}/FETCH_OK`,
  UPDATE: `${STORE_NAME}/UPDATE`,
  UPDATE_FAIL: `${STORE_NAME}/UPDATE_FAIL`,
  UPDATE_OK: `${STORE_NAME}/UPDATE_OK`,
}

export const userSettingsActions = {
  fetchMaalform: () => ({
    type: userSettingActionTypes.FETCH_MAALFORM,
  }),

  fetchInnstillinger: () => ({
    type: userSettingActionTypes.FETCH_INNSTILLINGER,
  }),

  fetchOk: (data: IUserSettingsState['data']) => ({
    type: userSettingActionTypes.FETCH_OK,
    data,
  }),

  fetchFail: (error: IUserSettingsState['error']) => ({
    type: userSettingActionTypes.FETCH_FAIL,
    error,
  }),

  update: (settingKey: IUserSettingsKeys, value: string | boolean) => ({
    type: userSettingActionTypes.UPDATE,
    settingKey,
    value,
  }),

  updateOk: () => ({
    type: userSettingActionTypes.UPDATE_OK,
  }),

  updateFail: (error) => ({
    type: userSettingActionTypes.UPDATE_FAIL,
    error,
  }),
}

export const initialState = {
  data: undefined,
  error: '',
  loaded: false,
  loading: true,
}

const customReducer = createReducer(initialState, {
  [userSettingActionTypes.FETCH_OK]: (state, { data }) =>
    R.mergeLeft(
      {
        data,
        error: '',
        loaded: true,
        loading: false,
      },
      state
    ),

  [userSettingActionTypes.FETCH_FAIL]: (state, { error }) =>
    R.mergeLeft(
      {
        data: undefined,
        error,
        loaded: false,
        loading: false,
      },
      state
    ),

  [userSettingActionTypes.UPDATE]: (state, { value, settingKey }) =>
    R.over(R.lensPath(['data']), R.mergeLeft({ [settingKey]: value }))(state),
})

export const userSettingsReducer = {
  [STORE_NAME]: customReducer,
}
