// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-number-item-number-area {
  position: relative;
  z-index: 1;
  width: 98%;
}

.mobile-number-item-number-area .text {
  position: relative;
  top: -0.25em;
}

.mobile-number-item-number-area input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.mobile-number-item-number-area input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
`, "",{"version":3,"sources":["webpack://./src/components/mobilenumber-item/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;;EAEE,wBAAwB;EACxB,SAAS,EAAE,uEAAuE;AACpF;;AAEA;EACE,0BAA0B,EAAE,YAAY;AAC1C","sourcesContent":[".mobile-number-item-number-area {\n  position: relative;\n  z-index: 1;\n  width: 98%;\n}\n\n.mobile-number-item-number-area .text {\n  position: relative;\n  top: -0.25em;\n}\n\n.mobile-number-item-number-area input::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */\n}\n\n.mobile-number-item-number-area input[type='number'] {\n  -moz-appearance: textfield; /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
