import { arkiveringStatusActions } from './actions'

export interface ISkjermedeOrdWithSelected
  extends ITilsynskvitteringRemote.SakstittelOrdRes {
  selected: boolean
}

export const FETCH_KVITTERING_ARKIV_INFO =
  'arkivering-status/FETCH_KVITTERING_ARKIV_INFO'
export const FETCH_KVITTERING_ARKIV_INFO_SUCCESS =
  'arkivering-status/FETCH_KVITTERING_ARKIV_INFO_SUCCESS'
export const FETCH_KVITTERING_ARKIV_INFO_FAILED =
  'arkivering-status/FETCH_KVITTERING_ARKIV_INFO_FAILED'

export const UPDATE_SKJERMEDE_ORD = 'arkivering-status/UPDATE_SKJERMEDE_ORD'
export const UPDATE_SKJERMEDE_ORD_SUCCESS =
  'arkivering-status/UPDATE_SKJERMEDE_ORD_SUCCESS'
export const UPDATE_SKJERMEDE_ORD_FAILED =
  'arkivering-status/UPDATE_SKJERMEDE_ORD_FAILED'

export interface IArkiveringStatusState {
  loading: boolean
  error: string
  data: IArkivRemote.ArchiveJob[]
  arkivInfo: ITilsynskvitteringRemote.ArkivInfoRes
}

interface IFetchKvitteringArkivInfoAction {
  type: typeof FETCH_KVITTERING_ARKIV_INFO
}

interface IFetchKvitteringArkivInfoSuccessAction {
  type: typeof FETCH_KVITTERING_ARKIV_INFO_SUCCESS
  payload: ITilsynskvitteringRemote.ArkivInfoRes
}

interface IFetchKvitteringArkivInfoFailedAction {
  type: typeof FETCH_KVITTERING_ARKIV_INFO_FAILED
  error: string
}

interface IUpdateSkjermedeOrdAction {
  type: typeof UPDATE_SKJERMEDE_ORD
  skjermedeOrd: ISkjermedeOrdWithSelected[]
}

interface IUpdateSkjermedeOrdSuccessAction {
  type: typeof UPDATE_SKJERMEDE_ORD_SUCCESS
}

interface IUpdateSkjermedeOrdFailedAction {
  type: typeof UPDATE_SKJERMEDE_ORD_FAILED
  error: string
}

export type ArkiveringStatusActions =
  | ReturnType<typeof arkiveringStatusActions.fetch>
  | ReturnType<typeof arkiveringStatusActions.fetchFailed>
  | ReturnType<typeof arkiveringStatusActions.fetchSuccess>
  | IFetchKvitteringArkivInfoAction
  | IFetchKvitteringArkivInfoSuccessAction
  | IFetchKvitteringArkivInfoFailedAction
  | IUpdateSkjermedeOrdAction
  | IUpdateSkjermedeOrdSuccessAction
  | IUpdateSkjermedeOrdFailedAction
