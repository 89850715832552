import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  CheckboxItem,
  Column,
  ContrastBox,
  Header,
  LoadingDots,
  RadioButtonList,
  Row,
  Text,
} from '@mattilsynet/mt-ui'
import { parseSakTitleToHTML } from '../../../common/parse-string'
import { kvitteringActions } from '../../../ducks/kvittering/actions'
import { useTypedSelector } from '../../../common/custom-hooks'
import { IStoreState } from '../../../reducers/types'
import { useDispatch } from 'react-redux'
import { clearSak, getSak } from '../../../ducks/sak/actions'
import {
  kvitteringStatusEnum,
  NONE,
  SAVING,
} from '../../../ducks/kvittering/types'

import {
  ArchiveModal,
  HjemmelModal,
  InfoModal,
  SkjermingModal,
} from '../../../modals'
import { kodeverk } from '@mattilsynet/mt-common'
import { IRemoteKodeverk } from '@mattilsynet/mt-common/lib/kodeverk/store/types'
import { isValidSak } from '../utils'
import {
  originalTittel,
  skjermOrdWithinBrackets,
} from '../../../modals/skjerming-modal/utils'
import {
  fetchKvitteringArkivInfo,
  updateSkjermedeOrd,
} from '../../../ducks/arkivering-status/actions'
import { ISkjermedeOrdWithSelected } from '../../../ducks/arkivering-status/types'
import { InfoModalWithoutConfirmation } from '../../../modals/info-modal'
import {
  dangerToast,
  useSingleToast,
  offlineInHjemmelSelect,
  offlineOnArchiveKvittering,
  offlineOnSkjermMottaker,
  offlineOnUnntaOffentlighet,
  arkiveringTemporaryDisabled,
} from '../../../common/toast'
import { TextWithValidation } from '../../../components/text-with-validation'
import { KjaeledyrholdWarning } from '../../../components/kjaeledyrhold-warning'
import { ITilsynsobjekt } from '../../../ducks/kvittering/tilsynsobjekt/types'
import { isArkivertStatus } from '../../../utils/kvittering'
import '../style.css'
import { TilsynsdatoPicker } from '../../../components/tilsynsdato-picker'
import { useTKNavigate } from '../../../common/navigation'
import {
  TOGGLES,
  useSafeUnleashWithoutAnnoyingBugs,
} from '../../../common/unleashToggles'

enum SkjermingAvMottakerOptions {
  SKAL_SKJERMES = '1',
  SKAL_IKKE_SKJERMES = '0',
}

export const ArkiveringSlide = () => {
  const navigate = useTKNavigate()
  const singleToast = useSingleToast()
  const { isEnabled: isTKArkiveringEnabled } =
    useSafeUnleashWithoutAnnoyingBugs(TOGGLES.tkArkivering)
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)
  const currentKvittering = useTypedSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering.data
  )

  const currentKvitteringSavingStatus = useTypedSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering.status
  )

  const currentSak = useTypedSelector((state: IStoreState) => state.sak)

  const arkivInfo = useTypedSelector(
    (state: IStoreState) => state.arkiveringStatus.arkivInfo
  )

  const isPrivatperson = currentKvittering.virksomhetsOrgNr === undefined

  const showKjaeledyrholdWarning =
    isPrivatperson &&
    currentKvittering.tilsynsobjekter
      ?.map(
        (tilsynsobjekt: ITilsynsobjekt) => tilsynsobjekt.aktivitetsBeskrivelse
      )
      .includes('Kjæledyrhold')

  const kodeverkResponse = kodeverk.hooks.useKodeverk('TILGANGSHJEMMEL')

  const [isArchiveModalOpen, setIsArchiveModalopen] = useState(false)
  const [isErrorInfoModalOpen, setIsErrorInfoModalOpen] = useState(false)
  const [isNotFoundInfoModalOpen, setIsNotFoundInfoModalOpen] = useState(false)
  const [isHjemmelModalOpen, setIsHjemmelModalOpen] = useState(false)
  const [isSakstittelModalOpen, setIsSakstittelModalOpen] = useState(false)
  const [hjemmelMoreInfo, setHjemmelMoreInfo] = useState<string | undefined>('')
  const [existingHjemmelMoreInfo, setExistingHjemmelMoreInfo] = useState<
    string | undefined
  >('')
  const [isErrorSkjermMottaker, setIsErrorSkjermMottaker] = useState(false)
  const [isErrorSkalTaggeVirksomhetsnavn, setIsErrorSkalTaggeVirksomhetsnavn] =
    useState(false)

  const dispatch = useDispatch()
  // todo temporarily dispatch fetchSammenstilling to assure fresh data, might be redundant after validating is in place
  useEffect(() => {
    dispatch(kvitteringActions.fetchSammenstilling())
  }, [dispatch])

  useEffect(() => {
    !!currentSak.sakNumber && dispatch(getSak(currentSak.sakNumber))
  }, [])

  useEffect(() => {
    if (!isValidSak(currentKvittering, currentSak)) {
      dispatch(fetchKvitteringArkivInfo())
    }
  }, [])

  useEffect(() => {
    if (currentKvittering.tilsynsobjekter[0].tilsynsobjektId) {
      dispatch(
        kvitteringActions.fetchVirksomhetOrganisasjonsForm(
          currentKvittering.tilsynsobjekter[0].tilsynsobjektId
        )
      )
    }
  }, [])

  const currentKvitteringSakNumber =
    currentKvittering.noarksakAar &&
    `${currentKvittering.noarksakAar}/${currentKvittering.noarksakSekvensnummer}`

  const isTittelSkjermet =
    !!arkivInfo?.skjermedeOrd?.length || showKjaeledyrholdWarning

  const isNewSak =
    !isValidSak(currentKvittering, currentSak) && !currentSak.sakClosed

  const onArchive = useCallback(
    (nextStatus?: string) => {
      if (nextStatus === kvitteringStatusEnum.FERDIGSTILT) {
        return dispatch(
          kvitteringActions.ferdigstillKvittering(currentKvittering.id)
        )
      } else {
        return dispatch(
          kvitteringActions.arkiverKvittering(currentKvittering.id)
        )
      }
    },
    [dispatch, currentKvittering.id]
  )

  const onCancelModal = (shouldRefreshData: boolean) => {
    if (currentSak.error) {
      dispatch(clearSak())
    }
    if (currentKvittering?.status === kvitteringStatusEnum.FERDIGSTILT) {
      navigate('/')
    }
    if (shouldRefreshData) {
      dispatch(kvitteringActions.fetchSammenstilling())
    }
    setIsArchiveModalopen(false)
  }

  const showHjemmel =
    !!currentKvittering.unntattOffentlighet ||
    !!currentKvittering.personSkjermet ||
    !!(arkivInfo?.skjermedeOrd && arkivInfo.skjermedeOrd.length > 0)

  const onUnntattOffentlighet = useCallback(() => {
    const { unntattOffentlighet, hjemmelForUnntattOffentlighet } =
      currentKvittering
    dispatch(
      kvitteringActions.updateCurrentKvittering({
        unntattOffentlighet: !unntattOffentlighet,
        hjemmelForUnntattOffentlighet: showHjemmel
          ? hjemmelForUnntattOffentlighet
          : '',
      })
    )
  }, [dispatch, currentKvittering, showHjemmel])

  const onSkjerming = useCallback(
    (option: SkjermingAvMottakerOptions) => {
      setIsErrorSkjermMottaker(false)
      const { hjemmelForUnntattOffentlighet } = currentKvittering
      dispatch(
        kvitteringActions.updateCurrentKvittering({
          personSkjermet: option === SkjermingAvMottakerOptions.SKAL_SKJERMES,
          hjemmelForUnntattOffentlighet: showHjemmel
            ? hjemmelForUnntattOffentlighet
            : '',
        })
      )
    },
    [currentKvittering, showHjemmel, dispatch, setIsErrorSkjermMottaker]
  )

  const onCancelHjemmelModal = () => {
    setIsHjemmelModalOpen(false)
  }

  const skjermSaksTittel = useCallback(
    (skjermedeOrd: ISkjermedeOrdWithSelected[]) =>
      dispatch(updateSkjermedeOrd(skjermedeOrd)),
    [dispatch]
  )

  const onCloseSakstittelModal = (
    skjermedeOrd: ISkjermedeOrdWithSelected[]
  ) => {
    skjermSaksTittel(skjermedeOrd)
    setIsSakstittelModalOpen(false)
  }

  const onSkjermingSakstittel = (checked: boolean) => {
    if (!checked) {
      skjermSaksTittel([])
      return
    }

    const sakstittelOrd = arkivInfo.ord?.map((o) => ({
      index: o.index,
      ord: o.ord,
      selected: !isTittelSkjermet,
    }))
    skjermSaksTittel(sakstittelOrd ?? [])
  }

  useEffect(() => {
    if (!showHjemmel) {
      dispatch(
        kvitteringActions.updateCurrentKvittering({
          hjemmelForUnntattOffentlighet: '',
        })
      )
      setHjemmelMoreInfo('')
    } else {
      const hjemmel = kodeverkResponse?.data?.find(
        (hjemmel) =>
          hjemmel.codeString ===
          currentKvittering?.hjemmelForUnntattOffentlighet
      )
      setHjemmelMoreInfo(hjemmel?.displayNames?.descr_ifelt)
    }
  }, [showHjemmel, kodeverkResponse?.data])

  const onSelectHjemmel = useCallback(
    (selectedHjemmel: IRemoteKodeverk) => {
      setHjemmelMoreInfo(selectedHjemmel?.displayNames?.descr_ifelt)
      dispatch(
        kvitteringActions.updateCurrentKvittering({
          hjemmelForUnntattOffentlighet: selectedHjemmel.codeString,
        })
      )
    },
    [dispatch, setHjemmelMoreInfo]
  )

  const archiveStatus = isArkivertStatus(currentKvittering.status)
    ? currentKvitteringSavingStatus
    : NONE

  const onOpprettTilsynskvittering = () => {
    const {
      unntattOffentlighet,
      hjemmelForUnntattOffentlighet,
      personSkjermet,
    } = currentKvittering

    if (isOffline) {
      return singleToast.showToast(offlineOnArchiveKvittering())
    }

    if (!isTKArkiveringEnabled) {
      return singleToast.showToast(arkiveringTemporaryDisabled())
    }

    if (
      (!!unntattOffentlighet || !!personSkjermet || isTittelSkjermet) &&
      !hjemmelForUnntattOffentlighet
    ) {
      const errorText =
        'Du må velge hjemmel før du kan opprette tilsynskvitteringen i arkivet.'
      return dispatch(dangerToast(errorText))
    }

    if (currentSak.sakClosed) {
      return setIsErrorInfoModalOpen(true)
    }

    if (currentSak.sakNotFound) {
      return setIsNotFoundInfoModalOpen(true)
    }

    if (currentKvittering.personSkjermet === undefined) {
      return setIsErrorSkjermMottaker(true)
    }

    if (
      currentKvittering?.virksomhetNavnSkalTagges === undefined &&
      currentKvittering.orgFormKode === 'ENK' &&
      !currentSak.sakNumber
    ) {
      return setIsErrorSkalTaggeVirksomhetsnavn(true)
    }

    setIsArchiveModalopen(true)
  }

  useEffect(() => {
    if (currentSak.sakHjemmel) {
      setExistingHjemmelMoreInfo(
        kodeverkResponse?.data?.find(
          (hjemmel) =>
            hjemmel.displayNames &&
            hjemmel.displayNames.no === currentSak.sakHjemmel
        )?.displayNames?.descr_ifelt
      )
    }
  }, [currentSak.sakHjemmel, kodeverkResponse.data])

  const renderInfo = () => {
    if (currentSak.sakNotFound) {
      return (
        <Column>
          <Text size="normal" weight="bold">
            Saken du har søkt opp finnes ikke.
          </Text>
          <Text size="normal">
            Det vil derfor opprettes en sak når du oppretter tilsynskvitteringen
            i arkivet.
          </Text>
        </Column>
      )
    }
    if (currentSak.sakClosed) {
      return (
        <Column>
          <Text size="normal" weight="bold">
            Saken du har søkt opp er ugyldig.
          </Text>
          <Text size="normal">
            Du må enten gjenåpne saken, legge til en gyldig sak, eller fjerne
            saken slik at det opprettes en ny sak når tilsynskvitteringen
            arkiveres.
          </Text>
        </Column>
      )
    }
    if (currentSak.sakNumber) {
      return (
        <Text size="normal" weight="semibold">
          Tilsynskvitteringen opprettes i arkivet på følgende sak:
        </Text>
      )
    }

    return (
      <Column>
        <Text size="normal" weight="bold">
          Du har ikke søkt opp en sak.
        </Text>
        <Text size="normal">
          Det vil derfor opprettes en sak når du oppretter tilsynskvitteringen i
          arkivet.
        </Text>
      </Column>
    )
  }

  const renderSakInfo = () => {
    // If user has searched for a sak
    if (currentSak.sakNumber && !currentSak.sakNotFound) {
      return (
        <Column fluid spacing={2.5}>
          <div>
            <Text size="normal" weight="bold">
              Saksnummer
            </Text>
            <Text size="normal">{currentSak.sakNumber}</Text>
          </div>
          {currentSak.title && (
            <div>
              <Text size="normal" weight="bold">
                Sakstittel
              </Text>
              <Text as="div" size="normal">
                <div
                  dangerouslySetInnerHTML={{
                    __html: parseSakTitleToHTML(
                      originalTittel(currentSak.title)
                    ),
                  }}
                />
              </Text>
            </div>
          )}
          {existingHjemmelMoreInfo && existingHjemmelMoreInfo?.length > 1 && (
            <div>
              <Text size="normal" weight="bold">
                Saken er unntatt offentlighet
              </Text>
              <Text size="normal">{currentSak.sakHjemmel}</Text>
              <Text size="medium" weight="lighter">
                {existingHjemmelMoreInfo}
              </Text>
            </div>
          )}
        </Column>
      )
    }

    if (isNewSak) {
      return (
        <Column fluid spacing={0.5}>
          <Text size="normal" weight="bold">
            Sakstittel
          </Text>
          <Text as="div" size="normal">
            <div
              dangerouslySetInnerHTML={{
                __html: parseSakTitleToHTML(
                  isTittelSkjermet
                    ? skjermOrdWithinBrackets(arkivInfo.sakstittel ?? '')
                    : originalTittel(arkivInfo.sakstittel ?? '')
                ),
              }}
            />
          </Text>
          <CheckboxItem
            selected={isTittelSkjermet}
            onClick={() => onSkjermingSakstittel(!isTittelSkjermet)}
            disabled={showKjaeledyrholdWarning}
          >
            <Text size="normal">Sakstittel skal unntas offentlighet</Text>
          </CheckboxItem>
        </Column>
      )
    }

    return <LoadingDots />
  }

  const renderSkjerming = () => {
    const options = [
      {
        value: SkjermingAvMottakerOptions.SKAL_SKJERMES,
        label: 'Mottakeren skal skjermes',
      },
      {
        value: SkjermingAvMottakerOptions.SKAL_IKKE_SKJERMES,
        label: 'Mottakeren skal ikke skjermes',
      },
    ]

    const value =
      currentKvittering.personSkjermet !== undefined
        ? currentKvittering.personSkjermet
          ? SkjermingAvMottakerOptions.SKAL_SKJERMES
          : SkjermingAvMottakerOptions.SKAL_IKKE_SKJERMES
        : undefined

    return (
      <Column fluid spacing={2.5}>
        <Header as="h2" size="heading3">
          Skjerming av mottaker
        </Header>
        <Row justify="space-between">
          <RadioButtonList
            disabled={showKjaeledyrholdWarning}
            options={options}
            onChange={(option) => {
              isOffline
                ? singleToast.showToast(offlineOnSkjermMottaker())
                : onSkjerming(option as SkjermingAvMottakerOptions)
            }}
            value={value}
            noBackgroundColor
            hasError={isErrorSkjermMottaker}
          />
        </Row>
        <TextWithValidation
          errorText={'Utfylling mangler'}
          value={''}
          hasError={isErrorSkjermMottaker}
          withWhiteSpace
          as="h4"
          bold
        />
      </Column>
    )
  }

  const renderSkalTaggeVirksomhet = () => {
    const onChange = (value) => {
      dispatch(
        kvitteringActions.updateCurrentKvittering({
          virksomhetNavnSkalTagges: value === '0',
        })
      )
      setIsErrorSkalTaggeVirksomhetsnavn(false)
    }
    return (
      <Column>
        <Text
          margin={[2, 0, 2, 0]}
          weight="bold"
        >{`Inneholder virksomhetsnavnet "${currentKvittering.virksomhetsNavn}" andre ord enn personnavn?`}</Text>
        <RadioButtonList
          options={[
            {
              value: '1',
              label: 'Ja',
            },
            {
              value: '0',
              label:
                'Nei, virksomhetsnavnet består kun av innehavers personnavn',
            },
          ]}
          onChange={(value) => onChange(value)}
          noBackgroundColor
          value={
            currentKvittering?.virksomhetNavnSkalTagges === undefined
              ? ''
              : currentKvittering.virksomhetNavnSkalTagges
                ? '0'
                : '1'
          }
          hasError={isErrorSkalTaggeVirksomhetsnavn}
        />
        <TextWithValidation
          errorText={'Utfylling mangler'}
          value={''}
          hasError={isErrorSkalTaggeVirksomhetsnavn}
          withWhiteSpace
          as="h4"
          bold
        />
      </Column>
    )
  }

  const renderUnntattOffentlighet = () => {
    return (
      <Column fluid spacing={2.5}>
        <Header as="h2" size="heading3">
          Unnta dokument og/eller bilder fra offentlighet
        </Header>
        <Row justify="space-between">
          <CheckboxItem
            disabled={showKjaeledyrholdWarning}
            onClick={() =>
              isOffline
                ? singleToast.showToast(offlineOnUnntaOffentlighet())
                : onUnntattOffentlighet()
            }
            selected={!!currentKvittering.unntattOffentlighet}
          >
            <Text size="normal">
              Dokumentet og/eller bilder skal unntas offentlighet
            </Text>
          </CheckboxItem>
        </Row>
      </Column>
    )
  }

  const onHjemmelButton = () => {
    if (isOffline) {
      return singleToast.showToast(offlineInHjemmelSelect())
    }
    setIsHjemmelModalOpen(true)
  }

  const renderHjemmelInfo = () => {
    const isSelectedHjemmel = !!currentKvittering.hjemmelForUnntattOffentlighet

    const hjemmel = {
      title: isSelectedHjemmel
        ? 'Hjemmel for unntatt offentlighet er valgt'
        : 'Velg hjemmel for unntatt offentlighet',
      info: isSelectedHjemmel
        ? currentKvittering.hjemmelForUnntattOffentlighet
        : '',
      buttonText: isSelectedHjemmel ? 'Bytt hjemmel' : 'Velg hjemmel',
      moreInfo: hjemmelMoreInfo,
    }

    return (
      <Column fluid spacing={2.5}>
        <Header as="h2" size="heading3">
          {hjemmel.title}
        </Header>
        <Column spacing={1}>
          <Text size="normal">{hjemmel.info}</Text>
          <Text size="medium" weight="light">
            {hjemmel.moreInfo}
          </Text>
        </Column>
        <Row justify="center">
          <Button width="16em" secondary onClick={onHjemmelButton}>
            {hjemmel.buttonText}
          </Button>
        </Row>
      </Column>
    )
  }

  useEffect(() => {
    const {
      personSkjermet,
      unntattOffentlighet,
      hjemmelForUnntattOffentlighet,
    } = currentKvittering
    const hjemmel = kodeverkResponse?.data?.find(
      (kodeverk: IRemoteKodeverk) =>
        kodeverk.codeString ===
        'Offl. § 13 første ledd jf fvl. § 13 første ledd nr 1'
    )

    const { ord: saksTittelOrd, skjermedeOrd } = arkivInfo

    const isSakTitleSkjermet = skjermedeOrd?.length === saksTittelOrd?.length

    if (showKjaeledyrholdWarning && !personSkjermet) {
      onSkjerming(SkjermingAvMottakerOptions.SKAL_SKJERMES)
    }

    if (showKjaeledyrholdWarning && !unntattOffentlighet) {
      onUnntattOffentlighet()
    }

    if (showKjaeledyrholdWarning && !hjemmelForUnntattOffentlighet && hjemmel) {
      onSelectHjemmel(hjemmel)
    }

    if (
      showKjaeledyrholdWarning &&
      isNewSak &&
      !isSakTitleSkjermet &&
      saksTittelOrd
    ) {
      skjermSaksTittel(
        saksTittelOrd?.map(
          (ord: ITilsynskvitteringRemote.SakstittelOrdRes) => ({
            ...ord,
            selected: true,
          })
        )
      )
    }
  }, [
    showKjaeledyrholdWarning,
    currentKvittering,
    arkivInfo,
    isNewSak,
    kodeverkResponse?.data,
    onSkjerming,
    onUnntattOffentlighet,
    onSelectHjemmel,
    skjermSaksTittel,
  ])

  return (
    <>
      <Column spacing={3} fluid key={2}>
        {showKjaeledyrholdWarning && <KjaeledyrholdWarning />}
        <Row className="title">
          <Header as="h1" size="heading1">
            Opprettelse av tilsynskvittering i arkivet
          </Header>
        </Row>

        <ContrastBox noBorders backgroundColor="white">
          <TilsynsdatoPicker
            currentKvittering={currentKvittering}
            currentKvitteringSavingStatus={currentKvitteringSavingStatus}
          />
        </ContrastBox>

        <Column spacing={2}>
          <ContrastBox noBorders backgroundColor="white">
            {renderInfo()}
          </ContrastBox>
          <ContrastBox noBorders backgroundColor="white">
            {renderSakInfo()}
          </ContrastBox>
          <ContrastBox noBorders backgroundColor="white">
            {renderSkjerming()}
          </ContrastBox>
          {currentKvittering.orgFormKode === 'ENK' && (
            <ContrastBox noBorders backgroundColor="white">
              {renderSkalTaggeVirksomhet()}
            </ContrastBox>
          )}
          <ContrastBox noBorders backgroundColor="white">
            {renderUnntattOffentlighet()}
          </ContrastBox>
          {showHjemmel && (
            <ContrastBox noBorders backgroundColor="white">
              {renderHjemmelInfo()}
            </ContrastBox>
          )}
        </Column>
        <Row justify="center">
          <Button large onClick={onOpprettTilsynskvittering}>
            Ferdigstill og send ut
          </Button>
        </Row>
      </Column>
      <InfoModalWithoutConfirmation
        isOpen={isErrorInfoModalOpen}
        title="Du har valgt en ugyldig sak."
        info="Du må enten gjenåpne saken, legge til en gyldig sak, eller fjerne saken slik at det opprettes en ny sak når tilsynskvitteringen arkiveres."
        cancelText="Gå tilbake"
        onCancel={() => setIsErrorInfoModalOpen(false)}
      />
      <InfoModal
        isOpen={isNotFoundInfoModalOpen}
        title="Saken du har valgt finnes ikke."
        info="Du må enten gå tilbake for å søke opp en gyldig sak, eller så kan du opprette en ny sak her og nå."
        confirmText="Opprett ny sak"
        cancelText="Gå tilbake"
        onConfirm={() => {
          setIsNotFoundInfoModalOpen(false)
          setIsArchiveModalopen(true)
        }}
        onCancel={() => setIsNotFoundInfoModalOpen(false)}
      />
      <ArchiveModal
        onCancel={onCancelModal}
        isOpen={isArchiveModalOpen}
        onArchive={onArchive}
        archiveStatus={archiveStatus}
        sakNumber={currentKvitteringSakNumber}
        tilsynskvitteringId={currentKvittering.id!}
        isLoading={archiveStatus === SAVING || currentSak.loading}
        existingHjemmelDesc={existingHjemmelMoreInfo}
        existingHjemmelName={currentSak.sakHjemmel}
        chosenHjemmelDesc={hjemmelMoreInfo}
        chosenHjemmelName={currentKvittering.hjemmelForUnntattOffentlighet}
        hjemmelChosen={showHjemmel}
      />
      <HjemmelModal
        isOpen={isHjemmelModalOpen}
        onCancel={onCancelHjemmelModal}
        hjemmelList={kodeverkResponse?.data}
        onSelectHjemmel={onSelectHjemmel}
        selectedHjemmel={currentKvittering.hjemmelForUnntattOffentlighet}
        loading={!!kodeverkResponse?.loading}
        error={!!kodeverkResponse?.error}
        onRetry={kodeverkResponse.fetchKodeverk}
      />
      {!!arkivInfo.sakstittel && isSakstittelModalOpen && (
        <SkjermingModal
          isOpen={isSakstittelModalOpen}
          onOk={(skjermedOrd) => {
            onCloseSakstittelModal(skjermedOrd)
          }}
          ord={arkivInfo.ord || []}
          skjermedeOrd={arkivInfo.skjermedeOrd || []}
        />
      )}
    </>
  )
}
