import React from 'react'
import './style.css'
import {
  Cross,
  IconButton,
  LoadingDots,
  Column,
  CrossDeleteIcon,
} from '@mattilsynet/mt-ui'
import { CloudIcon } from '../../../../assets/cloud-icon'
import CheckmarkIcon from '@mattilsynet/mt-ui/dist/icons/checkmark'

interface IBildeThumbnail {
  alt: string
  isSelected?: boolean
  onClick: () => void
  onRemove?: () => void
  status?: BildeStatus
  tag?: JSX.Element
  url: string
}

export type BildeStatus =
  | 'ERROR'
  | 'INVALID'
  | 'LOADING'
  | 'NOT_UPLOADED'
  | 'SUCCESS'
  | 'UNDEFINED'
  | 'UPLOADING'

/**
 * ## BildeThumbnail ##
 * @alt
 * This component is used to display a thumbnail of an image.
 * @component
 * @category Bilder
 * @example
 *  <BildeThumbnail
 *   alt={bildeBeskrivelse}
 *   isSelected={isSelected}
 *   onClick={onClick}
 *   onRemove={onRemove}
 *   status={status}
 *   url={url}
 * />
 * @param {string} description - description of the image
 * @param {boolean} isSelected - if the image is selected
 * @param {function} onClick - function to be called when the image is clicked
 * @param {function} onRemove - function to be called when the image is removed
 * @param {BildeStatus} status - status of the image
 * @param {string} url - url of the image
 * @param {JSX.Element} tags - tags to be displayed on the image
 * @returns {JSX.Element} - returns a JSX element
 */
export const BildeThumbnail = ({
  alt,
  isSelected,
  onClick,
  onRemove,
  status,
  tag,
  url,
}: IBildeThumbnail) => {
  const statusMap: Record<BildeStatus, string> = {
    ERROR: 'bilde-thumbnail__image--error',
    INVALID: 'bilde-thumbnail__image--error',
    LOADING: 'bilde-thumbnail__image--loading',
    NOT_UPLOADED: 'bilde-thumbnail__image--not-uploaded',
    SUCCESS: 'bilde-thumbnail__image--default',
    UNDEFINED: 'bilde-thumbnail__image--default',
    UPLOADING: 'bilde-thumbnail__image--error',
  }

  const imageStatus = url && status ? statusMap[status] : statusMap['UNDEFINED']
  const hasRemove =
    onRemove !== undefined && status !== 'LOADING' && isSelected === undefined
  const hasOverlay =
    status === 'ERROR' ||
    status === 'UPLOADING' ||
    status === 'NOT_UPLOADED' ||
    isSelected
  const isSelectedClass = isSelected ? 'bilde-thumbnail__image--selected' : ''

  return (
    <div
      className={`bilde-thumbnail${
        isSelected ? ' bilde-thumbnail--selected' : ''
      }`}
    >
      {hasRemove && (
        <div className="bilde-thumbnail__actions">
          <IconButton
            onClick={onRemove}
            large
            icon={<Cross innerColor="white" size="large" />}
            className="bilde-thumbnail__remove"
            ariaLabel="Fjern bildet"
          />
        </div>
      )}

      <div
        className={`bilde-thumbnail__image ${imageStatus} ${isSelectedClass}`}
        onClick={onClick}
      >
        {tag && <div className="bilde-thumbnail__tag">{tag}</div>}

        {/* loading placeholder */}
        {!url || status === 'LOADING' ? (
          <div className="bilde-thumbnail__placeholder" />
        ) : (
          <img
            src={url}
            alt={alt}
            className={hasOverlay ? 'bilde-thumbnail__img-overlay' : ''}
          />
        )}

        {/* overlays */}
        {hasOverlay && (
          <div className="bilde-thumbnail__overlay">
            {status === 'NOT_UPLOADED' && (
              <Column
                align="center"
                className="bilde-thumbnail__overlay--not-uploaded"
              >
                <CloudIcon color="white" />
              </Column>
            )}

            {status === 'ERROR' && (
              <Column
                align="center"
                className="bilde-thumbnail__overlay--not-uploaded"
              >
                <CrossDeleteIcon color="white" />
              </Column>
            )}

            {status === 'UPLOADING' && <LoadingDots />}
          </div>
        )}
        {isSelected && (
          <CheckmarkIcon
            className="selected-checkmark"
            color="white"
            size="small"
          />
        )}
      </div>
    </div>
  )
}
