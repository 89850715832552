// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.archive-modal .LoadingSpinner.small {
  height: initial;
}
`, "",{"version":3,"sources":["webpack://./src/modals/archive-modal/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB","sourcesContent":[".archive-modal .LoadingSpinner.small {\n  height: initial;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
