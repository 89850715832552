import React, { useContext, useState } from 'react'
import { Row, SelectableButton, ThemeContext } from '@mattilsynet/mt-ui'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'
import { ISkjermedeOrdWithSelected } from '../../ducks/arkivering-status/types'
import { ModalWrapper } from '../../components/modal-wrapper'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { ModalButton } from '../../components/modal-button'
import { modalContentPadding } from '../../components/modal-buttons-container/constants'

interface ISkjermingModalProps {
  isOpen: boolean
  onOk: (skjermedeOrd: ISkjermedeOrdWithSelected[]) => void
  ord: ITilsynskvitteringRemote.SakstittelOrdRes[]
  skjermedeOrd: ITilsynskvitteringRemote.SakstittelOrdRes[]
}
export const SkjermingModal = ({
  isOpen,
  onOk,
  ord,
  skjermedeOrd,
}: ISkjermingModalProps) => {
  const initialState = ord
    .filter(
      (ordItem: ITilsynskvitteringRemote.SakstittelOrdRes) =>
        ordItem.ord.length > 0
    )
    .map((item: ITilsynskvitteringRemote.SakstittelOrdRes) => ({
      index: item.index,
      ord: item.ord,
      selected: skjermedeOrd.some(
        (skjermetOrdItem: ITilsynskvitteringRemote.SakstittelOrdRes) =>
          skjermetOrdItem.index === item.index
      ),
    }))

  const [arrayOfTitle, setArrayOfTitle] = useState(() => initialState)

  const theme: IThemeColors = useContext(ThemeContext)

  const buttonText = 'Skjerm valgte ord'

  const onSkjermTittel = () => {
    const skjermetOrd = arrayOfTitle.filter(
      (ordItem: ISkjermedeOrdWithSelected) => ordItem.selected
    )

    onOk(skjermetOrd)
  }

  const onClick = (index) => {
    setArrayOfTitle((state) =>
      state.map((item: ISkjermedeOrdWithSelected) =>
        item.index === index
          ? {
              ...item,
              selected: !item.selected,
            }
          : item
      )
    )
  }

  return (
    <>
      <ModalWrapper
        fullscreenMobile
        isOpen={isOpen}
        onCancel={onSkjermTittel}
        title="Velg ord i sakstittelen som skal skjermes"
        className="sakstittel-modal"
      >
        <Row padding={modalContentPadding}>
          {arrayOfTitle.map((titleItem, index) => (
            <SelectableButton
              key={`${titleItem.ord}-${index}`}
              onClick={() => onClick(titleItem.index)}
              selected={titleItem.selected}
              withMargin
              withPadding
              borderColor={theme.primary}
              small
            >
              {titleItem.ord}
            </SelectableButton>
          ))}
        </Row>
        <ModalButtonsContainer>
          <ModalButton onClick={onSkjermTittel}>{buttonText}</ModalButton>
        </ModalButtonsContainer>
      </ModalWrapper>
    </>
  )
}
