import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { IStoreState } from '../reducers/types'
import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as R from 'ramda'
import {
  IAktivitet,
  IMidlertidigTilsynsobjektVirksomhet,
} from '../ducks/kvittering/types'
import {
  useCreateKvitteringMutation,
  useKvittering,
} from '../features/kvitteringer'
import { kvitteringQueryHelpers } from '../features/kvitteringer/queries/helpers'
import {
  useAddMidlertidigTilsynsobjekt,
  useUpdateMidlertidigTilsynsobjekt,
} from '../features/midlertidig-tilsynsobjekter'
import { IMidlertidigTilsynsobjektPayload } from '../features/midlertidig-tilsynsobjekter/types'
import { kvitteringSelectors } from '../ducks/kvittering/selectors'

export const useTypedSelector: TypedUseSelectorHook<IStoreState> = useSelector

export const useToggleCards = <T = string>(initialOpencards: T[] = []) => {
  const [openCards, setOpenCards] = useState<T[]>(initialOpencards)
  const toggleCard = useCallback(
    (id: T) => () =>
      setOpenCards(
        openCards.includes(id)
          ? R.without([id], openCards)
          : R.append(id, openCards)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openCards, setOpenCards]
  )
  return { openCards, toggleCard, setOpenCards }
}

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => clearTimeout(timeOut)
  }, [delay, value])
  return debouncedValue
}

/**
 * Debounce a function call
 * @param delay in ms
 */
export const useDebounceFn = (delay = 200) => {
  const timeoutRef = useRef<NodeJS.Timeout>()

  const debounce = useCallback(
    (func: () => void) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        func()
      }, delay)
    },
    [delay]
  )

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return debounce
}

export const useMidlertidigTilsynsobjekt = (
  kodeverkAktiviteterBehandlesIMats,
  setIsVirksomhetModalOpen
) => {
  const [isAktivitetModalOpen, setIsAktivitetModalOpen] = useState(false)
  const [aktiviteter, setAktiviteter] = useState<IAktivitet[] | undefined>([])
  const [aktivitetOptions, setAktivitetOptions] = useState<
    IAktivitet[] | undefined
  >([])
  const [selectedAktivitet, setSelectedAktivitet] = useState<
    IAktivitet | undefined
  >()
  const [
    midlertidigTilsynsObjektVirksomhet,
    setMidlertidigTilsynsObjektVirksomhet,
  ] = useState<IMidlertidigTilsynsobjektVirksomhet>({ navn: '', nummer: '' })
  const [
    unselectedMidlertidigTilsynsobjekt,
    setUnselectedMidlertidigTilsynsobjekt,
  ] = useState()

  const { mutateAsync: createKvittering } = useCreateKvitteringMutation()
  const { mutate: createMidlertidigTilsynsojekt } =
    useAddMidlertidigTilsynsobjekt()

  const currentKvitteringId = useSelector(
    kvitteringSelectors.getCurrentKvitteringId
  )

  const { data: kvittering } = useKvittering(currentKvitteringId)

  const { mutate: updateMidlertidigTilsynsobjekt } =
    useUpdateMidlertidigTilsynsobjekt(currentKvitteringId)

  const addMidlertidigTilsynsobjekt = (
    kvitteringId: string,
    tilsynsobjekt: IMidlertidigTilsynsobjektPayload,
    isSelected: boolean,
    orgNr: string
  ) => {
    createMidlertidigTilsynsojekt(
      {
        kvitteringId,
        tilsynsobjekt,
        isSelected,
        orgNr,
      },
      {
        onSettled: () => {
          kvitteringQueryHelpers.invalidateKvittering(kvitteringId)
        },
      }
    )
  }

  const getAktiviteterBehandlesIMats = useCallback(
    (aktivitet) =>
      aktivitet.children && aktivitet.children.length > 0
        ? aktivitet.children.map((child) => getAktiviteterBehandlesIMats(child))
        : {
            label: aktivitet.displayNames.no,
            value: `AKTIVITET$${aktivitet.codeString}`,
          },
    []
  )

  useEffect(() => {
    if (!kodeverkAktiviteterBehandlesIMats.data) return
    const childrenAktiviteter = kodeverkAktiviteterBehandlesIMats.data
      .map((aktivitet) => getAktiviteterBehandlesIMats(aktivitet))
      .flat(Infinity)
      .sort((a, b) => a.label.localeCompare(b.label))

    setAktiviteter(childrenAktiviteter)
    setAktivitetOptions(childrenAktiviteter)
  }, [getAktiviteterBehandlesIMats, kodeverkAktiviteterBehandlesIMats.data])

  const onSelectAktivitet = useDeepCallback(
    (value) => {
      const aktivitet = aktiviteter?.find(
        (aktivitet) => aktivitet.value === value
      )
      if (aktivitet) {
        setSelectedAktivitet({
          label: aktivitet.label,
          value: aktivitet?.value,
        })
      }
    },
    [aktiviteter]
  )

  const onSearchAktivitet = useDeepCallback(
    (searchValue) => {
      const aktivitetSearch = aktiviteter?.filter((aktivitet) =>
        aktivitet.label.toLowerCase().includes(searchValue.toLowerCase())
      )
      setAktivitetOptions(aktivitetSearch)
    },
    [aktiviteter]
  )

  const onClickMidlertidigTilsynsobjekt = useCallback(
    (navn, nummer) => {
      if (
        navn !== midlertidigTilsynsObjektVirksomhet.navn ||
        nummer !== midlertidigTilsynsObjektVirksomhet.nummer
      ) {
        const tilsynsobjekt = kvittering?.tilsynsobjekter.find(
          (t) => t.midlertidig
        )
        if (
          !tilsynsobjekt ||
          tilsynsobjekt.virksomhetsNavn !== navn ||
          tilsynsobjekt.virksomhetsNummer !== nummer
        ) {
          setSelectedAktivitet(undefined)
        }
      }
      setMidlertidigTilsynsObjektVirksomhet({ navn: navn, nummer: nummer })
      setIsAktivitetModalOpen(true)
    },
    [
      kvittering?.tilsynsobjekter,
      midlertidigTilsynsObjektVirksomhet.navn,
      midlertidigTilsynsObjektVirksomhet.nummer,
    ]
  )

  const onCancelAktivitetModal = async () => {
    if (!selectedAktivitet) {
      setMidlertidigTilsynsObjektVirksomhet({ navn: '', nummer: '' })
    } else {
      if (kvittering?.tilsynsobjekter.some((t) => t.midlertidig)) {
        const virksomhetTilsynsobjekt = kvittering?.tilsynsobjekter.find(
          (t) => t.midlertidig
        )
        if (virksomhetTilsynsobjekt) {
          const updatedTilsynsobjekt = {
            ...virksomhetTilsynsobjekt,
            aktivitetsBeskrivelse: selectedAktivitet?.label,
            aktivitetsId: selectedAktivitet?.value,
          }
          updateMidlertidigTilsynsobjekt({
            tilsynsobjekt: updatedTilsynsobjekt,
            kvitteringId: currentKvitteringId,
          })
        }
      } else {
        if (
          (!kvittering?.virksomhetsNavn && !kvittering?.virksomhetsOrgNr) ||
          (kvittering?.virksomhetsNavn ===
            midlertidigTilsynsObjektVirksomhet.navn &&
            kvittering?.virksomhetsOrgNr ===
              midlertidigTilsynsObjektVirksomhet.nummer)
        ) {
          const virksomhetTilsynsobjekt = {
            aktivitetsBeskrivelse: selectedAktivitet?.label,
            midlertidig: true,
            aktivitetsId: selectedAktivitet?.value,
            navn: 'Midlertidig Tilsynsobjekt',
            virksomhetsNavn: midlertidigTilsynsObjektVirksomhet.navn,
            virksomhetsNummer: midlertidigTilsynsObjektVirksomhet.nummer,
          }
          const isSelected =
            (!kvittering?.virksomhetsNavn && !kvittering?.virksomhetsOrgNr) ||
            (virksomhetTilsynsobjekt.virksomhetsNavn ===
              kvittering?.virksomhetsNavn &&
              virksomhetTilsynsobjekt.virksomhetsNummer ===
                kvittering?.virksomhetsOrgNr)

          const orgNr = midlertidigTilsynsObjektVirksomhet.nummer

          if (!currentKvitteringId) {
            const kvitteringId = await createKvittering({
              virksomhetTilsynsobjekt,
              orgNr,
              isSelected,
            })

            addMidlertidigTilsynsobjekt(
              kvitteringId,
              virksomhetTilsynsobjekt,
              isSelected,
              orgNr
            )
          }
        }
      }
    }
    setIsAktivitetModalOpen(false)
    setIsVirksomhetModalOpen(true)
  }

  return {
    isAktivitetModalOpen,
    setIsAktivitetModalOpen,
    aktivitetOptions,
    selectedAktivitet,
    setSelectedAktivitet,
    onSelectAktivitet,
    onSearchAktivitet,
    midlertidigTilsynsObjektVirksomhet,
    setMidlertidigTilsynsObjektVirksomhet,
    onClickMidlertidigTilsynsobjekt,
    onCancelAktivitetModal,
    unselectedMidlertidigTilsynsobjekt,
    setUnselectedMidlertidigTilsynsobjekt,
  }
}

const useDeepCompareMemoize = (deps: DependencyList): DependencyList => {
  const ref = useRef<DependencyList>()

  if (!R.equals(deps, ref.current)) {
    ref.current = deps
  }

  return ref.current!
}

/**
 * @warning Be careful! May be inefficient if large array or object in dependencies.
 * @param effectCallback
 * @param deps
 */
export const useDeepEffect = (
  effectCallback: EffectCallback,
  deps: DependencyList
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCallback, useDeepCompareMemoize(deps))
}

/**
 * @warning Be careful! May be inefficient if large array or object in dependencies.
 * @param callback
 * @param deps
 */
export const useDeepCallback = <T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback<T>(callback, useDeepCompareMemoize(deps))
}

/**
 * @warning Be careful! May be inefficient if large array or object in dependencies.
 * @param factory
 * @param deps
 */
export const useDeepMemo = <T>(factory: () => T, deps: DependencyList) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<T>(factory, useDeepCompareMemoize(deps))
}

export const usePaginatedList = <T>(
  data: T[],
  pageSize: number
): [
  currentPage: T[],
  totalPages: number,
  pageNumber: number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
] => {
  const [page, setPage] = useState(0)

  const paginatedList = useMemo(
    () =>
      data.reduce((res, item, index) => {
        const page = Math.floor(index / pageSize)
        res[page] = [...(res[page] ?? []), item]
        return res
      }, [] as T[][]),
    [data, pageSize]
  )

  const currentPage = useMemo(
    () => paginatedList[page] ?? [],
    [page, paginatedList]
  )

  useDeepEffect(() => setPage(0), [data])

  return [currentPage, paginatedList.length, page, setPage]
}

export const useShouldShowNavigator = (isMobile: boolean) => {
  const [showNavigator, setShowNavigator] = useState(true)

  const resizeHandler = useCallback(
    (evt) => {
      const isPortrait = screen.availWidth < screen.availHeight
      const screenHeight = screen.availHeight
      const viewHeight = evt.currentTarget.height

      if (isPortrait) {
        setShowNavigator(true)
        return
      }

      if (viewHeight < screenHeight * 0.5) {
        setShowNavigator(false)
        return
      }

      setShowNavigator(true)
    },
    [setShowNavigator]
  )

  useEffect(() => {
    if (isMobile) {
      window.visualViewport?.addEventListener('resize', resizeHandler)
    }

    return () =>
      window.visualViewport?.removeEventListener('resize', resizeHandler)
  }, [resizeHandler, isMobile])

  return showNavigator
}

export const useScrollToElement = (shouldScroll: boolean) => {
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (elementRef.current && shouldScroll) {
      elementRef.current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    }
  }, [shouldScroll])

  return elementRef
}
