import React from 'react'
import {
  TextLink,
  GridItem,
  Row,
  Grid,
  Column,
  RowItem,
  ShadowBox,
} from '@mattilsynet/mt-ui'
import './style.css'

const AccessDeniedRoute = () => (
  <Grid id="access-denied">
    <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
      <ShadowBox>
        <Column spacing={1} padding={3}>
          <Row>Du har ikke tilgang til Tilsynskvittering</Row>
          <span>
            Kontakt brukerstøtte og{' '}
            <TextLink href="/login" inline>
              prøv på nytt
            </TextLink>
            .
          </span>
          <Column spacing={1}>
            <h2>Brukerstøtte</h2>
            <div>
              <span className="space">
                Opprett{' '}
                <TextLink
                  href="https://makuba.mattilsynet.no/"
                  linkOutside
                  inline
                >
                  brukerstøttesak
                </TextLink>
              </span>
            </div>
            <Row>
              Du kan også kontakte IKT-brukerstøtte på telefon eller e-post:
            </Row>
            <Row>
              <RowItem className="space">Telefon:</RowItem>
              <TextLink href="tel:22 77 88 99">22 77 88 99</TextLink>
            </Row>
            <Row>
              <RowItem className="space">E-post:</RowItem>
              <TextLink href="mailto:99@mattilsynet.no">
                99@mattilsynet.no
              </TextLink>
            </Row>
          </Column>
        </Column>
      </ShadowBox>
    </GridItem>
  </Grid>
)
export default AccessDeniedRoute
