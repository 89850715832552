import {
  NavigateFunction,
  NavigateOptions,
  To,
  useLocation,
  useNavigate as useReactRouterNavigate,
} from 'react-router-dom'
import { useCallback } from 'react'
import { router } from '../routes/router'

export { useTKNavigate, useGoBack, useReplaceQuery, navigate, goBack }

const _navigate =
  (navigateFn: NavigateFunction, pathname: string) =>
  (to: To, options?: NavigateOptions) => {
    navigateFn(to, {
      ...options,
      state: { fromPath: pathname, ...options?.state },
    })
  }

const useTKNavigate = () => {
  const navigate = useReactRouterNavigate()
  const { pathname } = useLocation()

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      _navigate(navigate, pathname)(to, options)
    },
    [navigate, pathname]
  )
}

const useGoBack = (fallbackPath = '/') => {
  const navigate = useReactRouterNavigate()
  const {
    state: { fromPath },
    pathname,
  } = useLocation()

  return useCallback(() => {
    if (fromPath && fromPath !== pathname) {
      navigate(-1)
    }

    navigate(fallbackPath)
  }, [fromPath, pathname, navigate, fallbackPath])
}

const useReplaceQuery = () => {
  const navigate = useReactRouterNavigate()

  return useCallback(
    (query: string) => {
      navigate(
        {
          search: query,
        },
        { replace: true }
      )
    },
    [navigate]
  )
}

/**
 * Navigate to a new route
 * @internal PRIVATE - DO NOT USE. See: https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
 */
const navigate = (to: To, options?: NavigateOptions) => {
  const { pathname } = router.state.location

  _navigate(router.navigate, pathname)(to, options)
}

/**
 * Go back a route
 * @internal PRIVATE - DO NOT USE. See: https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
 */
const goBack = (fallbackPath = '/') => {
  const fromPath = router.state.location.state?.fromPath

  if (fromPath) {
    router.navigate(-1)
  }

  router.navigate(fallbackPath)
}
