// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kravText {
  overflow-wrap: anywhere;
}

.navButtons {
  position: sticky;
  bottom: -1px;
  background-color: white;
}

.ansattName {
  white-space: pre-wrap;
}

.kollegerSearch {
  margin-bottom: 1rem;
}

.hentFraKollegaModal .modal-buttons-wrapper {
  margin: 0 0 0 0;
}

.hentFraKollegaModal .modal-body {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

@media only screen and (min-width: 992px) {
  .hentFraKollegaModal div.modal-child {
    height: 85vh;
  }
}

@media only screen and (max-width: 500px) {
  .kollegerSearch {
    padding: 0 1rem 0 1rem;
  }

  .hentFraKollegaModal div.modal-child {
    border-radius: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modals/hent-fra-kollega-modal/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".kravText {\n  overflow-wrap: anywhere;\n}\n\n.navButtons {\n  position: sticky;\n  bottom: -1px;\n  background-color: white;\n}\n\n.ansattName {\n  white-space: pre-wrap;\n}\n\n.kollegerSearch {\n  margin-bottom: 1rem;\n}\n\n.hentFraKollegaModal .modal-buttons-wrapper {\n  margin: 0 0 0 0;\n}\n\n.hentFraKollegaModal .modal-body {\n  flex-grow: 1;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n}\n\n@media only screen and (min-width: 992px) {\n  .hentFraKollegaModal div.modal-child {\n    height: 85vh;\n  }\n}\n\n@media only screen and (max-width: 500px) {\n  .kollegerSearch {\n    padding: 0 1rem 0 1rem;\n  }\n\n  .hentFraKollegaModal div.modal-child {\n    border-radius: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
