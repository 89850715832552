import { queryApi } from '../../../api'
import { ENDPOINT } from '../../../constants'
import { IMidlertidigTilsynsobjektMutateVars } from '../types'

export const midlertidigTilsynsobjektApi = {
  post: async ({
    kvitteringId,
    tilsynsobjekt,
  }: IMidlertidigTilsynsobjektMutateVars) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvitteringId}/tilsynsobjekter`

    return queryApi.post(url, tilsynsobjekt)
  },
  put: async ({ tilsynsobjekt }: IMidlertidigTilsynsobjektMutateVars) => {
    const { id, aktivitetsId, virksomhetsNavn, virksomhetsNummer, navn } =
      tilsynsobjekt

    const payload = { aktivitetsId, virksomhetsNavn, virksomhetsNummer, navn }

    const url = `${ENDPOINT.TILSYNSKVITTERING}/midlertidigtilsynsobjekter/${id}`

    return queryApi.put(url, payload)
  },
}
