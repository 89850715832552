import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IToast, toast } from '@mattilsynet/mt-common'
import { authSubject } from '../../auth/keycloak'
import { initKeycloak } from '../../auth/helpers'
import { PageLoading } from '../page-loading'
import { IStoreState } from '../../reducers/types'
import { Button, LoadingSpinner } from '@mattilsynet/mt-ui'
import './style.css'
import { useLocation } from 'react-router-dom'
import { useTKNavigate } from '../../common/navigation'

const excludedPaths =
  /^\/login|^\/logout|^\/setup|^\/access-denied|^\/forbered-new-tilsyn/

const pathIfValid = (path: string | null): string =>
  !path || excludedPaths.test(path) ? '/' : path

export const couldNotLogOut = (): IToast => ({
  text: 'Kunne ikke logge ut, applikasjonen er offline.',
  timeoutSeconds: 10,
  type: 'WARN',
  dismissible: true,
})

export const Login = () => {
  const navigate = useTKNavigate()
  const isOnline = window.navigator.onLine
  const { pathname, search } = useLocation()
  const path = pathIfValid(pathname.concat(search))

  useEffect(() => {
    authSubject.subscribe((keycloak) => {
      keycloak.addListener('logout', () => {
        navigate('/logout/callback')
      })

      initKeycloak(keycloak).then(({ isAuthenticated, hasRealmAccess }) => {
        if (isAuthenticated && hasRealmAccess) {
          navigate(path)
        } else if (!isAuthenticated && !hasRealmAccess && !isOnline) {
          navigate(path)
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <PageLoading loadingText="Logger inn" />
}

export const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useTKNavigate()
  const offline = useSelector((state: IStoreState) => state.ui.offline)

  useEffect(() => {
    authSubject.subscribe((keycloak) => {
      if (offline) {
        navigate('/')
        dispatch(toast.actions.showToast(couldNotLogOut()))
      } else {
        return keycloak.logout('/logout/callback')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <LoadingSpinner title="Logger ut" />
}

export const LogoutCallback = () => {
  const navigate = useTKNavigate()

  return (
    <div id="login">
      <div className="content">
        <div className="text">Du er ikke logget inn.</div>
        <Button onClick={() => navigate('/login')}>Logg inn</Button>
      </div>
    </div>
  )
}

export const LogoutView = () => {
  return <PageLoading loadingText="Logger ut" />
}
