// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
  padding: 0;
  overflow: hidden;
  min-height: calc(100vh - 300px);
}

.slide-item {
  position: relative;
  min-width: 100%;
  transition: transform 0.5s;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/slider/style.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,0BAA0B;EAC1B,gBAAgB;AAClB","sourcesContent":[".slider {\n  padding: 0;\n  overflow: hidden;\n  min-height: calc(100vh - 300px);\n}\n\n.slide-item {\n  position: relative;\n  min-width: 100%;\n  transition: transform 0.5s;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
