import { Text } from '@mattilsynet/mt-ui'
import React from 'react'

interface IHentFraKollegaBekreftViewProps {
  kollega: string
}

export const HentFraKollegaBekreftView = ({
  kollega,
}: IHentFraKollegaBekreftViewProps) => (
  <Text>{kollega} vil bli informert om at du har hentet tilsynkvittering.</Text>
)
