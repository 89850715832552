// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#type-tilsyn .subtitle {
  font-size: 1.125em;
  font-weight: bold;
}

#type-tilsyn .button-add .Button {
  max-width: 16em;
}
`, "",{"version":3,"sources":["webpack://./src/components/type-tilsyn/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["#type-tilsyn .subtitle {\n  font-size: 1.125em;\n  font-weight: bold;\n}\n\n#type-tilsyn .button-add .Button {\n  max-width: 16em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
