// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sammenstilling .info-header {
  font-size: 1em;
  font-weight: bold;
}

#sammenstilling .title {
  font-size: 1.5em;
  font-weight: bold;
}

#sammenstilling .RadioButtonItem .radio-item-content {
  cursor: pointer;
  padding: 0.5em 0;
  display: flex;
  align-items: center;
}

#sammenstilling .TextLink {
  display: inline-flex;
}
`, "",{"version":3,"sources":["webpack://./src/routes/sammenstilling/style.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":["#sammenstilling .info-header {\n  font-size: 1em;\n  font-weight: bold;\n}\n\n#sammenstilling .title {\n  font-size: 1.5em;\n  font-weight: bold;\n}\n\n#sammenstilling .RadioButtonItem .radio-item-content {\n  cursor: pointer;\n  padding: 0.5em 0;\n  display: flex;\n  align-items: center;\n}\n\n#sammenstilling .TextLink {\n  display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
