// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

.image-action-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 0px 4px black;
  animation: 0.1s slideFromBottom;
}

.image-action-menu__button {
  background: transparent;
  position: relative;
  cursor: pointer;
}

.tooltip-text {
  width: 5rem;
  background-color: var(--mt-granskog);
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-action-menu__button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text:after {
  content: '';
  position: absolute;
  top: 98%;
  right: 2%;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: var(--mt-granskog) transparent transparent transparent;
}
`, "",{"version":3,"sources":["webpack://./src/features/bilder-multi-action/style.css"],"names":[],"mappings":"AAAA;EACE;IACE,2BAA2B;EAC7B;;EAEA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,QAAQ;EACR,aAAa;EACb,uBAAuB;EACvB,6BAA6B;EAC7B,+BAA+B;AACjC;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,oCAAoC;EACpC,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,QAAQ;EACR,kBAAkB;EAClB,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,oEAAoE;AACtE","sourcesContent":["@keyframes slideFromBottom {\n  0% {\n    transform: translateY(100%);\n  }\n\n  100% {\n    transform: translateY(0);\n  }\n}\n\n.image-action-menu {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 1000;\n  background-color: white;\n  box-shadow: 0px 0px 4px black;\n  animation: 0.1s slideFromBottom;\n}\n\n.image-action-menu__button {\n  background: transparent;\n  position: relative;\n  cursor: pointer;\n}\n\n.tooltip-text {\n  width: 5rem;\n  background-color: var(--mt-granskog);\n  color: #fff;\n  text-align: center;\n  border-radius: 4px;\n  padding: 5px;\n  position: absolute;\n  z-index: 1;\n  bottom: 125%;\n  right: 0;\n  visibility: hidden;\n  opacity: 0;\n  transition: opacity 0.3s;\n}\n\n.image-action-menu__button:hover .tooltip-text {\n  visibility: visible;\n  opacity: 1;\n}\n\n.tooltip-text:after {\n  content: '';\n  position: absolute;\n  top: 98%;\n  right: 2%;\n  margin-left: -5px;\n  border-width: 10px;\n  border-style: solid;\n  border-color: var(--mt-granskog) transparent transparent transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
