import { Immutable } from 'immer'
import { queryClient } from '../../../api/query-client'
import { shouldIgnoreError } from '../../../common/query'
import { dangerToast } from '../../../common/toast'
import { ITilsynsobjekt } from '../../../ducks/kvittering/tilsynsobjekt/types'
import { IKvitteringData } from '../../../ducks/kvittering/types'
import { IVirksomhetTilsynsobjekt } from '../../../ducks/virksomhet-search/types'
import store from '../../../reducers/store'
import { kvitteringQueryHelpers } from '../../kvitteringer/queries/helpers'
import { getTilsynsobjekterFromKvittering } from '../cache-helpers'
import { TilsynsobjektMutationOptions } from '../types'
import { tilsynsobjektKeys } from './helpers'
import { tilsynsobjektApi } from './tilsynsobjekt-api'

/**
 * Optimistically updates tilsynsobjekter
 * in the cache.
 *
 * @returns Previous tilsynsobjekter (Context that
 * is passed to onError).
 */
const onMutate: (
  updateTilsynsobjekter: (
    tilsynsobjekter: ITilsynsobjekt[],
    tilsynsobjekt: ITilsynsobjekt | IVirksomhetTilsynsobjekt
  ) => ITilsynsobjekt[]
) => TilsynsobjektMutationOptions['onMutate'] =
  (updateTilsynsobjekter) =>
  async ({ kvitteringId, tilsynsobjekt }) => {
    await kvitteringQueryHelpers.cancelKvitteringQuery(kvitteringId)

    let kvittering: Immutable<IKvitteringData> | undefined
    try {
      kvittering = kvitteringQueryHelpers.getKvitteringCache(kvitteringId)
    } catch (error) {
      // Kvittering is created by this mutation -> no kvittering to update in cache yet
      return
    }

    const previousTilsynsobjekter = kvittering
      ? getTilsynsobjekterFromKvittering(kvittering) ?? []
      : []

    kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
      draft.tilsynsobjekter = updateTilsynsobjekter(
        draft.tilsynsobjekter,
        tilsynsobjekt
      )
    })

    return { previousTilsynsobjekter }
  }

/** Displays error toast and reverts cache to previous tilsynsobjekter. */

const onError: TilsynsobjektMutationOptions['onError'] = (
  error,
  { kvitteringId },
  context
) => {
  if (shouldIgnoreError(error)) {
    return
  }

  store.dispatch(
    dangerToast(
      'Det har skjedd en feil med tilsynsobjektene. Kunne ikke lagre.'
    )
  )

  kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (kvittering) => {
    kvittering.tilsynsobjekter =
      context?.previousTilsynsobjekter ?? kvittering.tilsynsobjekter
  })
}

// Add tilsynsobjekt
queryClient.setMutationDefaults(tilsynsobjektKeys.add(), {
  mutationFn: tilsynsobjektApi.post,
  onMutate: onMutate((tilsynsobjekter, tilsynsobjekt) => [
    ...tilsynsobjekter,
    tilsynsobjekt as ITilsynsobjekt,
  ]),
  onError: onError,
} satisfies TilsynsobjektMutationOptions)

// Remove tilsynsobjekt
queryClient.setMutationDefaults(tilsynsobjektKeys.removeBase(), {
  mutationFn: tilsynsobjektApi.delete,
  onMutate: onMutate((tilsynsobjekter, tilsynsobjekt) =>
    tilsynsobjekter.filter((t) => t.id !== tilsynsobjekt.id)
  ),
  onError: onError,
} satisfies TilsynsobjektMutationOptions)
