import React, { useState } from 'react'
import { ModalWrapper } from '../../components/modal-wrapper'
import { Column } from '@mattilsynet/mt-ui'
import './style.css'
import { HentFraKollegaBegrunnelserView } from '../../components/hent-fra-kollega-begrunnelser-view'
import { useSingleToast } from '../../common/toast'
import { useTypedSelector } from '../../common/custom-hooks'
import { IStoreState } from '../../reducers/types'
import { IToast, toast } from '@mattilsynet/mt-common'
import { useDispatch } from 'react-redux'
import { HentFraKollegaKollegerView } from '../../components/hent-fra-kollega-kolleger-view'
import { HentFraKollegaKvitteringView } from '../../components/hent-fra-kollega-kvittering-view'
import { HentFraKollegaBekreftView } from '../../components/hent-fra-kollega-bekreft-view'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { modalContentPaddingLarge } from '../../components/modal-buttons-container/constants'
import { ModalButton } from '../../components/modal-button'

export interface IKollega {
  name: string | undefined
  username: string | undefined
}

export interface IKvitteringToGet {
  id: string
  name: string
  harBilder: boolean
}

interface IHentFraKollegaModalProps {
  onCancel: () => void
  isOpen: boolean
}

const enum Views {
  BegrunnelserView = 'BegrunnelserView',
  KollegerView = 'KollegerView',
  KvitteringView = 'KvitteringView',
  BekreftView = 'BekreftView',
}

export const hasImagesToast: IToast = {
  text: 'På nåværende tidspunkt kan man ikke hente tilsynskvitteringer med bilder.',
  dismissible: true,
  type: 'DANGER',
}

const noBegrunnelseToast: IToast = {
  text: 'Du må velge en årsak til at du henter tilsynskvittering fra kollega.',
  dismissible: true,
  type: 'DANGER',
}
const noKollegaToast: IToast = {
  text: 'Du må velge en kollega du skal hente tilsynkvittering fra.',
  dismissible: true,
  type: 'DANGER',
}
const noKvitteringToast: IToast = {
  text: 'Du må velge en eller flere tilsynskvitteringer du ønsker å hente',
  dismissible: true,
  type: 'DANGER',
}

export const HentFraKollegaModal = ({
  onCancel,
  isOpen,
}: IHentFraKollegaModalProps) => {
  const [selectedBegrunnelse, setSelectedBegrunnelse] = useState<
    string | undefined
  >(undefined)
  const [selectedKollega, setSelectedKollega] = useState<IKollega | undefined>(
    undefined
  )
  const [selectedKvitteringer, setSelectedKvitteringer] = useState<
    IKvitteringToGet[]
  >([])
  const [hentFraKollegaView, setHentFraKollegaView] = useState<Views>(
    Views.BegrunnelserView
  )

  const dispatch = useDispatch()

  const toasts = useTypedSelector((state: IStoreState) => state['@toasts'])
  const isDesktop = useTypedSelector((state: IStoreState) => state.ui.isDesktop)

  const singleToast = useSingleToast()

  const canAdvanceInBegrunnelseView =
    hentFraKollegaView === Views.BegrunnelserView && selectedBegrunnelse
  const canAdvanceInKollegerView =
    hentFraKollegaView === Views.KollegerView && selectedKollega
  const canAdvanceInKvitteringView =
    hentFraKollegaView === Views.KvitteringView &&
    selectedKvitteringer.length > 0

  const contentPadding = isDesktop ? [1, 3, 12, 3] : modalContentPaddingLarge

  const showErrorToast = () => {
    switch (hentFraKollegaView) {
      case Views.BegrunnelserView:
        return singleToast.showToast(noBegrunnelseToast)
      case Views.KollegerView:
        return singleToast.showToast(noKollegaToast)
      case Views.KvitteringView:
        return singleToast.showToast(noKvitteringToast)
    }
  }

  const removeToasts = (toastsToBeRemoved: IToast[]) => {
    for (const toastToRemove of toastsToBeRemoved) {
      const match = toasts.find((toast) => toast.text === toastToRemove.text)
      dispatch(toast.actions.hideToast(match?.id ?? ''))
    }
  }

  const onHentKvitteringer = () => {
    const kvitteringIds = selectedKvitteringer.map(
      (kvittering) => kvittering.id
    )
    dispatch(
      kvitteringActions.hentFraKollega(
        selectedBegrunnelse ?? '',
        selectedKollega?.name ?? '',
        kvitteringIds
      )
    )
    onCancel()
  }

  const onPrevious = () => {
    removeToasts([
      hasImagesToast,
      noBegrunnelseToast,
      noKollegaToast,
      noKvitteringToast,
    ])

    switch (hentFraKollegaView) {
      case Views.BegrunnelserView:
        return onCancel()
      case Views.KollegerView:
        return setHentFraKollegaView(Views.BegrunnelserView)
      case Views.KvitteringView:
        return setHentFraKollegaView(Views.KollegerView)
      case Views.BekreftView:
        return setHentFraKollegaView(Views.KvitteringView)
    }
  }

  const onNext = () => {
    if (
      hentFraKollegaView === Views.BegrunnelserView &&
      canAdvanceInBegrunnelseView
    ) {
      return setHentFraKollegaView(Views.KollegerView)
    }

    if (hentFraKollegaView === Views.KollegerView && canAdvanceInKollegerView) {
      return setHentFraKollegaView(Views.KvitteringView)
    }

    if (
      hentFraKollegaView === Views.KvitteringView &&
      canAdvanceInKvitteringView
    ) {
      removeToasts([hasImagesToast])
      return setHentFraKollegaView(Views.BekreftView)
    }
    if (hentFraKollegaView === Views.BekreftView) {
      return onHentKvitteringer()
    }

    showErrorToast()
  }

  const onSelectBegrunnelse = (begrunnelse: string) => {
    setSelectedBegrunnelse(begrunnelse)
    removeToasts([noBegrunnelseToast])
  }

  const onSelectKollega = (kollega: IKollega) => {
    setSelectedKollega(kollega)
    removeToasts([noKollegaToast])
  }

  const onSelectKvitteringer = (kvitteringer: IKvitteringToGet[]) => {
    if (selectedKvitteringer.length < kvitteringer.length) {
      removeToasts([noKvitteringToast, hasImagesToast])
    }
    setSelectedKvitteringer(kvitteringer)
  }

  const renderHentFraKollegaViews = () => {
    switch (hentFraKollegaView) {
      case Views.BegrunnelserView:
        return (
          <HentFraKollegaBegrunnelserView
            onSelect={onSelectBegrunnelse}
            selectedBegrunnelse={selectedBegrunnelse}
          />
        )
      case Views.KollegerView:
        return (
          <HentFraKollegaKollegerView
            selectedKollega={selectedKollega}
            onSelect={onSelectKollega}
          />
        )
      case Views.KvitteringView:
        return (
          <HentFraKollegaKvitteringView
            kollega={selectedKollega ?? { name: '', username: '' }}
            onSelect={onSelectKvitteringer}
            selectedKvitteringer={selectedKvitteringer}
          />
        )
      case Views.BekreftView:
        return (
          <HentFraKollegaBekreftView kollega={selectedKollega?.name ?? ''} />
        )
    }
  }

  const renderKvitteringerNames = () => {
    const names = selectedKvitteringer.map(
      (kvittering, index) =>
        `"${kvittering.name}"${
          index < selectedKvitteringer.length - 1 ? ',' : ''
        }`
    )
    return names.join(' ')
  }

  const renderModalTitle = () => {
    switch (hentFraKollegaView) {
      case Views.BegrunnelserView:
        return 'Hvorfor henter du tilsynskvittering fra kollega?'
      case Views.KollegerView:
        return 'Velg kollega du skal hente Tilsynskvittering fra.'
      case Views.KvitteringView:
        return `Velg hvilke tilsynskvitteringer du skal hente fra ${selectedKollega?.name}.`
      case Views.BekreftView:
        return `Er du sikker på at du vil hente valgte tilsynskvittering/er ${renderKvitteringerNames()} fra ${selectedKollega?.name}?`
    }
  }

  return (
    <ModalWrapper
      title={renderModalTitle()}
      onCancel={onCancel}
      isOpen={isOpen}
      paddingHorizontal={0}
      fullscreenMobile
      className="hentFraKollegaModal"
    >
      <Column padding={contentPadding} spacing={2}>
        {renderHentFraKollegaViews()}
      </Column>
      <ModalButtonsContainer>
        <ModalButton secondary onClick={onPrevious}>
          {hentFraKollegaView === Views.BegrunnelserView ? 'Avbryt' : 'Tilbake'}
        </ModalButton>
        <ModalButton onClick={onNext}>
          {hentFraKollegaView === Views.BekreftView ? 'Ja' : 'Gå videre'}
        </ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>
  )
}
