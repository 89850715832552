import { mergeKeyWith } from '../../../common/query'

const getVeiledningerKeys = () => {
  const keys = {
    all: [{ entity: 'veiledninger' }] as const,

    /** Used as defaultMutation key or filter key */
    addBase: () => mergeKeyWith(keys.all, { action: 'add' }),
    /** Used as useMutation key */
    add: (kvitteringId: string) =>
      mergeKeyWith(keys.addBase(), { kvitteringId }),

    /** Used as defaultMutation key or filter key */
    updateBeskrivelseBase: () =>
      mergeKeyWith(keys.all, { action: 'update-beskrivelse' }),
    /** Used as useMutation key */
    updateBeskrivelse: (kvitteringId: string) =>
      mergeKeyWith(keys.updateBeskrivelseBase(), { kvitteringId }),

    /** Used as defaultMutation key or filter key */
    removeBase: () => mergeKeyWith(keys.all, { action: 'remove' }),
    /** Used as useMutation key */
    remove: (kvitteringId: string) =>
      mergeKeyWith(keys.removeBase(), { kvitteringId }),
  } as const

  return keys
}

const veiledningerKeys = getVeiledningerKeys()

export type VeiledningerKeys = typeof veiledningerKeys

export { veiledningerKeys }
