import { commonRedux } from '@mattilsynet/mt-common'
import { hentKvitteringBegrunnelserActionTypes } from './actions'
import {
  IHentKvitteringBegrunnelserActions,
  IHentKvitteringBegrunnelserState,
} from './types'

const initialState: IHentKvitteringBegrunnelserState = {
  overtagelseBegrunnelseList: [],
  error: null,
  loaded: false,
  loading: false,
}

export const hentKvitteringBegrunnelserReducer = commonRedux.createReducer<
  IHentKvitteringBegrunnelserState,
  IHentKvitteringBegrunnelserActions
>(initialState, {
  [hentKvitteringBegrunnelserActionTypes.FETCH_KVITTERING_BEGRUNNELSER]: (
    state: IHentKvitteringBegrunnelserState
  ) => ({
    ...state,
    loading: true,
  }),
  [hentKvitteringBegrunnelserActionTypes.FETCH_KVITTERING_BEGRUNNELSER_SUCCESS]:
    (
      state: IHentKvitteringBegrunnelserState,
      {
        begrunnelser,
      }: {
        begrunnelser: IHentKvitteringBegrunnelserState['overtagelseBegrunnelseList']
      }
    ) => ({
      ...state,
      overtagelseBegrunnelseList: begrunnelser,
      loaded: true,
      loading: false,
    }),
  [hentKvitteringBegrunnelserActionTypes.FETCH_KVITTERING_BEGRUNNELSER_FAIL]: (
    state: IHentKvitteringBegrunnelserState,
    { error }: { error: IHentKvitteringBegrunnelserState['error'] }
  ) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  }),
})
