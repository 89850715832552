import { offlineLexicon } from '../../offline-lexicon'
import { DependencyNotPersisted, mergeKeyWith } from '../../../common/query'

const getObservasjonKeys = () => {
  const keys = {
    all: [{ entity: 'observasjoner' }] as const,

    /** Used as defaultMutation key or filter key */
    addBase: () => mergeKeyWith(keys.all, { action: 'add' }),
    /** Used as useMutation key */
    add: (kvitteringId: string) =>
      mergeKeyWith(keys.addBase(), { kvitteringId }),

    /** Used as defaultMutation key or filter key */
    updateBase: () => mergeKeyWith(keys.all, { action: 'update' }),
    /** Used as useMutation key */
    update: (kvitteringId: string) =>
      mergeKeyWith(keys.updateBase(), { kvitteringId }),

    /** Used as defaultMutation key or filter key */
    removeBase: () => mergeKeyWith(keys.all, { action: 'remove' }),
    /** Used as useMutation key */
    remove: (kvitteringId: string) =>
      mergeKeyWith(keys.removeBase(), { kvitteringId }),
  }

  return keys
}

const observasjonKeys = getObservasjonKeys()

export type ObservasjonKeys = typeof observasjonKeys

export { getPersistedObservasjonId, observasjonKeys }

const getPersistedObservasjonId = async (
  kvitteringId: string,
  observasjonId: number
) => {
  const persistedObservasjon = offlineLexicon.get(
    offlineLexicon.types.observasjon,
    observasjonId + '',
    kvitteringId
  )

  if (!persistedObservasjon) {
    throw new DependencyNotPersisted()
  }

  return persistedObservasjon.id
}
