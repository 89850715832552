import { AxiosResponse } from 'axios'
import { Prettify } from '../../common/types'
import { MutationOptions } from '@tanstack/react-query'
import { Immutable } from 'immer'

/** Bilde file mutation variables. Used in default mutations and api. */
export type BildeMutationVars = Prettify<{
  kvitteringId: string
  observasjonId: number
  bildeId: string
  /** Only on upload */
  bildeMetadata?: IImage
  /** Only on upload */
  bilde?: File
}>

/** Bilde file upload to kvittering variables. Used in default mutations and api. */
export type BildeKvitteringUploadVars = Prettify<{
  kvitteringId: string
  bildeId: string
  /** Only on upload */
  bildeMetadata?: IImage
  /** Only on upload */
  bilde?: File
}>

/** Bilde metadata mutation variables. Used in default mutations and api. */
export type BildeUpdateVars = Prettify<{
  kvitteringId: string
  bildeMetadata: IImage
}>

/** Bilde upload variables. Used in mutation.mutate() (hook). */
export type BildeObservasjonVars = Prettify<{
  bildeId: string
  bilde: File
}>

/** Bilde upload variables. Used in mutation.mutate() (hook). */
export type BildeKvitteringVars = Prettify<
  Pick<BildeObservasjonVars, 'bildeId' | 'bilde'>
>

export type MetadataVars = Pick<BildeUpdateVars, 'bildeMetadata'>

/** Bilde mutation options used in default mutations. */
export type BildeMutationOptions<TData = null> = MutationOptions<
  AxiosResponse<TData>,
  string | Error,
  BildeMutationVars
>

/** Bilde mutation options used in default mutations. */
export type BildeAttachMutationOptions<TData = null> = MutationOptions<
  AxiosResponse<TData>,
  string | Error,
  BildeMutationVars,
  {
    previousObservasjonBilder: Immutable<string[]>
    previousKvitteringBilder: Immutable<string[]>
  }
>

/** Bilde mutation options used in default mutations. */
export type BildeAttachKvitteringMutationOptions<TData = null> =
  MutationOptions<
    AxiosResponse<TData>,
    string | Error,
    BildeKvitteringUploadVars,
    {
      previousKvitteringBilder: Immutable<string[]>
    }
  >

/** Bilde upload to kvittering options used in default mutations. */
export type BildeKvitteringUploadOptions<TData = null> = MutationOptions<
  AxiosResponse<TData>,
  string | Error,
  BildeKvitteringUploadVars,
  {
    previousBilder: Immutable<string[]>
    previousMetadataList: Immutable<IImage[]>
  }
>

/** Bilde metadata mutation options used in default mutations. */
export type BildeMetadataMutationOptions<TData = null> = MutationOptions<
  AxiosResponse<TData>,
  string | Error,
  BildeUpdateVars,
  { previousMetadataList: Immutable<IImage[]> }
>

export type BildeMetadataTuple = Prettify<[metadata: IImage, bilde: File]>

export interface BildeGroupedItem {
  id: string
  observasjonId?: number
}

export const BILDE_SIZE_VALUES = {
  original: 2,
  small: 1,
  tiny: 0,
  local: -1,
} as const

export type BildeSizes = keyof typeof BILDE_SIZE_VALUES

export enum BildeUploadStatus {
  UPLOADED = 'UPLOADED',
  UPLOADING = 'UPLOADING',
  ERROR = 'ERROR',
  WAITING = 'WAITING',
}

///
///// LEGACY
/// Can be looked through and optimized. Some of these are not used.
///
import { IError } from '../../validators/image-errors'

type IImagesContexts = 'ingen kontekst'

interface IBildeKatoegorierWithContext extends IBildeRemote.BildeKategori {
  kontekst?: IImagesContexts
}

export interface IImageMetaData
  extends Omit<IBildeRemote.EntityModelImageMetaDataKategorisert_, '_links'> {
  // extends image bildekategorier with specific contexts
  bildeKategorier?: IBildeKatoegorierWithContext[]
  _links?: {
    original?: { href: 'string' }
    small?: { href: 'string' }
    tiny?: { href: 'string' }
    self?: { href: 'string' }
    imageMetaData?: { href: 'string' }
  }
}

export interface IImage extends IImageMetaData {
  id: string
  metadataUploaded?: boolean
  errors?: any[]
  imageObject?: {
    url: string
    size: IImageSizes | 'local'
  }
  image?: File
  rotate?: number
  metadataUploadStatus?: 'UPLOADED' | 'UPLOADING' | 'WAITING' | 'ERROR'
  imageUploadStatus?: 'UPLOADED' | 'UPLOADING' | 'WAITING' | 'ERROR'
  locationUploaded?: boolean
  imageErrors?: Record<ImageErrorTypes, IError[]>
  // todo below is only on put image
  'kategori.app'?: string
  'kategori.kontekst'?: string | number
  'kategori.type'?: string

  /** Used for data that only exists locally, and not on the server. */
  localData?: {
    isOffline?: boolean
  }
}

export type IImageSizes = 'original' | 'small' | 'tiny'

export type ImageErrorTypes = 'description' | 'locationDescription'
