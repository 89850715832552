import { IImageSizes } from './features/bilder/types'
import dayjs from 'dayjs'

export enum ENDPOINT {
  TILSYNSKVITTERING = '/api/tilsynskvittering-api/v1',
  BILDE = '/api/bilde-api',
  ARKIV = '/api/arkiv-api',
}

export const DEBOUNCE_MS_OLD = 2000
export const DEBOUNCE_MS = 500

// Images
export const MAX_IMAGE_SIZE_IN_MB = 18
export const IMAGE_LOCATION_ACCURACY_CUTOFF = 500
export const ALLOWED_IMAGE_FILE_TYPES = ['image/gif', 'image/jpeg', 'image/png']
export const IMAGE_DESCRIPTION_MAX_LENGTH = 100
export const IMAGE_LOCATION_MAX_LENGTH = 255
export const CAPTURE_TIME_FROM = dayjs()
  .subtract(1, 'week')
  .format('YYYY-MM-DD')
export const CAPTURE_TIME_ERROR = 'CAPTURE_TIME_ERROR'
export const IMAGE_TYPE_ERROR = `støttes ikke, filtypen må være en av ${ALLOWED_IMAGE_FILE_TYPES.join(
  ', '
)}`
export const IMAGE_SIZE_ERROR = `Bildefilen er for stor, maksimal størrelse er ${MAX_IMAGE_SIZE_IN_MB}MB.`

export const IMAGE_SIZE_VALUES: Record<IImageSizes | 'local', number> = {
  original: 2,
  small: 1,
  tiny: 0,
  local: -1,
}
