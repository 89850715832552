import {
  IKvitteringData,
  kvitteringStatusEnum,
} from '../ducks/kvittering/types'

import { IObservasjon } from '../features/observasjoner/types'

export const hasImages = (kvittering: IKvitteringData) => {
  return (
    hasKvitteringImages(kvittering) ||
    kvittering.tilsynsobjekter.filter((tilsynsobjekt) =>
      tilsynsobjekt.kontrollpunkter.some((kontrollpunkt) =>
        hasObservasjonerImages(kontrollpunkt.observasjoner)
      )
    ).length > 0
  )
}

export const hasKvitteringImages = (kvittering: IKvitteringData) => {
  return kvittering.bildeIds?.length > 0
}

export const hasObservasjonerImages = (observasjoner: IObservasjon[]) => {
  return observasjoner.some(hasObservasjonImages)
}

export const hasObservasjonImages = (observasjon: IObservasjon) => {
  return observasjon.bildeIds?.length > 0
}

/**
 * A function to check if a kvittering is in an archived state.
 *
 * @param status string
 * @returns boolean
 */
export const isArkivertStatus = (status: string | undefined) => {
  return (
    status === kvitteringStatusEnum.ARKIVERT ||
    status === kvitteringStatusEnum.ARKIVERING_PAAGAAR
  )
}

/**
 * A function to check if a kvittering is in a finished state.
 * @param status string
 * @returns boolean
 * */
export const isFerdigstiltStatus = (status: string | undefined) => {
  return (
    status === kvitteringStatusEnum.FERDIGSTILT ||
    status === kvitteringStatusEnum.FERDIGSTILLING_PAAGAAR
  )
}

/**
 * A function to check if a kvittering is in a failed state.
 * @param status string
 * @returns boolean
 * */
export const isFeiletStatus = (status: string | undefined) => {
  return (
    status === kvitteringStatusEnum.ARKIVERING_PDF_FEILET ||
    status === kvitteringStatusEnum.ARKIVERING_VEDLEGG_FEILET ||
    status === kvitteringStatusEnum.FERDIGSTILLING_PDF_FEILET ||
    status === kvitteringStatusEnum.FERDIGSTILLING_VEDLEGG_FEILET
  )
}
