import { colors } from '@mattilsynet/mt-ui'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'

const testTheme: IThemeColors = {
  ...colors.seedTheme,
  themeName: 'test',
  primary: '#FF7300',
  secondary: '#FFB300',
}

export const themePicker = (env: string) => {
  switch (env) {
    case 'test':
      return testTheme
    default:
      return colors.seedTheme
  }
}
