import { AtLeastOne } from '../../../common/types'
import { BildeMutationVars, IImage } from '../types'
import { AxiosResponse } from 'axios'
import { getPersistedObservasjonId } from '../../observasjoner/queries/helpers'
import { DependencyNotPersisted, mergeKeyWith } from '../../../common/query'
import { offlineLexicon } from '../../offline-lexicon'
import { queryClient } from '../../../api/query-client'
import { Draft, produce } from 'immer'
import { MutationKey } from '@tanstack/react-query'
import { isImageValid } from '../../../validators/text-input-validators'

const getBildeKeys = () => {
  const keys = {
    all: [{ entity: 'bilder' }] as const,
    kvitteringId: (kvitteringId: string) =>
      mergeKeyWith(keys.all, { kvitteringId }),
    bildeFile: (
      kvitteringId: string,
      preferredImageSize: string,
      bildeMetadata?: IImage
    ) =>
      mergeKeyWith(keys.kvitteringId(kvitteringId), {
        preferredImageSize,
        bildeMetadata,
      }),
    metadataList: (kvitteringId: string) =>
      mergeKeyWith(keys.kvitteringId(kvitteringId), {
        type: 'metadataList',
      }),

    /** Used as filter key */
    uploadBase: () => mergeKeyWith(keys.all, { action: 'upload' }),
    /** Used as defaultMutation key or filter key */
    uploadToObservasjonBase: () =>
      mergeKeyWith(keys.uploadBase(), { destination: 'observasjon' }),
    /** Used as useMutation key */
    uploadToObservasjon: (kvitteringId: string, observasjonId: number) =>
      mergeKeyWith(keys.uploadToObservasjonBase(), {
        kvitteringId,
        observasjonId,
      }),

    /** Used as defaultMutation key or filter key */
    uploadToKvitteringBase: () =>
      mergeKeyWith(keys.uploadBase(), { destination: 'kvittering' }),
    /** Used as useMutation key */
    uploadToKvittering: (kvitteringId: string) =>
      mergeKeyWith(keys.uploadToKvitteringBase(), {
        kvitteringId,
      }),

    /** Used as defaultMutation key or filter key */
    attachToObservasjonBase: () =>
      mergeKeyWith(keys.all, { action: 'attach-to-observasjon' }),
    /** Used as useMutation key */
    attachToObservasjon: (kvitteringId: string) =>
      mergeKeyWith(keys.attachToObservasjonBase(), {
        kvitteringId,
      }),

    /** Used as defaultMutation key or filter key */
    attachToKvitteringBase: () =>
      mergeKeyWith(keys.all, { action: 'attach-to-kvittering' }),
    /** Used as useMutation key */
    attachToKvittering: (kvitteringId: string) =>
      mergeKeyWith(keys.attachToKvitteringBase(), {
        kvitteringId,
      }),

    /** Used as defaultMutation key or filter key */
    updateBeskrivelseBase: () =>
      mergeKeyWith(keys.all, { action: 'update-beskrivelse' }),
    /** Used as useMutation key */
    updateBeskrivelse: (kvitteringId: string) =>
      mergeKeyWith(keys.updateBeskrivelseBase(), { kvitteringId }),
    /** Used as defaultMutation key or filter key */

    updateLokasjonBase: () =>
      mergeKeyWith(keys.all, { action: 'update-lokasjon' }),
    /** Used as useMutation key */
    updateLokasjon: (kvitteringId: string) =>
      mergeKeyWith(keys.updateLokasjonBase(), { kvitteringId }),

    /** Used as defaultMutation key or filter key */
    removeFromObservasjonBase: () =>
      mergeKeyWith(keys.all, { action: 'remove-from-observasjon' }),
    /** Used as useMutation key */
    removeFromObservasjon: (kvitteringId: string) =>
      mergeKeyWith(keys.removeFromObservasjonBase(), {
        kvitteringId,
      }),

    // TODO Kan slettes når vi er sikre på at det ikke kommer flere mutasjoner med denne keyen
    /** Used as defaultMutation key or filter key */
    deleteFromObservasjonBase: () =>
      mergeKeyWith(keys.all, { action: 'delete-from-observasjon' }),
    // TODO Kan slettes når vi er sikre på at det ikke kommer flere mutasjoner med denne keyen
    /** Used as useMutation key */
    deleteFromObservasjon: (kvitteringId: string) =>
      mergeKeyWith(keys.deleteFromObservasjonBase(), {
        kvitteringId,
      }),

    /** Used as defaultMutation key or filter key */
    deleteFromKvitteringBase: () =>
      mergeKeyWith(keys.all, { action: 'delete-from-kvittering' }),
    /** Used as useMutation key */
    deleteFromKvittering: (kvitteringId: string) =>
      mergeKeyWith(keys.deleteFromKvitteringBase(), {
        kvitteringId,
      }),
  } as const

  return keys
}

const bildeKeys = getBildeKeys()

export type BildeKeys = typeof bildeKeys

export {
  bildeKeys,
  withPersistedObservasjonId,
  withPersistedBilde,
  metadataQueryHelpers,
  isBildeUploadKey,
  isBilderValid,
  getMetadataWithGeodata,
  getMetadataListWithoutLocation,
}

/** Finds and merges persisted observasjonId to the variables. */
const withPersistedObservasjonId =
  (request: (variables: BildeMutationVars) => Promise<AxiosResponse>) =>
  async (variables: BildeMutationVars) => {
    const { kvitteringId, observasjonId } = variables

    let persistedObservasjonId: number | undefined = observasjonId

    const isOfflineObservasjon = observasjonId < 0

    if (isOfflineObservasjon) {
      persistedObservasjonId = await getPersistedObservasjonId(
        kvitteringId,
        observasjonId
      )
    }

    if (!persistedObservasjonId) {
      throw new DependencyNotPersisted()
    }

    return request({ ...variables, observasjonId: persistedObservasjonId })
  }

const withPersistedBilde =
  <
    T extends {
      kvitteringId: string
    } & AtLeastOne<{ bildeId: string; bildeMetadata: IImage }>,
  >(
    request: (variables: T) => Promise<AxiosResponse>
  ) =>
  (variables: T) => {
    const { kvitteringId, bildeId, bildeMetadata } = variables
    const bildeIdVal = bildeMetadata?.id ?? bildeId!

    const persistedMetadata = metadataQueryHelpers
      .getMetadataList(kvitteringId)
      ?.find(
        (bilde) =>
          bilde.id === bildeIdVal && bilde.localData?.isOffline === undefined
      )

    let persistedBilde: IImage | undefined
    if (!persistedMetadata) {
      persistedBilde = offlineLexicon.get(
        offlineLexicon.types.bilde,
        bildeIdVal,
        kvitteringId
      )
    }

    if (!persistedBilde && !persistedMetadata) {
      throw new DependencyNotPersisted()
    }

    return request(variables)
  }

const metadataQueryHelpers = {
  getMetadataList: (kvitteringId: string) =>
    queryClient.getQueryData<IImage[]>(bildeKeys.metadataList(kvitteringId)),
  updateMetadataList: (
    kvitteringId: string,
    updateMetadata: (draftMetadataList: Draft<IImage[]> | undefined) => void
  ) => {
    queryClient.setQueryData<IImage[]>(
      bildeKeys.metadataList(kvitteringId),
      (metadataList) => produce(metadataList, (draft) => updateMetadata(draft))
    )
  },
  invalidateMetadataList: (kvitteringId: string) =>
    queryClient.invalidateQueries({
      queryKey: bildeKeys.metadataList(kvitteringId),
    }),
}

const isBildeUploadKey = (mutationKey: MutationKey) => {
  const uploadKey = bildeKeys.uploadBase()[0]
  const key = mutationKey[0] as Record<string, unknown> | undefined

  if (!key) {
    return false
  }

  return key.entity === uploadKey.entity && key.action === uploadKey.action
}

const isBilderValid = (kvitteringId: string) => {
  const metadatas = metadataQueryHelpers.getMetadataList(kvitteringId) ?? []

  return !metadatas.some((metadata) => !isImageValid(metadata))
}

const getMetadataWithGeodata = (kvitteringId: string) => {
  const metadatas = metadataQueryHelpers.getMetadataList(kvitteringId)
  const locationMetadata = metadatas
    ?.filter(
      (metadata) => metadata.locationLatitude && metadata.locationLongitude
    )
    ?.sort(
      (a, b) =>
        new Date(b.captureTime || 0).getTime() -
        new Date(a.captureTime || 0).getTime()
    )
    ?.pop()

  return locationMetadata
}

const getMetadataListWithoutLocation = (kvitteringId: string) => {
  const metadatas = metadataQueryHelpers.getMetadataList(kvitteringId)
  const filteredMetadatas = metadatas?.filter(
    (metadata) =>
      !metadata.locationLatitude &&
      !metadata.locationLongitude &&
      !metadata.locationDescription
  )

  return filteredMetadatas
}
