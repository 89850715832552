import React, { ReactNode } from 'react'
import { SliderDotItem } from '../slider-dot-item'
import { Row, RowItem, Text } from '@mattilsynet/mt-ui'
import { NavigatorSelectionBorder } from '../navigator-selection-border'
import { useMediaQuery } from 'react-responsive'

interface ISliderDotsProps {
  slides: ReactNode[]
  currentSlide: number
  goToSlide: (position: number) => void
}

export const SliderDots = ({
  slides,
  currentSlide,
  goToSlide,
}: ISliderDotsProps) => {
  const getSlideName = (slide: any, index: number) =>
    slide?.['key'] || `Side ${index + 1}`

  const smallDevice = useMediaQuery({ maxWidth: '1152px' })

  return (
    <Row
      justify="space-between"
      align="center"
      spacing={smallDevice ? 1 : 2}
      padding={[1, 0, 1, 0]}
    >
      {slides.map((slide, i) => {
        const isActive = currentSlide === i
        const slideNumber = i + 1
        const slideName = getSlideName(slide, i)
        const navigate = () => {
          goToSlide(i)
        }

        if (smallDevice) {
          return (
            <SliderDotItem
              key={i}
              active={isActive}
              goToSlide={navigate}
              title={slideName}
              number={slideNumber}
            />
          )
        }

        return (
          <NavigatorSelectionBorder key={i} active={isActive} goTo={navigate}>
            <RowItem display="flex">
              <Text size="medium" weight="semibold">
                {slideNumber}.
              </Text>
              <Text size="medium" weight="semibold" margin={[0, 0, 0, 1]}>
                {slideName}
              </Text>
            </RowItem>
          </NavigatorSelectionBorder>
        )
      })}
    </Row>
  )
}
