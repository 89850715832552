import React from 'react'
import { ArrowIcon, Row, RowItem, Text } from '@mattilsynet/mt-ui'

const buttonStyle = {
  width: '100%',
  cursor: 'pointer',
}

export const UpdateNotification = () => {
  const onClickUpdate = () => window.location.reload()

  return (
    <button style={buttonStyle} onClick={onClickUpdate}>
      <Row
        backgroundColor="var(--mt-sommerdag)"
        align="center"
        justify="center"
        spacing={2}
        padding={2}
      >
        <RowItem>
          <Text>Trykk for å oppdatere til siste versjon</Text>
        </RowItem>
        <RowItem display="flex">
          <ArrowIcon size="small" rotateDirection="DOWN" />
        </RowItem>
      </Row>
    </button>
  )
}
