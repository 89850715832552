import React, { useCallback, useEffect, useState } from 'react'
import {
  Accordion,
  LoadingDots,
  Header,
  Column,
  TextLink,
  Text,
} from '@mattilsynet/mt-ui'
import { useDispatch } from 'react-redux'
import { VirksomhetAdresseInfo } from '../virksomhet-adresse-info'
import { PrivatPersonAdresseInfo } from '../privat-person-adresse-info'
import { IKvitteringData } from '../../ducks/kvittering/types'
import { IVirksomhetItem } from '../../ducks/virksomhet-search/types'
import './style.css'

interface IAdresseAccordionProps {
  orgNr?: string
  fetchAddress: any
  item: IKvitteringData | IVirksomhetItem
  hasError: boolean
  isDefaultOpen?: boolean
}

export const AdresseAccordion = ({
  orgNr,
  fetchAddress,
  item: { adresse, beliggenhetsadresse, postadresse, overordnetOrgNr },
  hasError,
  isDefaultOpen = false,
}: IAdresseAccordionProps) => {
  const dispatch = useDispatch()
  const [showAdress, setShowAdress] = useState(isDefaultOpen)

  const onToggleAdresseDropdown = useCallback(() => {
    setShowAdress(!showAdress)

    const hasAddress = orgNr ? beliggenhetsadresse : adresse
    if (!showAdress && !hasAddress) {
      dispatch(fetchAddress)
    }
  }, [adresse, beliggenhetsadresse, dispatch, fetchAddress, orgNr, showAdress])

  useEffect(() => {
    if (isDefaultOpen) {
      dispatch(fetchAddress)
    }
  }, [])

  const renderOverordnetOrgNr = (orgNr: string | undefined) => {
    const hasOrgNr = orgNr !== undefined

    return (
      <Column spacing={1}>
        <Header as="h3" size="normal">
          Overordnet org nr
        </Header>
        {!hasOrgNr && <Text>Intet registrert overordnet org nr</Text>}
        {hasOrgNr && (
          <>
            <Column>
              <Text textAlign="left">{orgNr}</Text>
            </Column>
            <Column>
              <TextLink
                href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${orgNr}`}
                linkOutside
              >
                Vis overordnet enhet
              </TextLink>
            </Column>
          </>
        )}
        &nbsp;
      </Column>
    )
  }

  const renderAdresse = () => {
    const hasAdresse = !(
      (beliggenhetsadresse && Object.keys(beliggenhetsadresse).length === 0) ||
      (adresse && Object.keys(adresse).length === 0)
    )
    if (!hasAdresse) {
      return <Text>Ingen registrert adresse</Text>
    }

    if (!orgNr) {
      return <PrivatPersonAdresseInfo tittel="Adresse" adresse={adresse} />
    }

    return (
      <div className="virksomhet-adresse-info">
        <VirksomhetAdresseInfo
          adresse={beliggenhetsadresse}
          tittel="Beliggenhetsadresse"
        />
        <VirksomhetAdresseInfo adresse={postadresse} tittel="Postadresse" />
      </div>
    )
  }

  const renderAdresseError = () => {
    return (
      <>
        <Text margin={[0, 0, 1, 0]}>
          Kunne ikke hente adresse og overordnet org nr
        </Text>
        <TextLink onClick={() => dispatch(fetchAddress)}>
          Last innhold på nytt
        </TextLink>
      </>
    )
  }

  let toggleText = 'Vis adresse'
  if (showAdress) {
    toggleText = 'Skjul adresse'
  }
  if (orgNr) {
    toggleText += ' og overordnet org nr'
  }

  const hasFetched = Boolean(beliggenhetsadresse || adresse)

  return (
    <Accordion
      className="adresse-accordion"
      open={showAdress}
      paddingChildren={[0, 0, 0, 0]}
      header={
        <Header as="h3" size="heading3">
          {toggleText}
        </Header>
      }
      onClick={onToggleAdresseDropdown}
    >
      <Column justify="flex-start" align="flex-start">
        {hasError && renderAdresseError()}
        {!hasError && !hasFetched && <LoadingDots />}

        {!hasError && hasFetched && renderAdresse()}
      </Column>
      &nbsp;
      {orgNr &&
        !hasError &&
        hasFetched &&
        renderOverordnetOrgNr(overordnetOrgNr)}
    </Accordion>
  )
}
