import { SEVERITY_DANGER, SEVERITY_WARNING } from './common'

export const imageErrors = {
  NO_DESCRIPTION: 'IMAGE_NO_DESCRIPTION' as const,
  DESCRIPTION_TO_LONG: 'IMAGE_DESCRIPTION_TOO_LONG' as const,
  INVALID_CHARACTERS: 'INVALID_CHARACTERS' as const,
}

export interface IError {
  errorType: string
  severity: typeof SEVERITY_DANGER | typeof SEVERITY_WARNING
  message: string
}

export const noDescriptionError = (): IError => ({
  errorType: imageErrors.NO_DESCRIPTION,
  severity: SEVERITY_DANGER,
  message: 'Feltet kan ikke være tomt',
})

export const descriptionToLongError = () => ({
  errorType: imageErrors.DESCRIPTION_TO_LONG,
  severity: SEVERITY_DANGER,
  message: 'Innholdet er for langt',
})

export const invalidCharactersError = () => ({
  errorType: imageErrors.INVALID_CHARACTERS,
  severity: SEVERITY_DANGER,
  message: 'Inneholder ulovlige tegn',
})

export const getErrorInfo = (
  errors: IError[]
): [danger: boolean, warning: boolean, message: string | undefined] => {
  if (errors.length > 0) {
    const { severity, message } = errors[0]
    switch (severity) {
      case 'danger':
        return [true, false, message]
      case 'warning':
        return [false, true, message]
      default:
        break
    }
  }

  return [false, false, undefined]
}
