// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-navigation .thumbnail-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(4.5rem, 1fr));
  grid-gap: 1rem;
  justify-content: start;
}

.image-navigation .ExpandArrow {
  min-width: 2.5rem;
  min-height: 2.5rem;
  max-width: 3rem;
  max-height: 3rem;
  cursor: pointer;
  position: relative;
  bottom: 8em;
}

.image-navigation .thumbnail-selected {
  border: 4px solid #4597f7;
}

.image-navigation .image-container {
  position: relative;
  padding-bottom: 1.1em;
}

.image-navigation .image-container .image-view-display {
  max-width: 100%;
  max-height: 80vh;
  width: auto;
  height: auto;
}

.image-navigation .image-fullscreen-link {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 2.5em;
}

.image-navigation .image-wrapper {
  border: 1px solid #d7d7d7;
}

.image-navigation .image-modal {
  align-self: center;
}

.image-navigation .Textarea {
  width: 100%;
}

.image-navigation .image-modal-container {
  display: grid;
  justify-content: center;
}

.image-navigation:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/features/bilder/components/image-navigation/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6DAA6D;EAC7D,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,gCAAgC;EAChC,iBAAiB;EACjB,0CAA0C;EAC1C,WAAW;EACX,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".image-navigation .thumbnail-list {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(4.5rem, 1fr));\n  grid-gap: 1rem;\n  justify-content: start;\n}\n\n.image-navigation .ExpandArrow {\n  min-width: 2.5rem;\n  min-height: 2.5rem;\n  max-width: 3rem;\n  max-height: 3rem;\n  cursor: pointer;\n  position: relative;\n  bottom: 8em;\n}\n\n.image-navigation .thumbnail-selected {\n  border: 4px solid #4597f7;\n}\n\n.image-navigation .image-container {\n  position: relative;\n  padding-bottom: 1.1em;\n}\n\n.image-navigation .image-container .image-view-display {\n  max-width: 100%;\n  max-height: 80vh;\n  width: auto;\n  height: auto;\n}\n\n.image-navigation .image-fullscreen-link {\n  position: absolute;\n  bottom: 0;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  font-weight: bold;\n  background-color: rgba(255, 255, 255, 0.6);\n  width: 100%;\n  height: 2.5em;\n}\n\n.image-navigation .image-wrapper {\n  border: 1px solid #d7d7d7;\n}\n\n.image-navigation .image-modal {\n  align-self: center;\n}\n\n.image-navigation .Textarea {\n  width: 100%;\n}\n\n.image-navigation .image-modal-container {\n  display: grid;\n  justify-content: center;\n}\n\n.image-navigation:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
