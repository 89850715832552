import { ofType, combineEpics, StateObservable } from 'redux-observable'
import { AnyAction } from 'redux'
import {
  mergeMap,
  catchError,
  withLatestFrom,
  switchMap,
  map,
} from 'rxjs/operators'
import { of, Observable, concat, EMPTY, merge, throwError } from 'rxjs'
import { CREATE_SAK, GET_SAK } from './types'
import { ICommonApi } from '../../api'
import { IStoreState } from '../../reducers/types'
import { kvitteringActions } from '../kvittering/actions'
import {
  getSakSuccess,
  getSakNotFound,
  getSakFailed,
  createSakSuccess,
  createSakFailed,
} from './actions'
import { kvitteringStatusEnum } from '../kvittering/types'

const ROOT_URL = '/api/arkiv-api/arkiv/saker'

const updateKvitteringAction$ = (
  shouldUpdateKvittering: boolean,
  isKvitteringCreated: boolean,
  payload: any
) => {
  if (!shouldUpdateKvittering) return EMPTY
  if (isKvitteringCreated)
    return of(kvitteringActions.updateCurrentKvittering(payload))
  return of(kvitteringActions.addKvittering(payload, 'sak'))
}

export const getSakEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(GET_SAK),
      withLatestFrom(state$),
      mergeMap(([action, state]) => {
        const { sakNumber, shouldUpdateKvittering } = action
        if (!sakNumber) return EMPTY
        return of(state).pipe(
          commonApi.get(`${ROOT_URL}/${sakNumber}`, state),
          mergeMap((sak) => {
            const isKvitteringCreated =
              !!state.kvittering.selectedKvittering.data.id
            const [noarksakAar = '', noarksakSekvensnummer = ''] =
              sakNumber.split('/')
            const payload = {
              noarksakAar,
              noarksakSekvensnummer,
            }
            return of(state).pipe(
              mergeMap(() =>
                concat(
                  of(
                    getSakSuccess(
                      sak.saksnummer,
                      sak.tittel,
                      sak.lukket,
                      sak.tilgangskode ? sak.tilgangskode.id : '',
                      sak.skjermingshjemmel
                        ? sak.skjermingshjemmel.beskrivelse
                        : ''
                    )
                  ),
                  updateKvitteringAction$(
                    shouldUpdateKvittering,
                    isKvitteringCreated,
                    payload
                  )
                )
              )
            )
          }),
          catchError((err) => {
            if (err.status === 404) {
              return concat(
                of(getSakNotFound(sakNumber)),
                of(
                  kvitteringActions.updateCurrentKvittering({
                    noarksakSekvensnummer: '',
                    noarksakAar: '',
                  })
                )
              )
            } else {
              return concat(
                of(getSakFailed(sakNumber)),
                of(
                  kvitteringActions.updateCurrentKvittering({
                    noarksakSekvensnummer: '',
                    noarksakAar: '',
                  })
                )
              )
            }
          })
        )
      })
    )

/**
 * Listens for redux action [sak/CREATE_SAK]
 * finds images in state with `marked = true`
 *
 * @category epic
 * @param commonApi to communicate with backend
 * @returns
 * - OK: [sak/CREATE_SAK_SUCCESS, current-kvittering/UPDATE_CURRENT_KVITTERING_SUCCESS]
 * - FAIL: [sak/CREATE_SAK_FAILED]
 */
export const createSakEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(CREATE_SAK),
      withLatestFrom(state$),
      switchMap(([{ tittel, isSkjermet }, state]) => {
        const aktivitetsId =
          state.kvittering.selectedKvittering.data.tilsynsobjekter[0].aktivitetsId.replace(
            '$',
            '/'
          )
        const hjemmelForUnntattOffentlighet =
          state.kvittering.selectedKvittering.data.hjemmelForUnntattOffentlighet
        const ordningsverdiUrl = `/api/kodeverk-api/kodeverk/code/related/aktivitet_ordningsverdi/${aktivitetsId}`

        const tilgangskode = isSkjermet ? { id: 'TILGANGSKODE$UO' } : null
        const skjermingshjemmel = isSkjermet
          ? { id: `TILGANGSHJEMMEL$${hjemmelForUnntattOffentlighet}` }
          : null

        return of(state).pipe(
          commonApi.get(ordningsverdiUrl, state),
          map((response) => {
            const code = response._embedded.relatedCodeList[0]
            return `${code.codeType}$${code.codeString}`
          }),
          catchError((err) => {
            if (err.status === 404) {
              return of('ORDNINGSVERDI$70')
            }
            return throwError(err)
          }),
          mergeMap((ordningsverdi) => {
            return of(state).pipe(
              commonApi.post(ROOT_URL, state, {
                tittel,
                ordningsverdi,
                tilgangskode,
                skjermingshjemmel,
              }),
              mergeMap(({ response }) => {
                return merge(
                  of(
                    createSakSuccess({
                      tittel,
                      sakNumber: response.saksnummer,
                    })
                  ),
                  of(
                    kvitteringActions.updateCurrentKvittering({
                      status: kvitteringStatusEnum.ARKIVERT,
                      noarksakAar: response.saksaar,
                      noarksakSekvensnummer: response.sekvensnummer,
                    })
                  )
                )
              })
            )
          }),
          catchError((err) => {
            return concat(of(createSakFailed(err.message)))
          })
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(getSakEpic(commonApi), createSakEpic(commonApi))
