import { IAnsatt, IAnsatteState } from './types'
import { IStoreState } from '../../reducers/types'
import { createSelector } from 'reselect'

export const STORE_NAME = 'ansatte'

export const ansatteActionTypes = {
  FETCH_ANSATTE: `${STORE_NAME}/FETCH_ANSATTE` as const,
  FETCH_ALL_ANSATTE: `${STORE_NAME}/FETCH_ALL_ANSATTE` as const,
  FETCH_ANSATTE_OK: `${STORE_NAME}/FETCH_ANSATTE_OK` as const,
  FETCH_ANSATTE_FAIL: `${STORE_NAME}/FETCH_ANSATTE_FAIL` as const,

  UPDATE_FORDELT_TIL: `${STORE_NAME}/UPDATE_FORDELT_TIL` as const,
  UPDATE_FORDELT_TIL_OK: `${STORE_NAME}/UPDATE_FORDELT_TIL_OK` as const,
  UPDATE_FORDELT_TIL_FAIL: `${STORE_NAME}/UPDATE_FORDELT_TIL_FAIL` as const,
}

export const ansatteActions = {
  fetchAnsatte: () => ({
    type: ansatteActionTypes.FETCH_ANSATTE,
  }),

  fetchAllAnsatte: () => ({
    type: ansatteActionTypes.FETCH_ALL_ANSATTE,
  }),

  fetchAnsatteOk: (ansatte: IAnsatt[], kontor: IAnsatteState['kontor']) => ({
    type: ansatteActionTypes.FETCH_ANSATTE_OK,
    ansatte,
    kontor,
  }),

  fetchAnsatteFail: (error: string) => ({
    type: ansatteActionTypes.FETCH_ANSATTE_FAIL,
    error,
  }),
}

export const ansatteSelectors = {
  getLoadingStatus: createSelector(
    (state: IStoreState) => state[STORE_NAME],
    ({ loading, loaded, error }) => ({ loading, loaded, error })
  ),

  getAnsatte: (state: IStoreState): IAnsatteState['ansatte'] =>
    state[STORE_NAME].ansatte,

  getKontor: (state: IStoreState): IAnsatteState['kontor'] =>
    state[STORE_NAME].kontor,
}
