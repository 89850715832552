import React, { useCallback, useMemo, useState } from 'react'
import RemoveImageModal from '../../modals/remove-image-modal'
import { DeleteDialogModal } from '../../modals/delete-dialog-modal'
import { useKvitteringId } from '../../common/kvittering-context'
import {
  useRemoveBildeFromObservasjon,
  useDeleteBilde,
} from '../../features/bilder'

export { useImageRemoval, useImageDeletionModal }

const useImageRemoval = (
  observasjonId: number,
  onDeleteConfirm: (imageId: string) => void
) => {
  const kvitteringId = useKvitteringId()

  const [removeImageId, setRemoveImageId] = useState('')
  const { mutate: removeBildeFromObservasjon } =
    useRemoveBildeFromObservasjon(kvitteringId)

  const onRemove = useCallback(
    (bildeId: string, observasjonId: number) => {
      setRemoveImageId('')

      if (observasjonId) {
        removeBildeFromObservasjon({ bildeId, observasjonId })
      }
    },
    [removeBildeFromObservasjon]
  )

  const onCancel = useCallback(() => {
    setRemoveImageId('')
  }, [])

  const ImageRemoveDialog = useMemo(() => {
    const ImageRemoveDialog: React.FC = () => (
      <RemoveImageModal
        entityId={removeImageId}
        observasjonId={observasjonId}
        isOpen={removeImageId !== ''}
        onRemove={onRemove}
        onDelete={onDeleteConfirm}
        onCancel={onCancel}
      />
    )

    return ImageRemoveDialog
  }, [removeImageId, observasjonId, onRemove, onDeleteConfirm, onCancel])

  return {
    openRemoveConfirmation: setRemoveImageId,
    ImageRemoveDialog,
  }
}

const useImageDeletionModal = (kvitteringId: string) => {
  const { mutate: deleteBilde } = useDeleteBilde(kvitteringId)

  const onDelete = useCallback(
    (bildeId: string) => {
      deleteBilde({ bildeId })
    },
    [deleteBilde]
  )

  const { ImageDeleteDialog, openDeleteConfirmation } = useDeletion(onDelete)

  return {
    openDeleteConfirmation,
    ImageDeleteDialog,
  }
}

const useDeletion = (onDelete: (bildeId: string) => void) => {
  const [deleteImageId, setDeleteImageId] = useState('')

  const onDeletion = useCallback(
    (bildeId: string) => {
      setDeleteImageId('')
      onDelete(bildeId)
    },
    [onDelete]
  )

  const onCancel = useCallback(() => {
    setDeleteImageId('')
  }, [])

  const ImageDeleteDialog = useMemo(() => {
    const ImageDeleteDialog: React.FC = () => (
      <DeleteDialogModal
        isOpen={deleteImageId !== ''}
        entityId={deleteImageId}
        entity="bildet"
        onDelete={onDeletion}
        onCancel={onCancel}
      />
    )

    return ImageDeleteDialog
  }, [deleteImageId, onDeletion, onCancel])

  return {
    openDeleteConfirmation: setDeleteImageId,
    ImageDeleteDialog,
  }
}
