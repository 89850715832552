import { queryOptions, useQuery } from '@tanstack/react-query'
import { sakKeys } from './helpers'
import { sakApi } from './sak-api'

export { useSak }

const sakQueryOptions = (sakNumber?: string) =>
  queryOptions({
    queryKey: sakKeys.get(sakNumber ?? ''),
    queryFn: async ({ queryKey: [{ sakNumber }] }) => {
      const { data } = await sakApi.get(sakNumber)

      if (data) {
        const {
          saksnummer: sakNumber,
          tittel: title,
          lukket: sakClosed,
          skjermingshjemmel: sakHjemmel,
          tilgangskode: sakTilgang,
        } = data

        return {
          sakNumber,
          title,
          sakClosed,
          sakHjemmel: sakHjemmel?.beskrivelse ?? '',
          sakTilgang: sakTilgang?.id ?? '',
        }
      }
      return null
    },
    enabled: !!sakNumber,
    retry: false,
  })

const useSak = (sakNumber?: string) => useQuery(sakQueryOptions(sakNumber))
