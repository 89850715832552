import { AxiosError } from 'axios'
import { ISakState } from './types'
import { IKvitteringData } from '../../ducks/kvittering/types'

export { getSakData, getSaksnummer }

const getSakData = (
  sakData: Partial<ISakState> | null | undefined,
  loading,
  isError,
  sakError
): ISakState => {
  const initialState = {
    loading,
    sakNumber: '',
    title: '',
    sakFail: false,
    sakNotFound: false,
    sakClosed: false,
    sakHjemmel: '',
    sakTilgang: '',
    error: '',
  }

  if (isError) {
    const { message, response } = sakError as AxiosError

    return {
      ...initialState,
      error: message ?? '',
      sakNotFound: response?.status ? response?.status === 404 : false,
      sakFail: response?.status ? response?.status !== 404 : false,
    }
  }

  if (sakData) {
    return {
      ...initialState,
      ...sakData,
    }
  }

  return initialState
}

const getSaksnummer = (saknummer: string, kvittering?: IKvitteringData) => {
  if (saknummer) {
    return saknummer
  }

  if (kvittering?.noarksakAar && kvittering.noarksakSekvensnummer) {
    return `${kvittering.noarksakAar}/${kvittering.noarksakSekvensnummer}`
  }

  return ''
}
