// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.begrunnelse-item-text-area {
  position: relative;
  z-index: 1;
}

.begrunnelse-item-text-area .text {
  position: relative;
  top: -0.25em;
}
`, "",{"version":3,"sources":["webpack://./src/modals/begrunnelse-modal/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".begrunnelse-item-text-area {\n  position: relative;\n  z-index: 1;\n}\n\n.begrunnelse-item-text-area .text {\n  position: relative;\n  top: -0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
