// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.camera-button {
  position: fixed;
  z-index: 10;
  bottom: 10%;
  right: 5%;
  scale: 130%;
}

.camera-button button {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .camera-button {
    bottom: 15%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/gallery-page/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,WAAW;EACX,SAAS;EACT,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".camera-button {\n  position: fixed;\n  z-index: 10;\n  bottom: 10%;\n  right: 5%;\n  scale: 130%;\n}\n\n.camera-button button {\n  cursor: pointer;\n}\n\n@media screen and (max-width: 767px) {\n  .camera-button {\n    bottom: 15%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
