import React from 'react'
import { createRoot } from 'react-dom/client'
import App, { updateInstalled } from './app'
import store from './reducers/store'
import { Provider } from 'react-redux'
import './defaultQueries'

const container = document.getElementById('app')
const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(
      (registration) => {
        // eslint-disable-next-line
        console.warn('Service worker registered')
        registration.addEventListener('updatefound', function () {
          updateInstalled()
        })
      },
      (error) => {
        // eslint-disable-next-line
        console.warn('Error from SW', error)
      }
    )
  })
} else {
  // eslint-disable-next-line
  console.warn('Service worker not supported')
}
