import React, { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTypedSelector } from '../../common/custom-hooks'
import {
  TOGGLES,
  fetchToggle,
  togglesValues,
  useLocalToggles,
} from '../../common/unleashToggles'
import { useDispatch } from 'react-redux'

export const ActiveTogglesView = () => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  const toggles = useTypedSelector((state) => state['@unleashToggles'])
  const isOnlyTestersEnabled =
    toggles[TOGGLES.tilsynskvitteringOnlyTesters]?.enabled

  const { state: localTogglesState, dispatch: localToggleDispatch } =
    useLocalToggles()

  const onChecked = useCallback(
    (toggle) => () => {
      localToggleDispatch({
        type: toggle,
        payload: !(localTogglesState[toggle] ?? toggles[toggle]?.enabled),
      })
    },
    [localToggleDispatch, localTogglesState, toggles]
  )

  const onReset = useCallback(
    () => localToggleDispatch({ type: 'reset' }),
    [localToggleDispatch]
  )

  const activeToggles = togglesValues.map((toggle) => {
    const localToggle = localTogglesState[toggle]
    const toggleStatus = toggles[toggle]

    const isInitiated = localToggle !== undefined || Boolean(toggleStatus)
    const isLoading =
      localToggle !== undefined ? false : Boolean(toggleStatus?.loading)
    const isEnabled = localToggle ?? Boolean(toggleStatus?.enabled)

    let backgroundColor: string
    if (!isInitiated) {
      backgroundColor = 'lightgrey'
    } else if (isLoading) {
      backgroundColor = 'lightblue'
    } else if (isEnabled) {
      backgroundColor = 'lightgreen'
    } else {
      backgroundColor = 'lightpink'
    }

    return (
      <div
        key={toggle}
        style={{
          backgroundColor,
          padding: '4px',
          cursor: 'pointer',
        }}
        onClick={onChecked(toggle)}
      >
        <input type="checkbox" checked={isEnabled} readOnly />
        &nbsp;
        {isLoading && '...'}
        &nbsp;
        {toggle}
        {localToggle !== undefined ? '*' : <>&nbsp;</>}
      </div>
    )
  })

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchToggle(TOGGLES.tilsynskvitteringOnlyTesters))
    }, 3000)
  }, [dispatch])

  if (!isOnlyTestersEnabled) {
    return null
  }

  return (
    <>
      <button
        style={{
          display: 'block',
          backgroundColor: 'transparent',
          color: 'white',
          padding: '2px 4px',
          margin: '.5rem 4px',
          border: '2px dashed orange',
        }}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        toggler
      </button>

      {isOpen &&
        createPortal(
          <div
            style={{
              position: 'fixed',
              top: '52px',
              right: '0',
              backgroundColor: 'white',
              border: '4px solid orange',
              padding: '1rem',
              boxShadow: '0px 0px 10px grey',
            }}
          >
            <h3 style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
              Aktive toggler:
            </h3>

            <button
              style={{ display: 'block', cursor: 'pointer' }}
              onClick={onReset}
            >
              Reset to unleash toggles
            </button>

            {activeToggles}

            <div style={{ fontStyle: 'italic', margin: '1rem 0' }}>
              Dette er bare for testing!
            </div>

            <button
              style={{
                display: 'block',
                padding: '.5rem',
                marginTop: '1rem',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={() => setIsOpen(false)}
            >
              Lukk
            </button>
          </div>,
          document.body
        )}
    </>
  )
}
