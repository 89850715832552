import { queryApi } from '../../api'
import { ENDPOINT } from '../../constants'

export const avdelingstilhorighetApi = {
  get: (kvitteringId: string) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvitteringId}/avdelingstilhorighet`

    return queryApi.get<
      'TILHORER' | 'TILHORER_IKKE' | 'MIDLERTIDIG_TILSYNSOBJEKT'
    >(url)
  },
}
