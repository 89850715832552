import { IObservasjonMutateVars } from '../types'
import { queryApi } from '../../../api'
import { ENDPOINT } from '../../../constants'

export const observasjonApi = {
  post: async ({ kontrollpunkt, observasjon }: IObservasjonMutateVars) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/kontrollpunkter/${kontrollpunkt.id}/observasjoner`

    if (!url) {
      throw new Error('url is missing')
    }

    return queryApi.post(url, { tekst: observasjon.tekst })
  },
  put: async ({ observasjon }: IObservasjonMutateVars) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/observasjoner/${observasjon.id}`

    if (!url) {
      throw new Error('url is missing')
    }

    return queryApi.put(url, {
      ...observasjon,
      id: null,
      _links: {
        self: {
          href: url,
        },
      },
    })
  },
  delete: async ({ observasjon }: IObservasjonMutateVars) => {
    const url = `${ENDPOINT.TILSYNSKVITTERING}/observasjoner/${observasjon.id}`

    if (!url) {
      throw new Error('url is missing')
    }

    return queryApi.delete(url)
  },
}
