import { Observable, of } from 'rxjs'
import { combineEpics, ofType, StateObservable } from 'redux-observable'
import {
  withLatestFrom,
  filter,
  map,
  mergeMap,
  catchError,
} from 'rxjs/operators'

// selectors
import { userSelectors } from '../../user/selectors'

import { userSettingActionTypes, userSettingsActions } from '../store'

// helpers
import { formatSettingsDataResp } from './helpers'

// api
import { ICommonApi } from '../../../api'

// Types
import { AnyAction } from 'redux'
import { IStoreState } from '../../../reducers/types'

export const fetchUserSettings =
  (commonApi: ICommonApi) =>
  (state: IStoreState): Observable<AnyAction> =>
    of(state).pipe(
      commonApi.get(
        `/api/bruker-api/v1/brukere/${userSelectors.getAuthUsername(
          state
        )}/innstillinger`,
        state
      ),
      map((resp) => resp._embedded?.innstillingList || []),
      map((resp) => formatSettingsDataResp(resp)),
      map((data) => userSettingsActions.fetchOk(data)),
      catchError((err) => of(userSettingsActions.fetchFail(err.message)))
    )

export const updateUserSettingsEpic =
  (commonApi: ICommonApi) =>
  (
    action$: Observable<AnyAction>,
    state$: StateObservable<IStoreState>
  ): Observable<AnyAction> =>
    action$.pipe(
      ofType(userSettingActionTypes.UPDATE),
      withLatestFrom(state$),
      mergeMap(([{ settingKey, value }, state]: [AnyAction, IStoreState]) =>
        of(state).pipe(
          filter(() => !(value == null)),
          commonApi.put(
            `/api/bruker-api/v1/brukere/${userSelectors.getAuthUsername(
              state
            )}/innstillinger/${settingKey}/${value}`,
            state,
            null
          ),
          map(() => userSettingsActions.updateOk()),
          catchError((err) => of(userSettingsActions.updateFail(err.message)))
        )
      )
    )

export default (commonApi: ICommonApi) =>
  combineEpics(updateUserSettingsEpic(commonApi))
