import { AddCircle, Row, TextLink } from '@mattilsynet/mt-ui'
import React from 'react'

export interface IAddDeltaker {
  disabled?: boolean
  onAdd: () => void
}

const AddDeltaker = ({ disabled, onAdd }: IAddDeltaker) => {
  return (
    <Row justify="center">
      <TextLink leftIcon={<AddCircle />} onClick={onAdd} disabled={disabled}>
        Ny deltaker
      </TextLink>
    </Row>
  )
}

export default AddDeltaker
