import {
  CrossDeleteIcon,
  IconText,
  ImageGroup,
  Text,
  ThemeContext,
} from '@mattilsynet/mt-ui'
import { ObservasjonImage } from '../../../components/gallery-page/components/observasjon-image'
import { TextWithValidation } from '../../../components/text-with-validation'
import { RedCloudIcon } from '../../../assets/cloud-icon'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  useHasAnyBilderUploadError,
  useIsAnyBilderNotUploaded,
  useIsBilderValid,
  useUploadStatus,
} from '../hooks/component-hooks'
import { BildeUploadStatus } from '../types'
import { uiActions } from '../../../ducks/ui/actions'
import { useDispatch } from 'react-redux'
import { KvitteringImage } from '../../../components/gallery-page/components/kvittering-image'

interface BilderEditProps {
  bildeIds: string[]
  kvitteringId: string
  observasjonId?: number
  galleryBildeIds?: string[]
}

export const BilderEdit = ({
  bildeIds,
  kvitteringId,
  observasjonId,
  galleryBildeIds,
}: BilderEditProps) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)

  const [shouldScrollIntoView, setShouldScrollIntoView] = useState(false)
  const bildeIdsRef = useRef(bildeIds)

  useEffect(() => {
    if (!shouldScrollIntoView && bildeIds.length > bildeIdsRef.current.length) {
      setShouldScrollIntoView(true)
      bildeIdsRef.current = bildeIds
    }
  }, [bildeIds, shouldScrollIntoView])

  const isBilderValid = useIsBilderValid(kvitteringId, bildeIds)
  const isAnyBilderNotUploaded = useIsAnyBilderNotUploaded(bildeIds)
  const hasAnyBilderUploadError = useHasAnyBilderUploadError(bildeIds)
  const lastBildeId = bildeIds[bildeIds.length - 1]
  const isLastBildeUploading =
    useUploadStatus(lastBildeId) !== BildeUploadStatus.UPLOADED &&
    shouldScrollIntoView

  const navigationBildeIds = galleryBildeIds ?? bildeIds
  const onThumbnailClick = useCallback(
    (imageId: string) => {
      dispatch(
        uiActions.setImageNavigationModal(true, imageId, navigationBildeIds)
      )
    },
    [dispatch, navigationBildeIds]
  )

  if (bildeIds.length === 0) {
    return null
  }

  return (
    <>
      <Text>Antall bilder: {bildeIds.length}</Text>
      <ImageGroup maxColumns="auto-fill" style={{ width: '97%' }}>
        {bildeIds.map((bildeId) =>
          observasjonId !== undefined ? (
            <ObservasjonImage
              key={bildeId}
              id={bildeId}
              kvitteringId={kvitteringId}
              observasjonId={observasjonId}
              onClick={onThumbnailClick}
              shouldScrollIntoView={
                bildeId === lastBildeId && isLastBildeUploading
              }
            />
          ) : (
            <KvitteringImage
              key={bildeId}
              id={bildeId}
              kvitteringId={kvitteringId}
              onClick={onThumbnailClick}
              shouldScrollIntoView={
                bildeId === lastBildeId && isLastBildeUploading
              }
            />
          )
        )}
      </ImageGroup>

      <TextWithValidation
        errorText="Bilder som mangler beskrivelse"
        value={''}
        hasError={!isBilderValid}
        withWhiteSpace
        as="h4"
        bold
      />

      {isAnyBilderNotUploaded && (
        <div className="images-not-uploaded">
          <IconText
            text="Bilder som venter på internettforbindelse for å laste opp"
            icon={RedCloudIcon}
            color={theme.signalRed}
          />
        </div>
      )}

      {hasAnyBilderUploadError && (
        <div className="images-not-uploaded">
          <IconText
            text="Bilder feilet ved opplasting, prøv å last inn siden på nytt"
            icon={CrossDeleteIcon}
            color={theme.signalRed}
          />
        </div>
      )}
    </>
  )
}
