import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { ICreateKvitteringPayload, IUpdateKvitteringPayload } from '../types'
import { IKvitteringData } from '../../../ducks/kvittering/types'
import { kvitteringKeys, kvitteringQueryHelpers } from '../queries/helpers'
import { kvitteringApi } from '../queries/kvittering-api'
import { useDispatch } from 'react-redux'
import { kvitteringActions } from '../../../ducks/kvittering/actions'

export { useCreateKvitteringMutation, useUpdateKvittering }

const useCreateKvitteringMutation = () => {
  const dispatch = useDispatch()

  return useMutation<
    string,
    string | Error,
    ICreateKvitteringPayload,
    { previousKvittering: IKvitteringData }
  >({
    mutationKey: kvitteringKeys.create(),
    mutationFn: async (payload: ICreateKvitteringPayload) => {
      const { headers } = await kvitteringApi.post(payload)

      const kvitteringId = headers.location.match(/(\d+)$/)?.pop()

      return kvitteringId
    },
    onSuccess: (kvitteringId) => {
      dispatch(kvitteringActions.setCurrentKvitteringId(kvitteringId))
      return kvitteringQueryHelpers.invalidateKvittering(kvitteringId)
    },
  })
}

const useUpdateKvittering = () => {
  return useMutation<
    AxiosResponse<null>,
    string | Error,
    IUpdateKvitteringPayload
  >({
    mutationKey: kvitteringKeys.update(),
    mutationFn: kvitteringApi.put,
    onSuccess: async (_, { kvittering }) => {
      const id = kvittering?.id

      if (id) {
        await kvitteringQueryHelpers.invalidateKvittering(id)
      }
    },
  })
}
