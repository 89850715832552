// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion-bar-header-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.accordion-bar-header-empty-icon {
  height: 16px;
  min-width: 2rem;
  visibility: hidden;
}

.accordion-bar-header-icon-size {
  height: 16px;
  padding-right: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./src/components/accordion-bar-offline/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":[".accordion-bar-header-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n\n.accordion-bar-header-empty-icon {\n  height: 16px;\n  min-width: 2rem;\n  visibility: hidden;\n}\n\n.accordion-bar-header-icon-size {\n  height: 16px;\n  padding-right: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
