import { useQuery } from '@tanstack/react-query'
import { avdelingstilhorighetKeys } from './queries/helpers'
import { avdelingstilhorighetApi } from './tilhorighet-api'

export { useAvdelingstilhorighet }

const useAvdelingstilhorighet = (kvitteringId: string) =>
  useQuery({
    queryKey: avdelingstilhorighetKeys.kvitteringId(kvitteringId),
    queryFn: async () => {
      const { data } = await avdelingstilhorighetApi.get(kvitteringId)
      return data
    },
    enabled: !!kvitteringId,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  })
