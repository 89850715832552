import { IKvitteringData } from '../../ducks/kvittering/types'
import { isKontrollpunkterEqual } from '../kontrollpunkter/helpers'
import { Draft, Immutable } from 'immer'
import { IObservasjon } from './types'
import { IKontrollpunkt } from '../kontrollpunkter/types'

export {
  getKontrollpunktObservasjonerFromKvittering,
  getObservasjonFromKvittering,
  getAllObservasjonerFromKvittering,
  updateKontrollpunktObservasjonerFromKvittering,
}

const getKontrollpunktObservasjonerFromKvittering = (
  kvittering: Immutable<IKvitteringData>,
  kontrollpunkt: IKontrollpunkt
) => {
  let foundKontrollpunkt: IKontrollpunkt | undefined

  kvittering.tilsynsobjekter.some((tilsynsobjekt) =>
    tilsynsobjekt.kontrollpunkter.some((kp) =>
      isKontrollpunkterEqual(kp, kontrollpunkt)
    )
  )

  return foundKontrollpunkt?.observasjoner
}

const getObservasjonFromKvittering = (
  kvittering: Immutable<IKvitteringData>,
  observasjonId: number
) => {
  let foundObservasjon: Immutable<IObservasjon> | undefined

  kvittering.tilsynsobjekter.some((tilsynsobjekt) =>
    tilsynsobjekt.kontrollpunkter.some((kontrollpunkt) => {
      foundObservasjon = kontrollpunkt.observasjoner.find(
        (observasjon) => observasjon.id === observasjonId
      )
      return foundObservasjon !== undefined
    })
  )

  return foundObservasjon
}

const getAllObservasjonerFromKvittering = (
  kvittering: Immutable<IKvitteringData>
) => {
  return kvittering.tilsynsobjekter.flatMap((tilsynsobjekt) =>
    tilsynsobjekt.kontrollpunkter.flatMap((kontrollpunkt) => {
      return kontrollpunkt.observasjoner
    })
  )
}

const updateKontrollpunktObservasjonerFromKvittering = (
  draft: Draft<IKvitteringData>,
  kontrollpunkt: IKontrollpunkt,
  updateObservasjoner: (observasjoner: IObservasjon[]) => IObservasjon[]
) => {
  draft.tilsynsobjekter.some((tilsynsobjekt) =>
    tilsynsobjekt.kontrollpunkter.some((oldKontrollpunkt) => {
      if (isKontrollpunkterEqual(oldKontrollpunkt, kontrollpunkt)) {
        oldKontrollpunkt.observasjoner = updateObservasjoner(
          oldKontrollpunkt.observasjoner
        )
        return true
      }
      return false
    })
  )
}
