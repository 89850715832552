import { IDeltaker } from '../types'
import { IDeltakerMutation } from './query-hooks'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIsMutating, UseQueryResult } from '@tanstack/react-query'
import { initialDeltaker } from '../queries/helpers'

type IUseDeltakereFns = (
  query: UseQueryResult<IDeltaker[]>,
  mutation: IDeltakerMutation,
  options?: {
    noInitialData?: boolean
    type?: 'virksomhet' | 'mattilsynet' | 'andre'
  }
) => {
  onAdd: () => void
  onUpdate: (deltaker: IDeltaker) => void
  onRemove: (deltaker: IDeltaker) => void
  remove: () => void
  hasError: boolean
  shouldAutoFocus: (deltaker: IDeltaker) => boolean
  deltakerToBeRemoved: IDeltaker | undefined
  setDeltakerToBeRemoved: (deltaker: IDeltaker | undefined) => void
  isAddLoading: boolean
}

export const useDeltakereFns: IUseDeltakereFns = (
  query,
  mutation,
  { noInitialData, type } = { noInitialData: false, type: 'virksomhet' }
) => {
  const deltakere = useMemo(() => query.data ?? [], [query.data])

  const [hasError, setHasError] = useState(false)
  const [deltakerToBeRemoved, setDeltakerToBeRemoved] = useState<
    IDeltaker | undefined
  >(undefined)
  const [shouldFocus, setShouldFocus] = useState(false)

  const {
    add: {
      mutate: addDeltakere,
      isPending: isPendingAddDeltaker,
      isPaused: isPausedAddDeltaker,
    },
    onUpdate: onUpdateDeltakere,
    remove: { mutate: removeDeltakere },
  } = mutation

  const isPendingAddDeltakere = useIsMutating({
    predicate: (mutation) =>
      (mutation.options.mutationKey?.[0] as any)?.entity.includes(
        `${type}-deltakere`
      ) &&
      (mutation.options.mutationKey?.[0] as any)?.action === 'add' &&
      (mutation.state.status === 'pending' || mutation.state.isPaused),
  })

  useEffect(() => {
    if (
      !query.isFetching &&
      isPendingAddDeltakere === 0 &&
      !noInitialData &&
      deltakere.length === 0
    ) {
      setHasError(false)
      addDeltakere(initialDeltaker())
    }
  }, [
    query.isFetching,
    isPendingAddDeltakere,
    noInitialData,
    deltakere,
    addDeltakere,
  ])

  const onAdd = useCallback(() => {
    const isLastDeltakerEmpty =
      deltakere[deltakere.length - 1]?.navn.length === 0
    if (isLastDeltakerEmpty) {
      setHasError(true)
      return
    }

    setHasError(false)
    setShouldFocus(true)
    addDeltakere(initialDeltaker())
  }, [deltakere, addDeltakere])

  const onUpdate = useCallback(
    (selectedDeltaker: IDeltaker) => {
      onUpdateDeltakere(selectedDeltaker)
      setShouldFocus(true)
    },
    [onUpdateDeltakere]
  )

  const remove = useCallback(() => {
    if (!deltakerToBeRemoved) return

    removeDeltakere(deltakerToBeRemoved)
    setDeltakerToBeRemoved(undefined)
  }, [deltakerToBeRemoved, removeDeltakere])

  const onRemove = useCallback(
    (deltaker: IDeltaker) => {
      if (deltaker.navn.length > 0) {
        setDeltakerToBeRemoved(deltaker)
        return
      }

      removeDeltakere(deltaker)
    },
    [removeDeltakere]
  )

  const shouldAutoFocus = useCallback(
    (deltaker: IDeltaker) =>
      shouldFocus && deltaker.id === deltakere[deltakere.length - 1].id,
    [deltakere, shouldFocus]
  )

  return {
    onAdd,
    onUpdate,
    onRemove,
    remove,
    hasError,
    shouldAutoFocus,
    deltakerToBeRemoved,
    setDeltakerToBeRemoved,
    isAddLoading: isPendingAddDeltaker && !isPausedAddDeltaker,
  }
}

export const useRemoveEmptyDeltaker = (
  deltakere: IDeltaker[],
  removeDeltaker: (deltaker: IDeltaker) => void
) => {
  const deltakereRef = useRef(deltakere)
  const removeRef = useRef(removeDeltaker)

  useEffect(() => {
    deltakereRef.current = deltakere
    removeRef.current = removeDeltaker
  }, [deltakere, removeDeltaker])

  useEffect(() => {
    return () => {
      const deltakere = deltakereRef.current ?? []
      const remove = removeRef.current

      const lastDeltaker = deltakere[deltakere.length - 1]
      if (remove && lastDeltaker?.navn.length === 0) {
        remove(lastDeltaker)
      }
    }
  }, [])
}
