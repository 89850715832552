import { BildeMetadataMutationOptions, IImage } from '../types'
import { queryClient } from '../../../api/query-client'
import { bildeKeys, metadataQueryHelpers, withPersistedBilde } from './helpers'
import { Draft } from 'immer'
import { shouldIgnoreError } from '../../../common/query'
import store from '../../../reducers/store'
import { dangerToast } from '../../../common/toast'
import { bildeApi } from './bilde-api'
import { toValidCoord } from '../metadata-helpers'

/**
 * Optimistically updates bilde metadata
 * in the cache.
 *
 * @returns Previous bilde metadatas (Context that
 * is passed to onError).
 */
const onMutate: (
  updateMetadata: (draftMetadata: Draft<IImage>, bildeMetadata: IImage) => void
) => BildeMetadataMutationOptions['onMutate'] =
  (updateMetadata) =>
  async ({ kvitteringId, bildeMetadata }) => {
    await queryClient.cancelQueries({
      queryKey: bildeKeys.metadataList(kvitteringId),
    })

    const previousMetadataList =
      metadataQueryHelpers.getMetadataList(kvitteringId) ?? []

    metadataQueryHelpers.updateMetadataList(kvitteringId, (draft) => {
      if (!draft) return draft

      const metadata = draft.find(
        (metadata) => metadata.id === bildeMetadata.id
      )

      if (metadata) {
        updateMetadata(metadata, bildeMetadata)
      }
    })

    return { previousMetadataList }
  }

/** Displays error toast and reverts cache to previous bilde metadatas. */
const onError: BildeMetadataMutationOptions['onError'] = (
  error,
  { kvitteringId },
  context
) => {
  if (shouldIgnoreError(error)) {
    return
  }

  store.dispatch(
    dangerToast('Det har skjedd en feil med bildene. Kunne ikke lagre.')
  )

  metadataQueryHelpers.updateMetadataList(
    kvitteringId,
    (draft) => context?.previousMetadataList ?? draft
  )
}

// Leaving it here for now, in case we need it later
/** Refetches bilde metadatas either on success or error. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onSettled: BildeMetadataMutationOptions['onSettled'] = (
  _,
  __,
  { kvitteringId }: { kvitteringId: string }
) =>
  queryClient.invalidateQueries({
    queryKey: bildeKeys.metadataList(kvitteringId),
  })

// Update bilde beskrivelse
queryClient.setMutationDefaults(bildeKeys.updateBeskrivelseBase(), {
  mutationFn: withPersistedBilde(({ kvitteringId, bildeMetadata }) =>
    bildeApi.updateMetadata({
      kvitteringId,
      bildeId: bildeMetadata.id,
      data: { description: bildeMetadata.description },
    })
  ),
  onMutate: onMutate((metadata, bildeMetadata) => {
    metadata.description = bildeMetadata.description
  }),
  onError: onError,
} satisfies BildeMetadataMutationOptions)

// Update bilde lokasjon
queryClient.setMutationDefaults(bildeKeys.updateLokasjonBase(), {
  mutationFn: withPersistedBilde(
    ({
      kvitteringId,
      bildeMetadata: {
        id,
        locationDescription,
        locationDescriptionUpdateTime,
        locationCopiedFromId,
        locationLatitude,
        locationLongitude,
      },
    }) =>
      bildeApi.updateMetadata({
        kvitteringId,
        bildeId: id,
        data: {
          locationDescription: locationDescription,
          locationDescriptionUpdateTime: locationDescriptionUpdateTime,
          locationCopiedFromId: locationCopiedFromId,
          locationLatitude: toValidCoord(locationLatitude),
          locationLongitude: toValidCoord(locationLongitude),
        },
      })
  ),
  onMutate: onMutate((metadata, bildeMetadata) => {
    metadata.locationDescription = bildeMetadata.locationDescription
    metadata.locationDescriptionUpdateTime =
      bildeMetadata.locationDescriptionUpdateTime
    metadata.locationLatitude = bildeMetadata.locationLatitude
    metadata.locationLongitude = bildeMetadata.locationLongitude
    metadata.locationCopiedFromId = bildeMetadata.locationCopiedFromId
  }),
  onError: onError,
} satisfies BildeMetadataMutationOptions)
