import { mergeKeyWith } from '../../../common/query'

const getSakKeys = () => {
  const keys = {
    all: [{ entity: 'sak' }],
    get: (sakNumber: string) => mergeKeyWith(keys.all, { sakNumber }),
  } as const

  return keys
}

const sakKeys = getSakKeys()

export { sakKeys }
