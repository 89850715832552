import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'
import { useContext } from 'react'
import { ThemeContext } from '@mattilsynet/mt-ui'

interface IPlaceholderBox {
  /**
   *  Width of the placeholderBox
   */
  width?: CSSProperties['width']
  /**
   *  Height of the placeholderBox
   */
  height?: CSSProperties['width']
  /**
   *  BackgroundColor of the placeholderBox
   *  @default theme.white
   */
  backgroundColor?: keyof IThemeColors | string
  /**
   *  Prop to set shadow
   *  @default false
   */
  withShadow?: boolean
  id?: string
}

const StyledPlaceholderBox = styled.div<
  IPlaceholderBox & { theme: IThemeColors }
>`
  & {
    background-color: ${({ backgroundColor }) => backgroundColor};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    box-shadow: ${({ withShadow, theme }) =>
      withShadow ? `1px 1px 2px 1px ${theme.gray4}` : 'none'};
    border-radius: 3px;
    padding-top: ${({ height }) => (!height ? '100%' : undefined)};
  }
`

export const PlaceholderBox = ({
  backgroundColor,
  ...rest
}: IPlaceholderBox) => {
  const theme = useContext(ThemeContext)
  const themeBackgroundColor = !!backgroundColor
    ? theme[backgroundColor] || backgroundColor
    : theme.white

  return (
    <StyledPlaceholderBox
      theme={theme}
      backgroundColor={themeBackgroundColor}
      {...rest}
    />
  )
}
