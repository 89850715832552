// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.virksomhet-adresse-info > *:not(:first-child) {
  margin-top: 1em;
}
.adresse-accordion > .header {
  padding: 0em !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/adresse-accordion/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".virksomhet-adresse-info > *:not(:first-child) {\n  margin-top: 1em;\n}\n.adresse-accordion > .header {\n  padding: 0em !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
