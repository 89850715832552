import { IVeiledning } from './types'
import { v1 as uuidv1 } from 'uuid'

export const veiledningInitialState: IVeiledning = {
  tekst: '',
  tilsynsobjektId: '',
  id: uuidv1(),
  _links: {
    self: {
      href: '',
    },
  },
}
