import React, { useState } from 'react'
import { Column, Header, Row, Text } from '@mattilsynet/mt-ui'
import {
  IKvitteringData,
  kvitteringStatusEnum,
} from '../../ducks/kvittering/types'
import HorizontalLine from '../horizontal-line'
import TilsynCardItem from '../tilsyn-card-item'
import { TilsynHandlingsMenuModal } from '../../modals/tilsyn-handling-modal'
import { offlineOnSeSammenstilling, useSingleToast } from '../../common/toast'
import { useTypedSelector } from '../../common/custom-hooks'
import { IStoreState } from '../../reducers/types'
import { getSammenstillingRoute } from '../../routes/sammenstilling'
import {
  isArkivertStatus,
  isFeiletStatus,
  isFerdigstiltStatus,
} from '../../utils/kvittering'
import { useTKNavigate } from '../../common/navigation'
import './style.css'

interface IKvitteringerListProps {
  title: string
  kvitteringer: IKvitteringData[]
  onClickTilsyn: (kvittering: IKvitteringData) => void
  buttonText?: string
  onOpenDeleteModal: (kvittering: IKvitteringData) => void
  info?: string
  onRedigerTilsyn: (kvittering: IKvitteringData) => void
  onFordelTilKollega: (kvittering: IKvitteringData) => void
}

export const KvitteringerList = ({
  title,
  kvitteringer,
  onClickTilsyn,
  buttonText,
  onOpenDeleteModal,
  info,
  onRedigerTilsyn,
  onFordelTilKollega,
}: IKvitteringerListProps) => {
  const navigate = useTKNavigate()
  const singleToast = useSingleToast()
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)

  const [isOpenHandlingModal, setIsOpenHandlingModal] = useState<boolean>(false)
  const [selectedKvittering, setSelectedKvittering] = useState<
    IKvitteringData | undefined
  >()

  if (!kvitteringer.length) return <></> //TODO: Waiting for what to add when there is none.

  const onOpenHandlingsMenu = (kvittering) => {
    setSelectedKvittering(kvittering)
    setIsOpenHandlingModal(true)
  }

  const onGoToSammenstilling = (kvittering) => {
    if (isOffline) {
      singleToast.showToast(offlineOnSeSammenstilling())
      return
    }
    navigate(getSammenstillingRoute(kvittering.id))
  }

  return (
    <>
      <Row className="forberedte-tilsyn-title">
        <Column spacing={1} fluid>
          <Column fluid spacing={0.25}>
            <Header as="h2" size="heading1">
              {title}
            </Header>
            <HorizontalLine />
          </Column>
          <Text size="medium">{info}</Text>
        </Column>
      </Row>
      {kvitteringer.map((kvittering: IKvitteringData) => {
        const shouldStartTilsyn = kvittering.tilsynsobjekter.length > 0

        return (
          <React.Fragment key={kvittering.id}>
            {selectedKvittering?.id === kvittering.id && (
              <TilsynHandlingsMenuModal
                key={kvittering.id}
                isOpen={isOpenHandlingModal}
                onCancel={() => setIsOpenHandlingModal(false)}
                onEdit={() => onRedigerTilsyn(kvittering)}
                onDelete={() => onOpenDeleteModal(kvittering)}
                onGoToSammenstilling={() => onGoToSammenstilling(kvittering)}
                showSammenstilling={
                  isArkivertStatus(kvittering.status) ||
                  isFerdigstiltStatus(kvittering.status) ||
                  isFeiletStatus(kvittering.status)
                }
                onFordelTilKollega={() => onFordelTilKollega(kvittering)}
                disableFordelTilKollega={false}
              />
            )}

            {isFerdigstiltStatus(kvittering.status) ||
            isFeiletStatus(kvittering.status) ? (
              <TilsynCardItem
                key={kvittering.oppdatert}
                kvittering={kvittering}
                onOpenHandlingsMenu={() => onOpenHandlingsMenu(kvittering)}
                isEditable={false}
                hasError={isFeiletStatus(kvittering.status)}
              />
            ) : (
              <TilsynCardItem
                key={kvittering.oppdatert}
                onOpenDeleteModal={() => onOpenDeleteModal(kvittering)}
                onRedigerTilsyn={() => onRedigerTilsyn(kvittering)}
                onClickTilsyn={() => onClickTilsyn(kvittering)}
                buttonText={
                  shouldStartTilsyn ? buttonText : 'Fullfør forberedelse'
                }
                isButtonDisabled={
                  kvittering.status === kvitteringStatusEnum.ARKIVERING_PAAGAAR
                }
                kvittering={kvittering}
                isEditable={!isArkivertStatus(kvittering.status)}
                onOpenHandlingsMenu={() => onOpenHandlingsMenu(kvittering)}
              />
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}
