import React from 'react'
import { InformationBox } from '../../components/information-box'
import { useAvdelingstilhorighet } from './tilhorighet-hooks'
import { useKvitteringId } from '../../common/kvittering-context'
import { LoadingDots } from '@mattilsynet/mt-ui'

export const Avdelingstilhorighet = () => {
  const kvitteringId = useKvitteringId()
  const { data, isLoading } = useAvdelingstilhorighet(kvitteringId)

  if (isLoading) {
    return <LoadingDots />
  }

  // TILHORER
  // TILHORER_IKKE
  // MIDLERTIDIG_TILSYNSOBJEKT
  if (data === undefined || data !== 'TILHORER_IKKE') {
    return null
  }

  return (
    <InformationBox small variant="warning">
      Tilsynsobjektet har adresse utenfor din avdeling, sjekk at du har valgt
      rett!
    </InformationBox>
  )
}
