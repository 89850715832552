import { IUiState } from '../types'

export const initialState: IUiState = {
  oidcUrl: '',
  oidcConfig: null,
  kcIdpHint: '',
  environment: '',
  offline: false,
  initializationStatus: {
    loading: false,
    error: null,
    loaded: false,
  },
  openImageNavigationModal: {
    isOpen: false,
    imageId: '',
    bildeIds: [],
    isFullversjonOpen: false,
  },
  isDesktop: true,
  gcp: false,
  gcpUrl: '',
  hasUpdated: false,
}
