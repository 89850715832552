import { AxiosResponse } from 'axios'
import { queryClient } from '../../../api/query-client'
import {
  DependencyNotPersisted,
  getIdFromSelfUrl,
  shouldIgnoreError,
} from '../../../common/query'
import { dangerToast } from '../../../common/toast'
import store from '../../../reducers/store'
import { kvitteringQueryHelpers } from '../../kvitteringer/queries/helpers'
import { isKontrollpunkterEqual } from '../helpers'
import {
  IKontrollpunkt,
  IKontrollpunktMutateVars,
  KontrollpunktMutationOptions,
} from '../types'
import { kontrollpunktKeys } from './helpers'
import { kontrollpunktApi } from './kontrollpunkt-api'
import { offlineLexicon } from '../../offline-lexicon'
import { ENDPOINT } from '../../../constants'
import { onlineManager } from '@tanstack/react-query'

/**
 * Optimistically updates tilsynsobjekter.kontrollpunkter
 * in the cache.
 *
 * @returns Previous kontrollpunkter (Context that
 * is passed to onError).
 */
const onMutate: (
  updateKontrollpunkter: (
    kontrollpunkter: IKontrollpunkt[],
    kontrollpunkt: IKontrollpunkt
  ) => IKontrollpunkt[]
) => KontrollpunktMutationOptions['onMutate'] =
  (updateKontrollpunkter) =>
  async ({ kvitteringId, kontrollpunkt }) => {
    await kvitteringQueryHelpers.cancelKvitteringQuery(kvitteringId)

    const previousKontrollpunkter =
      kvitteringQueryHelpers
        .getKvitteringCache(kvitteringId)
        ?.tilsynsobjekter.find(
          (to) => to.id === String(kontrollpunkt.tilsynsobjektId)
        )?.kontrollpunkter ?? []

    kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
      draft.tilsynsobjekter.forEach((tilsynsobjekt) => {
        if (
          String(tilsynsobjekt.id) === String(kontrollpunkt.tilsynsobjektId)
        ) {
          tilsynsobjekt.kontrollpunkter = updateKontrollpunkter(
            tilsynsobjekt.kontrollpunkter,
            kontrollpunkt
          )
        }
      })
    })

    return { previousKontrollpunkter }
  }

/** Displays error toast and reverts cache to previous kontrollpunkter. */
const onError: KontrollpunktMutationOptions['onError'] = (
  error,
  { kvitteringId, kontrollpunkt },
  context
) => {
  if (shouldIgnoreError(error)) {
    return
  }

  store.dispatch(
    dangerToast(
      'Det har skjedd en feil med kontrollpunktene. Kunne ikke lagre.'
    )
  )

  kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (kvittering) => ({
    ...kvittering,
    tilsynsobjekter: kvittering.tilsynsobjekter.map((tilsynsobjekt) => {
      if (tilsynsobjekt.id !== String(kontrollpunkt.tilsynsobjektId)) {
        return tilsynsobjekt
      }

      return {
        ...tilsynsobjekt,
        kontrollpunkter:
          context?.previousKontrollpunkter ?? tilsynsobjekt.kontrollpunkter,
      }
    }),
  }))
}

// Leaving it here for now, in case we need it later
/** Refetches kvittering either on success or error. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onSettled: KontrollpunktMutationOptions['onSettled'] = (
  _,
  __,
  { kvitteringId }
) => kvitteringQueryHelpers.invalidateKvittering(kvitteringId)

/** Gets mutation url from persisted kontrollpunkt. */
const getMutationUrl = (
  kontrollpunkt: IKontrollpunkt,
  kvitteringId: string
) => {
  let mutationUrl = `${ENDPOINT.TILSYNSKVITTERING}/kontrollpunkter/${kontrollpunkt.id}`
  let persistedId = kontrollpunkt.id?.toString()
  if (typeof kontrollpunkt.id !== 'number') {
    const localKontrollpunkt = offlineLexicon.get(
      offlineLexicon.types.kontrollpunkt,
      kontrollpunkt.id ?? '',
      kvitteringId
    )
    mutationUrl = localKontrollpunkt?._links.self.href ?? mutationUrl
    persistedId = localKontrollpunkt?.id?.toString()
  }

  if (!mutationUrl) {
    throw new DependencyNotPersisted()
  }

  return [mutationUrl, persistedId] as const
}

/** Gets and merges the mutation url with the variables. */
const withMutationUrl =
  (request: (variables: IKontrollpunktMutateVars) => Promise<AxiosResponse>) =>
  (variables: IKontrollpunktMutateVars) => {
    const { kvitteringId, kontrollpunkt } = variables

    let url = ''
    let pId: string | undefined = ''
    try {
      const [mutationUrl, persistedId] = getMutationUrl(
        kontrollpunkt,
        kvitteringId
      )
      url = mutationUrl
      pId = persistedId ?? kontrollpunkt.id
    } catch (error) {
      return Promise.reject(error)
    }

    return request({
      ...variables,
      kontrollpunkt: {
        ...kontrollpunkt,
        id: pId,
        _links: {
          ...kontrollpunkt._links,
          self: {
            href: url,
          },
        },
      },
    })
  }

// Add kontrollpunkt
queryClient.setMutationDefaults(kontrollpunktKeys.addBase(), {
  mutationFn: kontrollpunktApi.post,
  onMutate: onMutate((kontrollpunkter, kontrollpunkt) => {
    if (onlineManager.isOnline()) {
      return kontrollpunkter
    }

    return [...kontrollpunkter, kontrollpunkt]
  }),
  onSuccess: (res, { kvitteringId, kontrollpunkt }) => {
    const persistedId =
      getIdFromSelfUrl(res.headers.location)?.toString() ?? kontrollpunkt.id
    const newKontrollpunkt = {
      ...kontrollpunkt,
      id: persistedId,
      _links: {
        self: {
          href: `${ENDPOINT.TILSYNSKVITTERING}/kontrollpunkter/${persistedId}`,
        },
        observasjoner: {
          href: `${ENDPOINT.TILSYNSKVITTERING}/kontrollpunkter/${persistedId}/observasjoner`,
        },
      },
    } satisfies IKontrollpunkt

    offlineLexicon.set(
      offlineLexicon.types.kontrollpunkt,
      kontrollpunkt.id + '',
      kvitteringId,
      newKontrollpunkt
    )
  },
  onError: onError,
  onSettled: (_, __, { kvitteringId }) => {
    if (queryClient.isMutating() > 1) {
      return
    }

    return kvitteringQueryHelpers.invalidateKvittering(kvitteringId)
  },
} satisfies KontrollpunktMutationOptions)

// Update kontrollpunkt
queryClient.setMutationDefaults(kontrollpunktKeys.updateBeskrivelseBase(), {
  mutationFn: withMutationUrl(({ kontrollpunkt }) =>
    kontrollpunktApi.patch({
      kontrollpunkt,
      data: { beskrivelse: kontrollpunkt.beskrivelse },
    })
  ),
  onMutate: onMutate((kontrollpunkter, kontrollpunkt) => {
    for (const oldKontrollpunkt of kontrollpunkter) {
      if (isKontrollpunkterEqual(oldKontrollpunkt, kontrollpunkt)) {
        oldKontrollpunkt.beskrivelse = kontrollpunkt.beskrivelse
        break
      }
    }

    return kontrollpunkter
  }),
  onError: onError,
} satisfies KontrollpunktMutationOptions)

// Update sorteringsrekkefølge for kontrollpunkt
queryClient.setMutationDefaults(
  kontrollpunktKeys.updateSorteringsrekkefoelgeBase(),
  {
    mutationFn: withMutationUrl(({ kontrollpunkt }) =>
      kontrollpunktApi.patch({
        kontrollpunkt,
        data: { sorteringsrekkefoelge: kontrollpunkt.sorteringsrekkefoelge },
      })
    ),
    onMutate: onMutate((kontrollpunkter, kontrollpunkt) => {
      for (const oldKontrollpunkt of kontrollpunkter) {
        if (isKontrollpunkterEqual(oldKontrollpunkt, kontrollpunkt)) {
          oldKontrollpunkt.sorteringsrekkefoelge =
            kontrollpunkt.sorteringsrekkefoelge
          break
        }
      }
      return kontrollpunkter
    }),
    onError: onError,
  } satisfies KontrollpunktMutationOptions
)

// Remove kontrollpunkt
queryClient.setMutationDefaults(kontrollpunktKeys.removeBase(), {
  mutationFn: withMutationUrl(kontrollpunktApi.delete),
  onMutate: onMutate((kontrollpunkter, kontrollpunkt) =>
    kontrollpunkter.filter((kp) => !isKontrollpunkterEqual(kp, kontrollpunkt))
  ),
  onError: onError,
} satisfies KontrollpunktMutationOptions)
