import React, { useCallback } from 'react'
import { Column, LoadingDots, LoadingSpinner, Row } from '@mattilsynet/mt-ui'
import {
  useMattilsynetDeltakere,
  useMattilsynetDeltakereMutation,
} from '../index'
import { useTypedSelector } from '../../../common/custom-hooks'
import {
  useDeltakereFns,
  useRemoveEmptyDeltaker,
} from '../hooks/component-hooks'
import { DeleteModal } from '../../../modals'
import { userSelectors } from '../../../ducks/user/selectors'
import SkjulNavn from './skjul-navn'
import AddDeltaker from './add-deltaker'
import { DeltakerItem } from './deltaker-item'
import { createSelector } from 'reselect'
import { IStoreState } from '../../../reducers/types'
import { ENDPOINT } from '../../../constants'

const MATTILSYNET_DELTAKERE = 'mattilsynet-deltagere'

const selectUrlAndDeltakere = createSelector(
  (state: IStoreState) => state.kvittering.selectedKvittering.data,
  ({ id, [MATTILSYNET_DELTAKERE]: kvitteringDeltakere }) => ({
    url: `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${id}/${MATTILSYNET_DELTAKERE}`,
    kvitteringDeltakere,
  })
)

export const MattilsynetDeltakere = () => {
  const user = useTypedSelector(userSelectors.getAuthUser)
  const { url, kvitteringDeltakere } = useTypedSelector(selectUrlAndDeltakere)

  const deltakere = useMattilsynetDeltakere(url, {
    initialData: kvitteringDeltakere,
  })
  const mutation = useMattilsynetDeltakereMutation(url)
  const {
    onAdd,
    onUpdate,
    onRemove,
    shouldAutoFocus,
    hasError,
    remove,
    setDeltakerToBeRemoved,
    deltakerToBeRemoved,
    isAddLoading,
  } = useDeltakereFns(deltakere, mutation, {
    noInitialData: true,
    type: 'mattilsynet',
  })

  useRemoveEmptyDeltaker(deltakere.data ?? [], onRemove)

  const onCancelRemove = useCallback(
    () => setDeltakerToBeRemoved(undefined),
    [setDeltakerToBeRemoved]
  )

  const filteredDeltakere =
    deltakere.data?.filter(
      (deltaker) => user?.name.trimEnd() !== deltaker.navn.trimEnd()
    ) ?? []

  return (
    <>
      <Column spacing={2}>
        <Column spacing={2}>
          <Row className="info-header">Fra Mattilsynet</Row>

          {deltakere.isLoading && (
            <Row center>
              <LoadingSpinner title={'Laster deltakerer...'} small />
            </Row>
          )}

          {!deltakere.isLoading && (
            <>
              <Row>{user?.name}</Row>
              {filteredDeltakere.map((deltaker) => (
                <DeltakerItem
                  key={deltaker.id}
                  deltaker={deltaker}
                  onUpdateDeltaker={onUpdate}
                  onRemoveDeltaker={onRemove}
                  autoFocus={shouldAutoFocus(deltaker)}
                  danger={hasError}
                />
              ))}
            </>
          )}
        </Column>

        {(isAddLoading ||
          (deltakere.isFetching && deltakere.data?.length === 0)) && (
          <Row justify="center">
            <LoadingDots />
          </Row>
        )}

        {!deltakere.isLoading && (
          <>
            <AddDeltaker onAdd={onAdd} />
            <SkjulNavn />
          </>
        )}
      </Column>

      <DeleteModal
        isOpen={!!deltakerToBeRemoved}
        onCancel={onCancelRemove}
        onDelete={remove}
        title="Er du sikker på at du vil slette deltaker?"
        confirmText="Ja, jeg vil slette deltaker"
        cancelText="Avbryt"
      />
    </>
  )
}
