import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IMidlertidigTilsynsobjektMutateVars } from '../types'
import { midlertidigTilsynsobjektKeys } from '../queries/helpers'
import { midlertidigTilsynsobjektApi } from '../queries/midlertidig-tilsynsobjekt-api'
import { shouldIgnoreError } from '../../../common/query'
import store from '../../../reducers/store'
import { dangerToast } from '../../../common/toast'

export { useUpdateMidlertidigTilsynsobjekt, useAddMidlertidigTilsynsobjekt }

const useUpdateMidlertidigTilsynsobjekt = (kvitteringId) =>
  useMutation<
    AxiosResponse<null>,
    string | Error,
    IMidlertidigTilsynsobjektMutateVars
  >({
    mutationKey: midlertidigTilsynsobjektKeys.update(kvitteringId),
    mutationFn: midlertidigTilsynsobjektApi.put,
  })

const useAddMidlertidigTilsynsobjekt = () =>
  useMutation<
    AxiosResponse<null>,
    string | Error,
    IMidlertidigTilsynsobjektMutateVars
  >({
    mutationKey: midlertidigTilsynsobjektKeys.add(),
    mutationFn: midlertidigTilsynsobjektApi.post,
    onError: (error) => {
      if (shouldIgnoreError(error)) {
        return
      }

      store.dispatch(
        dangerToast(
          'Det har skjedd en feil med tilsynsobjektene. Kunne ikke lagre.'
        )
      )
    },
  })
