import {
  noDescriptionError,
  descriptionToLongError,
  invalidCharactersError,
  IError,
} from './image-errors'
import { IImage } from '../features/bilder/types'

const legalCharacterRanges = new Map<string, { min: number; max: number }>([
  ['legalPunctuationOrSymbol', { min: 32, max: 47 }],
  ['lineFeed', { min: 10, max: 10 }],
  ['carriageReturn', { min: 13, max: 13 }],
  ['digits', { min: 48, max: 57 }],
  ['latinAlphabetUppercase', { min: 65, max: 90 }],
  ['latinAlphabetLowercase', { min: 97, max: 122 }],

  ['decoratedLatinLetters#1Uppercase', { min: 192, max: 214 }],
  ['decoratedLatinLetters#2Uppercase', { min: 216, max: 222 }],
  ['decoratedLatinLetters#1Lowercase', { min: 223, max: 246 }],
  ['decoratedLatinLetters#2Lowercase', { min: 248, max: 255 }],

  ['europeanLatinLetters', { min: 256, max: 383 }],
  ['samiCharacters#1', { min: 477, max: 495 }],
])

export const isIllegalCharacter = (character: string) => {
  const code = character.charCodeAt(0)
  for (const range of legalCharacterRanges.values()) {
    if (code <= range.max && code >= range.min) {
      return false
    }
  }
  return true
}

export const hasDescription = (description?: string) =>
  description && description.trim().length > 0 ? null : noDescriptionError()

const hasShortDescription = (description?: string) =>
  description && description.length < 251

export const hasValidCharacters = (description?: string) => {
  return (
    description && description.split('').find(isIllegalCharacter) === undefined
  )
}

const validateDescription = (description?: string): IError[] =>
  [
    hasDescription(description),
    hasShortDescription(description) ? null : descriptionToLongError(),
    hasValidCharacters(description) ? null : invalidCharactersError(),
  ].filter((err) => err) as IError[]

export const isImageValid = (image?: IImage): boolean => {
  return validateDescription(image?.description).length === 0
}

export default {
  validateDescription,
}
