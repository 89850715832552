// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 992px) {
  .fordelTilKollegaModal div.modal-child {
    height: 85vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modals/fordel-til-kollega-modal/style.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":["@media only screen and (min-width: 992px) {\n  .fordelTilKollegaModal div.modal-child {\n    height: 85vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
