import {
  IArkiveringStatusState,
  FETCH_KVITTERING_ARKIV_INFO,
  FETCH_KVITTERING_ARKIV_INFO_SUCCESS,
  FETCH_KVITTERING_ARKIV_INFO_FAILED,
  UPDATE_SKJERMEDE_ORD,
  UPDATE_SKJERMEDE_ORD_SUCCESS,
  UPDATE_SKJERMEDE_ORD_FAILED,
  ArkiveringStatusActions,
  ISkjermedeOrdWithSelected,
} from './types'

export const arkiveringStatusActionsTypes = {
  FETCH: 'arkivering-status/FETCH_ARKIVERING_STATUS' as const,
  FETCH_SUCCESS: 'arkivering-status/FETCH_ARKIVERING_STATUS_SUCCESS' as const,
  FETCH_FAILED: 'arkivering-status/FETCH_ARKIVERING_STATUS_FAILED' as const,
}

export const arkiveringStatusActions = {
  fetch: (aar: string, sekvensnummer: string, hoveddokumentId: string) => ({
    type: arkiveringStatusActionsTypes.FETCH,
    aar,
    sekvensnummer,
    hoveddokumentId,
  }),

  fetchSuccess: (data: IArkiveringStatusState['data']) => ({
    type: arkiveringStatusActionsTypes.FETCH_SUCCESS,
    data,
  }),

  fetchFailed: (error: string) => ({
    type: arkiveringStatusActionsTypes.FETCH_FAILED,
    error,
  }),
}

export const fetchKvitteringArkivInfo = (): ArkiveringStatusActions => {
  return {
    type: FETCH_KVITTERING_ARKIV_INFO,
  }
}

export const fetchKvitteringArkivInfoSuccess = (
  payload: ITilsynskvitteringRemote.ArkivInfoRes
): ArkiveringStatusActions => {
  return {
    type: FETCH_KVITTERING_ARKIV_INFO_SUCCESS,
    payload,
  }
}

export const fetchKvitteringArkivInfoFailed = (
  error: string
): ArkiveringStatusActions => {
  return {
    type: FETCH_KVITTERING_ARKIV_INFO_FAILED,
    error,
  }
}

export const updateSkjermedeOrd = (
  skjermedeOrd: ISkjermedeOrdWithSelected[]
): ArkiveringStatusActions => {
  return {
    type: UPDATE_SKJERMEDE_ORD,
    skjermedeOrd,
  }
}

export const updateSkjermedeOrdSuccess = (): ArkiveringStatusActions => {
  return {
    type: UPDATE_SKJERMEDE_ORD_SUCCESS,
  }
}

export const updateSkjermedeOrdFailed = (
  error: string
): ArkiveringStatusActions => {
  return {
    type: UPDATE_SKJERMEDE_ORD_FAILED,
    error,
  }
}
