import React, { useCallback, useContext, useState } from 'react'
import { ModalWrapper } from '../../../components/modal-wrapper'
import {
  Button,
  Column,
  Row,
  RowItem,
  Text,
  ThemeContext,
} from '@mattilsynet/mt-ui'
import { ModalButtonsContainer } from '../../../components/modal-buttons-container'
import { ModalButton } from '../../../components/modal-button'
import { ITilsynsobjekt } from '../../../ducks/kvittering/tilsynsobjekt/types'
import { modalContentPaddingLarge } from '../../../components/modal-buttons-container/constants'
import { useKvitteringId } from '../../../common/kvittering-context'
import { useKvittering } from '../../kvitteringer'
import { IKvitteringData } from '../../../ducks/kvittering/types'
import { BildeGroupedItem } from '../../bilder/types'
import {
  alreadyAttachedImagesToObservasjon,
  useSingleToast,
} from '../../../common/toast'
import { IObservasjon } from '../../observasjoner/types'
import { IKontrollpunkt } from '../../kontrollpunkter/types'

interface IAttachImagesToObservationModal {
  isOpen: boolean
  selectedImages: BildeGroupedItem[]
  onCancel: () => void
  onAttach: (observasjonId: number) => void
}

export const AttachImagesToObservationModal = ({
  isOpen,
  selectedImages,
  onCancel,
  onAttach,
}: IAttachImagesToObservationModal) => {
  const kvitteringId = useKvitteringId()

  const singleToast = useSingleToast()

  const [selectedObservasjon, setSelectedObservasjon] = useState<
    number | undefined
  >()

  const theme = useContext(ThemeContext)

  const { data: observasjoner } = useKvittering(kvitteringId, {
    select: (kvittering: IKvitteringData) =>
      kvittering.tilsynsobjekter.flatMap((tilsynsobjekt: ITilsynsobjekt) =>
        tilsynsobjekt.kontrollpunkter.flatMap(
          (kontrollpunkt: IKontrollpunkt) => kontrollpunkt.observasjoner
        )
      ),
  })

  const alreadyAttachedImages = selectedImages.filter(
    (image: BildeGroupedItem) => image.observasjonId
  )

  const alreadyAttachedBilderDescription = `${alreadyAttachedImages.length} valgte bilder er allerede knyttet til en observasjon.`

  const isAttachButtonDisabled =
    observasjoner?.length === 0 || !selectedObservasjon

  const onSelectObservasjon = useCallback(
    (id: number) => setSelectedObservasjon(id),
    [setSelectedObservasjon]
  )

  const onCancelModal = useCallback(() => {
    setSelectedObservasjon(undefined)
    onCancel()
  }, [setSelectedObservasjon, onCancel])

  const onAttachToObservasjon = () => {
    if (alreadyAttachedImages.length > 0) {
      singleToast.showToast(alreadyAttachedImagesToObservasjon())
      return
    }

    if (selectedObservasjon) {
      onAttach(selectedObservasjon)
    }
  }

  const title = `Knytt ${selectedImages.length} valgte bilder til en observasjon`

  return (
    <ModalWrapper
      fullscreenMobile
      isOpen={isOpen}
      title={title}
      onCancel={onCancelModal}
    >
      <Column padding={modalContentPaddingLarge}>
        <Row>
          <Column fluid>
            {observasjoner?.length === 0 && (
              <Text>Det er ingen observasjoner på kvitteringen</Text>
            )}

            {observasjoner?.map(
              ({ tekst, id }: IObservasjon, index: number) => {
                const observasjonText =
                  tekst.length > 0 ? tekst : 'Observasjon mangler beskrivelse'

                return (
                  <Row
                    justify="space-between"
                    key={id}
                    padding={2}
                    backgroundColor={
                      index % 2 === 0 ? theme.white : theme.gray7
                    }
                    align="center"
                  >
                    <RowItem>
                      <Text whiteSpace="pre-wrap">{observasjonText}</Text>
                    </RowItem>
                    <RowItem>
                      <Button
                        onClick={() => {
                          onSelectObservasjon(id)
                        }}
                        secondary={selectedObservasjon !== id}
                      >
                        Velg
                      </Button>
                    </RowItem>
                  </Row>
                )
              }
            )}
          </Column>
        </Row>
        {alreadyAttachedImages.length > 0 && (
          <Row margin={[2, 0, 0, 0]}>
            <Text>{alreadyAttachedBilderDescription}</Text>
          </Row>
        )}
      </Column>
      <ModalButtonsContainer>
        <ModalButton secondary onClick={onCancelModal}>
          Avbryt
        </ModalButton>
        <ModalButton
          disabled={isAttachButtonDisabled}
          onClick={onAttachToObservasjon}
        >
          Knytt
        </ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>
  )
}
