import React, { ChangeEvent, useCallback, useState } from 'react'
import { InputField, Row, RowItem, IconButton, Cross } from '@mattilsynet/mt-ui'
import './style.css'
import { IDeltaker } from '../../types'

interface IDeltakerItemProps {
  onUpdateDeltaker: (deltaker: IDeltaker) => void
  deltaker: IDeltaker
  onRemoveDeltaker: (deltaker: IDeltaker) => void
  autoFocus?: boolean
  danger: boolean
  disabled?: boolean
}
export const DeltakerItem = ({
  onUpdateDeltaker,
  deltaker,
  onRemoveDeltaker,
  autoFocus,
  danger,
  disabled,
}: IDeltakerItemProps) => {
  const [deltakerNavn, setDeltakerNavn] = useState<string | undefined>(
    undefined
  )

  const onChangeDeltaker = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDeltakerNavn(event.target.value)
      onUpdateDeltaker({
        ...deltaker,
        navn: event.currentTarget.value,
      })
    },
    [deltaker, onUpdateDeltaker]
  )

  const onRemove = useCallback(
    () => onRemoveDeltaker(deltaker),
    [deltaker, onRemoveDeltaker]
  )

  const errorText = danger && !deltaker.navn ? 'Deltaker mangler' : ''

  return (
    <Row className="deltaker-item-text-area">
      <RowItem flex={1}>
        <InputField
          value={deltakerNavn ?? deltaker.navn}
          placeholder="Person som var til stede"
          onChange={onChangeDeltaker}
          type="text"
          autoFocus={autoFocus}
          status={Boolean(errorText) ? 'danger' : undefined}
          errorText={errorText}
          aria-label="Person som var til stede"
          disabled={disabled}
        />
      </RowItem>
      <IconButton
        onClick={onRemove}
        icon={<Cross innerColor="white" />}
        className="deltaker-item-close-icon"
        ariaLabel="Person som var til stede"
        disabled={disabled}
      />
    </Row>
  )
}
