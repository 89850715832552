import { ERROR, IKvitteringState, OK, SAVING } from '../types'
import { tilsynsobjektActionTypes } from './actions'
import { ITilsynsobjekt } from './types'
import { IVirksomhetTilsynsobjekt } from '../../virksomhet-search/types'

export const tilsynsobjektReducer = {
  [tilsynsobjektActionTypes.CREATE_TILSYNSOBJEKT]: (
    state: IKvitteringState
  ) => ({
    ...state,
    error: '',
    selectedKvittering: {
      ...state.selectedKvittering,
      status: SAVING,
    },
  }),
  [tilsynsobjektActionTypes.CREATE_TILSYNSOBJEKT_SUCCESS]: (
    state: IKvitteringState,
    action: { tilsynsobjekt: IVirksomhetTilsynsobjekt; orgNr: string }
  ) => ({
    ...state,
    error: '',
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            virksomhetsOrgNr: action.orgNr,
            virksomhetsNavn: action.tilsynsobjekt.virksomhetsnavn,
            tilsynsobjekter: [
              ...state.selectedKvittering.data.tilsynsobjekter,
              action.tilsynsobjekt,
            ],
          }
        : k
    ),
    selectedKvittering: {
      status: OK,
      data: {
        ...state.selectedKvittering.data,
        virksomhetsOrgNr: action.orgNr,
        virksomhetsNavn: action.tilsynsobjekt.virksomhetsnavn,
        tilsynsobjekter: [
          ...state.selectedKvittering.data.tilsynsobjekter,
          action.tilsynsobjekt,
        ],
      },
    },
  }),
  [tilsynsobjektActionTypes.CREATE_TILSYNSOBJEKT_FAILED]: (
    state: IKvitteringState,
    action: { tilsynsobjekt: IVirksomhetTilsynsobjekt; error: string }
  ) => ({
    ...state,
    error: action.error,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
              (tilsynsobjekt) =>
                tilsynsobjekt.idstring === action.tilsynsobjekt.idstring
                  ? {
                      ...action.tilsynsobjekt,
                      isSavedLocally: true,
                    }
                  : tilsynsobjekt
            ),
            virksomhetsNavn:
              state.selectedKvittering.data.tilsynsobjekter.length === 0
                ? ''
                : state.selectedKvittering.data.virksomhetsNavn,
          }
        : k
    ),
    selectedKvittering: {
      status: ERROR,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (tilsynsobjekt) =>
            tilsynsobjekt.idstring === action.tilsynsobjekt.idstring
              ? {
                  ...action.tilsynsobjekt,
                  isSavedLocally: true,
                }
              : tilsynsobjekt
        ),
        virksomhetsNavn:
          state.selectedKvittering.data.tilsynsobjekter.length === 0
            ? ''
            : state.selectedKvittering.data.virksomhetsNavn,
      },
    },
  }),
  [tilsynsobjektActionTypes.DELETE_TILSYNSOBJEKT]: (
    state: IKvitteringState
  ) => ({
    ...state,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
          }
        : k
    ),
    selectedKvittering: {
      status: SAVING,
      data: {
        ...state.selectedKvittering.data,
      },
    },
  }),
  [tilsynsobjektActionTypes.DELETE_TILSYNSOBJEKT_SUCCESS]: (
    state: IKvitteringState,
    action: { tilsynsobjekt: ITilsynsobjekt }
  ) => {
    const { virksomhetsNavn } = action.tilsynsobjekt
    const newVirksomhetsNavn =
      state.selectedKvittering.data.tilsynsobjekter.length === 1 &&
      !action.tilsynsobjekt.midlertidig
        ? ''
        : action.tilsynsobjekt.midlertidig
          ? state.selectedKvittering.data.tilsynsobjekter.length === 1
            ? ''
            : state.selectedKvittering.data.virksomhetsNavn
          : virksomhetsNavn
    return {
      ...state,
      error: '',
      kvitteringer: state.kvitteringer.map((k) =>
        k.id === state.selectedKvittering.data.id
          ? {
              ...k,
              virksomhetsOrgNr:
                state.selectedKvittering.data.tilsynsobjekter.length === 1
                  ? ''
                  : state.selectedKvittering.data.virksomhetsOrgNr,
              virksomhetsNavn: newVirksomhetsNavn,
              tilsynsobjekter:
                state.selectedKvittering.data.tilsynsobjekter.length === 1
                  ? []
                  : state.selectedKvittering.data.tilsynsobjekter.filter(
                      (t) => t.idstring !== action.tilsynsobjekt.idstring
                    ),
              kontrollpunktGrupper:
                state.selectedKvittering.data.tilsynsobjekter.length === 1
                  ? []
                  : state.selectedKvittering.data.kontrollpunktGrupper,
            }
          : k
      ),
      selectedKvittering: {
        status: OK,
        data: {
          ...state.selectedKvittering.data,
          virksomhetsOrgNr:
            state.selectedKvittering.data.tilsynsobjekter.length === 1
              ? ''
              : state.selectedKvittering.data.virksomhetsOrgNr,
          virksomhetsNavn: newVirksomhetsNavn,
          tilsynsobjekter:
            state.selectedKvittering.data.tilsynsobjekter.length === 1
              ? []
              : state.selectedKvittering.data.tilsynsobjekter.filter(
                  (t) => t.idstring !== action.tilsynsobjekt.idstring
                ),
          kontrollpunktGrupper:
            state.selectedKvittering.data.tilsynsobjekter.length === 1
              ? []
              : state.selectedKvittering.data.kontrollpunktGrupper,
        },
      },
    }
  },
  [tilsynsobjektActionTypes.DELETE_TILSYNSOBJEKT_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    error: action.error,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
          }
        : k
    ),
    selectedKvittering: {
      status: ERROR,
      data: {
        ...state.selectedKvittering.data,
      },
    },
  }),

  [tilsynsobjektActionTypes.CLEAR_SELECTED_TILSYNSOBJEKTER]: (
    state: IKvitteringState
  ) => ({
    ...state,
  }),

  [tilsynsobjektActionTypes.FETCH_TILSYNSOBJEKTER]: (
    state: IKvitteringState,
    action: { loading: boolean }
  ) => ({
    ...state,
    loading: action.loading,
    error: '',
  }),
  [tilsynsobjektActionTypes.FETCH_TILSYNSOBJEKTER_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
    selectedKvittering: {
      ...state.selectedKvittering,
      status: ERROR,
    },
  }),
  [tilsynsobjektActionTypes.FETCH_TILSYNSOBJEKTER_SUCCESS]: (
    state: IKvitteringState,
    action: { tilsynsobjekter: ITilsynsobjekt[] }
  ) => ({
    ...state,
    loading: false,
    error: '',
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            tilsynsobjekter: action.tilsynsobjekter.map(
              (tilsynsobjekt: ITilsynsobjekt) => {
                return {
                  ...tilsynsobjekt,
                  idstring: tilsynsobjekt.tilsynsobjektId,
                  selected: true,
                  isSavedLocally: false,
                  virksomhetsNavn:
                    state.selectedKvittering.data.virksomhetsNavn,
                  kontrollpunkter: tilsynsobjekt.kontrollpunkter.map(
                    (kontrollpunkt) => ({
                      ...kontrollpunkt,
                      synced: window.navigator.onLine,
                    })
                  ),
                }
              }
            ),
          }
        : k
    ),
    selectedKvittering: {
      status: OK,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: action.tilsynsobjekter.map(
          (tilsynsobjekt: ITilsynsobjekt) => {
            return {
              ...tilsynsobjekt,
              idstring: tilsynsobjekt.tilsynsobjektId,
              selected: true,
              isSavedLocally: false,
              virksomhetsNavn: state.selectedKvittering.data.virksomhetsNavn,
            }
          }
        ),
      },
    },
  }),
  [tilsynsobjektActionTypes.SET_CURRENT_TILSYNSOBJEKT]: (
    state: IKvitteringState,
    action: { tilsynsobjekt: ITilsynsobjekt }
  ) => ({
    ...state,
    loading: false, // TODO: Check if we need this. We might not need it.
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: action.tilsynsobjekt,
    },
  }),

  [tilsynsobjektActionTypes.UPDATE_TILSYNSOBJEKT]: (
    state: IKvitteringState
  ) => ({
    ...state,
    loading: true,
  }),
  [tilsynsobjektActionTypes.UPDATE_TILSYNSOBJEKT_SUCCESS]: (
    state: IKvitteringState,
    action: { tilsynsobjekt: ITilsynsobjekt }
  ) => ({
    ...state,
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (t) =>
            t.id === action.tilsynsobjekt.id
              ? {
                  ...t,
                  ...action.tilsynsobjekt,
                }
              : t
        ),
      },
    },
  }),
  [tilsynsobjektActionTypes.UPDATE_TILSYNSOBJEKT_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
}
