import React from 'react'
import { InformationBox } from '../information-box'
import { Text } from '@mattilsynet/mt-ui'

export const KjaeledyrholdWarning = () => {
  const warningDescription = `Denne tilsynskvitteringen gjelder et privat kjæledyrhold. For at
  vi ikke skal bryte vår taushetsplikt er skjerming og UO satt
  automatisk.
  
  `

  return (
    <InformationBox variant="warning">
      <Text whiteSpace="pre-line">{warningDescription}</Text>
      <Text>
        <b>NB!</b> om saken er opprettet i arkivet eller Mats må du huske å
        forsikre deg om at personens navn i sakstittel også er skjermet.
      </Text>
    </InformationBox>
  )
}
