import React, { ChangeEvent, useEffect, useState } from 'react'
import { Row, RowItem, TextArea } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../../components/modal-wrapper'
import './style.css'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { ModalButton } from '../../components/modal-button'
import { modalContentPadding } from '../../components/modal-buttons-container/constants'

interface IBegrunnelseModalProps {
  isOpen: boolean
  onCancel: () => void
  begrunnelse?: string
  onUpdate: (string) => void
  hasContent: boolean
}
export const BegrunnelseModal = ({
  isOpen,
  onCancel,
  begrunnelse,
  onUpdate,
  hasContent,
}: IBegrunnelseModalProps) => {
  const [isInputDirty, setIsInputDirty] = useState(() => false)
  const [hasContentConstant] = useState(hasContent)

  const onUpdateBegrunnelse = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onUpdate(event.currentTarget.value)
  }

  const onSave = () => {
    if (Boolean(begrunnelse)) {
      onCancel()
    } else {
      setIsInputDirty(true)
      onCancel()
    }
  }

  const addOrEditText = () =>
    hasContentConstant ? 'Endre utdypning' : 'Legg til utdypning'

  useEffect(() => {
    Boolean(begrunnelse) && setIsInputDirty(true)
  }, [begrunnelse])

  return (
    <>
      <ModalWrapper
        fullscreenMobile
        isOpen={isOpen}
        onCancel={onSave}
        title={addOrEditText()}
      >
        <Row
          padding={modalContentPadding}
          className="begrunnelse-item-text-area"
        >
          <RowItem flex={1}>
            <TextArea
              onChange={onUpdateBegrunnelse}
              placeholder={'Utdypning av begrunnelse'}
              value={begrunnelse || ''}
              label={'Utdypning'}
              danger={!Boolean(begrunnelse) && isInputDirty}
              errorText={
                !Boolean(begrunnelse) && isInputDirty ? 'Utdypning mangler' : ''
              }
            />
          </RowItem>
        </Row>
        <ModalButtonsContainer>
          <ModalButton secondary onClick={onSave}>
            {addOrEditText()}
          </ModalButton>
        </ModalButtonsContainer>
      </ModalWrapper>
    </>
  )
}
