import { ERROR, IKvitteringState, OK, SAVING } from '../types'
import { veiledningActionTypes } from './actions'
import { IVeiledning } from './types'

export const veiledningReducer = {
  [veiledningActionTypes.UPDATE_VEILEDNINGER]: (state: IKvitteringState) => ({
    ...state,
    loading: true,
    error: '',
  }),
  [veiledningActionTypes.UPDATE_VEILEDNINGER_SUCCESS]: (
    state: IKvitteringState
  ) => ({
    ...state,
    loading: false,
  }),
  [veiledningActionTypes.UPDATE_VEILEDNINGER_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [veiledningActionTypes.FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT_SUCCESS]: (
    state: IKvitteringState,
    action: { veiledninger: IVeiledning[] }
  ) => ({
    ...state,
    error: '',
    loading: false,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
              (t) =>
                t.tilsynsobjektId ===
                state.selectedTilsynsobjekt.data.tilsynsobjektId
                  ? {
                      ...t,
                      veiledninger: action.veiledninger,
                    }
                  : t
            ),
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (t) =>
            t.tilsynsobjektId ===
            state.selectedTilsynsobjekt.data.tilsynsobjektId
              ? {
                  ...t,
                  veiledninger: action.veiledninger,
                }
              : t
        ),
      },
    },
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: {
        ...state.selectedTilsynsobjekt.data,
        veiledninger: action.veiledninger,
      },
    },
  }),
  [veiledningActionTypes.FETCH_VEILEDNINGER_BY_TILSYNSOBJEKT_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    loading: false,
    error: action.error,
  }),
  [veiledningActionTypes.UPDATE_VEILEDNING]: (
    state: IKvitteringState,
    action: { veiledning: IVeiledning }
  ) => ({
    ...state,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            synced: window.navigator.onLine,
            tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
              (t) =>
                t.tilsynsobjektId ===
                state.selectedTilsynsobjekt.data.tilsynsobjektId
                  ? {
                      ...t,
                      veiledninger:
                        state.selectedTilsynsobjekt.data.veiledninger &&
                        state.selectedTilsynsobjekt.data.veiledninger.map(
                          (veiledning: IVeiledning) =>
                            (
                              veiledning._links?.self.href
                                ? veiledning._links?.self.href ===
                                  action.veiledning._links?.self.href
                                : veiledning.id === action.veiledning.id
                            )
                              ? action.veiledning
                              : veiledning
                        ),
                    }
                  : t
            ),
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (t) =>
            t.tilsynsobjektId ===
            state.selectedTilsynsobjekt.data.tilsynsobjektId
              ? {
                  ...t,
                  veiledninger:
                    state.selectedTilsynsobjekt.data.veiledninger &&
                    state.selectedTilsynsobjekt.data.veiledninger.map(
                      (veiledning: IVeiledning) =>
                        (
                          veiledning._links?.self.href
                            ? veiledning._links?.self.href ===
                              action.veiledning._links?.self.href
                            : veiledning.id === action.veiledning.id
                        )
                          ? action.veiledning
                          : veiledning
                    ),
                }
              : t
        ),
      },
    },
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      status: SAVING,
      data: {
        ...state.selectedTilsynsobjekt.data,
        veiledninger:
          state.selectedTilsynsobjekt.data.veiledninger &&
          state.selectedTilsynsobjekt.data.veiledninger.map(
            (veiledning: IVeiledning) =>
              (
                veiledning._links?.self.href
                  ? veiledning._links?.self.href ===
                    action.veiledning._links?.self.href
                  : veiledning.id === action.veiledning.id
              )
                ? action.veiledning
                : veiledning
          ),
      },
    },
  }),
  [veiledningActionTypes.UPDATE_VEILEDNING_SUCCESS]: (
    state: IKvitteringState
  ) => ({
    ...state,
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: {
        ...state.selectedTilsynsobjekt.data,
      },
      status: OK,
    },
    error: '',
  }),
  [veiledningActionTypes.UPDATE_VEILEDNING_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: {
        ...state.selectedTilsynsobjekt.data,
      },
      status: ERROR,
    },
    error: action.error,
  }),
  [veiledningActionTypes.DELETE_LOCAL_VEILEDNING]: (
    state: IKvitteringState,
    action: { veiledning: IVeiledning }
  ) => ({
    ...state,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
              (t) =>
                t.tilsynsobjektId ===
                state.selectedTilsynsobjekt.data.tilsynsobjektId
                  ? {
                      ...t,
                      veiledninger:
                        state.selectedTilsynsobjekt.data.veiledninger?.filter(
                          (veiledning: IVeiledning) =>
                            veiledning.id !== action.veiledning.id
                        ),
                    }
                  : t
            ),
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (t) =>
            t.tilsynsobjektId ===
            state.selectedTilsynsobjekt.data.tilsynsobjektId
              ? {
                  ...t,
                  veiledninger:
                    state.selectedTilsynsobjekt.data.veiledninger?.filter(
                      (veiledning: IVeiledning) =>
                        veiledning.id !== action.veiledning.id
                    ),
                }
              : t
        ),
      },
    },
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: {
        ...state.selectedTilsynsobjekt.data,
        veiledninger: state.selectedTilsynsobjekt.data.veiledninger?.filter(
          (veiledning: IVeiledning) => veiledning.id !== action.veiledning.id
        ),
      },
    },
  }),
  [veiledningActionTypes.DELETE_VEILEDNING_SUCCESS]: (
    state: IKvitteringState,
    action: { veiledning: IVeiledning }
  ) => ({
    ...state,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            synced: window.navigator.onLine,
            tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
              (t) =>
                t.tilsynsobjektId ===
                state.selectedTilsynsobjekt.data.tilsynsobjektId
                  ? {
                      ...t,
                      veiledninger:
                        state.selectedTilsynsobjekt.data.veiledninger?.filter(
                          (veiledning: IVeiledning) =>
                            veiledning._links.self.href !==
                            action.veiledning._links.self.href
                        ),
                    }
                  : t
            ),
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (t) =>
            t.tilsynsobjektId ===
            state.selectedTilsynsobjekt.data.tilsynsobjektId
              ? {
                  ...t,
                  veiledninger:
                    state.selectedTilsynsobjekt.data.veiledninger?.filter(
                      (veiledning: IVeiledning) =>
                        veiledning._links.self.href !==
                        action.veiledning._links.self.href
                    ),
                }
              : t
        ),
      },
    },
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: {
        ...state.selectedTilsynsobjekt.data,
        veiledninger: state.selectedTilsynsobjekt.data.veiledninger?.filter(
          (veiledning: IVeiledning) =>
            veiledning._links.self.href !== action.veiledning._links.self.href
        ),
      },
    },
  }),
  [veiledningActionTypes.DELETE_VEILEDNING_FAILED]: (
    state: IKvitteringState,
    action: { error: string }
  ) => ({
    ...state,
    error: action.error,
  }),
  [veiledningActionTypes.CREATE_LOCAL_VEILEDNING]: (
    state: IKvitteringState,
    action: { veiledning: IVeiledning }
  ) => ({
    ...state,
    error: '',
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
              (t) =>
                t.tilsynsobjektId ===
                state.selectedTilsynsobjekt.data.tilsynsobjektId
                  ? {
                      ...t,
                      veiledninger: [
                        ...(state.selectedTilsynsobjekt.data.veiledninger ||
                          []),
                        action.veiledning,
                      ],
                    }
                  : t
            ),
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (t) =>
            t.tilsynsobjektId ===
            state.selectedTilsynsobjekt.data.tilsynsobjektId
              ? {
                  ...t,
                  veiledninger: [
                    ...(state.selectedTilsynsobjekt.data.veiledninger || []),
                    action.veiledning,
                  ],
                }
              : t
        ),
      },
    },
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: {
        ...state.selectedTilsynsobjekt.data,
        veiledninger: [
          ...(state.selectedTilsynsobjekt.data.veiledninger || []),
          action.veiledning,
        ],
      },
    },
  }),
  [veiledningActionTypes.UPDATE_LOCAL_VEILEDNING]: (
    state: IKvitteringState,
    action: { veiledning: IVeiledning }
  ) => ({
    ...state,
    error: '',
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
              (t) =>
                t.tilsynsobjektId ===
                state.selectedTilsynsobjekt.data.tilsynsobjektId
                  ? {
                      ...t,
                      veiledninger:
                        state?.selectedTilsynsobjekt.data?.veiledninger?.map(
                          (veiledning: IVeiledning) =>
                            veiledning.id === action.veiledning.id
                              ? {
                                  ...veiledning,
                                  tekst: action.veiledning.tekst,
                                }
                              : veiledning
                        ),
                    }
                  : t
            ),
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (t) =>
            t.tilsynsobjektId ===
            state.selectedTilsynsobjekt.data.tilsynsobjektId
              ? {
                  ...t,
                  veiledninger:
                    state?.selectedTilsynsobjekt.data?.veiledninger?.map(
                      (veiledning: IVeiledning) =>
                        veiledning.id === action.veiledning.id
                          ? {
                              ...veiledning,
                              tekst: action.veiledning.tekst,
                            }
                          : veiledning
                    ),
                }
              : t
        ),
      },
    },
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: {
        ...state.selectedTilsynsobjekt.data,
        veiledninger: state?.selectedTilsynsobjekt.data?.veiledninger?.map(
          (veiledning: IVeiledning) =>
            veiledning.id === action.veiledning.id
              ? {
                  ...veiledning,
                  tekst: action.veiledning.tekst,
                }
              : veiledning
        ),
      },
    },
  }),
  [veiledningActionTypes.CREATE_VEILEDNING]: (
    state: IKvitteringState,
    action: { veiledning: IVeiledning }
  ) => ({
    ...state,
    error: '',
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            synced: window.navigator.onLine,
            tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
              (t) =>
                t.tilsynsobjektId ===
                state.selectedTilsynsobjekt.data.tilsynsobjektId
                  ? {
                      ...t,
                      veiledninger:
                        state?.selectedTilsynsobjekt.data?.veiledninger?.map(
                          (veiledning: IVeiledning) =>
                            veiledning.id === action.veiledning.id
                              ? {
                                  ...action.veiledning,
                                  isCreating: true,
                                }
                              : veiledning
                        ),
                    }
                  : t
            ),
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (t) =>
            t.tilsynsobjektId ===
            state.selectedTilsynsobjekt.data.tilsynsobjektId
              ? {
                  ...t,
                  veiledninger:
                    state?.selectedTilsynsobjekt.data?.veiledninger?.map(
                      (veiledning: IVeiledning) =>
                        veiledning.id === action.veiledning.id
                          ? {
                              ...action.veiledning,
                              isCreating: true,
                            }
                          : veiledning
                    ),
                }
              : t
        ),
      },
    },
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: {
        ...state.selectedTilsynsobjekt.data,
        veiledninger: state?.selectedTilsynsobjekt.data?.veiledninger?.map(
          (veiledning: IVeiledning) =>
            veiledning.id === action.veiledning.id
              ? {
                  ...action.veiledning,
                  isCreating: true,
                }
              : veiledning
        ),
      },
    },
  }),
  [veiledningActionTypes.CREATE_VEILEDNING_SUCCESS]: (
    state: IKvitteringState,
    action: { veiledning: IVeiledning }
  ) => ({
    ...state,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
              (t) =>
                t.tilsynsobjektId ===
                state.selectedTilsynsobjekt.data.tilsynsobjektId
                  ? {
                      ...t,
                      veiledninger:
                        state.selectedTilsynsobjekt.data.veiledninger &&
                        state.selectedTilsynsobjekt.data.veiledninger.map(
                          (veiledning: IVeiledning) =>
                            veiledning.id === action.veiledning.id
                              ? {
                                  ...action.veiledning,
                                  isSavedLocally: false,
                                  tekst: veiledning.tekst,
                                }
                              : veiledning
                        ),
                    }
                  : t
            ),
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (t) =>
            t.tilsynsobjektId ===
            state.selectedTilsynsobjekt.data.tilsynsobjektId
              ? {
                  ...t,
                  veiledninger:
                    state.selectedTilsynsobjekt.data.veiledninger &&
                    state.selectedTilsynsobjekt.data.veiledninger.map(
                      (veiledning: IVeiledning) =>
                        veiledning.id === action.veiledning.id
                          ? {
                              ...action.veiledning,
                              isSavedLocally: false,
                              tekst: veiledning.tekst,
                            }
                          : veiledning
                    ),
                }
              : t
        ),
      },
    },
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: {
        ...state.selectedTilsynsobjekt.data,
        veiledninger:
          state.selectedTilsynsobjekt.data.veiledninger &&
          state.selectedTilsynsobjekt.data.veiledninger.map(
            (veiledning: IVeiledning) =>
              veiledning.id === action.veiledning.id
                ? {
                    ...action.veiledning,
                    isSavedLocally: false,
                    tekst: veiledning.tekst,
                  }
                : veiledning
          ),
      },
    },
    error: '',
  }),
  [veiledningActionTypes.CREATE_VEILEDNING_FAILED]: (
    state: IKvitteringState,
    action: { veiledning: IVeiledning; error: string }
  ) => ({
    ...state,
    kvitteringer: state.kvitteringer.map((k) =>
      k.id === state.selectedKvittering.data.id
        ? {
            ...k,
            tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
              (t) =>
                t.tilsynsobjektId ===
                state.selectedTilsynsobjekt.data.tilsynsobjektId
                  ? {
                      ...t,
                      veiledninger:
                        state.selectedTilsynsobjekt.data.veiledninger &&
                        state.selectedTilsynsobjekt.data.veiledninger.map(
                          (veiledning: IVeiledning) =>
                            veiledning.id === action.veiledning.id
                              ? {
                                  ...action.veiledning,
                                  isSavedLocally: true,
                                }
                              : veiledning
                        ),
                    }
                  : t
            ),
          }
        : k
    ),
    selectedKvittering: {
      ...state.selectedKvittering,
      data: {
        ...state.selectedKvittering.data,
        tilsynsobjekter: state.selectedKvittering.data.tilsynsobjekter.map(
          (t) =>
            t.tilsynsobjektId ===
            state.selectedTilsynsobjekt.data.tilsynsobjektId
              ? {
                  ...t,
                  veiledninger:
                    state.selectedTilsynsobjekt.data.veiledninger &&
                    state.selectedTilsynsobjekt.data.veiledninger.map(
                      (veiledning: IVeiledning) =>
                        veiledning.id === action.veiledning.id
                          ? {
                              ...action.veiledning,
                              isSavedLocally: true,
                            }
                          : veiledning
                    ),
                }
              : t
        ),
      },
    },
    selectedTilsynsobjekt: {
      ...state.selectedTilsynsobjekt,
      data: {
        ...state.selectedTilsynsobjekt.data,
        veiledninger:
          state.selectedTilsynsobjekt.data.veiledninger &&
          state.selectedTilsynsobjekt.data.veiledninger.map(
            (veiledning: IVeiledning) =>
              veiledning.id === action.veiledning.id
                ? {
                    ...action.veiledning,
                    isSavedLocally: true,
                  }
                : veiledning
          ),
      },
    },
    error: action.error,
  }),
}
