import { Checkmark, ThemeContext, Row, Cross } from '@mattilsynet/mt-ui'
import React, { useContext } from 'react'
import './styles.css'
import { ERROR, SAVING, NONE, OK } from '../../ducks/kvittering/types'

interface IStatusProps {
  status: any
  error: string
  isLoading: boolean
}

export const Status = ({ status, error, isLoading }: IStatusProps) => {
  const theme = useContext(ThemeContext)
  const renderSavingStatus = () => {
    if (status === NONE) {
      return undefined
    }

    if (status === OK) {
      return (
        <>
          <Checkmark color={theme.signalGreen} />
          Lagret
        </>
      )
    }

    // TODO: Create X icon in mt-ui (line 32)
    if (error || status === ERROR) {
      return (
        <>
          <Cross color="white" innerColor="red" />
          Siste endring ble ikke lagret
        </>
      )
    }

    if (isLoading || SAVING) {
      return 'Lagrer...'
    }

    return undefined
  }
  return (
    <Row
      justify="center"
      id="bottom-status"
      backgroundColor={theme.white}
      padding={1}
      align="center"
    >
      {renderSavingStatus()}
    </Row>
  )
}
