// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-display {
  flex-wrap: wrap;
}

.date-display__title {
  flex-basis: 100%;
}

.date-display__pickers {
  margin: 0.5rem 0;
}

.date-display__pickers--row {
  border: 1px solid var(--text-color);
  border-radius: 0.25rem;
  cursor: pointer;
}

.date-display__has-end-date label {
  width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/tilsynsdato-picker/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mCAAmC;EACnC,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".date-display {\n  flex-wrap: wrap;\n}\n\n.date-display__title {\n  flex-basis: 100%;\n}\n\n.date-display__pickers {\n  margin: 0.5rem 0;\n}\n\n.date-display__pickers--row {\n  border: 1px solid var(--text-color);\n  border-radius: 0.25rem;\n  cursor: pointer;\n}\n\n.date-display__has-end-date label {\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
