import { ThemeContext } from '@mattilsynet/mt-ui'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'
import React, { ReactNode, useContext } from 'react'
import styled from 'styled-components'

interface INavigatorSelectionBorderProps {
  active: boolean
  goTo?: () => void
  children: ReactNode
}

interface IStyledNavigatorSelectionBorder {
  active: boolean
  theme: IThemeColors
}

const StyledNavigatorSelectionBorder = styled.div<IStyledNavigatorSelectionBorder>`
  & {
    cursor: pointer;
    padding: 0 0.75rem;
    background-color: ${({ active }) => (active ? 'var(--navigator)' : '')};
    color: ${({ active, theme }) => (active ? theme.white : '')};
    border-radius: 1.5rem;
  }
`

export const NavigatorSelectionBorder = ({
  active,
  children,
  goTo,
}: INavigatorSelectionBorderProps) => {
  const theme = useContext(ThemeContext)

  return (
    <StyledNavigatorSelectionBorder
      active={active}
      theme={theme}
      onClick={goTo}
    >
      {children}
    </StyledNavigatorSelectionBorder>
  )
}
