import { CommonReduxTypes, commonRedux } from '@mattilsynet/mt-common'
import { IStoreState } from '../../reducers/types'
import { BildeSizes } from './types'
import { produce } from 'immer'

interface SetBildeUrlPayload {
  kvitteringId: string
  bildeId: string
  url: string
  size: BildeSizes
}

interface RemoveBildeUrlPayload {
  kvitteringId: string
  bildeId: string
}

/**
 * Structure: { \[kvitteringId\]: { \[bildeId\]: { ... } } }
 */
export type BildeState = Record<
  string, // kvitteringId
  Record<
    string, // bildeId
    {
      urlData?: { size: BildeSizes; url: string }
    }
  >
>

export { bildeActions, bildeReducer, bildeSelectors }

const STORE_NAME = 'bilder'

const bildeActionTypes = {
  REPLACE_BILDE_URL: `${STORE_NAME}/REPLACE_BILDE_URL` as const,
  REMOVE_BILDE_URL: `${STORE_NAME}/REMOVE_BILDE_URL` as const,
  SET_UPLOAD_STATUS: `${STORE_NAME}/SET_UPLOAD_STATUS` as const,
} as const

const bildeActions = {
  setBildeUrl: ({ kvitteringId, bildeId, url, size }: SetBildeUrlPayload) => ({
    type: bildeActionTypes.REPLACE_BILDE_URL,
    kvitteringId,
    bildeId,
    url,
    size,
  }),
  removeBildeUrl: ({ kvitteringId, bildeId }: RemoveBildeUrlPayload) => ({
    type: bildeActionTypes.REMOVE_BILDE_URL,
    kvitteringId,
    bildeId,
  }),
}

type BildeActions = CommonReduxTypes.IGetActionTypesFromObject<
  typeof bildeActions
>

const ensureState =
  (kvitteringId: string, bildeId: string) => (draft: BildeState) => {
    if (draft[kvitteringId] === undefined) {
      draft[kvitteringId] = {}
    }

    if (draft[kvitteringId][bildeId] === undefined) {
      draft[kvitteringId][bildeId] = {}
    }
  }

const bildeReducer = commonRedux.createReducer<BildeState, BildeActions>(
  {},
  {
    [bildeActionTypes.REPLACE_BILDE_URL]: (
      state,
      { kvitteringId, bildeId, url, size }
    ) =>
      produce(state, (draft) => {
        ensureState(kvitteringId, bildeId)(draft)

        draft[kvitteringId][bildeId].urlData = { size, url }
      }),
    [bildeActionTypes.REMOVE_BILDE_URL]: (state, { kvitteringId, bildeId }) =>
      produce(state, (draft) => {
        ensureState(kvitteringId, bildeId)(draft)

        delete draft[kvitteringId][bildeId].urlData
      }),
  }
)

const bildeSelectors = {
  selectBildeUrl:
    (kvitteringId: string, bildeId: string) => (state: IStoreState) =>
      state.bilder?.[kvitteringId]?.[bildeId]?.urlData,
}
