import {
  ErrorBox,
  LoadingSpinner,
  ThemeContext,
  Column,
} from '@mattilsynet/mt-ui'
import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { kvitteringSelectors } from '../../ducks/kvittering/selectors'
import { useTypedSelector } from '../../common/custom-hooks'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { IHentetKvittering } from '../../ducks/kvittering/types'
import { HentFraKollegaKvitteringDataListItem } from '../hent-fra-kollega-kvittering-data-list-item'
import { IKollega, IKvitteringToGet } from '../../modals/hent-fra-kollega-modal'

interface IHentFraKollegaKvitteringViewProps {
  kollega: IKollega
  onSelect: (kvitteringer: IKvitteringToGet[]) => void
  selectedKvitteringer: IKvitteringToGet[]
}

export const HentFraKollegaKvitteringView = ({
  kollega,
  onSelect,
  selectedKvitteringer,
}: IHentFraKollegaKvitteringViewProps) => {
  const dispatch = useDispatch()

  const theme = useContext(ThemeContext)

  const loadingStatus = useTypedSelector(
    kvitteringSelectors.getKvitteringerFraKollegaLoadingStatus
  )
  const kvitteringer = useTypedSelector<IHentetKvittering[]>(
    kvitteringSelectors.getKvitteringerFraKollega
  ).filter((kvittering) => !!kvittering.virksomhet)

  const fetchData = useCallback(() => {
    dispatch(
      kvitteringActions.fetchKvitteringerFraKollega(kollega?.username ?? '')
    )
  }, [dispatch, kollega])

  const onSelectKvittering = useCallback(
    (updatedKvittering: IKvitteringToGet) => {
      const kvitteringExists = selectedKvitteringer.find(
        (kvittering) => kvittering.id === updatedKvittering.id
      )

      if (kvitteringExists) {
        onSelect(
          selectedKvitteringer.filter(
            (kvittering) => kvittering.id !== updatedKvittering.id
          )
        )
        return
      }

      onSelect([...selectedKvitteringer, updatedKvittering])
    },
    [selectedKvitteringer, onSelect]
  )

  const isKvitteringSelected = (kvitteringId) =>
    !!selectedKvitteringer.find((kvittering) => kvittering.id === kvitteringId)

  useEffect(() => fetchData(), [fetchData])

  if (loadingStatus?.loading && !loadingStatus?.loaded) {
    return <LoadingSpinner title="" />
  }

  if (loadingStatus?.error) {
    return (
      <ErrorBox
        errorText="Kunne ikke hente kvitteringer"
        errorAction={fetchData}
        errorActionText="Prøv på nytt"
      />
    )
  }

  if (kvitteringer.length === 0) {
    return (
      <ErrorBox errorText={`Fant ingen kvitteringer fra ${kollega.name}`} />
    )
  }

  return (
    <Column as={'ul'}>
      {kvitteringer.map((kvittering, index) => (
        <HentFraKollegaKvitteringDataListItem
          kvitteringId={kvittering.id}
          virksomhetsNavn={kvittering.virksomhet.navn}
          orgNr={kvittering.virksomhet.orgNr}
          theme={index % 2 ? theme.white : theme.gray7}
          onSelect={onSelectKvittering}
          isSelected={isKvitteringSelected(kvittering.id)}
          key={kvittering.id}
          harBilder={kvittering.harBilder}
        />
      ))}
    </Column>
  )
}
