import { queryClient } from '../../../api/query-client'
import { BildeMutationOptions, IImage } from '../types'
import { bildeApi } from './bilde-api'
import {
  bildeKeys,
  metadataQueryHelpers,
  withPersistedBilde,
  withPersistedObservasjonId,
} from './helpers'
import { kvitteringQueryHelpers } from '../../kvitteringer/queries/helpers'
import { updateObservasjonBildeIdsFromKvittering } from '../cache-helpers'
import { setBildeUrl, toValidCoord } from '../metadata-helpers'
import { offlineLexicon } from '../../offline-lexicon'
import { Draft } from 'immer'
import { AxiosError } from 'axios'

/**
 * Optimistically updates bildeIds in the cache.
 *
 * @returns Previous bildeIds (Context that
 * is passed to onError).
 */
const onMutate: (
  updateBilder: (bildeIds: string[], bildeId: string) => string[],
  updateMetadata?: (
    draftMetadataList: Draft<IImage[]>,
    bildeMetadata: IImage
  ) => void
) => BildeMutationOptions['onMutate'] =
  (updateBilder, updateMetadata) =>
  async ({ kvitteringId, bilde, bildeId, observasjonId, bildeMetadata }) => {
    await kvitteringQueryHelpers.cancelKvitteringQuery(kvitteringId)

    kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
      updateObservasjonBildeIdsFromKvittering(
        draft,
        observasjonId,
        (bildeIds) => updateBilder(bildeIds, bildeId)
      )
    })

    if (bildeMetadata !== undefined && updateMetadata !== undefined) {
      metadataQueryHelpers.updateMetadataList(kvitteringId, (draft) =>
        updateMetadata(draft ?? [], {
          ...bildeMetadata,
          localData: { isOffline: true },
        })
      )
    }

    // Only on upload
    if (bilde !== undefined) {
      // Creates local image url to use in img tag
      setBildeUrl(kvitteringId, bildeId, bilde, 'local')
    }
  }

/** Displays error toast and reverts cache to previous bildeIds. */
const onError: BildeMutationOptions['onError'] = () => {
  /* ignore */
}

// Leaving it here for now, in case we need it later
/** Refetches kvittering and bildeMetadatas either on success or error. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onSettled: BildeMutationOptions['onSettled'] = (
  _,
  __,
  { kvitteringId }
) =>
  Promise.all([
    kvitteringQueryHelpers.invalidateKvittering(kvitteringId),
    queryClient.invalidateQueries({
      queryKey: bildeKeys.metadataList(kvitteringId),
    }),
  ])

// Upload to observasjon
queryClient.setMutationDefaults(bildeKeys.uploadToObservasjonBase(), {
  mutationFn: withPersistedObservasjonId(async (variables) => {
    try {
      const metadata = variables.bildeMetadata!

      await bildeApi.upload({
        bildeMetadata: {
          ...metadata,
          locationLatitude: toValidCoord(metadata.locationLatitude),
          locationLongitude: toValidCoord(metadata.locationLongitude),
        },
        bilde: variables.bilde!,
      })
    } catch (error) {
      // 409: image is already uploaded
      if (error instanceof AxiosError && error.response?.status !== 409) {
        throw error
      }
    }

    return bildeApi.attachToObservasjon(variables)
  }),
  onMutate: onMutate(
    (bildeIds, bildeId) => [...bildeIds, bildeId],
    (metadataList, bildeMetadata) => [...metadataList, bildeMetadata]
  ),
  onSuccess: (_, { kvitteringId, bildeId, bildeMetadata }) => {
    // Used to indicate that the image is uploaded
    offlineLexicon.set(
      offlineLexicon.types.bilde,
      bildeId,
      kvitteringId,
      bildeMetadata!
    )
  },
  onError: onError,
} satisfies BildeMutationOptions)

// Delete bilde from observasjon
// TODO Kan slettes når vi er sikre på at det ikke kommer flere mutasjoner med denne keyen
queryClient.setMutationDefaults(bildeKeys.deleteFromObservasjonBase(), {
  mutationFn: withPersistedBilde(
    withPersistedObservasjonId(async (variables) => {
      return bildeApi.delete(variables)
    })
  ),
  onMutate: onMutate(
    (bildeIds, bildeId) => bildeIds.filter((bilde) => bilde !== bildeId),
    (metadataList, bildeMetadata) =>
      metadataList.filter((metadata) => metadata.id !== bildeMetadata.id)
  ),
  onError: onError,
} satisfies BildeMutationOptions)
