import { CheckboxItem, Row, RowItem, Text } from '@mattilsynet/mt-ui'
import Textarea from '@mattilsynet/mt-ui/dist/elements/text-area'
import React, { ChangeEvent, useCallback } from 'react'
import { kvitteringActions } from '../../../ducks/kvittering/actions'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../common/custom-hooks'
import { createSelector } from 'reselect'
import { IStoreState } from '../../../reducers/types'

const selectSkjermetData = createSelector(
  (state: IStoreState) => state.kvittering.selectedKvittering.data,
  ({ mattilsynetSkjermet, mattilsynetSkjermetBegrunnelse }) => ({
    mattilsynetSkjermet,
    mattilsynetSkjermetBegrunnelse,
  })
)

const SkjulNavn = () => {
  const dispatch = useDispatch()
  const { mattilsynetSkjermet, mattilsynetSkjermetBegrunnelse } =
    useTypedSelector(selectSkjermetData)

  const onMattilsynetSkjermet = useCallback(() => {
    dispatch(
      kvitteringActions.updateCurrentKvittering({
        mattilsynetSkjermet: !mattilsynetSkjermet,
        mattilsynetSkjermetBegrunnelse: mattilsynetSkjermet
          ? mattilsynetSkjermetBegrunnelse
          : '',
      })
    )
  }, [mattilsynetSkjermet, mattilsynetSkjermetBegrunnelse, dispatch])

  const onMattilsynetSkjermetBegrunnelse = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(
        kvitteringActions.updateCurrentKvittering({
          mattilsynetSkjermetBegrunnelse: event.currentTarget.value,
        })
      )
    },
    [dispatch]
  )

  return (
    <>
      <Row>
        <CheckboxItem
          onClick={onMattilsynetSkjermet}
          selected={!!mattilsynetSkjermet}
        >
          <Text size="normal">
            Navn på deltakere fra Mattilsynet skal skjules i tilsynskvitteringen
          </Text>
        </CheckboxItem>
      </Row>

      {mattilsynetSkjermet && (
        <Row style={{ width: '97%' }}>
          <RowItem flex={1}>
            <Textarea
              onChange={onMattilsynetSkjermetBegrunnelse}
              placeholder="Forklaring"
              value={
                mattilsynetSkjermetBegrunnelse
                  ? mattilsynetSkjermetBegrunnelse
                  : ''
              }
              label="Forklaring på hvorfor navn skal skjules"
              errorText="Forklaringen vil ikke fremkomme i tilsynskvitteringen."
            />
          </RowItem>
        </Row>
      )}
    </>
  )
}

export default SkjulNavn
