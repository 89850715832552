import { ROOT_URL_TILSYNKVITTERING } from './../kvittering/epic'
import { ICommonApi } from '../../api'
import { concat, Observable, of, merge } from 'rxjs'
import { combineEpics, ofType, StateObservable } from 'redux-observable'
import { IStoreState } from '../../reducers/types'
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { dangerToast } from '../../common/toast'
import {
  arkiveringStatusActions,
  arkiveringStatusActionsTypes,
  updateSkjermedeOrdSuccess,
  fetchKvitteringArkivInfoSuccess,
  fetchKvitteringArkivInfoFailed,
  updateSkjermedeOrdFailed,
  fetchKvitteringArkivInfo,
} from './actions'
import { AnyAction } from 'redux'
import { UPDATE_SKJERMEDE_ORD, FETCH_KVITTERING_ARKIV_INFO } from './types'
import { ENDPOINT } from '../../constants'

const ROOT_URL = `${ENDPOINT.ARKIV}/arkiv/bilder/saksnummer`

export const fetchArkiveringStatus =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(arkiveringStatusActionsTypes.FETCH),
      withLatestFrom(state$),
      mergeMap(([{ aar, hoveddokumentId, sekvensnummer }, state]) => {
        const URL = `${ROOT_URL}/${aar}/${sekvensnummer}/hoveddokumentId/${hoveddokumentId}/arkivert`
        return of(state).pipe(
          commonApi.get(URL, state),
          map((resp) => resp._embedded?.archiveJobs || []),
          map((resp) => arkiveringStatusActions.fetchSuccess(resp)),
          catchError((err) => {
            return concat(
              of(arkiveringStatusActions.fetchFailed(err.message)),
              of(dangerToast('Kunne ikke hente arkiveringsstatus'))
            )
          })
        )
      })
    )

/**
 * Listens for redux action [arkivering-status/FETCH_KVITTERING_ARKIV_INFO]
 *
 * @category epic
 * @param commonApi to communicate with backend
 * @returns
 * - OK: [arkivering-status/FETCH_KVITTERING_ARKIV_INFO_SUCCESS, arkivering-status/UPDATE_CURRENT_KVITTERING_SUCCESS]
 * - FAIL: [arkivering-status/FETCH_KVITTERING_ARKIV_INFO_FAILED]
 */
export const fetchKvitteringArkivInfoEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(FETCH_KVITTERING_ARKIV_INFO),
      withLatestFrom(state$),
      mergeMap(([, state]) => {
        const id = state.kvittering.selectedKvittering.data.id
        const URL = `${ROOT_URL_TILSYNKVITTERING}/tilsynskvitteringer/${id}/arkivinfo`

        return of(state).pipe(
          commonApi.get(URL, state),
          map((payload) => fetchKvitteringArkivInfoSuccess(payload)),
          catchError((err) => {
            return concat(of(fetchKvitteringArkivInfoFailed(err.message)))
          })
        )
      })
    )

/**
 * Listens for redux action [arkivering-status/UPDATE_SKJERME_ORD]
 *
 * @category epic
 * @param commonApi to communicate with backend
 * @returns
 * - OK: [arkivering-status/FETCH_KVITTERING_ARKIV_INFO, current-kvittering/UPDATE_CURRENT_KVITTERING_SUCCESS]
 * - FAIL: [arkivering-status/UPDATE_SKJERME_ORD_FAILED]
 */
export const updateSkjermeTittelEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(UPDATE_SKJERMEDE_ORD),
      withLatestFrom(state$),
      mergeMap(([{ skjermedeOrd }, state]) => {
        const id = state.kvittering.selectedKvittering.data.id!
        const URL = `${ROOT_URL_TILSYNKVITTERING}/tilsynskvitteringer/${id}/arkivinfo`

        return of(state).pipe(
          commonApi.put(URL, state, {
            skjermedeOrd,
          }),
          mergeMap(() => {
            return merge(
              of(fetchKvitteringArkivInfo()),
              of(updateSkjermedeOrdSuccess())
            )
          }),
          catchError((err) => {
            return concat(of(updateSkjermedeOrdFailed(err.message)))
          })
        )
      })
    )

const fetchArkiveringStatusEpic = (commonApi: ICommonApi) =>
  combineEpics(
    fetchArkiveringStatus(commonApi),
    fetchKvitteringArkivInfoEpic(commonApi) as any,
    updateSkjermeTittelEpic(commonApi) as any
  )

export default fetchArkiveringStatusEpic
