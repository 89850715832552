import React from 'react'
import { IStoreState } from '../../../reducers/types'
import { useSelector, useDispatch } from 'react-redux'
import {
  Column,
  Row,
  LoadingSpinner,
  Text,
  ContrastBox,
} from '@mattilsynet/mt-ui'
import '../style.css'
import { IKvitteringData } from '../../../ducks/kvittering/types'
import { kvitteringActions } from '../../../ducks/kvittering/actions'
import { MobilenumberItem } from '../../../components/mobilenumber-item'
import {
  AndreDeltakere,
  MattilsynetDeltakere,
  VirksomhetDeltakere,
} from '../../../features/deltakere'
import { InformationBox } from '../../../components/information-box'
import BreakableAnchor from '../../../components/breakable-anchor'

export const DeltakereSlide = () => {
  const dispatch = useDispatch()

  const currentKvittering = useSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering.data
  )

  const isLoading = useSelector(
    (state: IStoreState) => state.kvittering.loading
  )

  const onUpdateMobilnummer = (mobilnummer?: string) => {
    dispatch(
      kvitteringActions.updateCurrentKvittering({
        mobilForUndersoekelse: mobilnummer?.replace(/\s/g, ''),
      })
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        <Row center>
          <LoadingSpinner title={'Laster deltagerer...'} small />
        </Row>
      )
    }

    return (
      <>
        <ContrastBox noBorders backgroundColor="white">
          <VirksomhetDeltakere />
        </ContrastBox>

        <ContrastBox noBorders backgroundColor="white">
          <MattilsynetDeltakere />
        </ContrastBox>

        <ContrastBox noBorders backgroundColor="white">
          <AndreDeltakere />
        </ContrastBox>

        <ContrastBox noBorders backgroundColor="white">
          <Column>
            <MobilenumberItem
              onUpdateKvittering={(kvittering: IKvitteringData) =>
                onUpdateMobilnummer(kvittering.mobilForUndersoekelse)
              }
              kvittering={currentKvittering}
            />
            <Row>
              <Text as="p" weight="regular">
                Telefonnummeret vil ikke vises i tilsynskvitteringen.
              </Text>
            </Row>
            <Row margin={[1, 0]}>
              <Text as="p" weight="regular">
                Du vil få et varsel etter at tilsynskvitteringen er opprettet i
                arkivet der telefonnummeret vil stå.
              </Text>
            </Row>
            <Row>
              <InformationBox>
                <Text>
                  Brukerundersøkelsen må sendes ut manuelt. For bruksanvisning
                  se{' '}
                  <BreakableAnchor href="https://mattilsynet.pureservice.com/#/faqs/faq/307">
                    https://mattilsynet.pureservice.com/#/faqs/faq/307
                  </BreakableAnchor>
                </Text>
              </InformationBox>
            </Row>
          </Column>
        </ContrastBox>
      </>
    )
  }

  return (
    <Column spacing={3} fluid key={2}>
      <Row className="title">
        <h1>Deltakere</h1>
      </Row>
      {renderContent()}
    </Column>
  )
}
