import {
  Column,
  Row,
  Text,
  RowItem,
  Button,
  PhotoLibrary,
} from '@mattilsynet/mt-ui'
import React, { useCallback } from 'react'
import { IKvitteringToGet } from '../../modals/hent-fra-kollega-modal'

interface IHentFraKollegaKvitteringDataListItemProps {
  orgNr: string
  virksomhetsNavn: string
  kvitteringId: string
  theme: string
  onSelect: (kvittering: IKvitteringToGet) => void
  isSelected: boolean
  harBilder: boolean
}

export const HentFraKollegaKvitteringDataListItem = ({
  orgNr,
  virksomhetsNavn,
  kvitteringId,
  theme,
  onSelect,
  isSelected,
  harBilder,
}: IHentFraKollegaKvitteringDataListItemProps) => {
  const textWeight = isSelected ? 'bold' : 'regular'
  const orgNrEllerPrivatperson = orgNr ? `Org.nr: ${orgNr}` : `Privatperson`

  const onSelectKvittering = useCallback(
    () =>
      onSelect({
        id: kvitteringId,
        name: virksomhetsNavn,
        harBilder: harBilder,
      }),
    [kvitteringId, virksomhetsNavn, harBilder, onSelect]
  )

  return (
    <Column as={'li'}>
      <Row
        padding={[2, 2.5]}
        backgroundColor={theme}
        justify={'space-between'}
        align="center"
      >
        <RowItem alignSelf="flex-start">
          <Column spacing={1} fluid>
            <Text weight={textWeight}>{virksomhetsNavn}</Text>
            <Text weight={textWeight}>{orgNrEllerPrivatperson}</Text>
          </Column>
        </RowItem>
        <RowItem>
          <Row>
            {harBilder && (
              <RowItem>
                <PhotoLibrary size="large" />
              </RowItem>
            )}
            <RowItem margin={[0, 0, 0, 2]}>
              <Button
                secondary={!isSelected}
                width="8em"
                small
                onClick={onSelectKvittering}
              >
                {isSelected ? 'Valgt' : 'Velg'}
              </Button>
            </RowItem>
          </Row>
        </RowItem>
      </Row>
    </Column>
  )
}
