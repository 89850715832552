import { Row, Text } from '@mattilsynet/mt-ui'
import React from 'react'
import styled from 'styled-components'

interface IAnsattDataListItemProps {
  brukernavn: string
  navn: string
  kontorNavn: string
  selected: boolean
}

const StyledRow = styled(Row)`
  & {
    width: 100%;
  }
  & .Text.ansatt-navn {
    min-width: 250px;
  }
`

export const AnsattDataListItem = ({
  brukernavn,
  navn,
  kontorNavn,
  selected,
}: IAnsattDataListItemProps) => {
  return (
    <StyledRow justify="space-between" key={brukernavn} smColumn>
      <Text
        whiteSpace="pre-wrap"
        className="ansattName"
        weight={selected ? 'bold' : 'regular'}
      >
        {`${navn}\n${kontorNavn}`}
      </Text>
    </StyledRow>
  )
}
