// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.synk-status {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--warning-bg-color);
  padding: 0.5rem;
  position: fixed;
  top: 52px;
  width: 100%;
  z-index: 10;
}

.synk-status__icon {
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/synk-status/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yCAAyC;EACzC,eAAe;EACf,eAAe;EACf,SAAS;EACT,WAAW;EACX,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".synk-status {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: var(--warning-bg-color);\n  padding: 0.5rem;\n  position: fixed;\n  top: 52px;\n  width: 100%;\n  z-index: 10;\n}\n\n.synk-status__icon {\n  height: 16px;\n  animation: spin 2s linear infinite;\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(360deg);\n  }\n  to {\n    transform: rotate(0deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
