import { IConfigInterface, IOidcConfig } from '../../config.d'

export const uiActionTypes = {
  ACCESS_DENIED: 'ui/ACCESS_DENIED',
  OFFLINE: 'UI: Set application in offline mode',
  ONLINE: 'UI: Set application in online mode',
  CLOSE_PLANLAGT_SLAKT_CARD: 'ui/CLOSE_PLANLAGT_SLAKT_CARD',
  CONFIG_LOADED: 'ui/CONFIG_LOADED',
  INITIALIZE: 'ui/INITIALIZE',
  INITIALIZE_FAILED: 'ui/INITIALIZE_FAILED',
  INITIALIZE_SUCCESS: 'ui/INITIALIZE_SUCCESS',
  OPEN_PLANLAGT_SLAKT_CARD: 'ui/OPEN_PLANLAGT_SLAKT_CARD',
  SET_DATE_FROM_FETCH_MKI: 'ui/SET_DATE_FROM_FETCH_MKI',
  SET_OIDC_CONFIG: 'ui/SET_OIDC_CONFIG',
  SET_PAGE_TITLE: 'ui/SET_PAGE_TITLE',
  SET_IMAGE_NAVIGATION_MODAL: 'ui/SET_IMAGE_NAVIGATION_MODAL',
  SET_IMAGE_NAVIGATION_IMAGE: 'ui/SET_IMAGE_NAVIGATION_IMAGE',
  SET_IMAGE_FULLVERSJON_OPEN: 'ui/SET_IMAGE_FULLVERSJON_OPEN',
  SET_USER_DEVICE: 'ui/SET_USER_DEVICE',
  UPDATE_INSTALLED: 'UPDATE_INSTALLED',
}

export const uiActions = {
  setUserDevice: (isDesktop: boolean) => ({
    type: uiActionTypes.SET_USER_DEVICE,
    isDesktop,
  }),

  accessDenied: () => ({
    type: uiActionTypes.ACCESS_DENIED,
  }),

  configLoaded: (config: IConfigInterface) => ({
    type: uiActionTypes.CONFIG_LOADED,
    payload: config,
  }),

  setOidcConfig: (oidcConfig: IOidcConfig) => ({
    type: uiActionTypes.SET_OIDC_CONFIG,
    oidcConfig: oidcConfig,
  }),

  initialize: () => ({ type: uiActionTypes.INITIALIZE }),
  initializeSuccess: () => ({ type: uiActionTypes.INITIALIZE_SUCCESS }),
  initializeFailed: (error) => ({
    type: uiActionTypes.INITIALIZE_FAILED,
    error,
  }),

  isOffline: () => ({
    type: uiActionTypes.OFFLINE,
  }),

  isOnline: () => ({
    type: uiActionTypes.ONLINE,
  }),

  setImageNavigationModal: (
    isOpen: boolean,
    imageId: string,
    bildeIds: string[]
  ) => ({
    type: uiActionTypes.SET_IMAGE_NAVIGATION_MODAL,
    isOpen,
    imageId,
    bildeIds,
  }),
  setImageNavigationImage: (imageId?: string) => ({
    type: uiActionTypes.SET_IMAGE_NAVIGATION_IMAGE,
    imageId,
  }),
  setImageFullversjonOpen: (isOpen: boolean) => ({
    type: uiActionTypes.SET_IMAGE_FULLVERSJON_OPEN,
    isOpen,
  }),
}
