import { mergeKeyWith } from '../../../common/query'

export { midlertidigTilsynsobjektKeys }

const getMidlertidigTilsynsobjektKeys = () => {
  const keys = {
    all: [{ entity: 'midlertidig-tilsynsobjekter' }] as const,

    /** Used as defaultMutation key or filter key */
    add: () => mergeKeyWith(keys.all, { action: 'add' }),

    /** Used as defaultMutation key or filter key */
    updateBase: () => mergeKeyWith(keys.all, { action: 'update' }),
    /** Used as useMutation key */
    update: (kvitteringId: string) =>
      mergeKeyWith(keys.updateBase(), { kvitteringId }),
  }

  return keys
}

const midlertidigTilsynsobjektKeys = getMidlertidigTilsynsobjektKeys()

export type TilsynsobjektKeys = typeof midlertidigTilsynsobjektKeys
