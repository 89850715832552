import React from 'react'
import { Column, LoadingDots, Row } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../../components/modal-wrapper'

interface ILoadingModalProps {
  isOpen: boolean
  onCancel: () => void
  text?: string
}
export const LoadingModal = ({ isOpen, onCancel }: ILoadingModalProps) => {
  return (
    <>
      <ModalWrapper isOpen={isOpen} onCancel={onCancel}>
        <Column height="10vh" align="center" fluid spacing={1}>
          <Row center>
            <p>Sjekker status på utfylling</p>
          </Row>
          <Row center>
            <LoadingDots />
          </Row>
        </Column>
      </ModalWrapper>
    </>
  )
}
