import { queryClient } from '../../../api/query-client'
import { bildeKeys, withPersistedBilde } from './helpers'
import { bildeApi } from './bilde-api'
import { BildeAttachKvitteringMutationOptions } from '../types'
import { shouldIgnoreError } from '../../../common/query'
import store from '../../../reducers/store'
import { dangerToast } from '../../../common/toast'
import { kvitteringQueryHelpers } from '../../kvitteringer/queries/helpers'

/**
 * Optimistically updates bildeIds in the cache.
 *
 * @returns Previous bildeIds (Context that
 * is passed to onError).
 */
const onMutate: (
  updateKvitteringBilder: (bildeIds: string[], bildeId: string) => string[],
  updateObservasjonBilder: (bildeIds: string[], bildeId: string) => string[]
) => BildeAttachKvitteringMutationOptions['onMutate'] =
  (updateKvitteringBilder) =>
  async ({ kvitteringId, bildeId }) => {
    await kvitteringQueryHelpers.cancelKvitteringQuery(kvitteringId)

    const kvittering = kvitteringQueryHelpers.getKvitteringCache(kvitteringId)
    const previousKvitteringBilder = kvittering?.bildeIds ?? []

    kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
      draft.bildeIds = updateKvitteringBilder(draft.bildeIds, bildeId)
    })

    return { previousKvitteringBilder }
  }

/** Displays error toast and reverts cache to previous bildeIds. */
const onError: BildeAttachKvitteringMutationOptions['onError'] = (
  error,
  { kvitteringId },
  context
) => {
  if (shouldIgnoreError(error)) {
    return
  }

  store.dispatch(
    dangerToast('Det har skjedd en feil med bildene. Kunne ikke lagre.')
  )

  kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
    draft.bildeIds = (context?.previousKvitteringBilder as string[]) ?? []
  })
}

// Attach bilde to kvittering
queryClient.setMutationDefaults(bildeKeys.attachToKvitteringBase(), {
  mutationFn: withPersistedBilde(bildeApi.attachToKvittering),
  onMutate: onMutate(
    (bildeIds, bildeId) => bildeIds.filter((id) => id !== bildeId),
    (bildeIds, bildeId) => [...bildeIds, bildeId]
  ),
  onError: onError,
} satisfies BildeAttachKvitteringMutationOptions)
