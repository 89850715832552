// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-navigator {
  padding: 0.25rem 0;
  border-bottom: 1px solid var(--mt-ui-gray4);
}

.bottom-navigator .header {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.bottom-navigator .dots {
  text-align: center;
}

.bottom-navigator .focus {
  z-index: 1;
}

.bottom-navigator .hide {
  visibility: hidden;
}

@media screen and (min-width: 1152px) {
  .bottom-navigator .dots {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .bottom-navigator {
    padding: 0;
  }
  
}
`, "",{"version":3,"sources":["webpack://./src/components/bottom-navigator/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2CAA2C;AAC7C;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,UAAU;EACZ;;AAEF","sourcesContent":[".bottom-navigator {\n  padding: 0.25rem 0;\n  border-bottom: 1px solid var(--mt-ui-gray4);\n}\n\n.bottom-navigator .header {\n  font-weight: bold;\n  text-align: center;\n  text-transform: uppercase;\n  letter-spacing: 0.05rem;\n}\n\n.bottom-navigator .dots {\n  text-align: center;\n}\n\n.bottom-navigator .focus {\n  z-index: 1;\n}\n\n.bottom-navigator .hide {\n  visibility: hidden;\n}\n\n@media screen and (min-width: 1152px) {\n  .bottom-navigator .dots {\n    width: 100%;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .bottom-navigator {\n    padding: 0;\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
