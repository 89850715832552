import React from 'react'
import Svg, { IIconTemplate } from '@mattilsynet/mt-ui/dist/icons/icon-template'

export const DetachIcon = ({ color, ...rest }: IIconTemplate) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 125 100"
    height="100%"
    fill={color}
    {...rest}
  >
    <path
      d="M7.57751 13.496C5.54626 11.8945 2.59704 12.2656 0.995483 14.2968C-0.60608 16.3281 -0.234986 19.2773 1.79626 20.8788L117.421 111.504C119.453 113.105 122.402 112.734 124.003 110.703C125.605 108.672 125.234 105.723 123.203 104.121L95.5658 82.4609L113.242 64.7851C124.277 53.7499 124.277 35.8788 113.242 24.8437C103.476 15.0781 88.0853 13.8085 76.8549 21.8359L76.5423 22.0507C73.7298 24.0624 73.0853 27.9687 75.097 30.7617C77.1088 33.5546 81.015 34.2187 83.808 32.207L84.1205 31.9921C90.39 27.5195 98.9642 28.2226 104.394 33.6718C110.546 39.8242 110.546 49.7851 104.394 55.9374L85.6439 74.6874L79.4134 69.8046C84.1595 59.2968 82.0502 46.6992 73.6322 38.2812C63.4369 28.0663 47.3627 27.3046 36.2689 35.996L7.57751 13.496ZM46.6791 44.1406C52.558 41.2304 59.9017 42.207 64.8041 47.1288C68.7103 51.0351 70.1752 56.5624 69.0424 61.6796L46.6791 44.1406ZM79.4134 93.8281L43.1439 65.2343C42.7338 73.0077 45.5267 80.8788 51.3861 86.7187C58.9838 94.3163 69.8236 96.6796 79.433 93.8085L79.4134 93.8281ZM22.7728 49.1992L11.7572 60.2148C0.722046 71.2499 0.722046 89.121 11.7572 100.156C21.5228 109.922 36.9134 111.191 48.1439 103.164L48.4564 102.949C51.2689 100.937 51.9134 97.0312 49.9017 94.2382C47.89 91.4452 43.9838 90.7812 41.1908 92.7929L40.8783 93.0078C34.6088 97.4804 26.0345 96.7773 20.6049 91.3281C14.4525 85.1562 14.4525 75.1952 20.6049 69.0429L32.6752 56.9726L22.7924 49.1796L22.7728 49.1992Z"
      fill="black"
    />
  </Svg>
)
