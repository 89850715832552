import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Grid,
  GridItem,
  Row,
  TextLink,
  Column,
  Button,
  RowItem,
  ContrastBox,
} from '@mattilsynet/mt-ui'
import { useDispatch, useSelector } from 'react-redux'
import { IStoreState } from '../../reducers/types'
import './style.css'
import { VirksomhetModal } from '../../modals'
import SakCardInfo from '../../components/sak-card-info'
import TilsynsobjekterCardInfo from '../../components/tilsynsobjekter-card-info'
import SakSearch from '../../components/sak-search'
import { KontrollpunkterSection } from '../../features/kontrollpunkter'
import { Status } from '../../components/status'
import { initialSearch } from './utils'
import {
  useMidlertidigTilsynsobjekt,
  useTypedSelector,
} from '../../common/custom-hooks'
import {
  offlineInSakAndTilsynsobjekt,
  offlineInTypeTilsynSelect,
  useSingleToast,
} from '../../common/toast'
import TypeTilsyn from '../../components/type-tilsyn'
import { AktivitetModal } from '../../modals/aktivitet-modal'
import { tilsynsobjektActions } from '../../ducks/kvittering/tilsynsobjekt/actions'
import {
  useCreateKvitteringMutation,
  useKvittering,
  useUpdateKvittering,
} from '../../features/kvitteringer'
import { KvitteringIdProvider } from '../../common/kvittering-context'
import { useViewsContext } from '.'
import { TidligereKontrollpunkter } from '../../features/tidligere-kontrollpunkter'
import { ISakState } from '../../features/sak/types'
import { MutationStatus, useMutationStatus } from '../../common/query'
import { kvitteringKeys } from '../../features/kvitteringer/queries/helpers'
import { kvitteringSelectors } from '../../ducks/kvittering/selectors'

interface IForberedNewTilsynViewProps {
  sakNumberValue: string
  sakData: ISakState
  onChange: (e: any) => void
  onAddKvittering: () => void
  onStartTilsyn: (id: string) => void
  onWarnTilsynsObjekt: () => void
  onWarnSak: () => void
  onGoBack: () => void
  onAddSak: () => void
  onCancelSak: () => void
  showSak: boolean
  onWarnType: () => void
  onWarnTypeBeskrivelse: () => void
  kodeverkAktiviteterBehandlesIMats: any
}

export const ForberedNewTilsynView = ({
  sakNumberValue,
  sakData,
  onChange,
  onStartTilsyn,
  onWarnTilsynsObjekt,
  onWarnSak,
  onGoBack,
  onAddSak,
  onCancelSak,
  showSak,
  onWarnType,
  onWarnTypeBeskrivelse,
  kodeverkAktiviteterBehandlesIMats,
}: IForberedNewTilsynViewProps) => {
  const dispatch = useDispatch()
  const singleToast = useSingleToast()
  const { view, viewDispatch } = useViewsContext()
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)
  const {
    sakNumber,
    title,
    sakFail,
    sakNotFound,
    sakClosed,
    sakTilgang,
    sakHjemmel,
    loading: isLoadingSak,
  } = sakData

  const [isVirksomhetModalOpen, setIsVirksomhetModalOpen] = useState(false)

  const [isStartTilsynClicked, setIsStartTilsynClicked] = useState(false)

  const currentKvittering = useSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering.data
  )

  const currentKvitteringId = useSelector(
    kvitteringSelectors.getCurrentKvitteringId
  )

  const {
    data: kvittering,
    isFetching,
    isLoading: isKvitteringLoading,
    refetch,
  } = useKvittering(currentKvitteringId, {
    enabled: currentKvitteringId !== '',
  })

  const { mutateAsync: createKvittering } = useCreateKvitteringMutation()

  const { mutate: updateKvittering } = useUpdateKvittering()

  const error = useSelector((state: IStoreState) => state.kvittering.error)

  const status = useSelector(
    (state: IStoreState) => state.kvittering.selectedKvittering.status
  )

  const isLoading = useSelector(
    (state: IStoreState) => state.kvittering.loading
  )

  const [hasFetchedKvittering, setHasFetchedKvittering] = useState(false)

  const isAKvitteringBeingCreated =
    useMutationStatus({
      mutationKey: kvitteringKeys.create(),
    }).filter(([status]) => status !== MutationStatus.SUCCESS).length > 0

  // Slett isFetchingRef og refetch-useEffecten når vi er ferdige
  // med å konvertere til tanstack
  const isFetchingRef = useRef(isFetching)
  useEffect(() => {
    isFetchingRef.current = isFetching
  }, [isFetching])

  useEffect(() => {
    if (!isFetchingRef.current && currentKvittering.id !== '') {
      refetch()
    }
  }, [currentKvittering, refetch])

  const {
    isAktivitetModalOpen,
    aktivitetOptions,
    selectedAktivitet,
    onSelectAktivitet,
    onSearchAktivitet,
    midlertidigTilsynsObjektVirksomhet,
    setMidlertidigTilsynsObjektVirksomhet,
    onClickMidlertidigTilsynsobjekt,
    onCancelAktivitetModal,
    unselectedMidlertidigTilsynsobjekt,
    setUnselectedMidlertidigTilsynsobjekt,
  } = useMidlertidigTilsynsobjekt(
    kodeverkAktiviteterBehandlesIMats,
    setIsVirksomhetModalOpen
  )

  useEffect(() => {
    if (currentKvittering.id) {
      setHasFetchedKvittering(true)
    }
  }, [currentKvittering])

  useEffect(() => {
    if (hasFetchedKvittering) {
      dispatch(tilsynsobjektActions.fetchTilsynsobjekter())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedKvittering])

  const openTidligereKontrollpunkter = useCallback(
    () => viewDispatch('tidligereKontrollpunkter'),
    [viewDispatch]
  )

  const closeTidligereKontrollpunkter = useCallback(
    () => viewDispatch('default'),
    [viewDispatch]
  )

  const onCancelVirksomhetModal = () => {
    setIsVirksomhetModalOpen(false)
  }

  const onClickTilsyn = () => {
    setIsStartTilsynClicked(true)
    if (
      ((kvittering?.typeTilsyn && kvittering?.typeTilsyn !== 'ANNET') ||
        (kvittering?.typeTilsyn === 'ANNET' &&
          kvittering?.typeTilsynBeskrivelse)) &&
      kvittering?.tilsynsobjekter &&
      kvittering?.tilsynsobjekter.length > 0 &&
      kvittering?.id &&
      ((kvittering?.noarksakSekvensnummer && kvittering?.noarksakAar) ||
        !sakNumberValue)
    ) {
      return onStartTilsyn(kvittering?.id)
    }
    if (
      sakNumberValue.length > 0 &&
      !(kvittering?.noarksakSekvensnummer && kvittering?.noarksakAar)
    ) {
      return onWarnSak()
    }

    if (!kvittering?.typeTilsyn) {
      return onWarnType()
    }

    if (
      kvittering?.typeTilsyn === 'ANNET' &&
      (!kvittering?.typeTilsynBeskrivelse ||
        kvittering?.typeTilsynBeskrivelse.trim().length === 0)
    ) {
      return onWarnTypeBeskrivelse()
    }

    return onWarnTilsynsObjekt()
  }

  const onAddRemoveTilsynsobjekt = () => {
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt())
    }
    setIsVirksomhetModalOpen(true)
  }

  const onChangeTypeTilsyn = async (typeValue) => {
    if (isOffline) {
      return singleToast.showToast(offlineInTypeTilsynSelect())
    }

    if (!currentKvitteringId) {
      await createKvittering({ typeTilsyn: typeValue })
    }

    if (currentKvitteringId && kvittering) {
      updateKvittering({ kvittering: { ...kvittering, typeTilsyn: typeValue } })
    }
  }

  if (view === 'tidligereKontrollpunkter') {
    return (
      <TidligereKontrollpunkter
        tilsynsobjekter={currentKvittering.tilsynsobjekter}
        kvitteringId={currentKvittering.id ?? ''}
        onCancel={closeTidligereKontrollpunkter}
      />
    )
  }

  return (
    <KvitteringIdProvider value={kvittering?.id ?? ''}>
      <Grid>
        <VirksomhetModal
          isOpen={isVirksomhetModalOpen}
          onCancel={onCancelVirksomhetModal}
          initialSearch={initialSearch(kvittering?.virksomhetsNavn, title)}
          onClickMidlertidigTilsynsobjekt={onClickMidlertidigTilsynsobjekt}
          midlertidigTilsynsobjektAktivitet={selectedAktivitet}
          midlertidigTilsynsobjektVirksomhet={
            midlertidigTilsynsObjektVirksomhet
          }
          onResetMidlertidigTilsynsojekt={() => {
            setMidlertidigTilsynsObjektVirksomhet({ navn: '', nummer: '' })
          }}
          onUnselectMidlertidigTilsynsobjekt={(tilsynsobjekt) =>
            setUnselectedMidlertidigTilsynsobjekt(tilsynsobjekt)
          }
          unselectedMidlertidigTilsynsobjekt={
            unselectedMidlertidigTilsynsobjekt
          }
          setIsVirksomhetModalOpen={setIsVirksomhetModalOpen}
        />
        {isAktivitetModalOpen && (
          <AktivitetModal
            isOpen={isAktivitetModalOpen}
            onCancel={() => onCancelAktivitetModal()}
            aktivitetOptions={aktivitetOptions}
            selectedAktivitet={selectedAktivitet}
            onSelect={onSelectAktivitet}
            onSearch={onSearchAktivitet}
            onUnselectMidlertidigTilsynsobjekt={(tilsynsobjekt) =>
              setUnselectedMidlertidigTilsynsobjekt(tilsynsobjekt)
            }
            unselectedMidlertidigTilsynsobjekt={
              unselectedMidlertidigTilsynsobjekt
            }
          />
        )}

        <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
          <Row>
            <TextLink leftArrow onClick={onGoBack}>
              Tilbake
            </TextLink>
          </Row>
        </GridItem>
        <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
          <Grid id="forbered-new-tilsyn">
            <GridItem xl={[2, -2]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
              <Column spacing={3} fluid>
                <Row className="forbered-title">
                  <h2>Forbered nytt tilsyn</h2>
                </Row>
                <Row>
                  <Column spacing={2} fluid>
                    <TypeTilsyn
                      disabled={isAKvitteringBeingCreated}
                      onChange={onChangeTypeTilsyn}
                      currentType={kvittering?.typeTilsyn ?? ''}
                      currentTypeTypeTilsynBeskrivelse={
                        kvittering?.typeTilsynBeskrivelse
                      }
                    />
                    <ContrastBox noBorders backgroundColor="white">
                      <Row margin={[0, 0, 1, 0]} className="forbered-subtitle">
                        <h4>Legg til sak</h4>
                      </Row>
                      <SakSearch
                        onAddSak={onAddSak}
                        onChange={onChange}
                        disabled={isAKvitteringBeingCreated}
                        sakNumberValue={sakNumberValue}
                        errorText={
                          sakNumberValue.length > 0 &&
                          isStartTilsynClicked &&
                          !(
                            kvittering?.noarksakSekvensnummer &&
                            kvittering?.noarksakAar
                          )
                            ? 'Saken må legges til eller tekstfeltet må være tomt'
                            : ''
                        }
                      />
                      <br />
                      <SakCardInfo
                        sakNumber={sakNumber}
                        title={title}
                        onCancelSak={onCancelSak}
                        sakFail={sakFail}
                        sakNotFound={sakNotFound}
                        sakClosed={sakClosed}
                        isLoading={isLoadingSak}
                        sakTilgang={sakTilgang}
                        sakHjemmel={sakHjemmel}
                        showSak={showSak}
                      />
                    </ContrastBox>

                    <TilsynsobjekterCardInfo
                      disabled={isAKvitteringBeingCreated}
                      onAddRemoveTilsynsobjekt={onAddRemoveTilsynsobjekt}
                      tilsynsobjekter={kvittering?.tilsynsobjekter ?? []}
                      virksomhetsNavn={kvittering?.virksomhetsNavn}
                      virksomhetsOrgNr={kvittering?.virksomhetsOrgNr}
                    />
                    {kvittering && kvittering.tilsynsobjekter?.length > 0 && (
                      <KontrollpunkterSection
                        isLoading={isKvitteringLoading}
                        tilsynsobjekter={kvittering.tilsynsobjekter}
                        openTidligereKontrollpunkter={
                          openTidligereKontrollpunkter
                        }
                      />
                    )}
                    <Row justify="space-between" align="center">
                      <RowItem>
                        <TextLink leftArrow onClick={onGoBack}>
                          Tilbake
                        </TextLink>
                      </RowItem>
                      <RowItem>
                        <Button secondary arrow onClick={onClickTilsyn}>
                          Start tilsyn
                        </Button>
                      </RowItem>
                    </Row>
                  </Column>
                </Row>
              </Column>
            </GridItem>
          </Grid>
        </GridItem>
        <Status status={status} error={error} isLoading={isLoading} />
      </Grid>
    </KvitteringIdProvider>
  )
}
