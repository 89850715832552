// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.type-tilsyn-modal .modal-child .modal-body {
  padding: 0;
}

.type-tilsyn-modal .modal-child .modal-body .Textarea {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/modals/type-tilsyn-modal/style.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb","sourcesContent":[".type-tilsyn-modal .modal-child .modal-body {\n  padding: 0;\n}\n\n.type-tilsyn-modal .modal-child .modal-body .Textarea {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
