import React, { ReactNode } from 'react'
import { Row } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../../components/modal-wrapper'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { modalContentPadding } from '../../components/modal-buttons-container/constants'
import { ModalButton } from '../../components/modal-button'

interface IDeleteModalStorProps {
  isOpen: boolean
  onCancel: () => void
  onDelete: () => void
  title?: string
  description?: string | ReactNode
  confirmText?: string
  cancelText?: string
}

export const DeleteModalLarge = ({
  isOpen,
  onCancel,
  onDelete,
  title = 'Er du sikker?',
  description = '',
  confirmText = 'Ja',
  cancelText = 'Avbryt',
}: IDeleteModalStorProps) => (
  <ModalWrapper
    fullscreenMobile
    isOpen={isOpen}
    onCancel={onCancel}
    title={title}
  >
    <div id="delete-modal-large">
      <Row
        className="description"
        spacing={2}
        padding={modalContentPadding}
        align={'flex-start'}
      >
        {description}
      </Row>
      <ModalButtonsContainer>
        <ModalButton onClick={onCancel} secondary>
          {cancelText}
        </ModalButton>

        <ModalButton onClick={onDelete}>{confirmText}</ModalButton>
      </ModalButtonsContainer>
    </div>
  </ModalWrapper>
)
