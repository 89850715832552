import { of, ReplaySubject, combineLatest } from 'rxjs'
import { ajax } from 'rxjs/ajax'

import { IConfigInterface, IOidcConfig } from './config.d'

import { mergeMap, pluck } from 'rxjs/operators'

export const config$ = new ReplaySubject<IConfigInterface>(1)
export const oidcConfig$ = new ReplaySubject<IOidcConfig>(1)

of({
  method: 'GET',
  url: '/config.json',
})
  .pipe(
    mergeMap((req) => ajax(req)),
    pluck('response')
  )
  .subscribe((config) => {
    config$.next(config as IConfigInterface)
  })

const oidcCert$ = of({
  url: '/api/login/certs',
}).pipe(
  mergeMap((req) => ajax(req)),
  pluck('response'),
  pluck('keys')
)

const oidcLinks$ = of({
  url: '/api/login/.well-known/openid-configuration',
}).pipe(
  mergeMap((req) => ajax(req)),
  pluck('response')
)

combineLatest([oidcCert$, oidcLinks$]).subscribe(([certs, links]) =>
  oidcConfig$.next({ certs, links })
)
