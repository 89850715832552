// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-box-container {
  border: 1px solid var(--mt-ui-primary);
  border-radius: 5px;
}

.info-box-container--info {
  background-color: var(--info-bg-color);
}

.info-box-container--warning {
  background-color: var(--warning-bg-color);
}

.info-box-container--danger {
  background-color: var(--error-bg-color);
}

.info-box-container__indicator--warning {
  fill: var(--warning-icon-color);
  position: relative;
  top: 4px;
}

.info-box-container__indicator--info > div {
  background-color: var(--info-icon-color);
  font-size: 0.9rem;
}

.info-box-container__indicator--danger > div {
  background-color: var(--error-icon-color);
  font-size: 0.9rem;
}

.info-box-container__indicator--small > div {
  max-width: 1rem;
  max-height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
}

.info-box-container__header {
  padding-bottom: 0.5rem;
  padding-right: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/information-box/style.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,wCAAwC;EACxC,iBAAiB;AACnB;;AAEA;EACE,yCAAyC;EACzC,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;AACxB","sourcesContent":[".info-box-container {\n  border: 1px solid var(--mt-ui-primary);\n  border-radius: 5px;\n}\n\n.info-box-container--info {\n  background-color: var(--info-bg-color);\n}\n\n.info-box-container--warning {\n  background-color: var(--warning-bg-color);\n}\n\n.info-box-container--danger {\n  background-color: var(--error-bg-color);\n}\n\n.info-box-container__indicator--warning {\n  fill: var(--warning-icon-color);\n  position: relative;\n  top: 4px;\n}\n\n.info-box-container__indicator--info > div {\n  background-color: var(--info-icon-color);\n  font-size: 0.9rem;\n}\n\n.info-box-container__indicator--danger > div {\n  background-color: var(--error-icon-color);\n  font-size: 0.9rem;\n}\n\n.info-box-container__indicator--small > div {\n  max-width: 1rem;\n  max-height: 1rem;\n  min-width: 1rem;\n  min-height: 1rem;\n}\n\n.info-box-container__header {\n  padding-bottom: 0.5rem;\n  padding-right: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
