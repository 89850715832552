import React from 'react'
import { Column, Row, Button, DeleteIcon } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../../components/modal-wrapper'
import './style.css'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { ModalButton } from '../../components/modal-button'
import { modalContentPadding } from '../../components/modal-buttons-container/constants'

interface ITilsynHandlingsMenuModalProps {
  isOpen: boolean
  onCancel: () => void
  onEdit: () => void
  onDelete: () => void
  onGoToSammenstilling: () => void
  showSammenstilling: boolean
  onFordelTilKollega: () => void
  disableFordelTilKollega: boolean
}

export const TilsynHandlingsMenuModal = ({
  isOpen,
  onCancel,
  onEdit,
  onGoToSammenstilling,
  showSammenstilling,
  onFordelTilKollega,
  disableFordelTilKollega,
  onDelete,
}: ITilsynHandlingsMenuModalProps) => {
  return (
    <ModalWrapper
      isOpen={isOpen}
      onCancel={onCancel}
      title={'Handlinger'}
      className="tilsynsobjekt-handling-modal"
    >
      <Column padding={modalContentPadding} margin={[2, 0]}>
        {showSammenstilling ? (
          <Column>
            <Row justify="center">
              <Button
                fill
                large
                secondary
                onClick={onGoToSammenstilling}
                arrow
                allowMultiLine
              >
                Se sammenstilling
              </Button>
            </Row>
          </Column>
        ) : (
          <Column spacing={1}>
            <Row justify="center">
              <Button
                fill
                large
                secondary
                onClick={onEdit}
                arrow
                allowMultiLine
              >
                Rediger
              </Button>
            </Row>
            <Row justify="center">
              <Button
                fill
                large
                secondary
                onClick={() => {
                  onCancel()
                  onFordelTilKollega()
                }}
                disabled={disableFordelTilKollega}
                arrow
                allowMultiLine
              >
                Fordel til kollega
              </Button>
            </Row>
            <Row justify="center">
              <Button
                fill
                large
                secondary
                onClick={onDelete}
                icon={<DeleteIcon />}
              >
                Slett tilsyn
              </Button>
            </Row>
          </Column>
        )}
      </Column>
      <ModalButtonsContainer small>
        <ModalButton secondary onClick={onCancel}>
          Lukk
        </ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>
  )
}
