import { queryApi } from '../../../api'
import { createQueryStringFromObject } from '../../../common/createQueryStringFromObject'
import { IQueryApiResponse } from '../../../common/types'
import { ENDPOINT } from '../../../constants'
import { ITidligereKontrollpunktDto } from '../types'

export const tidligereKontrollpunkterApi = {
  get: (aktiviteter: string[]) => {
    const queries = createQueryStringFromObject('?')({ aktiviteter })

    return queryApi.get<
      IQueryApiResponse<{
        tilsynskvitteringKontrollpunktBeskrivelseDTOList: ITidligereKontrollpunktDto[]
      }>
    >(
      `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/mine-tidligere-kontrollpunkter${queries}`
    )
  },
}
