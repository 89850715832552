import { useEffect, useRef } from 'react'
import { IVeiledning } from '../../../ducks/kvittering/veiledning/types'
import { IVeiledningVars } from '../types'
import { useTilsynsobjekter } from '../../tilsynsobjekter'
import { useKvitteringId } from '../../../common/kvittering-context'
import { veiledningInitialState } from '../helpers'
import { useIsMutating } from '@tanstack/react-query'

export { useRemoveEmptyVeiledninger, useAddEmptyVeiledninger }

const useVeiledningerByTilsynsobjekt = (
  veiledninger: IVeiledning[]
): Record<string, IVeiledning[]> => {
  const kvitteringId = useKvitteringId()
  const { data: tilsynsobjekter } = useTilsynsobjekter(kvitteringId)

  return (
    tilsynsobjekter?.reduce(
      (groups, tilsynsobjekt) => {
        if (!tilsynsobjekt.id) {
          return groups
        }

        if (!groups[tilsynsobjekt.id]) {
          groups[tilsynsobjekt.id] = []
        }

        groups[tilsynsobjekt.id] = veiledninger.filter(
          (veiledning) => veiledning.tilsynsobjektId === tilsynsobjekt.id
        )

        return groups
      },
      {} as Record<string, IVeiledning[]>
    ) ?? {}
  )
}

const useRemoveEmptyVeiledninger = (
  veiledninger: IVeiledning[],
  removeVeiledning: ({ veiledning }: IVeiledningVars) => void
) => {
  const veiledningerByTilsynsobjekt =
    useVeiledningerByTilsynsobjekt(veiledninger)

  const veiledningerRef = useRef(veiledningerByTilsynsobjekt)
  const removeRef = useRef(removeVeiledning)

  useEffect(() => {
    veiledningerRef.current = veiledningerByTilsynsobjekt
    removeRef.current = removeVeiledning
  }, [veiledningerByTilsynsobjekt, removeVeiledning])

  useEffect(() => {
    return () => {
      const veiledningerByTilsynsobjekt = veiledningerRef.current ?? []
      const remove = removeRef.current

      Object.values(veiledningerByTilsynsobjekt).forEach((veiledninger) => {
        const lastVeiledning = veiledninger[veiledninger.length - 1]

        if (remove && lastVeiledning?.tekst.length === 0) {
          remove({ veiledning: lastVeiledning })
        }
      })
    }
  }, [])
}

const useAddEmptyVeiledninger = (
  veiledninger: IVeiledning[],
  tilsynsobjektId: string,
  addVeiledning: (data: IVeiledningVars) => void,
  shouldAdd: boolean
) => {
  const isPendingAddVeiledninger = useIsMutating({
    predicate: (mutation) =>
      (mutation.options.mutationKey?.[0] as any).entity.includes(
        'veiledninger'
      ) &&
      (mutation.options.mutationKey?.[0] as any).action === 'add' &&
      (mutation.state.status === 'pending' || mutation.state.isPaused),
  })

  useEffect(() => {
    if (
      shouldAdd &&
      isPendingAddVeiledninger === 0 &&
      tilsynsobjektId &&
      veiledninger.length === 0
    ) {
      addVeiledning({
        veiledning: {
          ...veiledningInitialState(tilsynsobjektId),
        },
      })
    }
  }, [
    addVeiledning,
    tilsynsobjektId,
    veiledninger,
    shouldAdd,
    isPendingAddVeiledninger,
  ])
}
