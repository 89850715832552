import {
  MutateOptions,
  Mutation,
  MutationFilters,
  QueryKey,
  UseMutateFunction,
  useMutationState,
} from '@tanstack/react-query'
import { useCallback, useRef } from 'react'
import { queryClient } from '../api/query-client'
import { AxiosError } from 'axios'

export {
  DependencyNotPersisted,
  getIdFromSelfUrl,
  shouldHandleError,
  shouldIgnoreError,
  useDebounceMutate,
  useOptimisticDebounceMutate,
  useMutationStatus,
  isErrorMutation,
}

type DebounceMutateOptions<TData, TError, TVariables, TContext> = MutateOptions<
  TData,
  TError,
  TVariables,
  TContext
> & {
  /**
   * Debounce milliseconds
   */
  debounceMs: number
  /**
   * Used to differentiate inputs
   */
  key: string
}

export type DebounceMutate<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = (
  variables: TVariables,
  options: DebounceMutateOptions<TData, TError, TVariables, TContext>
) => void

const useDebounceMutate = <
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
>(
  mutate: UseMutateFunction<TData, TError, TVariables, TContext>
) => {
  const timerRef = useRef<Record<string, NodeJS.Timeout>>({})

  return useCallback<DebounceMutate<TData, TError, TVariables, TContext>>(
    (variables, { debounceMs, key, ...options }) => {
      clearTimeout(timerRef.current[key])

      timerRef.current[key] = setTimeout(() => {
        mutate(variables, options)
      }, debounceMs)
    },
    [mutate]
  )
}

const useOptimisticDebounceMutate = <
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
>(
  mutate: UseMutateFunction<TData, TError, TVariables, TContext>,
  queryDataKey: QueryKey,
  updateFn: (items: TData[], updatedItem: TVariables) => TData[]
) => {
  const debounceMutate = useDebounceMutate(mutate)

  return useCallback<DebounceMutate<TData, TError, TVariables, TContext>>(
    (variables, options) => {
      queryClient.setQueryData<TData[]>(queryDataKey, (items) =>
        updateFn(items ?? [], variables)
      )

      debounceMutate(variables, options)
    },
    [debounceMutate, queryDataKey, updateFn]
  )
}

const shouldHandleError = (error: unknown) => {
  if (error instanceof AxiosError && error.response?.status) {
    return (
      error.response.status === 401 ||
      (error.response.status >= 500 && error.response.status <= 599)
    )
  }

  return true
}

class DependencyNotPersisted {
  public readonly name = 'DependencyNotPersisted'
  public readonly ignore = true
}

const isDependencyNotPersisted = (
  val: unknown
): val is DependencyNotPersisted => {
  return (
    typeof val === 'object' &&
    val !== null &&
    'ignore' in val &&
    'name' in val &&
    val.name === 'DependencyNotPersisted'
  )
}

const shouldIgnoreError = (error: unknown) => {
  return isDependencyNotPersisted(error) && error.ignore
}

const getIdFromSelfUrl = (selfHref: string): number | null => {
  const match = RegExp(/\/(\d+)$/).exec(selfHref)
  return match ? parseInt(match[1]) : null
}

export const mergeKeyWith = <T, U>(
  a: readonly [{ readonly [K in keyof T]: T[K] }],
  b: { readonly [K in keyof U]: U[K] }
) => {
  return [{ ...a[0], ...b }] as const
}

export enum MutationStatus {
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const getMutationStatus = (
  mutation: Mutation<unknown, Error, unknown, unknown>
) => {
  const statusMapper = {
    idle: MutationStatus.WAITING,
    pending: MutationStatus.PENDING,
    success: MutationStatus.SUCCESS,
    error: MutationStatus.ERROR,
  } as const

  return mutation.state.isPaused
    ? MutationStatus.WAITING
    : statusMapper[mutation.state.status]
}

/**
 * Listens to status changes in the mutation cache.
 * @param filters The filters to apply to the mutations in the cache.
 */
const useMutationStatus = <TVariables = unknown>(filters: MutationFilters) =>
  useMutationState({
    filters,
    select: (mutation) =>
      [getMutationStatus(mutation), mutation.state.variables] as [
        MutationStatus,
        TVariables,
      ],
  })

const isErrorMutation = (mutation: Mutation<any, any, any, any>) =>
  mutation.state.status === 'error' ||
  (mutation.state.status === 'pending' &&
    mutation.state.isPaused &&
    mutation.state.failureCount > 0)

/** Checks if the sync is finished and there are no errors */
export const isKvitteringSyncing = (kvitteringId: string) => {
  const mutationCount = queryClient.isMutating({
    predicate: (mutation) =>
      mutation.state.variables.kvitteringId === kvitteringId &&
      mutation.state.status !== 'success',
  })

  return mutationCount > 0
}
