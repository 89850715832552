import { v1 as uuidv1 } from 'uuid'

export { veiledningInitialState }

const veiledningInitialState = (tilsynsobketId: string) => ({
  tekst: '',
  tilsynsobjektId: tilsynsobketId,
  id: uuidv1(),
  _links: {
    self: {
      href: '',
    },
  },
})
