export interface ISakState {
  loading: boolean
  sakNumber: string
  title: string
  sakFail: boolean
  sakNotFound: boolean
  sakClosed: boolean
  sakTilgang: string
  sakHjemmel: string
  error: string
}

export const INITIAL_STATE = 'sak/INITIAL_STATE'
export const GET_SAK = 'sak/GET_SAK'
export const GET_SAK_SUCCESS = 'sak/GET_SAK_SUCCESS'
export const GET_SAK_FAILURE = 'sak/GET_SAK_FAILURE'
export const GET_SAK_NOT_FOUND = 'sak/GET_SAK_NOT_FOUND'
export const CLEAR_SAK = 'sak/CLEAR_SAK'
export const CREATE_SAK = 'sak/CREATE_SAK'
export const CREATE_SAK_SUCCESS = 'sak/CREATE_SAK_SUCCESS'
export const CREATE_SAK_FAILED = 'sak/CREATE_SAK_FAILED'

interface IInitialStateAction {
  type: typeof INITIAL_STATE
}

export interface ISakNummerData {
  sakNumber: string
  title: string
}

interface IGetSakAction {
  type: typeof GET_SAK
  sakNumber: string
  shouldUpdateKvittering: boolean
}

interface IGetSakSuccessAction {
  type: typeof GET_SAK_SUCCESS
  sakNumber: string
  title: string
  sakClosed: boolean
  sakTilgang: string
  sakHjemmel: string
}

interface IGetSakNotFoundAction {
  type: typeof GET_SAK_NOT_FOUND
  sakNumber: string
}

interface IGetSakNotFailedAction {
  type: typeof GET_SAK_FAILURE
  sakNumber: string
}

interface IClearSakAction {
  type: typeof CLEAR_SAK
}

interface ICreateSakAction {
  type: typeof CREATE_SAK
  tittel: string
  isSkjermet: boolean
}

interface ICreateSakSuccessdAction {
  type: typeof CREATE_SAK_SUCCESS
  tittel: string
  sakNumber: string
}

interface ICreateSakFailedAction {
  type: typeof CREATE_SAK_FAILED
  error: string
}

export type SakActionTypes =
  | IGetSakAction
  | IGetSakSuccessAction
  | IGetSakNotFoundAction
  | IGetSakNotFailedAction
  | IClearSakAction
  | IInitialStateAction
  | ICreateSakAction
  | ICreateSakSuccessdAction
  | ICreateSakFailedAction
