// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.virksomhet-modal-content {
  min-height: 25em;
}

.RadioButtonList .radio-button-list-label {
  font-weight: bold;
  padding: 0.5em 0em;
}
`, "",{"version":3,"sources":["webpack://./src/modals/virksomhet-modal/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".virksomhet-modal-content {\n  min-height: 25em;\n}\n\n.RadioButtonList .radio-button-list-label {\n  font-weight: bold;\n  padding: 0.5em 0em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
