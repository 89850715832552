import React from 'react'
import { StartTilsynView } from './view'
import { useParams } from 'react-router'
import { KvitteringIdProvider } from '../../common/kvittering-context'
import { createViewContext } from '../../features/views'

const [ViewsProvider, useViewsContext] = createViewContext<
  'default' | 'fullversjonImage' | 'tidligereKontrollpunkter'
>('default')

export { useViewsContext }

const StartTilsyn = () => {
  const { id: kvitteringId } = useParams()

  return (
    <KvitteringIdProvider value={kvitteringId!}>
      <ViewsProvider>
        <StartTilsynView />
      </ViewsProvider>
    </KvitteringIdProvider>
  )
}

export default StartTilsyn
