// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-buttons-container {
  position: fixed;
  bottom: 0;
  right: 3%;
  left: 3%;
  background-color: white;
  border-top: #cacaca 1px solid;
  z-index: 1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.modal-buttons-container.small {
  right: 5.5%;
  left: 5.5%;
}
`, "",{"version":3,"sources":["webpack://./src/components/modal-buttons-container/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,SAAS;EACT,QAAQ;EACR,uBAAuB;EACvB,6BAA6B;EAC7B,UAAU;EACV,8BAA8B;EAC9B,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,UAAU;AACZ","sourcesContent":[".modal-buttons-container {\n  position: fixed;\n  bottom: 0;\n  right: 3%;\n  left: 3%;\n  background-color: white;\n  border-top: #cacaca 1px solid;\n  z-index: 1;\n  border-bottom-left-radius: 5px;\n  border-bottom-right-radius: 5px;\n}\n\n.modal-buttons-container.small {\n  right: 5.5%;\n  left: 5.5%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
