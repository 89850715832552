import React, { ReactNode } from 'react'
import { Column, Row } from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../../components/modal-wrapper'
import './style.css'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { modalContentPadding } from '../../components/modal-buttons-container/constants'
import { ModalButton } from '../../components/modal-button'

interface IDeleteModalProps {
  isOpen: boolean
  onCancel: () => void
  onDelete: () => void
  title?: string
  explanation?: string | ReactNode
  confirmText?: string
  cancelText?: string
}

export const DeleteModal = ({
  isOpen,
  onCancel,
  onDelete,
  title = 'Er du sikker?',
  explanation = '',
  confirmText = 'Ja',
  cancelText = 'Avbryt',
}: IDeleteModalProps) => {
  return (
    <ModalWrapper fullscreenMobile isOpen={isOpen} onCancel={onCancel}>
      <div id="delete-modal">
        <Column padding={modalContentPadding} spacing={5} margin={2}>
          {explanation && (
            <Row justify="center" padding={[0, 4]} className="explanation">
              <div>{explanation}</div>
            </Row>
          )}
          <Row justify="center" padding={[0, 4]} className="title">
            <h1>{title}</h1>
          </Row>
        </Column>
      </div>
      <ModalButtonsContainer>
        <ModalButton secondary onClick={onCancel}>
          {cancelText}
        </ModalButton>

        <ModalButton onClick={onDelete}>{confirmText}</ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>
  )
}
