import { queryApi } from '../../../api'
import { IQueryApiResponse } from '../../../common/types'
import { IDeltaker, IDeltakerVars } from '../types'

interface IGetDeltakereOptions {
  signal?: AbortSignal
}

interface IDeltakereResponse
  extends IQueryApiResponse<
    | {
        virksomhetDeltagere: IDeltaker[]
      }
    | {
        mattilsynetDeltagere: IDeltaker[]
      }
    | {
        andreDeltagere: IDeltaker[]
      }
  > {}

export const deltakerApi = {
  get: async (url: string, options?: IGetDeltakereOptions) => {
    return await queryApi.get<IDeltakereResponse>(
      `${url}?page.size=100&sort.direction=ASC&sort.property=opprettet`,
      { signal: options?.signal }
    )
  },
  post: async ({ url, deltaker }: IDeltakerVars) => {
    return queryApi.post(url, {
      navn: deltaker.navn,
    })
  },
  put: async ({ url, deltaker }: IDeltakerVars) => {
    return queryApi.put(url, {
      ...deltaker,
      id: null,
      _links: {
        self: {
          href: url,
        },
      },
    })
  },
  delete: async ({ url }: IDeltakerVars) => {
    return queryApi.delete(url)
  },
}
