import React, { useEffect, useState } from 'react'
import {
  Column,
  Row,
  Button,
  ContrastBox,
  Text,
  LoadingDots,
} from '@mattilsynet/mt-ui'
import './style.css'
import { TypeTilsynModal } from '../../modals/type-tilsyn-modal'
import { useDispatch } from 'react-redux'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { useTypedSelector } from '../../common/custom-hooks'
import { IStoreState } from '../../reducers/types'
import { kvitteringSelectors } from '../../ducks/kvittering/selectors'
import { offlineInTypeTilsynSelect, useSingleToast } from '../../common/toast'
import { useKvittering, useUpdateKvittering } from '../../features/kvitteringer'
import { useKvitteringId } from '../../common/kvittering-context'

interface ITypeTilsynProps {
  onChange: (type) => void
  currentType: string
  currentTypeTypeTilsynBeskrivelse?: string
  disabled: boolean
}

const TypeTilsyn = ({
  onChange,
  currentType,
  currentTypeTypeTilsynBeskrivelse,
  disabled,
}: ITypeTilsynProps) => {
  const dispatch = useDispatch()

  const kvitteringId = useKvitteringId()

  const { data: kvittering } = useKvittering(kvitteringId)

  const singleToast = useSingleToast()
  const [isTypeTilsynModalOpen, setIsTypeTilsynModalOpen] = useState(false)
  const [typeTilsyn, setTypeTilsyn] = useState<string | undefined>(currentType)
  const tilsynstyper = useTypedSelector(
    (state: IStoreState) => state.kvittering.tilsynstyper
  )
  const typeBeskrivelse = useTypedSelector(
    kvitteringSelectors.getTypeBeskrivelse(currentType)
  )
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)

  const { mutate: updateKvittering } = useUpdateKvittering()

  useEffect(() => {
    if (!tilsynstyper.loaded) {
      dispatch(kvitteringActions.fetchTilsynstyper())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTypeTilsyn(currentType)
  }, [currentType])

  const setTypeTilsynBeskrivelse = (beskrivelse: string) => {
    if (kvittering) {
      updateKvittering({
        kvittering: { ...kvittering, typeTilsynBeskrivelse: beskrivelse },
      })
    }
  }

  const openTilsynstypeModal = () => {
    if (isOffline) {
      return singleToast.showToast(offlineInTypeTilsynSelect())
    } else {
      setIsTypeTilsynModalOpen(true)
    }
  }

  const typeTilsynButtonContent = () => {
    if (disabled) {
      return 'Kvittering blir opprettet'
    }

    if (currentType) {
      return 'Endre type tilsyn'
    }

    return 'Velg type tilsyn'
  }

  return (
    <div id="type-tilsyn">
      <ContrastBox noBorders backgroundColor="white">
        <Column fluid spacing={2}>
          <Row className="subtitle">
            <h3>Type tilsyn</h3>
          </Row>
          <>
            <TypeTilsynModal
              isOpen={isTypeTilsynModalOpen}
              onCancel={() => setIsTypeTilsynModalOpen(false)}
              currentType={typeTilsyn}
              currentTypeTilsynBeskrivelse={currentTypeTypeTilsynBeskrivelse}
              onClick={(type) => {
                setTypeTilsyn(type)
                onChange(type)
              }}
              onChangeBeskrivelse={setTypeTilsynBeskrivelse}
              tilsynstyper={tilsynstyper}
              errorAction={() =>
                dispatch(kvitteringActions.fetchTilsynstyper())
              }
            />
            <Text>{typeBeskrivelse}</Text>
            {currentTypeTypeTilsynBeskrivelse && (
              <Text>{currentTypeTypeTilsynBeskrivelse}</Text>
            )}
            <Row justify="center" className="button-add">
              <Button
                icon={disabled ? <LoadingDots /> : undefined}
                iconAfter={disabled}
                fill
                secondary
                disabled={disabled}
                onClick={() => openTilsynstypeModal()}
              >
                {typeTilsynButtonContent()}
              </Button>
            </Row>
          </>
        </Column>
      </ContrastBox>
    </div>
  )
}
export default TypeTilsyn
