import React from 'react'
import Svg, { IIconTemplate } from '@mattilsynet/mt-ui/dist/icons/icon-template'

export const AttachIcon = ({ color, ...rest }: IIconTemplate) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 125 100"
    height="100%"
    fill={color}
    {...rest}
  >
    <path
      d="M113.242 64.7849C124.277 53.7498 124.277 35.8787 113.242 24.8435C103.476 15.0779 88.0854 13.8084 76.855 21.8357L76.5425 22.0505C73.73 24.0623 73.0854 27.9685 75.0972 30.7615C77.1089 33.5544 81.0151 34.2185 83.8081 32.2068L84.1206 31.9919C90.3901 27.5193 98.9644 28.2224 104.394 33.6716C110.546 39.824 110.546 49.7849 104.394 55.9373L82.48 77.8904C76.3276 84.0427 66.3667 84.0427 60.2144 77.8904C54.7651 72.4412 54.062 63.8669 58.5347 57.6169L58.7495 57.3044C60.7612 54.4919 60.0972 50.5857 57.3042 48.5935C54.5112 46.6013 50.5855 47.2459 48.5933 50.0388L48.3784 50.3513C40.3315 61.5623 41.6011 76.9529 51.3667 86.7185C62.4019 97.7537 80.2729 97.7537 91.3081 86.7185L113.242 64.7849ZM11.7573 60.2146C0.722168 71.2498 0.722168 89.1209 11.7573 100.156C21.5229 109.922 36.9136 111.191 48.144 103.164L48.4565 102.949C51.269 100.937 51.9136 97.031 49.9019 94.238C47.8901 91.4451 43.9839 90.781 41.1909 92.7927L40.8784 93.0076C34.6089 97.4802 26.0347 96.7771 20.605 91.3279C14.4526 85.156 14.4526 75.1951 20.605 69.0427L42.519 47.1091C48.6714 40.9568 58.6323 40.9568 64.7847 47.1091C70.2339 52.5584 70.937 61.1326 66.4644 67.4021L66.2495 67.7146C64.2378 70.5271 64.9019 74.4334 67.6948 76.4255C70.4878 78.4177 74.4136 77.7732 76.4058 74.9802L76.6206 74.6677C84.6675 63.4373 83.3979 48.0466 73.6323 38.281C62.5972 27.2459 44.7261 27.2459 33.6909 38.281L11.7573 60.2146Z"
      fill="black"
    />
  </Svg>
)
