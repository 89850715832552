import {
  Column,
  Text,
  ErrorBox,
  FloatingButton,
  ImageGroup,
  FolderIcon,
  Row,
  Button,
} from '@mattilsynet/mt-ui'
import { v4 as uuidV4 } from 'uuid'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTypedSelector } from '../../common/custom-hooks'
import { IStoreState } from '../../reducers/types'
import { useDispatch } from 'react-redux'
import ImageNavigationModal from '../image-navigation-modal'
import { uiActions } from '../../ducks/ui/actions'
import { Camera as MTCamera } from '@mattilsynet/mt-ui/dist/icons/camera'
import Camera from '../camera'
import './style.css'
import {
  useGalleryImages,
  useUploadBildeToKvittering,
} from '../../features/bilder'
import { themePicker } from '../../common/theme'
import styled from 'styled-components'
import { KvitteringImage } from './components/kvittering-image'
import { ObservasjonImage } from './components/observasjon-image'
import { useKvitteringId } from '../../common/kvittering-context'
import { userSelectors } from '../../ducks/user/selectors'
import { BildeGroupedItem } from '../../features/bilder/types'
import { ImageActionsMenu } from '../../features/bilder-multi-action/components/image-actions-menu'

const CameraButtonContainer = styled.div`
  & .FloatingButton {
    background-color: ${(props) => props.theme.primary};
  }
`
export const GalleryPage = () => {
  const [selectedImages, setSelectedImages] = useState<BildeGroupedItem[]>([])
  const [isSelectingImages, setIsSelectingImages] = useState(false)

  const dispatch = useDispatch()

  const kvitteringId = useKvitteringId()
  const isDesktop = useTypedSelector((state: IStoreState) => state.ui.isDesktop)
  const environment = useTypedSelector((state) => state.ui.environment)
  const currentUsername = useTypedSelector(userSelectors.getAuthUsername)

  const cameraRef = useRef<HTMLInputElement>()

  const [allImages] = useGalleryImages(kvitteringId)
  const bildeIdToObservasjonIdMapper = useMemo(
    () =>
      allImages.reduce(
        (map, { id, observasjonId }) => ({
          ...map,
          [id]: observasjonId,
        }),
        {} as Record<string, number | undefined>
      ),
    [allImages]
  )

  const { mutate: uploadBilde } = useUploadBildeToKvittering(
    kvitteringId,
    currentUsername!
  )

  const selectButtonText = isSelectingImages ? 'Avbryt' : 'Velg bilder'

  const clearSelectedImages = () => setSelectedImages([])

  const toggleSelectingImages = useCallback(() => {
    if (isSelectingImages) {
      clearSelectedImages()
    }

    setIsSelectingImages((current) => !current)
  }, [isSelectingImages, setIsSelectingImages])

  const onOpenImageNavigation = useCallback(
    (imageId: string) => {
      const imageIds = allImages.map((image) => image.id)
      dispatch(uiActions.setImageNavigationModal(true, imageId, imageIds))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, allImages.length]
  )

  const openCamera = useCallback(() => {
    if (!cameraRef.current) return

    cameraRef.current.value = ''
    cameraRef.current.click()
  }, [cameraRef])

  const onPictureTaken = useCallback(
    (imageFiles: File[]) => {
      for (const file of imageFiles) {
        uploadBilde({ bildeId: uuidV4(), bilde: file })
      }
    },
    [uploadBilde]
  )

  const selectedImagesRef = useRef(selectedImages)
  useEffect(() => {
    if (selectedImagesRef.current.length !== selectedImages.length) {
      selectedImagesRef.current = selectedImages
    }
  }, [selectedImages])

  const onSelectImage = useCallback(
    (imageId: string) => {
      const observasjonId = bildeIdToObservasjonIdMapper[imageId]
      const typedImage: BildeGroupedItem = { id: imageId, observasjonId }
      const isImageSelected =
        selectedImagesRef.current.filter(
          (image: BildeGroupedItem) => image.id === imageId
        ).length > 0

      if (!isImageSelected) {
        setSelectedImages((current) => [...current, typedImage])
      }

      if (isImageSelected) {
        setSelectedImages((current) =>
          current.filter((image: BildeGroupedItem) => image.id !== imageId)
        )
      }
    },
    [bildeIdToObservasjonIdMapper]
  )

  const onCloseActionMenu = () => {
    clearSelectedImages()
    setIsSelectingImages(false)
  }

  const onClick = isSelectingImages ? onSelectImage : onOpenImageNavigation

  return (
    <div>
      <Column spacing={1} padding={2} minHeight="calc(100vh - 300px)">
        <Text size="heading1" weight="bold" margin={[0, 0, 2, 0]}>
          Bilder i tilsynskvitteringen
        </Text>
        <Row justify="end">
          <Button width="10rem" secondary onClick={toggleSelectingImages}>
            {selectButtonText}
          </Button>
        </Row>

        {allImages.length === 0 ? (
          <ErrorBox errorText="Ingen bilder på tilsynskvitteringen" />
        ) : (
          <>
            <Text>Antall bilder: {allImages.length}</Text>
            <ImageGroup maxColumns={4}>
              {allImages.map(({ id, observasjonId }) => {
                const isSelected = isSelectingImages
                  ? !!selectedImages.find((image) => image.id === id)
                  : undefined

                if (observasjonId === undefined) {
                  return (
                    <KvitteringImage
                      onClick={onClick}
                      isSelected={isSelected}
                      key={id}
                      id={id}
                      kvitteringId={kvitteringId}
                    />
                  )
                }

                return (
                  <ObservasjonImage
                    onClick={onClick}
                    key={id}
                    id={id}
                    isSelected={isSelected}
                    kvitteringId={kvitteringId}
                    observasjonId={observasjonId}
                    hasIndicator
                  />
                )
              })}
            </ImageGroup>
          </>
        )}
      </Column>

      <CameraButtonContainer
        theme={themePicker(environment)}
        className="camera-button"
      >
        <FloatingButton disabled={false} onClick={openCamera}>
          {isDesktop ? (
            <FolderIcon color="white" />
          ) : (
            <MTCamera color="white" />
          )}
        </FloatingButton>
      </CameraButtonContainer>

      <Camera cameraRef={cameraRef} pictureTakenAction={onPictureTaken} />
      <ImageNavigationModal title="Bilder tilknyttet tilsynskvitteringen" />

      {isSelectingImages && (
        <ImageActionsMenu
          selectedImages={selectedImages}
          onClose={onCloseActionMenu}
          clearSelectedImages={clearSelectedImages}
        />
      )}
    </div>
  )
}
