// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tilsynsobjekter-card-info .subtitle {
  font-size: 1.125em;
  font-weight: bold;
}

#tilsynsobjekter-card-info .info-header {
  font-size: 1em;
  font-weight: bold;
}

#tilsynsobjekter-card-info .button-add .Button {
  max-width: 16em;
}
`, "",{"version":3,"sources":["webpack://./src/components/tilsynsobjekter-card-info/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["#tilsynsobjekter-card-info .subtitle {\n  font-size: 1.125em;\n  font-weight: bold;\n}\n\n#tilsynsobjekter-card-info .info-header {\n  font-size: 1em;\n  font-weight: bold;\n}\n\n#tilsynsobjekter-card-info .button-add .Button {\n  max-width: 16em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
