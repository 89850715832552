import * as R from 'ramda'
import { IUiState } from './types'
import { STORE_NAME } from './reducer'
import { IStoreState } from '../../reducers/types'

export const reducerLenses = {
  initializationStatus: () => R.lensPath(['initializationStatus']),
  dateFromFetchMki: () => R.lensPath(['dateFromFetchMki']),
}

export const uiLenses: typeof reducerLenses = R.map(
  (lens) =>
    (...args) =>
      R.compose(R.lensPath([STORE_NAME]), lens(...args)),
  reducerLenses
)

export const uiSelectors = {
  getInitializationStatus: (
    uiState: IStoreState
  ): IUiState['initializationStatus'] =>
    R.view(uiLenses.initializationStatus())(uiState),

  getOpenImageNavigationModal: (
    state: IStoreState
  ): IUiState['openImageNavigationModal'] => state.ui.openImageNavigationModal,

  getHasUpdated: (state: IStoreState): IUiState['hasUpdated'] =>
    state.ui.hasUpdated,
}
