import {
  GET_SAK,
  GET_SAK_SUCCESS,
  GET_SAK_NOT_FOUND,
  GET_SAK_FAILURE,
  SakActionTypes,
  CLEAR_SAK,
  CREATE_SAK,
  CREATE_SAK_SUCCESS,
  CREATE_SAK_FAILED,
} from './types'

export const getSak = (
  sakNumber: string,
  shouldUpdateKvittering = true
): SakActionTypes => ({
  type: GET_SAK,
  sakNumber,
  shouldUpdateKvittering,
})

export const getSakSuccess = (
  sakNumber: string,
  title: string,
  sakClosed: boolean,
  sakTilgang: string,
  sakHjemmel: string
): SakActionTypes => ({
  type: GET_SAK_SUCCESS,
  sakNumber,
  title,
  sakClosed,
  sakTilgang,
  sakHjemmel,
})

export const getSakNotFound = (sakNumber: string): SakActionTypes => ({
  type: GET_SAK_NOT_FOUND,
  sakNumber,
})

export const getSakFailed = (sakNumber: string): SakActionTypes => ({
  type: GET_SAK_FAILURE,
  sakNumber,
})

export const clearSak = (): SakActionTypes => ({
  type: CLEAR_SAK,
})

export const createSak = (
  tittel: string,
  isSkjermet = false
): SakActionTypes => ({
  type: CREATE_SAK,
  tittel,
  isSkjermet,
})

export const createSakSuccess = ({ tittel, sakNumber }): SakActionTypes => ({
  type: CREATE_SAK_SUCCESS,
  tittel,
  sakNumber,
})

export const createSakFailed = (error: string): SakActionTypes => ({
  type: CREATE_SAK_FAILED,
  error,
})
