// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullSizeImage {
  padding: 1.25em 1.25em 5em 1.25em;
}

.fullSizeImage img {
  max-width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/routes/full-image/style.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".fullSizeImage {\n  padding: 1.25em 1.25em 5em 1.25em;\n}\n\n.fullSizeImage img {\n  max-width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
