import React from 'react'
import { useTypedSelector } from '../../common/custom-hooks'
import { useDispatch } from 'react-redux'
import { ModalWrapper } from '../modal-wrapper'
import { uiActions } from '../../ducks/ui/actions'
import { uiSelectors } from '../../ducks/ui/selectors'
import { ImageInfo } from '../../features/bilder'
import { ModalButtonsContainer } from '../modal-buttons-container'
import { ModalButton } from '../modal-button'

const ImageNavigationModal = ({
  title,
  editable = true,
}: {
  title: string
  editable?: boolean
}) => {
  const { isOpen } = useTypedSelector(uiSelectors.getOpenImageNavigationModal)

  const dispatch = useDispatch()

  return (
    <ModalWrapper
      pauseFocusTrap={true}
      isOpen={isOpen}
      onCancel={() =>
        dispatch(uiActions.setImageNavigationModal(false, '', []))
      }
      fullscreenMobile
      paddingHorizontal={0}
      className={'image-navigation-modal'}
      title={title}
    >
      <ImageInfo editable={editable} />

      <ModalButtonsContainer>
        <ModalButton
          secondary
          onClick={() =>
            dispatch(uiActions.setImageNavigationModal(false, '', []))
          }
        >
          Lukk
        </ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>
  )
}

export default ImageNavigationModal
