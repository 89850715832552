import React from 'react'
import { Column, Header, Text } from '@mattilsynet/mt-ui'
import { IPrivatPersonAdresse } from '../../ducks/kvittering/types'

interface IPrivatPersonAdresseInfo {
  adresse?: IPrivatPersonAdresse
  tittel: string
}

export const PrivatPersonAdresseInfo = ({
  adresse,
  tittel,
}: IPrivatPersonAdresseInfo) => {
  if (!adresse) return null

  return (
    <Column justify={'flex-start'} align={'flex-start'} spacing={1}>
      <Header as="h3" size="normal">
        {tittel}
      </Header>
      <Column>
        <Text textAlign="left">{adresse.gateadresse}</Text>
      </Column>
      <Column>
        <Text>{`${adresse.postNr || ''} ${adresse.poststed || ''}`}</Text>
      </Column>
    </Column>
  )
}
