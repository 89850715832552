import { MutationKey, useMutation } from '@tanstack/react-query'
import {
  useKvittering,
  useOptimisticDebounceKvitteringMutate,
} from '../../kvitteringer'
import { observasjonKeys } from '../queries/helpers'
import {
  IObservasjon,
  IObservasjonMutateVars,
  IObservasjonVars,
} from '../types'
import { updateKontrollpunktObservasjonerFromKvittering } from '../cache-helpers'
import { useCallback } from 'react'
import { DEBOUNCE_MS } from '../../../constants'
import { AxiosResponse } from 'axios'
import { getObservasjonById } from '../helpers'

export {
  useObservasjon,
  useAddObservasjon,
  useUpdateObservasjon,
  useRemoveObservasjon,
}

const useObservasjon = (kvitteringId: string, observasjonId: number) => {
  return useKvittering(kvitteringId, {
    select: (kvittering) => getObservasjonById(kvittering, observasjonId),
  })
}

const useObservasjonMutation = (
  mutationKey: MutationKey,
  kvitteringId: string
) => {
  const { mutate, ...rest } = useMutation<
    AxiosResponse<null>,
    string | Error,
    IObservasjonMutateVars,
    { previousObservasjoner: IObservasjon[] }
  >({ mutationKey })

  return {
    ...rest,
    mutate: useCallback(
      (variables: IObservasjonVars) => mutate({ kvitteringId, ...variables }),
      [mutate, kvitteringId]
    ),
  }
}

const useAddObservasjon = (kvitteringId: string) =>
  useObservasjonMutation(observasjonKeys.add(kvitteringId), kvitteringId)

const useUpdateObservasjon = (kvitteringId: string) => {
  const update = useObservasjonMutation(
    observasjonKeys.update(kvitteringId),
    kvitteringId
  )

  const optimisticDebounceUpdate = useOptimisticDebounceKvitteringMutate(
    update.mutate,
    kvitteringId,
    (kvittering, { kontrollpunkt, observasjon }) => {
      if (!kvittering) return kvittering

      return updateKontrollpunktObservasjonerFromKvittering(
        kvittering,
        kontrollpunkt,
        (observasjoner) => {
          for (const oldObservasjon of observasjoner) {
            if (oldObservasjon.id === observasjon.id) {
              oldObservasjon.tekst = observasjon.tekst
              break
            }
          }

          return observasjoner
        }
      )
    }
  )

  const updateBeskrivelseFn = useCallback(
    (data: IObservasjonVars) => {
      const updateKey = JSON.stringify(observasjonKeys.update(kvitteringId)[0])

      optimisticDebounceUpdate(data, {
        debounceMs: DEBOUNCE_MS,
        key: `${updateKey}_${data.observasjon.id}`,
      })
    },
    [kvitteringId, optimisticDebounceUpdate]
  )

  return { ...update, mutate: updateBeskrivelseFn }
}

const useRemoveObservasjon = (kvitteringId: string) =>
  useObservasjonMutation(observasjonKeys.remove(kvitteringId), kvitteringId)
