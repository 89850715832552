import React, { useEffect } from 'react'
import { Row, Column, Grid, GridItem, TextLink } from '@mattilsynet/mt-ui'
import { IKvitteringData } from '../../ducks/kvittering/types'
import { SammenstillingInfo } from '../../components/sammenstilling-info'
import { isArkivertStatus, isFerdigstiltStatus } from '../../utils/kvittering'
import { Sammenstilling } from '@mattilsynet/tk-sammenstilling'
import { queryApi } from '../../api'

interface ISammenstillingView {
  kvittering: IKvitteringData
  goBack: () => void
  showSammenstilling: boolean
  goToStartTilsynSammenstillingRoute: () => void
}

const fetchApi = {
  get: async <T,>(url: string) => (await queryApi.get<T>(url)).data,
}

export const SammenstillingView = ({
  kvittering,
  goBack,
  showSammenstilling,
  goToStartTilsynSammenstillingRoute,
}: ISammenstillingView) => {
  useEffect(() => {
    if (!showSammenstilling && kvittering.id) {
      goToStartTilsynSammenstillingRoute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kvittering.id, showSammenstilling])

  return (
    <Grid id="sammenstilling">
      <GridItem xl={[1, -1]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
        <Row>
          <TextLink leftArrow onClick={goBack}>
            Tilbake
          </TextLink>
        </Row>
      </GridItem>
      {(isArkivertStatus(kvittering.status) ||
        isFerdigstiltStatus(kvittering.status)) && (
        <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
          <SammenstillingInfo kvittering={kvittering} />
        </GridItem>
      )}
      <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
        <Grid>
          <GridItem xl={[2, -2]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
            <Column className={'sammenstilling-content'}>
              <Sammenstilling
                kvitteringId={kvittering.id!}
                fetchApi={fetchApi}
              />
            </Column>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  )
}
