import React, { useContext } from 'react'
import styled from 'styled-components'

import { ThemeContext } from '@mattilsynet/mt-ui'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'

// TODO: Remove when this issue is fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2452
// eslint-disable-next-line
interface IStyleProps {
  theme: IThemeColors
}

const StyledHorizontalLine = styled.div<IStyleProps>`
  box-sizing: border-box;
  border-bottom: ${({ theme }) => `4px solid ${theme.secondary}`};
`

const HorizontalLine = () => {
  const theme = useContext(ThemeContext)
  return <StyledHorizontalLine theme={theme} />
}

export default HorizontalLine
