import { v1 as uuidv1 } from 'uuid'
export const options = [
  {
    value: '1',
    label: 'Ja',
  },
  {
    value: '0',
    label: 'Ikke aktuelt',
  },
]

export const ANNET_BILDER_ID = uuidv1()
export const ANNET_REGISTRERT_INNHOLD_ID = uuidv1()

export const optionsBilderGjennomgaat = [
  {
    value: 'Det ble ikke tatt bilder på tilsynet',
  },
  {
    value: 'Ingen fra virksomheten til stede',
  },
  {
    value: 'Vanskelig kommunikasjon',
  },
  {
    value: 'Tekniske problemer',
  },
  {
    value: ANNET_BILDER_ID,
    label: 'Annet',
  },
]

export const optionsRegistrertInnholdGjennomgaatt = [
  {
    value: 'Ingen fra virksomheten til stede',
  },
  {
    value: 'Vanskelig kommunikasjon',
  },
  {
    value: 'Tekniske problemer',
  },
  {
    value: ANNET_REGISTRERT_INNHOLD_ID,
    label: 'Annet',
  },
]
