import Keycloak from '@mattilsynet/mt-keycloak'
import { userSelectors } from '../ducks/user/selectors'
import store from '../reducers/store'
import { userActions } from '../ducks/user/actions'
import { ReplaySubject } from 'rxjs'
import { authSubject } from './keycloak'
import { onlineManager } from '@tanstack/react-query'
import { navigate } from '../common/navigation'

export const authReadySubject = new ReplaySubject<boolean>(1)

onlineManager.setOnline(window.navigator.onLine)

window.addEventListener('offline', () => {
  authReadySubject.next(false)
  onlineManager.setOnline(false)
})

window.addEventListener('online', () => {
  authSubject.subscribe(async (keycloak) => {
    await initKeycloak(keycloak)
  })
})

const handleAuth = async (keycloak: Keycloak) => {
  const { isAuthenticated, hasRealmAccess } = await keycloak.init({
    expectedRole: 'tilsynskvittering-user',
  })

  if (isAuthenticated && hasRealmAccess) {
    const user = userSelectors.getAuthUser(store.getState())
    const profile = await keycloak.loadUserProfile()

    if (user === undefined || user.username !== profile.username) {
      store.dispatch(
        userActions.setUser(
          profile.username,
          `${profile.firstName} ${profile.lastName} `,
          'N/A'
        )
      )
    }
  } else if (isAuthenticated) {
    navigate('/access-denied')
  } else if (window.navigator.onLine) {
    keycloak.redirectToLogin(`${window.location.origin}/login/callback`)
  } else {
    // TODO fix this later, temp solution to avoid infinite login due to undefined username
    store.dispatch(userActions.setUser('N/A', '', 'N/A'))
  }

  return { isAuthenticated, hasRealmAccess }
}

export const initKeycloak = async (keycloak: Keycloak) => {
  let result = {
    isAuthenticated: keycloak.keycloak.authenticated,
    hasRealmAccess: !!keycloak.keycloak.realm,
  }

  if (!keycloak.keycloak.didInitialize) {
    result = await handleAuth(keycloak)
  }

  if (window.navigator.onLine) {
    authReadySubject.next(true)
    onlineManager.setOnline(true)
  }

  return {
    isAuthenticated: result.isAuthenticated,
    hasRealmAccess: result.hasRealmAccess,
  }
}
