import { queryApi } from '../../../api'
import { ENDPOINT } from '../../../constants'
import { ISakData } from '../types'

export const sakApi = {
  get: (saknummer?: string) => {
    if (!saknummer) {
      return { data: null }
    }
    const url = `${ENDPOINT.ARKIV}/arkiv/saker/${saknummer}`

    return queryApi.get<ISakData>(url)
  },
}
