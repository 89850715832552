import { CheckboxItem, Row, RowItem, Text } from '@mattilsynet/mt-ui'
import Textarea from '@mattilsynet/mt-ui/dist/elements/text-area'
import React, { ChangeEvent, useCallback } from 'react'
import { useTypedSelector } from '../../../common/custom-hooks'
import { kvitteringActions } from '../../../ducks/kvittering/actions'
import { useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { IStoreState } from '../../../reducers/types'

export interface IIngenTilstede {
  disabled?: boolean
}

const selectIngenTilStedeData = createSelector(
  (state: IStoreState) => state.kvittering.selectedKvittering.data,
  ({ ingenTilStede, ingenTilStedeBegrunnelse, virksomhetsOrgNr }) => ({
    ingenTilStede,
    ingenTilStedeBegrunnelse,
    virksomhetsOrgNr,
  })
)

const IngenTilstede = ({ disabled }: IIngenTilstede) => {
  const dispatch = useDispatch()
  const { ingenTilStede, ingenTilStedeBegrunnelse, virksomhetsOrgNr } =
    useTypedSelector(selectIngenTilStedeData)

  const onIngenTilStede = useCallback(() => {
    dispatch(
      kvitteringActions.updateCurrentKvittering({
        ingenTilStede: !ingenTilStede,
        ingenTilStedeBegrunnelse: ingenTilStede ? ingenTilStedeBegrunnelse : '',
      })
    )
  }, [ingenTilStede, ingenTilStedeBegrunnelse, dispatch])

  const onIngenTilStedeBegrunnelse = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(
        kvitteringActions.updateCurrentKvittering({
          ingenTilStedeBegrunnelse: event.currentTarget.value,
        })
      )
    },
    [dispatch]
  )

  return (
    <>
      <Row>
        <CheckboxItem
          onClick={onIngenTilStede}
          selected={!!ingenTilStede}
          disabled={disabled}
        >
          <Text size="normal">
            {virksomhetsOrgNr
              ? 'Ingen fra virksomheten er til stede'
              : 'Ingen privatperson er til stede'}
          </Text>
        </CheckboxItem>
      </Row>

      {ingenTilStede && (
        <Row style={{ width: '97%' }}>
          <RowItem flex={1}>
            <Textarea
              onChange={onIngenTilStedeBegrunnelse}
              placeholder="Forklaring"
              value={ingenTilStedeBegrunnelse ? ingenTilStedeBegrunnelse : ''}
              label="Forklaring på hvorfor ingen er til stede"
              errorText="Forklaringen vil fremkomme i tilsynskvitteringen."
            />
          </RowItem>
        </Row>
      )}
    </>
  )
}

export default IngenTilstede
