import { AxiosResponse } from 'axios'
import { queryClient } from '../../../api/query-client'
import {
  DependencyNotPersisted,
  getIdFromSelfUrl,
  shouldIgnoreError,
} from '../../../common/query'
import { dangerToast } from '../../../common/toast'
import store from '../../../reducers/store'
import { getPersistedKontrollpunkt } from '../../kontrollpunkter'
import { kvitteringQueryHelpers } from '../../kvitteringer/queries/helpers'
import { offlineLexicon } from '../../offline-lexicon'
import {
  getKontrollpunktObservasjonerFromKvittering,
  updateKontrollpunktObservasjonerFromKvittering,
} from '../cache-helpers'
import {
  IObservasjon,
  IObservasjonMutateVars,
  ObservasjonMutationOptions,
} from '../types'
import { observasjonKeys } from './helpers'
import { observasjonApi } from './observasjon-api'
import { ENDPOINT } from '../../../constants'
import { IKontrollpunkt } from '../../kontrollpunkter/types'

/**
 * Optimistically updates observasjoner
 * in the cache.
 *
 * @returns Previous observasjoner (Context that
 * is passed to onError).
 */
const onMutate: (
  updateObservasjoner: (
    observasjoner: IObservasjon[],
    observasjon: IObservasjon
  ) => IObservasjon[]
) => ObservasjonMutationOptions['onMutate'] =
  (updateObservasjoner) =>
  async ({ kvitteringId, kontrollpunkt, observasjon }) => {
    await kvitteringQueryHelpers.cancelKvitteringQuery(kvitteringId)

    const kvittering = kvitteringQueryHelpers.getKvitteringCache(kvitteringId)
    const previousObservasjoner = kvittering
      ? getKontrollpunktObservasjonerFromKvittering(
          kvittering,
          kontrollpunkt
        ) ?? []
      : []

    kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) =>
      updateKontrollpunktObservasjonerFromKvittering(
        draft,
        kontrollpunkt,
        (observasjoner) => updateObservasjoner(observasjoner, observasjon)
      )
    )
    return { previousObservasjoner }
  }

/** Displays error toast and reverts cache to previous observasjoner. */
const onError: ObservasjonMutationOptions['onError'] = (
  error,
  { kvitteringId, kontrollpunkt },
  context
) => {
  if (shouldIgnoreError(error)) {
    return
  }

  store.dispatch(
    dangerToast('Det har skjedd en feil med observasjonene. Kunne ikke lagre.')
  )

  kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (kvittering) =>
    updateKontrollpunktObservasjonerFromKvittering(
      kvittering,
      kontrollpunkt,
      (observasjoner) => context?.previousObservasjoner ?? observasjoner
    )
  )
}

// Leaving it here for now, in case we need it later
/** Refetches kvittering either on success or error. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onSettled: ObservasjonMutationOptions['onSettled'] = (
  _,
  __,
  { kvitteringId }
) => kvitteringQueryHelpers.invalidateKvittering(kvitteringId)

/** Finds and merges persisted kontrollpunkt to the variables. */
const withPersistedKontrollpunkt =
  (request: (variables: IObservasjonMutateVars) => Promise<AxiosResponse>) =>
  async (variables: IObservasjonMutateVars) => {
    const { kvitteringId, kontrollpunkt } = variables

    let persistedKontrollpunkt: IKontrollpunkt | undefined = kontrollpunkt

    const isOfflineKontrollpunkt =
      kontrollpunkt.id === undefined ||
      kontrollpunkt._links.observasjoner.href === ''

    if (isOfflineKontrollpunkt) {
      persistedKontrollpunkt = await getPersistedKontrollpunkt(
        kvitteringId,
        kontrollpunkt.id + ''
      )
    }

    if (!persistedKontrollpunkt) {
      throw new DependencyNotPersisted()
    }

    return request({
      ...variables,
      kontrollpunkt: persistedKontrollpunkt,
    })
  }

/** Gets mutation url from persisted observasjon. */
const getMutationUrl = (observasjon: IObservasjon, kvitteringId: string) => {
  let mutationUrl = `${ENDPOINT.TILSYNSKVITTERING}/observasjoner/${observasjon.id}`
  let persistedId = observasjon.id

  if (observasjon.id < 0) {
    const localObservasjon = offlineLexicon.get(
      offlineLexicon.types.observasjon,
      String(observasjon.id),
      kvitteringId
    )
    mutationUrl = localObservasjon?._links.self.href ?? mutationUrl
    persistedId = localObservasjon?.id ?? observasjon.id
  }

  if (!mutationUrl) {
    throw new DependencyNotPersisted()
  }

  return [mutationUrl, persistedId] as const
}

/** Gets and merges the mutation url with the variables. */
const withMutationUrl =
  (request: (variables: IObservasjonMutateVars) => Promise<AxiosResponse>) =>
  (variables: IObservasjonMutateVars) => {
    const { kvitteringId, observasjon } = variables

    let url = ''
    let pId = 0
    try {
      const [mutationUrl, persistedId] = getMutationUrl(
        observasjon,
        kvitteringId
      )
      url = mutationUrl
      pId = persistedId ?? observasjon.id
    } catch (error) {
      return Promise.reject(error)
    }

    return request({
      ...variables,
      observasjon: {
        ...observasjon,
        id: pId,
        _links: {
          self: {
            href: url,
          },
        },
      },
    })
  }

// Add observasjon
queryClient.setMutationDefaults(observasjonKeys.addBase(), {
  mutationFn: withPersistedKontrollpunkt(observasjonApi.post),
  onMutate: onMutate((observasjoner, observasjon) => {
    return [...observasjoner, observasjon]
  }),
  onSuccess: (res, { kvitteringId, observasjon }) => {
    const persistedId = getIdFromSelfUrl(res.headers.location) ?? observasjon.id
    const newObservasjon = {
      ...observasjon,
      id: persistedId,
      _links: {
        self: {
          href: `${ENDPOINT.TILSYNSKVITTERING}/observasjoner/${persistedId}`,
        },
      },
    } satisfies IObservasjon

    offlineLexicon.set(
      offlineLexicon.types.observasjon,
      observasjon.id + '',
      kvitteringId,
      newObservasjon
    )
  },
  onError: onError,
  onSettled: (_, __, { kvitteringId }) => {
    if (queryClient.isMutating() > 1) {
      return
    }

    return kvitteringQueryHelpers.invalidateKvittering(kvitteringId)
  },
} satisfies ObservasjonMutationOptions)

// Update observasjon beskrivelse
queryClient.setMutationDefaults(observasjonKeys.updateBase(), {
  mutationFn: withMutationUrl(observasjonApi.put),
  onMutate: onMutate((observasjoner, observasjon) => {
    const oldObservasjon = observasjoner.find(
      (oldObs) => oldObs.id === observasjon.id
    )

    if (oldObservasjon) {
      oldObservasjon.tekst = observasjon.tekst
    }

    return observasjoner
  }),
  onError: onError,
} satisfies ObservasjonMutationOptions)

// Remove observasjon
queryClient.setMutationDefaults(observasjonKeys.removeBase(), {
  mutationFn: withMutationUrl(observasjonApi.delete),
  onMutate: onMutate((observasjoner, observasjon) =>
    observasjoner.filter((o) => o.id !== observasjon.id)
  ),
  onError: onError,
} satisfies ObservasjonMutationOptions)
