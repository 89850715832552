// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-content {
  padding: 1.25em 1.25em 1.25em 1.25em;
  display: grid;
  grid: auto-flow min-content / repeat(12, 1fr);
  grid-gap: 1.25em;
  min-height: 80vh;
  max-width: 88.5em;
  margin: 0 auto;
  min-width: 0;
}

.page-content > main {
  display: grid;
  grid: inherit;
  grid-gap: inherit;
}

.page-content > *,
.page-content > main > * {
  grid-column: 1 / -1;
  min-height: 0;
  min-width: 0;
}

@media screen and (max-width: 1023px) {
  .page-content {
    grid: auto-flow min-content / repeat(8, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .page-content {
    padding: 0.625em 0.625em 5em 0.625em;
    grid: auto-flow min-content / repeat(4, 1fr);
    grid-gap: 0.625em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/tilsynsobjektinfo-page/style.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,aAAa;EACb,6CAA6C;EAC7C,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,aAAa;EACb,iBAAiB;AACnB;;AAEA;;EAEE,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE;IACE,oCAAoC;IACpC,4CAA4C;IAC5C,iBAAiB;EACnB;AACF","sourcesContent":[".page-content {\n  padding: 1.25em 1.25em 1.25em 1.25em;\n  display: grid;\n  grid: auto-flow min-content / repeat(12, 1fr);\n  grid-gap: 1.25em;\n  min-height: 80vh;\n  max-width: 88.5em;\n  margin: 0 auto;\n  min-width: 0;\n}\n\n.page-content > main {\n  display: grid;\n  grid: inherit;\n  grid-gap: inherit;\n}\n\n.page-content > *,\n.page-content > main > * {\n  grid-column: 1 / -1;\n  min-height: 0;\n  min-width: 0;\n}\n\n@media screen and (max-width: 1023px) {\n  .page-content {\n    grid: auto-flow min-content / repeat(8, 1fr);\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .page-content {\n    padding: 0.625em 0.625em 5em 0.625em;\n    grid: auto-flow min-content / repeat(4, 1fr);\n    grid-gap: 0.625em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
