// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#virksomhet-card-item {
  padding-bottom: 2em;
}

#virksomhet-card-item .name {
  font-size: 1.25em;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: bold;
  text-align: center;
}

#virksomhet-card-item .orgNr {
  font-size: 1.25em;
}
`, "",{"version":3,"sources":["webpack://./src/components/virksomhet-card-item/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["#virksomhet-card-item {\n  padding-bottom: 2em;\n}\n\n#virksomhet-card-item .name {\n  font-size: 1.25em;\n  text-transform: uppercase;\n  letter-spacing: 0.05rem;\n  font-weight: bold;\n  text-align: center;\n}\n\n#virksomhet-card-item .orgNr {\n  font-size: 1.25em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
