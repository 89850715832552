// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tilsynsobjekt-item .activity-header {
  font-size: 1.125em;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-weight: 600;
  text-align: center;
}

#tilsynsobjekt-item .activity-subtitle {
  font-size: 1.125em;
}

#tilsynsobjekt-item .activity-info {
  font-weight: 300;
  text-align: center;
}

#tilsynsobjekt-item {
  row-gap: 1rem;
}

@media screen and (max-width: 767px) {
  #tilsynsobjekt-item .button-container {
    margin-top: 1em;
    align-self: auto;
  }
}

@media screen and (min-width: 768px) {
  #tilsynsobjekt-item {
    flex-wrap: wrap;
  }

  #tilsynsobjekt-item > .info-box-container,
  #tilsynsobjekt-item > #LoadingDots {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/tilsynsobjekt-item/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;;IAEE,WAAW;EACb;AACF","sourcesContent":["#tilsynsobjekt-item .activity-header {\n  font-size: 1.125em;\n  text-transform: uppercase;\n  letter-spacing: 0.05rem;\n  font-weight: 600;\n  text-align: center;\n}\n\n#tilsynsobjekt-item .activity-subtitle {\n  font-size: 1.125em;\n}\n\n#tilsynsobjekt-item .activity-info {\n  font-weight: 300;\n  text-align: center;\n}\n\n#tilsynsobjekt-item {\n  row-gap: 1rem;\n}\n\n@media screen and (max-width: 767px) {\n  #tilsynsobjekt-item .button-container {\n    margin-top: 1em;\n    align-self: auto;\n  }\n}\n\n@media screen and (min-width: 768px) {\n  #tilsynsobjekt-item {\n    flex-wrap: wrap;\n  }\n\n  #tilsynsobjekt-item > .info-box-container,\n  #tilsynsobjekt-item > #LoadingDots {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
