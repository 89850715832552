import dayjs from 'dayjs'
import ExifReader from 'exifreader'
import {
  ALLOWED_IMAGE_FILE_TYPES,
  CAPTURE_TIME_FROM,
  IMAGE_LOCATION_ACCURACY_CUTOFF,
  MAX_IMAGE_SIZE_IN_MB,
} from '../../constants'
import store from '../../reducers/store'
import { ensureActualFileExtension } from '../../common/images'
import { bildeActions, bildeSelectors } from './bilde-slice'
import { BildeMetadataTuple, BildeSizes, IImage } from './types'
import { pipeAsync } from '../../common/helpers'

export {
  getExifFromBlob,
  createBildeMetadata,
  checkFormat,
  checkSize,
  withCaptureTime,
  withGeoData,
  toValidCoord,
  withRotation,
  withCorrectFileExtension,
  setBildeUrl,
  removeBildeUrl,
  getValidBildeMetadataFn,
}

const getExifFromBlob = (file: File) => ExifReader.load(file)

const createBildeMetadata = (
  bildeId: string,
  kvitteringId: string,
  username: string
): IImage => ({
  id: bildeId,
  currentUser: username,
  app: 'TILSYNSKVITTERING',
  'kategori.app': 'TILSYNSKVITTERING',
  'kategori.type': kvitteringId,
})

const checkFormat = (bilde: File) => {
  const validTypes = ALLOWED_IMAGE_FILE_TYPES.join(', ')

  if (!ALLOWED_IMAGE_FILE_TYPES.includes(bilde.type)) {
    throw new Error(
      `${bilde.type} støttes ikke, filtypen må være en av ${validTypes}`
    )
  }

  return bilde
}

const checkSize = (bilde: File) => {
  if (bilde.size >= MAX_IMAGE_SIZE_IN_MB * 1024 * 1024) {
    throw new Error('Bildefilen er for stor, maksimal størrelse er 18MB.')
  }

  return bilde
}

const withCaptureTime = async ([
  bildeMetadata,
  bilde,
]: BildeMetadataTuple): Promise<BildeMetadataTuple> => {
  const exifData = await getExifFromBlob(bilde)

  let captureTime = dayjs(
    (exifData['DateTimeOriginal']?.value as string) ?? '',
    'YYYY:MM:DD HH:mm:ss'
  )

  if (!captureTime.isValid()) {
    captureTime = dayjs()
  }

  if (dayjs(captureTime).isBefore(dayjs(CAPTURE_TIME_FROM))) {
    throw new Error(
      'Du kan ikke velge bilder som ble tatt for mer enn 7 dager siden'
    )
  }

  const updatedMetadata = {
    ...bildeMetadata,
    captureTime: captureTime.toISOString(),
  }

  return [updatedMetadata, bilde]
}

const withGeoData = async ([
  bildeMetadata,
  bilde,
]: BildeMetadataTuple): Promise<BildeMetadataTuple> => {
  if (!store.getState().ui.isDesktop) {
    try {
      const { latitude, longitude, accuracy } =
        await getGeolocationFromBrowser()
      // check if accuracy is within cutoff
      if (accuracy > IMAGE_LOCATION_ACCURACY_CUTOFF) {
        return [bildeMetadata, bilde]
      }

      // check if latitude and longitude exists
      if (!latitude || !longitude) {
        return [bildeMetadata, bilde]
      }

      // check if latitude and longitude are numbers
      if (isNaN(latitude) || isNaN(longitude)) {
        return [bildeMetadata, bilde]
      }

      const updatedMetadata = {
        ...bildeMetadata,
        locationLatitude: toValidCoord(latitude),
        locationLongitude: toValidCoord(longitude),
        accuracy,
      }

      return [updatedMetadata, bilde]
    } catch {
      return [bildeMetadata, bilde]
    }
  }

  try {
    const { latitude, longitude } = await getGeoDataFromFile(bilde)

    const updatedMetadata = {
      ...bildeMetadata,
      locationLatitude: toValidCoord(latitude),
      locationLongitude: toValidCoord(longitude),
    }

    return [updatedMetadata, bilde]
  } catch {
    return [bildeMetadata, bilde]
  }
}

const getGeolocationFromBrowser = () =>
  new Promise<{
    latitude: number
    longitude: number
    accuracy: number
  }>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude, accuracy } }) => {
        resolve({ latitude, longitude, accuracy })
      },
      (error) => {
        reject(error)
      },
      { timeout: 1000, maximumAge: 60000, enableHighAccuracy: true }
    )
  })

const getGeoDataFromFile = async (bilde: File) => {
  const { GPSLatitude, GPSLongitude, GPSLatitudeRef, GPSLongitudeRef } =
    await getExifFromBlob(bilde)

  if (!GPSLatitude || !GPSLongitude) {
    throw new Error('Bilde har ikke geodata')
  }

  const convertToDecimals = (arr: [numerator: number, denominator: number][]) =>
    arr.reduce(
      (decArray, [numerator, denominator]) => [
        ...decArray,
        numerator / denominator,
      ],
      [] as number[]
    )

  const latitudeDecimals = convertToDecimals(
    GPSLatitude?.value as unknown as [numerator: number, denominator: number][]
  )
  const latitude = convertDMSToDD(
    latitudeDecimals[0],
    latitudeDecimals[1],
    latitudeDecimals[2],
    GPSLatitudeRef?.value[0]
  )

  const longitudeDecimals = convertToDecimals(
    GPSLongitude?.value as unknown as [numerator: number, denominator: number][]
  )
  const longitude = convertDMSToDD(
    longitudeDecimals[0],
    longitudeDecimals[1],
    longitudeDecimals[2],
    GPSLongitudeRef?.value[0]
  )

  return { latitude, longitude }
}

/**
 * DMS (Degrees, minutes, seconds)
 * DD (Decimal degrees)
 */
const convertDMSToDD = (
  degrees: number,
  minutes: number,
  seconds: number,
  direction: string
) => {
  let dd = degrees + minutes / 60 + seconds / (60 * 60)
  if (direction == 'S' || direction == 'W') {
    dd = dd * -1
  } // Don't do anything for N or E
  return dd
}

const toValidCoord = (coord: unknown) => {
  if (typeof coord !== 'number' || Number.isNaN(coord)) {
    return 0
  }

  return coord
}

const getRotationInDeg = (exifOrientation: number) => {
  switch (exifOrientation) {
    case 3:
      return 180
    case 6:
      return 90
    case 8:
      return 270
    default:
      return 0
  }
}

const withRotation = async ([
  bildeMetadata,
  bilde,
]: BildeMetadataTuple): Promise<BildeMetadataTuple> => {
  const exifData = await getExifFromBlob(bilde)

  const orientation = (exifData.Orientation?.value as number) ?? 0

  const updatedMetadata = {
    ...bildeMetadata,
    rotate: getRotationInDeg(orientation),
  }

  return [updatedMetadata, bilde]
}

const withCorrectFileExtension = async ([
  bildeMetadata,
  bilde,
]: BildeMetadataTuple): Promise<BildeMetadataTuple> => {
  const newFile = await ensureActualFileExtension(bilde)

  return [bildeMetadata, newFile]
}

const setBildeUrl = (
  kvitteringId: string,
  bildeId: string,
  bildeFile: File,
  size: BildeSizes
) => {
  const bildeUrlData = bildeSelectors.selectBildeUrl(
    kvitteringId,
    bildeId
  )(store.getState())

  if (bildeUrlData?.url) {
    URL.revokeObjectURL(bildeUrlData.url)
  }

  store.dispatch(
    bildeActions.setBildeUrl({
      kvitteringId,
      bildeId,
      url: URL.createObjectURL(bildeFile),
      size,
    })
  )
}

const removeBildeUrl = (kvitteringId: string, bildeId: string) => {
  const bildeUrlData = bildeSelectors.selectBildeUrl(
    kvitteringId,
    bildeId
  )(store.getState())

  if (bildeUrlData) {
    URL.revokeObjectURL(bildeUrlData.url)
  }

  store.dispatch(bildeActions.removeBildeUrl({ kvitteringId, bildeId }))
}

const getValidBildeMetadataFn = (
  bildeId: string,
  kvitteringId: string,
  username: string
) =>
  pipeAsync<File, BildeMetadataTuple>(
    checkFormat,
    checkSize,
    (bilde: File) => {
      const metadata = createBildeMetadata(bildeId, kvitteringId, username)
      return [metadata, bilde] as BildeMetadataTuple
    },
    withCaptureTime,
    withGeoData,
    withRotation,
    withCorrectFileExtension
  )
