import React, { useCallback, useState } from 'react'
import {
  Row,
  Column,
  TextLink,
  AddCircle,
  LoadingSpinner,
  Text,
  LoadingDots,
  ContrastBox,
} from '@mattilsynet/mt-ui'
import { VeiledningItem } from '../../../features/veiledninger/components/veiledning-item'
import { IVeiledning } from '../../../ducks/kvittering/veiledning/types'
import { IStoreState } from '../../../reducers/types'
import { TilsynsobjektMenu } from '../../../components/tilsynsobjekt-menu'
import { useTypedSelector } from '../../../common/custom-hooks'
import { InformationBox } from '../../../components/information-box'
import { LabelWithInfo } from '../../../components/label-with-info'
import { useKvitteringId } from '../../../common/kvittering-context'
import {
  useAddEmptyVeiledninger,
  useAddVeiledning,
  useRemoveEmptyVeiledninger,
  useRemoveVeiledning,
  useVeiledninger,
  veiledningInitialState,
} from '../../../features/veiledninger'
import { DeleteModal } from '../../../modals'
import { useTilsynsobjekter } from '../../../features/tilsynsobjekter'

const VeiledningSlide = () => {
  const veiledningExamples = [
    'når virksomheten etterlever regelverket, men trenger mer informasjon om noe.',
    'når nytt regelverk blir innført.',
    'når virksomheten har spørsmål i forbindelse med oppstart av ny aktivitet.',
    'ved spørsmål om saksbehandling.',
  ]

  const [danger, setDanger] = useState(false)
  const [shouldCheckFocus, setShouldCheckFocus] = useState(false)

  const [selectedVeiledning, setSelectedVeiledning] = useState<IVeiledning>()

  const [selectedTilsynsobjektId, setSelectedTilsynsobjektId] =
    useState<string>()

  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)

  const kvitteringId = useKvitteringId()

  const { data: tilsynsobjekter } = useTilsynsobjekter(kvitteringId)

  const {
    data: veiledninger,
    isLoading,
    isFetching,
  } = useVeiledninger(kvitteringId)

  const selectedTilsynsobjekt =
    selectedTilsynsobjektId === undefined
      ? tilsynsobjekter![0]
      : tilsynsobjekter!.find((to) => to.id === selectedTilsynsobjektId)!

  const filteredVeiledninger =
    veiledninger?.filter(
      (veiledning: IVeiledning) =>
        veiledning.tilsynsobjektId === selectedTilsynsobjekt.id
    ) ?? []

  const {
    mutate: addVeiledning,
    isPaused,
    isPending,
  } = useAddVeiledning(kvitteringId, selectedTilsynsobjekt)

  const { mutate: removeVeiledning } = useRemoveVeiledning(
    kvitteringId,
    selectedTilsynsobjekt
  )

  useAddEmptyVeiledninger(
    filteredVeiledninger,
    selectedTilsynsobjekt.id ?? '',
    addVeiledning,
    !isFetching
  )

  useRemoveEmptyVeiledninger(veiledninger ?? [], removeVeiledning)

  const shouldCreateNewVeiledning = filteredVeiledninger
    ? Boolean(filteredVeiledninger[filteredVeiledninger.length - 1]?.tekst)
    : false

  const onCancelRemoveVeiledning = useCallback(
    () => setSelectedVeiledning(undefined),
    [setSelectedVeiledning]
  )

  const removeSelectedVeileding = useCallback(() => {
    if (selectedVeiledning) {
      removeVeiledning({ veiledning: selectedVeiledning })
    }

    onCancelRemoveVeiledning()
  }, [selectedVeiledning, removeVeiledning, onCancelRemoveVeiledning])

  const onClickRemoveVeiledning = useCallback(
    (veiledning: IVeiledning) => {
      if (veiledning.tekst.length !== 0) {
        return setSelectedVeiledning(veiledning)
      }

      removeVeiledning({ veiledning })
    },
    [setSelectedVeiledning, removeVeiledning]
  )

  const onAddVeiledning = useCallback(() => {
    if (!shouldCreateNewVeiledning && Boolean(filteredVeiledninger?.length)) {
      setDanger(true)
    } else {
      setDanger(false)
      setShouldCheckFocus(true)
      addVeiledning({
        veiledning: {
          ...veiledningInitialState(selectedTilsynsobjekt.id ?? ''),
        },
      })
    }
  }, [
    selectedTilsynsobjekt.id,
    shouldCreateNewVeiledning,
    filteredVeiledninger?.length,
    setDanger,
    setShouldCheckFocus,
    addVeiledning,
  ])

  const onSelectTilsynsobjekt = useCallback((tilsynsobjektId: string) => {
    setSelectedTilsynsobjektId(tilsynsobjektId)
  }, [])

  const renderVeiledninger = () => {
    return (
      <Column spacing={2.5}>
        {filteredVeiledninger?.map((veiledning: IVeiledning, index: number) => {
          return (
            <Row
              style={{ width: '97%' }}
              key={veiledning.id ?? veiledning._links.self.href}
            >
              <VeiledningItem
                tilsynsobjekt={selectedTilsynsobjekt}
                veiledning={veiledning}
                onRemoveVeiledning={onClickRemoveVeiledning}
                danger={danger}
                autoFocus={
                  filteredVeiledninger.length - 1 === index && shouldCheckFocus
                }
              />
            </Row>
          )
        })}

        {isPending && !isPaused && (
          <Row justify="center">
            <LoadingDots />
          </Row>
        )}
      </Column>
    )
  }

  const renderContent = () => {
    if (isLoading && !isOffline) {
      return (
        <Row center>
          <LoadingSpinner title={'Henter veiledninger...'} small />
        </Row>
      )
    }

    return (
      <ContrastBox noBorders backgroundColor="white">
        {renderVeiledninger()}
        <Row justify="center">
          <TextLink leftIcon={<AddCircle />} onClick={onAddVeiledning}>
            Ny veiledning
          </TextLink>
        </Row>
      </ContrastBox>
    )
  }

  return (
    <Column spacing={3} fluid key={2}>
      <InformationBox variant="warning">
        <b>NB!</b> Veiledningen du skriver her er ikke veiledning i forbindelse
        med regelverksbrudd.
      </InformationBox>
      <Row>
        <Column>
          <LabelWithInfo label="Veiledninger" as="h1">
            <Text>Eksempler på veiledning som kan stå i feltet er:</Text>
            <ul>
              {veiledningExamples.map((example: string) => (
                <Text margin={[0, 0, 0, 2]} as="li" key={example}>
                  {example}
                </Text>
              ))}
            </ul>
          </LabelWithInfo>
        </Column>
      </Row>
      <TilsynsobjektMenu
        tilsynsobjekter={tilsynsobjekter ?? []}
        selectedTilsynsobjektId={selectedTilsynsobjektId ?? ''}
        onSelectTilsynsobjekt={onSelectTilsynsobjekt}
      />
      {renderContent()}
      <DeleteModal
        isOpen={
          Boolean(selectedVeiledning?._links.self.href) ||
          typeof selectedVeiledning?.id === 'string'
        }
        onCancel={onCancelRemoveVeiledning}
        onDelete={removeSelectedVeileding}
        title="Er du sikker på at du vil slette veiledningen?"
        confirmText="Ja, jeg vil slette veiledningen"
        cancelText="Avbryt"
      />
    </Column>
  )
}

export { VeiledningSlide }
