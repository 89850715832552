import { IUserState } from './types'

export const userActionsTypes = {
  FETCH_USER_ORGENHET: 'user/FETCH_USER_ORGENHET',
  FETCH_USER_AVDELING_FAIL: 'user/FETCH_USER_AVDELING_FAIL',
  FETCH_USER_AVDELING_OK: 'user/FETCH_USER_AVDELING_OK',
  FETCH_USER_ORGENHET_FAIL: 'user/FETCH_USER_ORGENHET_FAIL',
  FETCH_USER_ORGENHET_OK: 'user/FETCH_USER_ORGENHET_OK',
  FETCH_USER_REGION_FAIL: 'user/FETCH_USER_REGION_FAIL',
  FETCH_USER_REGION_OK: 'user/FETCH_USER_REGION_OK',
  SET_LAST_LOCATION: 'user/SET_LAST_LOCATION',
  SET_USER: 'user/SET_USER',
  GET_USER: 'user/GET_USER',
  UPDATE_TOKEN: 'user/UPDATE_TOKEN',
  USER_EXPIRED: 'user/USER_EXPIRED',
  USER_SIGNED_OUT: 'user/USER_SIGNED_OUT',
}

export const userActions = {
  fetchUserOrgenhet: () => ({
    type: userActionsTypes.FETCH_USER_ORGENHET,
  }),

  setUser: (
    username: string,
    name: string,
    title: string,
    token?: string //TODO: Set as optional as there some places where it is not used
  ) => ({
    type: userActionsTypes.SET_USER,
    username,
    name,
    title,
    token,
  }),
  getUser: () => ({
    type: userActionsTypes.GET_USER,
  }),
  setLastLocation: (lastLocation: string) => ({
    type: userActionsTypes.SET_LAST_LOCATION,
    lastLocation,
  }),
  updateToken: (accessToken: string) => ({
    type: userActionsTypes.UPDATE_TOKEN,
    data: accessToken,
  }),
  userExpired: () => ({
    type: userActionsTypes.USER_EXPIRED,
  }),
  userSignedOut: () => ({
    type: userActionsTypes.USER_SIGNED_OUT,
  }),

  fetchUserAvdelingFail: (err: string) => ({
    type: userActionsTypes.FETCH_USER_AVDELING_FAIL,
    err,
  }),

  fetchUserAvdelingOk: (data: IUserState['avdeling']) => ({
    type: userActionsTypes.FETCH_USER_AVDELING_OK,
    data,
  }),

  fetchUserOrgenhetFail: (err: string) => ({
    type: userActionsTypes.FETCH_USER_ORGENHET_FAIL,
    err,
  }),

  fetchUserOrgenhetOk: (data: IUserState['me']) => ({
    type: userActionsTypes.FETCH_USER_ORGENHET_OK,
    data,
  }),

  fetchUserRegionFail: (err: string) => ({
    type: userActionsTypes.FETCH_USER_REGION_FAIL,
    err,
  }),

  fetchUserRegionOk: (data: IUserState['region']) => ({
    type: userActionsTypes.FETCH_USER_REGION_OK,
    data,
  }),
}
