import { Immutable } from 'immer'
import { v4 as uuidV4 } from 'uuid'
import { IKontrollpunkt } from './types'
import { TidligereKontrollpunkt } from '../tidligere-kontrollpunkter/types'
import { IKvitteringData } from '../../ducks/kvittering/types'
import { getDeepItemById } from '../../common/helpers'

export {
  kontrollpunktInitialState,
  isKontrollpunkterEqual,
  getKontrollpunktById,
}

const kontrollpunktInitialState = (tilsynsobjektId: number) =>
  ({
    tilsynsobjektId,
    id: uuidV4(),
    beskrivelse: '',
    _links: {
      self: {
        href: '',
      },
      observasjoner: {
        href: '',
      },
    },
    observasjoner: [],
    synced: true,
  }) satisfies IKontrollpunkt

const isKontrollpunkterEqual = (
  a: Immutable<IKontrollpunkt>,
  b: Immutable<IKontrollpunkt>
) => {
  const isKontrollpunktIdsUndefined = a.id === undefined || b.id === undefined

  return !isKontrollpunktIdsUndefined && a.id === b.id
}

export const compareSorteringsrekkefoelge = (
  a: IKontrollpunkt | TidligereKontrollpunkt,
  b: IKontrollpunkt | TidligereKontrollpunkt
) => {
  if (a.sorteringsrekkefoelge && b.sorteringsrekkefoelge) {
    return a.sorteringsrekkefoelge - b.sorteringsrekkefoelge
  }
  return 0
}

const getKontrollpunktById = (
  kvittering: IKvitteringData,
  kontrollpunktId: string
) =>
  getDeepItemById<IKontrollpunkt>(
    kvittering,
    ['tilsynsobjekter', 'kontrollpunkter'],
    kontrollpunktId
  )
