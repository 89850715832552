import { BildeAttachMutationOptions } from '../types'
import { kvitteringQueryHelpers } from '../../kvitteringer/queries/helpers'
import { shouldIgnoreError } from '../../../common/query'
import store from '../../../reducers/store'
import { dangerToast } from '../../../common/toast'
import { queryClient } from '../../../api/query-client'
import {
  bildeKeys,
  withPersistedBilde,
  withPersistedObservasjonId,
} from './helpers'
import { bildeApi } from './bilde-api'
import {
  getObservasjonBildeIdsFromKvittering,
  updateObservasjonBildeIdsFromKvittering,
} from '../cache-helpers'

/**
 * Optimistically updates bildeIds in the cache.
 *
 * @returns Previous bildeIds (Context that
 * is passed to onError).
 */
const onMutate: (
  updateKvitteringBilder: (bildeIds: string[], bildeId: string) => string[],
  updateObservasjonBilder: (bildeIds: string[], bildeId: string) => string[]
) => BildeAttachMutationOptions['onMutate'] =
  (updateKvitteringBilder, updateObservasjonBilder) =>
  async ({ kvitteringId, observasjonId, bildeId }) => {
    await kvitteringQueryHelpers.cancelKvitteringQuery(kvitteringId)

    const kvittering = kvitteringQueryHelpers.getKvitteringCache(kvitteringId)
    const previousKvitteringBilder = kvittering?.bildeIds ?? []
    const previousObservasjonBilder =
      getObservasjonBildeIdsFromKvittering(kvittering, observasjonId) ?? []

    kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
      draft.bildeIds = updateKvitteringBilder(draft.bildeIds, bildeId)
      updateObservasjonBildeIdsFromKvittering(
        draft,
        observasjonId,
        (bildeIds) => updateObservasjonBilder(bildeIds, bildeId)
      )
    })

    return { previousKvitteringBilder, previousObservasjonBilder }
  }

/** Displays error toast and reverts cache to previous bildeIds. */
const onError: BildeAttachMutationOptions['onError'] = (
  error,
  { kvitteringId, observasjonId },
  context
) => {
  if (shouldIgnoreError(error)) {
    return
  }

  store.dispatch(
    dangerToast('Det har skjedd en feil med bildene. Kunne ikke lagre.')
  )

  kvitteringQueryHelpers.updateKvitteringCache(kvitteringId, (draft) => {
    draft.bildeIds = (context?.previousKvitteringBilder as string[]) ?? []
    updateObservasjonBildeIdsFromKvittering(
      draft,
      observasjonId,
      (bildeIds) => (context?.previousObservasjonBilder as string[]) ?? bildeIds
    )
  })
}

// Leaving it here for now, in case we need it later
/** Refetches kvittering and bildeMetadatas either on success or error. */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onSettled: BildeAttachMutationOptions['onSettled'] = (
  _,
  __,
  { kvitteringId }
) => kvitteringQueryHelpers.invalidateKvittering(kvitteringId)

// Attach bilde to observasjon
queryClient.setMutationDefaults(bildeKeys.attachToObservasjonBase(), {
  mutationFn: withPersistedBilde(
    withPersistedObservasjonId(bildeApi.attachToObservasjon)
  ),
  onMutate: onMutate(
    (bildeIds, bildeId) => bildeIds.filter((id) => id !== bildeId),
    (bildeIds, bildeId) => [...bildeIds, bildeId]
  ),
  onError: onError,
} satisfies BildeAttachMutationOptions)

// Remove bilde from observasjon
queryClient.setMutationDefaults(bildeKeys.removeFromObservasjonBase(), {
  mutationFn: withPersistedBilde(
    withPersistedObservasjonId(async (variables) => {
      return bildeApi.removeFromObservasjon(variables)
    })
  ),
  onMutate: onMutate(
    (bildeIds, bildeId) => [...bildeIds, bildeId],
    (bildeIds, bildeId) => bildeIds.filter((id) => id !== bildeId)
  ),
  onError: onError,
} satisfies BildeAttachMutationOptions)
