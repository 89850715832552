/**
 * Format phone numbers
 *
 * @category utils
 * @param {string} phoneNumber
 * @returns {string} ### ## ###
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  let phone = phoneNumber.replace(/\D/g, '')
  const match = phone.match(/^(\d{0,3})(\d{0,2})(\d{0,3})$/)
  if (match) {
    phone = `${match[1]}${match[2] ? ' ' : ''}${match[2]}${
      match[3] ? ' ' : ''
    }${match[3]}`
  }

  return phone
}
