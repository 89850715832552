// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModalWrapper .hjemmel-modal .modal-body {
  padding: 0;
  overflow: auto;
  font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/modals/hjemmel-modal/style.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,cAAc;EACd,eAAe;AACjB","sourcesContent":[".ModalWrapper .hjemmel-modal .modal-body {\n  padding: 0;\n  overflow: auto;\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
