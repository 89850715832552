import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { useTypedSelector } from '../../../common/custom-hooks'
import { Button, ButtonGroup, Column, Row } from '@mattilsynet/mt-ui'
import Camera from '../../../components/camera'
import { GalleryModal } from '../../../components/image-gallery-modal'
import {
  offlineOnHentBilderFraGalleri,
  useSingleToast,
} from '../../../common/toast'
import { IStoreState } from '../../../reducers/types'
import {
  useAttachBildeToObservasjon,
  useUploadBildeToObservasjon,
} from '../hooks/bilde-query-hooks'
import { useKvitteringId } from '../../../common/kvittering-context'
import { userSelectors } from '../../../ducks/user/selectors'
import { BilderEdit } from './bilder-edit'
import {
  useBildeMetadatasFromIds,
  useObservasjonErrorBilder,
} from '../hooks/metadata-query-hooks'
import { distinctBy } from '../../../common/helpers'

interface IBilderProps {
  bildeIds: string[]
  observasjonId: number
  kontrollpunktTitle: string
}

export const ObservasjonBilder = ({
  bildeIds,
  observasjonId,
  kontrollpunktTitle,
}: IBilderProps) => {
  const singleToast = useSingleToast()

  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)
  const isDesktop = useTypedSelector((state) => state.ui.isDesktop)
  const kvitteringId = useKvitteringId()
  const currentUsername = useTypedSelector(userSelectors.getAuthUsername)

  const errorBildeIds = useObservasjonErrorBilder(observasjonId).map(
    (bilde) => bilde.id
  )
  const { data: bildeMetadataList } = useBildeMetadatasFromIds(
    kvitteringId,
    distinctBy([...bildeIds, ...errorBildeIds], (bildeId) => bildeId)
  )
  const allBildeIds = useMemo(
    () => bildeMetadataList.map((bilde) => bilde.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bildeMetadataList.length]
  )

  const [shouldScrollIntoView, setShouldScrollIntoView] = useState(false)
  const bildeIdsRef = useRef(bildeIds)
  useEffect(() => {
    if (!shouldScrollIntoView && bildeIds.length > bildeIdsRef.current.length) {
      setShouldScrollIntoView(true)
      bildeIdsRef.current = bildeIds
    }
  }, [bildeIds, shouldScrollIntoView])

  const { mutate: uploadBilde } = useUploadBildeToObservasjon(
    kvitteringId,
    observasjonId,
    currentUsername!
  )
  const { mutate: attachBildeToObservasjon } =
    useAttachBildeToObservasjon(kvitteringId)

  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false)

  const cameraRef = useRef<HTMLInputElement>()
  const openCamera = () => {
    if (!cameraRef.current) return

    cameraRef.current.value = ''
    cameraRef.current.click()
  }

  const pictureTaken = (bilder: File[]) => {
    for (const bilde of bilder) {
      uploadBilde({ bildeId: uuidV4(), bilde })
    }
  }

  const galleryAttachToObservasjon = useCallback(
    (bildeIds: string[]) => {
      bildeIds.forEach((bildeId) =>
        attachBildeToObservasjon({ bildeId, observasjonId })
      )

      setIsGalleryModalOpen(false)
    },
    [attachBildeToObservasjon, observasjonId]
  )

  const openGallery = useCallback(() => {
    if (isOffline) {
      singleToast.showToast(offlineOnHentBilderFraGalleri())
      return
    }
    setIsGalleryModalOpen(true)
  }, [isOffline, singleToast])

  const takePicture = () => {
    openCamera()
  }

  return (
    <Column spacing={1} padding={[0, 0, 3, 0]}>
      <Row style={{ width: '97%', display: 'block' }} padding={[0, 0, 1, 0]}>
        <ButtonGroup secondary flex={1}>
          <Button secondary onClick={openGallery}>
            Hent bilde fra galleriet
          </Button>
          <Button onClick={takePicture} secondary>
            {isDesktop ? 'Last opp bilde fra pc' : 'Ta bilde'}
          </Button>
        </ButtonGroup>
      </Row>

      <BilderEdit
        bildeIds={allBildeIds}
        kvitteringId={kvitteringId}
        observasjonId={observasjonId}
      />

      <Camera cameraRef={cameraRef} pictureTakenAction={pictureTaken} />

      <GalleryModal
        onClose={galleryAttachToObservasjon}
        isOpen={isGalleryModalOpen}
        title={`Knytt bilder til ${kontrollpunktTitle}`}
      />
    </Column>
  )
}
