import { ofType, combineEpics, StateObservable } from 'redux-observable'
import { AnyAction } from 'redux'
import {
  withLatestFrom,
  mergeMap,
  map,
  catchError,
  filter,
  takeUntil,
} from 'rxjs/operators'
import { of, Observable, concat, merge, EMPTY } from 'rxjs'

import { tilsynsobjektActions, tilsynsobjektActionTypes } from './actions'

import { ITilsynsobjekt } from './types'
import { IStoreState } from '../../../reducers/types'
import { ICommonApi } from '../../../api'

import { dangerToast } from '../../../common/toast'
import { kvitteringActions } from '../actions'
import { ENDPOINT } from '../../../constants'

export const createTilsynsobjektEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(tilsynsobjektActionTypes.CREATE_TILSYNSOBJEKT),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      filter(
        ([{ tilsyn }, { kvittering }]) =>
          !kvittering.selectedKvittering.data.tilsynsobjekter.some(
            (t: ITilsynsobjekt) => t.idstring === tilsyn.idstring
          ) || !!tilsyn.midlertidig
      ),
      mergeMap(([{ tilsyn, orgNr }, state]) => {
        const URL = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${state.kvittering.selectedKvittering.data.id}/tilsynsobjekter`
        const tilsynsobjekt = {
          tilsynsobjektId: tilsyn.idstring,
          navn: tilsyn.navn,
          aktivitetsBeskrivelse: tilsyn.aktivitetsBeskrivelse,
          aktivitetsId: tilsyn.aktivitetsId,
          midlertidig: !!tilsyn.midlertidig,
          virksomhetsNummer: tilsyn.virksomhetsNummer,
          virksomhetsNavn: tilsyn.virksomhetsnavn,
        }

        const midlertidigTilsynsobjektFromAnotherVirksomhet =
          state.kvittering.selectedKvittering.data.tilsynsobjekter.find(
            (t) =>
              !!t.midlertidig && t.virksomhetsNavn !== tilsyn.virksomhetsNavn
          )

        const createTilsynsobjektObservable = of(state).pipe(
          filter(() => !!URL),
          commonApi.post(URL, state, tilsynsobjekt),
          takeUntil(
            action$.pipe(ofType(tilsynsobjektActionTypes.DELETE_TILSYNSOBJEKT))
          ),
          map((response) => {
            const location = response.xhr.getResponseHeader('location')
            const newTilsynsobjekt = {
              ...tilsyn,
              kontrollpunkter: [],
              selected: true,
              isSavedLocally: false,
              _links: {
                self: {
                  href: location,
                },
              },
            }
            return newTilsynsobjekt
          }),
          mergeMap((tilsynsobjekt) => {
            return concat(
              of(tilsynsobjektActions.fetchTilsynsobjekter()),
              of(
                tilsynsobjektActions.createTilsynsobjektSuccess(
                  tilsynsobjekt,
                  orgNr
                )
              )
            )
          }),
          catchError((err) => {
            return merge(
              of(
                tilsynsobjektActions.createTilsynsobjektFailed(
                  tilsynsobjekt,
                  err.message
                )
              ),
              of(dangerToast())
            )
          })
        )

        if (midlertidigTilsynsobjektFromAnotherVirksomhet) {
          return of(state).pipe(
            commonApi.delete(
              midlertidigTilsynsobjektFromAnotherVirksomhet?._links?.self?.href,
              state
            ),
            map(() =>
              of(
                tilsynsobjektActions.deleteTilsynsObjektSuccess(
                  midlertidigTilsynsobjektFromAnotherVirksomhet
                )
              )
            ),
            mergeMap(() => {
              return createTilsynsobjektObservable
            }),
            catchError((err) =>
              of(kvitteringActions.fetchKvitteringerFailed(err.message))
            )
          )
        }
        return createTilsynsobjektObservable
      })
    )

export const deleteTilsynsobjektEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(tilsynsobjektActionTypes.DELETE_TILSYNSOBJEKT),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      filter(([{ tilsyn }]) => tilsyn?.hasOwnProperty('_links')),
      mergeMap(([{ tilsyn }, state]) => {
        const URL = `${ENDPOINT.TILSYNSKVITTERING}/tilsynsobjekter/${tilsyn.id}`
        return of(state).pipe(
          commonApi.delete(URL, state),
          takeUntil(
            action$.pipe(ofType(tilsynsobjektActionTypes.CREATE_TILSYNSOBJEKT))
          ),
          mergeMap(() => {
            return concat(
              of(tilsynsobjektActions.fetchTilsynsobjekter()),
              of(tilsynsobjektActions.deleteTilsynsObjektSuccess(tilsyn))
            )
          }),
          catchError((err) => {
            if (err.status === 404) {
              return concat(
                of(tilsynsobjektActions.fetchTilsynsobjekter()),
                of(tilsynsobjektActions.deleteTilsynsObjektSuccess(tilsyn))
              )
            } else {
              return concat(
                of(tilsynsobjektActions.deleteTilsynsObjektFailed(err.message)),
                of(dangerToast())
              )
            }
          })
        )
      })
    )

export const clearSelectedTilsynsobjekterEpic =
  () =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(tilsynsobjektActionTypes.CLEAR_SELECTED_TILSYNSOBJEKTER),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      mergeMap(([, state]) => {
        const tilsynsobjekter =
          state.kvittering.selectedKvittering.data.tilsynsobjekter
        return of(state).pipe(
          mergeMap(() => {
            return concat(
              tilsynsobjekter.length > 0
                ? tilsynsobjekter.map((tilsynsobjekt) =>
                    tilsynsobjektActions.deleteTilsynsObjekt(
                      tilsynsobjekt,
                      true
                    )
                  )
                : EMPTY
            )
          }),
          catchError((err) => {
            return merge(
              of(tilsynsobjektActions.deleteTilsynsObjektFailed(err.message)),
              of(dangerToast())
            )
          })
        )
      })
    )

export const fetchTilsynsobjekterEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(tilsynsobjektActionTypes.FETCH_TILSYNSOBJEKTER),
      withLatestFrom(state$),
      filter(() => window.navigator.onLine),
      filter(
        ([, { kvittering }]) =>
          kvittering.selectedKvittering.data._links?.tilsynsobjekter?.href
      ),
      mergeMap(([, state]) => {
        const kvitteringId = state.kvittering.selectedKvittering.data.id
        const url = `${ENDPOINT.TILSYNSKVITTERING}/tilsynskvitteringer/${kvitteringId}/tilsynsobjekter?expand=kontrollpunkter,observasjoner,bildeIds&sort.direction=ASC&sort.property=opprettet`
        return of(state).pipe(
          commonApi.get(url, state),
          map((resp: any) =>
            resp._embedded ? resp._embedded.tilsynsobjekter : []
          ),

          mergeMap((tilsynsobjekter: any[]) => {
            return of(state).pipe(
              mergeMap(() => {
                return concat(
                  of(
                    tilsynsobjektActions.fetchTilsynsobjekterSuccess(
                      tilsynsobjekter
                    )
                  )
                )
              })
            )
          }),
          catchError((err) =>
            of(tilsynsobjektActions.fetchTilsynsobjekterFailed(err))
          )
        )
      })
    )

export const updateTilsynsobjektEpic =
  (commonApi: ICommonApi) =>
  (action$: Observable<AnyAction>, state$: StateObservable<IStoreState>) =>
    action$.pipe(
      ofType(tilsynsobjektActionTypes.UPDATE_TILSYNSOBJEKT),
      withLatestFrom(state$),
      mergeMap(([{ tilsynsobjekt }, state]) => {
        const { id, aktivitetsId, virksomhetsNavn, virksomhetsNummer, navn } =
          tilsynsobjekt
        return of(state).pipe(
          commonApi.put(
            `${ENDPOINT.TILSYNSKVITTERING}/midlertidigtilsynsobjekter/${id}`,
            state,
            {
              aktivitetsId: aktivitetsId,
              virksomhetsNavn: virksomhetsNavn,
              virksomhetsNummer: virksomhetsNummer,
              navn: navn,
            }
          ),
          mergeMap(() => {
            return of(
              tilsynsobjektActions.updateTilsynsobjektSuccess(tilsynsobjekt)
            )
          }),
          catchError((err) =>
            of(tilsynsobjektActions.updateTilsynsobjektFailed(err.message))
          )
        )
      })
    )

export default (commonApi: ICommonApi) =>
  combineEpics(
    fetchTilsynsobjekterEpic(commonApi),
    createTilsynsobjektEpic(commonApi) as any,
    deleteTilsynsobjektEpic(commonApi) as any,
    clearSelectedTilsynsobjekterEpic() as any,
    updateTilsynsobjektEpic(commonApi) as any
  )
