import React, { ReactNode } from 'react'
import {
  Row,
  RowItem,
  IconButton,
  ArrowIcon,
  Grid,
  GridItem,
} from '@mattilsynet/mt-ui'
import { SliderDots } from '../slider-dots'
import './style.css'

interface IBottomNavigatorProps {
  slides: ReactNode[]
  currentSlide: number
  goToPrevious: () => void
  goToNext: () => void
  goToSlide: (slide: number) => void
}

export const BottomNavigator = ({
  slides,
  currentSlide,
  goToPrevious,
  goToNext,
  goToSlide,
}: IBottomNavigatorProps) => {
  const showPreviousButton = currentSlide !== 0
  const showNextButton = currentSlide !== slides.length - 1

  let nextSlideText
  let previousSlideText

  const getIconClass = (hide?: boolean) => `focus${hide ? ' hide' : ''}`

  if (showPreviousButton) {
    previousSlideText = slides[currentSlide - 1]?.['key'] || ''
  }

  if (showNextButton) {
    nextSlideText = slides[currentSlide + 1]?.['key'] || ''
  }

  return (
    <div className="bottom-navigator">
      <Grid className="navigator-grid">
        <GridItem xl={[3, -3]} lg={[3, -3]} md={[1, -1]} sm={[1, -1]}>
          <Row justify="center" spacing={2} align="center">
            <RowItem justify="center">
              <IconButton
                onClick={goToPrevious}
                icon={<ArrowIcon />}
                className={getIconClass(!showPreviousButton)}
                ariaLabel={previousSlideText}
                iconBefore
              />
            </RowItem>
            <RowItem className="dots">
              <SliderDots
                slides={slides}
                currentSlide={currentSlide}
                goToSlide={goToSlide}
              />
            </RowItem>
            <RowItem justify="center">
              <IconButton
                onClick={goToNext}
                icon={<ArrowIcon rotateDirection="RIGHT" />}
                className={getIconClass(!showNextButton)}
                ariaLabel={nextSlideText}
              />
            </RowItem>
          </Row>
        </GridItem>
      </Grid>
    </div>
  )
}
