import React, { useMemo, useState } from 'react'
import { useKvitteringId } from '../../../common/kvittering-context'

import { Button, Column, LoadingDots, Row } from '@mattilsynet/mt-ui'
import { KontrollpunktEdit } from './kontrollpunkt-edit'

import {
  useAddKontrollpunkt,
  useUpdateSorteringsrekkefoelge,
} from '../hooks/query-hooks'
import { kontrollpunktInitialState } from '../helpers'

import { IKontrollpunkt } from '../types'

interface KontrollpunktListEditProps {
  kontrollpunkter: IKontrollpunkt[]
  tilsynsobjektId: number
}

export const KontrollpunktListEdit = ({
  kontrollpunkter,
  tilsynsobjektId,
}: KontrollpunktListEditProps) => {
  const [isNew, setIsNew] = useState(false)
  const [isValidating, setIsValidating] = useState(true)
  const [shouldCheckFocus, setShouldCheckFocus] = useState(false)

  const kvitteringId = useKvitteringId()

  const { mutate: updateSorteringsrekkefoelge } =
    useUpdateSorteringsrekkefoelge(kvitteringId)

  const {
    mutate: addKontrollpunkt,
    isPending,
    isPaused,
  } = useAddKontrollpunkt(kvitteringId)

  const shouldCreateNewKontrollpunkt = useMemo(
    () =>
      kontrollpunkter &&
      (kontrollpunkter.length === 0 ||
        kontrollpunkter[kontrollpunkter.length - 1].beskrivelse.length > 0),
    [kontrollpunkter]
  )

  const onAddKontrollpunkt = () => {
    if (!shouldCreateNewKontrollpunkt) {
      setIsNew(false)
      setIsValidating(true)
      return
    }

    setShouldCheckFocus(true)
    setIsNew(true)
    setIsValidating(false)

    const lastKontrollpunkt = kontrollpunkter.at(-1)
    const lastSorteringsrekkefoelge =
      lastKontrollpunkt?.sorteringsrekkefoelge ?? Number(lastKontrollpunkt?.id)

    const kontrollpunkt = {
      ...kontrollpunktInitialState(tilsynsobjektId),
      sorteringsrekkefoelge: lastSorteringsrekkefoelge + 1,
    }

    addKontrollpunkt({
      kontrollpunkt,
    })
  }

  const onMove = async (fromIndex: number | undefined, direction: -1 | 1) => {
    if (fromIndex === undefined || !kontrollpunkter) {
      return
    }

    const toIndex = fromIndex + direction
    if (toIndex < 0 || toIndex >= kontrollpunkter.length) {
      return
    }

    const fromSorteringsrekkefoelge =
      kontrollpunkter[fromIndex].sorteringsrekkefoelge
    const toSorteringsrekkefoelge =
      kontrollpunkter[toIndex].sorteringsrekkefoelge

    updateSorteringsrekkefoelge({
      kontrollpunkt: {
        ...kontrollpunkter[fromIndex],
        sorteringsrekkefoelge: toSorteringsrekkefoelge,
      },
    })
    updateSorteringsrekkefoelge({
      kontrollpunkt: {
        ...kontrollpunkter[toIndex],
        sorteringsrekkefoelge: fromSorteringsrekkefoelge,
      },
    })
  }

  return (
    <Column spacing={1}>
      {kontrollpunkter?.map((kontrollpunkt, index) => {
        return (
          <KontrollpunktEdit
            key={kontrollpunkt.id}
            sequenceNumber={index + 1}
            kontrollpunkt={kontrollpunkt}
            shouldValidate={
              !(kontrollpunkter.length - 1 === index && isNew) || isValidating
            }
            autoFocus={kontrollpunkter.length - 1 === index && shouldCheckFocus}
            isLastItem={kontrollpunkter.length - 1 === index}
            onMoveUp={() => onMove(index, -1)}
            onMoveDown={() => onMove(index, 1)}
          />
        )
      })}

      {isPending && !isPaused && (
        <Row justify="center">
          <LoadingDots />
        </Row>
      )}
      <Row justify="center">
        <Column spacing={1}>
          <Button secondary onClick={onAddKontrollpunkt}>
            + Legg til kontrollpunkt
          </Button>
        </Column>
      </Row>
    </Column>
  )
}
