import React, { ReactNode, useState } from 'react'
import { IconButton, RoundIndicator, Text } from '@mattilsynet/mt-ui'
import './style.css'

interface ILabelWithInfoProps {
  label: string
  children: ReactNode
  as?: keyof JSX.IntrinsicElements
}

export const LabelWithInfo = ({
  label,
  children,
  as = 'span',
}: ILabelWithInfoProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className="label-with-info__main">
        <Text as={as} weight="bold">
          {label}
        </Text>

        <IconButton
          className={`label-with-info__icon-btn ${isOpen ? 'open' : ''}`}
          icon={
            <RoundIndicator backgroundColor="var(--info-icon-color)">
              <Text weight="bold">?</Text>
            </RoundIndicator>
          }
          onClick={() => setIsOpen((isOpen) => !isOpen)}
        />
      </div>

      <div className={`label-with-info__info-box ${isOpen ? 'open' : ''}`}>
        {children}
      </div>
    </>
  )
}
