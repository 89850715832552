import React, { FocusEvent, RefObject } from 'react'
import { UserMenu as UserMenuTkUi, TextLink, Row } from '@mattilsynet/mt-ui'
import { IUserState } from '../../ducks/user/types'
import './style.css'

export interface IUserMenuProps {
  isOpen: boolean
  toggleMenu: () => void
  goToLogout: () => void
  user: IUserState['authUser'] | IUserState['me']
  userAvdeling: IUserState['avdeling']
  userMenuRef: RefObject<HTMLDivElement>
  onCloseMenuOnBlur: (event: FocusEvent<HTMLDivElement>) => void
  goToSettings: () => void
  goToAbout: () => void
  calculateFotoPath: string
}

export const UserMenuView: React.FC<IUserMenuProps> = ({
  goToAbout,
  goToLogout,
  isOpen,
  onCloseMenuOnBlur,
  toggleMenu,
  user,
  userAvdeling,
  userMenuRef,
  goToSettings,
  calculateFotoPath,
}: any) => {
  return (
    <Row
      className="user-menu-container"
      onBlur={onCloseMenuOnBlur}
      ref={userMenuRef}
    >
      <UserMenuTkUi
        isOpen={isOpen}
        onClick={toggleMenu}
        name={user.name || user.username || ''}
        title={user.title || ''}
        division={userAvdeling.name || ''}
      >
        <div className="link-group">
          <TextLink href={calculateFotoPath} linkOutside>
            Gå til Fotoløsningen
          </TextLink>
          <TextLink href="https://makuba.mattilsynet.no/" linkOutside>
            Finn hjelp i MAKUBA
          </TextLink>
          <TextLink onClick={goToSettings}>Personlige innstillinger</TextLink>
          <TextLink onClick={goToAbout}>Om applikasjonen</TextLink>
        </div>
        <TextLink onClick={goToLogout}>Logg ut</TextLink>
      </UserMenuTkUi>
    </Row>
  )
}
