import { mergeKeyWith } from '../../../common/query'

export { tilsynsobjektKeys }

const getTilsynsobjektKeys = () => {
  const keys = {
    all: [{ entity: 'tilsynsobjekter' }] as const,

    /** Used as defaultMutation key or filter key */
    add: () => mergeKeyWith(keys.all, { action: 'add' }),

    /** Used as defaultMutation key or filter key */
    updateBase: () => mergeKeyWith(keys.all, { action: 'update' }),
    /** Used as useMutation key */
    update: (kvitteringId: string) =>
      mergeKeyWith(keys.updateBase(), { kvitteringId }),

    /** Used as defaultMutation key or filter key */
    removeBase: () => mergeKeyWith(keys.all, { action: 'remove' }),
    /** Used as useMutation key */
    remove: (kvitteringId: string) =>
      mergeKeyWith(keys.removeBase(), { kvitteringId }),
  }

  return keys
}

const tilsynsobjektKeys = getTilsynsobjektKeys()

export type TilsynsobjektKeys = typeof tilsynsobjektKeys
