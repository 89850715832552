import {
  ErrorBox,
  LoadingSpinner,
  Row,
  Text,
  SelectList,
} from '@mattilsynet/mt-ui'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../common/custom-hooks'
import {
  hentKvitteringBegrunnelserSelectors,
  hentKvitteringBegrunnelserActions,
} from '../../ducks/hent-kvittering-begrunnelse/actions'
import { InformationBox } from '../information-box'

interface IDataListItem {
  value: string
  label: string
}

interface IHentFraKollegaBegrunnelserViewProps {
  onSelect: (begrunnelse: string) => void
  selectedBegrunnelse: string | undefined
}

export const HentFraKollegaBegrunnelserView = ({
  onSelect,
  selectedBegrunnelse,
}: IHentFraKollegaBegrunnelserViewProps) => {
  const dispatch = useDispatch()

  const loadingStatus = useTypedSelector(
    hentKvitteringBegrunnelserSelectors.getLoadingStatus
  )
  const begrunnelser = useTypedSelector(
    hentKvitteringBegrunnelserSelectors.getBegrunnelser
  )
  const dataList = useMemo<IDataListItem[]>(
    () =>
      begrunnelser.map((begrunnelse) => {
        return {
          value: begrunnelse.typeBegrunnelse,
          label: begrunnelse.beskrivelse,
        }
      }),
    [begrunnelser]
  )

  const fetchData = useCallback(
    () =>
      dispatch(hentKvitteringBegrunnelserActions.fetchKvitteringBegrunnelser()),
    [dispatch]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (loadingStatus?.loading && !loadingStatus?.loaded) {
    return <LoadingSpinner title="" />
  }

  if (loadingStatus?.error) {
    return (
      <ErrorBox
        errorText="Kunne ikke hente overtagelse begrunnelser"
        errorAction={fetchData}
        errorActionText="Prøv på nytt"
      />
    )
  }

  return (
    <div>
      <InformationBox>
        <InformationBox.Header>Krav om tjenstlig behov</InformationBox.Header>

        <Row>
          <Text whiteSpace="pre-wrap" className="kravText">
            {`Du skal kun hente tilsynskvitteringer fra kollega når det er nødvendig for at du skal få utført jobben.

Inneholder tilsynskvitteringer taushetsbelagte opplysninger skal ikke disse gjøres kjent for flere ansatte i Mattilsynet enn det som trengs for en hensiktsmessig arbeids- og arkivordning. Se forvaltningsloven § 13b nr 3.

Vedkommende du henter tilsynskvittering fra vil bli informert.`}
          </Text>
        </Row>
      </InformationBox>
      <SelectList
        dataList={dataList}
        onClick={(begrunnelse) => onSelect(begrunnelse)}
        selected={[String(selectedBegrunnelse)]}
      />
    </div>
  )
}
